import { css } from '@emotion/core';
import { MenuItem, Select } from '@material-ui/core';
import * as React from 'react';

import { JobStatusFilter, jobStatusFilterLabels } from '../state';

const styles = css`
    background: white;
    margin: 0 0 0 15px;

    .MuiOutlinedInput-input {
        padding-top: 9px;
        padding-bottom: 9px;
        background: white;
    }
`;

interface JobsStatusFiltersProps {
    selectedFilter: JobStatusFilter;
    onSelectFilter: (filter: JobStatusFilter) => void;
}

export const JobsStatusFilters: React.FC<JobsStatusFiltersProps> = (props) => {
    const { selectedFilter } = props;

    const handleChange = (event: React.ChangeEvent<{ value: JobStatusFilter }>) => {
        props.onSelectFilter(event.target.value);
    };

    const menuItems = ([
        'active',
        'paused',
        'archived',
        'activeOrPaused',
        'pausedOrArchived',
        'all'
    ] as JobStatusFilter[]).map((filter) => (
        <MenuItem key={filter} value={filter}>
            {jobStatusFilterLabels.get(filter)}
        </MenuItem>
    ));
    return (
        <Select css={styles} value={selectedFilter} onChange={handleChange} variant="outlined">
            {menuItems}
        </Select>
    );
};
