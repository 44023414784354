import { css } from '@emotion/core';
import { Paper } from '@material-ui/core';
import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { ClientForm } from './client-form';
import { ClientPageHeader } from './client-page-header';

const styles = css`
    .client-settings {
        margin: 0 auto;
        flex: 1 1;
        padding: 40px;
        overflow-y: auto;

        .client-settings-section {
            margin: 0 auto;
            min-width: 768px;
            max-width: 1024px;
            margin-bottom: 30px;
            box-shadow: none !important;

            .main-card {
                padding: 1px 30px;
            }
        }
    }
`;

interface OwnProps {
    clientId: string;
}

export const ClientSettings: React.FC<OwnProps> = (props) => {
    const { clientId } = props;

    return (
        <DocumentTitle title="Settings">
            <div id="container">
                <ClientPageHeader clientId={clientId} actions={[]} activeTab="Settings" />
                <div id="content" className="flex-fill" css={styles}>
                    <div className="client-settings">
                        <div className="client-settings-section">
                            <Paper className="main-card">
                                <h1 className="secondary">Edit Client</h1>
                                <ClientForm clientId={clientId} />
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};
