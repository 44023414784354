import { css } from '@emotion/core';
import { Tab, Tabs, Theme, useTheme } from '@material-ui/core';
import React from 'react';

import { diversityOptToString, visaOptToString } from 'shared/models/job';

import { secondaryTextColor } from '../common/css-variables';
import { experienceText } from '../common/job';
import { timeMonthDate } from '../common/timestamp';
import { Job } from '../graphql/queries/person';
import { Avatar } from '../sfc/avatar';

const styles = (theme: Theme) => css`
    .tabs {
        flex: 0 0 auto;
        border-top: thin solid #e0e0e0;
        border-bottom: thin solid #e0e0e0;

        .MuiTab-wrapper {
            font-size: 0.78rem;
            line-height: 1.5;
        }

        .MuiTab-root,
        .MuiTabs-root {
            min-height: 40px;
        }
    }

    .job-info-row {
        padding: 10px 5px;
        border-bottom: thin solid ${theme.palette.divider};

        &:hover {
            background: ${theme.palette.action.hover};
        }

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }

        &.multiple {
            display: flex;

            .col {
                margin: 0 10px;
                flex: 1 1 auto;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .heading {
            font-size: 11px;
            text-transform: uppercase;
            font-weight: 500;
            color: ${secondaryTextColor};
            margin-bottom: 7px;
            opacity: 0.7;
        }

        .value {
            font-size: 14px;
        }
    }

    .job-phonescreen-notes {
        transform: scale(0.85);
        transform-origin: top left;
        width: 118%;
        overflow: hidden;

        .toggles {
            display: flex;
            justify-content: flex-end;
            margin: 10px;

            .toggle-title {
                text-transform: uppercase;
                font-size: 12px;
                margin-right: 10px;
                padding-right: 10px;
                border-right: thin solid #e0e0e0;
                opacity: 0.7;
                cursor: pointer;

                &:last-child {
                    border-right: none;
                    margin-right: 0;
                    padding-right: 0;
                }

                &.active {
                    font-weight: 600;
                    opacity: 1;
                }
            }
        }
    }
`;

const noteStyles = css`
    padding: 10px 24px 0;
    border: none;

    .note-body {
        border-bottom: thin solid #e0e0e0;
        margin-top: 15px;
        padding-bottom: 20px;
    }

    &:last-child {
        .note-body {
            border-bottom: none;
            margin-bottom: 0;
        }
    }
`;

export const JobDetails: React.FC<{ job: Job }> = ({ job }) => {
    const theme = useTheme();
    const [selectedTab, setSelectedTab] = React.useState<'notes' | 'details'>('details');
    const [jobNotesOnly, setJobNotesOnly] = React.useState(true);

    const handleTabChange = (_1: any, tab: 'notes' | 'details') => {
        setSelectedTab(tab);
    };

    const handleToggleJobNotesOnly = () => setJobNotesOnly(!jobNotesOnly);

    const tabs = job.client.notes ? (
        <div className="tabs">
            <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
                <Tab label="Details" value="details" />
                <Tab label="Notes" value="notes" />
            </Tabs>
        </div>
    ) : null;

    let content;

    if (selectedTab === 'details') {
        content = (
            <>
                <div className="job-info-row">
                    <div className="heading">Client Placements</div>
                    <div className="value">{job.client.hiredCandidates.aggregate.count}</div>
                </div>
                <div className="job-info-row multiple">
                    <div className="col">
                        <div className="heading">Placement Fees</div>
                        <div className="value">{job.placementFees}</div>
                    </div>
                    <div className="col">
                        <div className="heading">Recruiter Percentage</div>
                        <div className="value">{job.recruiterPercentage}</div>
                    </div>
                    <div className="col">
                        <div className="heading">AM Percentage</div>
                        <div className="value">{job.accountManagerPercentage}</div>
                    </div>
                </div>
                <div className="job-info-row">
                    <div className="heading">One Line Description</div>
                    <div className="value">{job.description.summary}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Keywords</div>
                    <div className="value">{job.description.keywords?.join(', ') ?? ''}</div>
                </div>{' '}
                <div className="job-info-row">
                    <div className="heading">Headcount</div>
                    <div className="value">{job.headcount}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Diversity</div>
                    <div className="value">{diversityOptToString(job.description.diversity)}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Visa</div>
                    <div className="value">{job.description.visa.map(visaOptToString).join(', ')}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Location</div>
                    <div className="value">{job.description.location}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Compensation</div>
                    <div className="value">{job.description.compensation}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Experience</div>
                    <div className="value">{experienceText(job)}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Technical Skills</div>
                    <div className="value">{job.description.skills}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Company Info</div>
                    <div className="value">
                        <span dangerouslySetInnerHTML={{ __html: job.client.description }} />
                    </div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Role Description</div>
                    <div className="value">
                        <span dangerouslySetInnerHTML={{ __html: job.description.description }} />
                    </div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Memorable Selling Point</div>
                    <div className="value">
                        <span dangerouslySetInnerHTML={{ __html: job.client.salesPitch }} />
                    </div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Hiring Manager</div>
                    <div className="value">{job.description.hiringManager}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Company HQ</div>
                    <div className="value">{job.client.hq}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Founders</div>
                    <div className="value">{job.client.founders}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Team Size</div>
                    <div className="value">{job.client.teamSize}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Founding Date</div>
                    <div className="value">{job.client.foundedDate}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Funding</div>
                    <div className="value">{job.client.funding}</div>
                </div>
                <div className="job-info-row">
                    <div className="heading">Interview Prep</div>
                    <div className="value">
                        <span dangerouslySetInnerHTML={{ __html: job.description.interviewPrep }} />
                    </div>
                </div>
            </>
        );
    } else {
        const notes = job.client.notes
            .filter((n) => !jobNotesOnly || !n.note.context.jobId || n.note.context.jobId === job.id)
            .map(({ note }) => {
                const body =
                    note.format === 'html' ? (
                        <div dangerouslySetInnerHTML={{ __html: note.content }} />
                    ) : (
                        <div className="note-plaintext">{note.content}</div>
                    );
                return (
                    <div key={note.id} css={noteStyles} className="note">
                        <div className="note-header-bar">
                            <div className="note-header flex-columns-container">
                                <Avatar entity={note.user} />
                                <div className="note-header-text flex-rows-container">
                                    <div>
                                        <span className="text600 title">{note.user.name.full}</span>
                                        <span className="secondary small2">{timeMonthDate(note.createdAt)}</span>
                                    </div>
                                    <div className="small2 secondary">
                                        {job.client.name} - {job.title}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="note-body">{body}</div>
                    </div>
                );
            });
        content = (
            <div className="job-phonescreen-notes">
                <div className="toggles">
                    <div className={`${jobNotesOnly ? '' : 'active'} toggle-title`} onClick={handleToggleJobNotesOnly}>
                        Client Notes
                    </div>
                    <div className={`${jobNotesOnly ? 'active' : ''} toggle-title`} onClick={handleToggleJobNotesOnly}>
                        Job Notes
                    </div>
                </div>
                {notes}
            </div>
        );
    }

    return (
        <div css={styles(theme)}>
            {tabs}
            <div className="job-details-content">{content}</div>
        </div>
    );
};
