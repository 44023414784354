import { Button, TextField } from '@material-ui/core';
import { parseOneAddress } from 'email-addresses';
import { Html5Entities } from 'html-entities';
import * as _ from 'lodash';
import * as React from 'react';

import { Editor } from 'react-ce';

import { EmailTemplateVariables } from 'shared/common/interpolate-template';
import { EmailTemplateView } from 'shared/models/email-templates';
import { EmailAddress } from 'shared/types/email-compose';

import { EmailAddressField } from '../email-compose/components/email-address-field';
import { EmailSequenceFormVariables } from './email-sequence-form-variables';
import { EmailTemplateSendSample } from './email-template-send-sample';

const variableTestCase: { [variable in keyof EmailTemplateVariables]: EmailTemplateVariables[variable] } = {
    atsLink: 'atsLink',
    awaitingClientFeedbackCandidates: [
        {
            link: 'awaitingClientFeedbackCandidates Link',
            name: 'awaitingClientFeedbackCandidates Candidate Name',
            submittedDate: 'awaitingClientFeedbackCandidates Submitted Date'
        }
    ],
    candidateContactDetails: '',
    candidateEducations: null,
    candidateExperiences: null,
    candidateFirstName: '',
    candidateNameWithLink: null,
    clientFinalRoundCandidates: [
        {
            link: 'clientFinalRoundCandidates Link',
            name: 'clientFinalRoundCandidates Candidate Name',
            submittedDate: 'clientFinalRoundCandidates Submitted Date'
        }
    ],
    clientFirstRoundCandidates: [
        {
            link: 'clientFirstRoundCandidates Link',
            name: 'clientFirstRoundCandidates Candidate Name',
            submittedDate: 'clientFirstRoundCandidates Submitted Date'
        }
    ],
    clientIntroPerson: {
        firstName: 'John',
        fullName: 'John Smith',
        linkedin: 'www.linkedin.com',
        role: 'Intro Person Role'
    },
    clientIntroPersonNameAndRoleWithLink: null,
    clientIntroPersonNameWithLink: null,
    clientName: 'Client Name',
    hasOnePager: true,
    hiringManagerFirstNames: ['Alice', 'Bob', 'Eve', 'Mallory'],
    hiringManagersNames: '',
    jobTitle: 'Job Title',
    onePagerText: '',
    personFirstName: 'Violet',
    personFullName: 'Violet Garden',
    personLinkedin: 'www.linkedin.com',
    personPublicLinkedin: 'www.linkedin.com',
    personRecruiterLinkedin: 'www.linkedin.com/recruiter',
    phoneNumberConfirmationText: '',
    primaryEmail: 'fake-email-test@getrocket.com',
    primaryPhone: '(999) 999-9999',
    profileDetails: {
        allEducations: [
            { degree: 'BS', fieldOfStudy: 'Computer Science', schoolName: 'U.C. Berkeley' },
            { degree: 'PhD', fieldOfStudy: 'Computer Science', schoolName: 'M.I.T.' }
        ],
        currentPositions: [{ companyName: 'Hireflow', startYear: '2018', title: 'Software Engineer' }],
        higherEducations: [{ degree: 'PhD', fieldOfStudy: 'Computer Science', schoolName: 'M.I.T.' }],
        latestDisciplineData: {
            discipline: 'Software Engineer',
            yearsOfExperience: '3'
        },
        latestPosition: {
            companyName: 'Hireflow',
            startYear: '2018',
            title: 'Software Engineer'
        },
        mostRecentPastPositionWithJobDiscipline: {
            companyName: 'Hireflow',
            startYear: '2018',
            title: 'Software Engineer'
        },
        pastPositions: [
            {
                companyName: 'Hireflow',
                startYear: '2018',
                title: 'Software Engineer'
            },
            { companyName: 'Rocket', startYear: '2016', title: 'Recruiter' }
        ]
    },
    recruitingActivity: 'Active - Final rounds',
    salary: 'Will accept 1 million',
    schedulingLink: 'www.mixmax.com',
    senderFirstName: 'Rocket',
    senderFullName: 'Rocket Submissions',
    senderSignature: '<div>Rocket Signature</div>',
    visaStatus: 'H1B'
};

const commonVariables = [
    '{{atsLink}}',
    '{{candidateContactDetails}}',
    '{{candidateExperiences}}',
    '{{candidateEducations}}',
    '{{candidateFirstName}}',
    '{{candidateNameWithLink}}',
    '{{clientIntroPersonNameAndRoleWithLink}}',
    '{{clientIntroPersonNameWithLink}}',
    '{{clientName}}',
    '{{hiringManagersNames}}',
    '{{jobTitle}}',
    '{{onePagerText}}',
    '{{personFirstName}}',
    '{{personFullName}}',
    '{{personLinkedin}}',
    '{{phoneNumberConfirmationText}}',
    '{{primaryEmail}}',
    '{{primaryPhone}}',
    '{{senderFirstName}}',
    '{{senderSignature}}'
];

interface EmailTemplateProps {
    template: Partial<EmailTemplateView>;
    onSave: (data: Partial<EmailTemplateView>) => void;
    onCancel: () => void;
    onDelete: () => void;
    showModalAlert: (description: string | JSX.Element, title: string) => void;
    saving: boolean;
    readonly: boolean;
}

export class EmailTemplate extends React.Component<EmailTemplateProps, Partial<EmailTemplateView>> {
    constructor(props: EmailTemplateProps) {
        super(props);
        this.state = props.template;
    }

    handleSave = () => {
        const errors = this.hasErrors();
        if (errors) {
            this.props.showModalAlert(errors, 'Errors found');
        } else {
            const entities = new Html5Entities();
            const plainBody = entities.decode(this.state.body);
            const plainSubject = entities.decode(this.state.subject);
            this.props.onSave({ ...this.state, body: plainBody, subject: plainSubject });
        }
    };

    hasChanged = () => {
        return (
            this.props.template.subject !== this.state.subject ||
            this.props.template.body !== this.state.body ||
            this.props.template.kind !== this.state.kind ||
            !_.isEqual(this.props.template.cc, this.state.cc) ||
            !_.isEqual(this.props.template.bcc, this.state.bcc)
        );
    };

    handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ subject: event.target.value });
    };

    handleBodyChange = (body: string) => {
        this.setState({ body });
    };

    handleKindChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ kind: event.target.value });
    };

    handleAddressChange = (field: 'bcc' | 'cc') => (addresses: EmailAddress[]) => {
        this.setState({ [field]: addresses });
    };

    canInterpolateTemplate = (content: string) => {
        try {
            _.template(content, { interpolate: /{{([\s\S]+?)}}/g, evaluate: /\[\%([\s\S]+?)\%\]/g })(variableTestCase);
            return true;
        } catch (err) {
            return false;
        }
    };

    hasErrors = () => {
        const { cc, bcc, subject, body, kind } = this.state;
        if (!kind) {
            return 'Please specify a name for the template';
        }
        if (!body) {
            return 'Please add content the template';
        }

        const entities = new Html5Entities();
        const plainBody = entities.decode(body);
        const plainSubject = entities.decode(subject);
        if (!this.canInterpolateTemplate(plainBody)) {
            return 'Please fix variables in the body';
        }
        if (!this.canInterpolateTemplate(plainSubject)) {
            return 'Please fix variables in the subject';
        }
        if ((cc || []).filter((a) => !parseOneAddress(a.address)).length > 0) {
            return 'Please provide valid email addresses in Cc field';
        }
        if ((bcc || []).filter((a) => !parseOneAddress(a.address)).length > 0) {
            return 'Please provide valid email addresses in Bcc field';
        }
        return null;
    };

    render() {
        const { template, saving, readonly, onDelete } = this.props;
        const { body, subject, kind, cc, bcc } = this.state;
        const changed = this.hasChanged();
        const deleteButton =
            onDelete && template.id ? (
                <Button onClick={onDelete} disabled={saving}>
                    Delete
                </Button>
            ) : null;
        const templateVariables =
            template.kind === 'Intro Client - Candidate' || template.group === 'submission'
                ? commonVariables.filter((cv) => cv !== '{{senderFirstName}}' && cv !== '{{senderSignature}}')
                : commonVariables;

        const actions = (
            <div className="email-template-actions">
                <div>
                    {deleteButton}
                    <EmailSequenceFormVariables variables={templateVariables} />
                </div>
                <div>
                    <EmailTemplateSendSample template={{ body, subject, group: template.group }} />
                    <Button onClick={this.props.onCancel} disabled={saving}>
                        Cancel
                    </Button>
                    <Button onClick={this.handleSave} disabled={saving || !changed}>
                        Save
                    </Button>
                </div>
            </div>
        );
        const header =
            template.id && template.group !== 'generic' ? (
                `${_.startCase(template.group)} - ${_.startCase(template.kind)}`
            ) : (
                <TextField
                    name={`template-${template.id}-kind`}
                    value={kind}
                    placeholder="Add a name for the template"
                    disabled={readonly || saving}
                    onChange={this.handleKindChange}
                    InputProps={{ disableUnderline: true }}
                    fullWidth={true}
                    style={{ fontSize: 16 }}
                />
            );

        return (
            <div className="email-template-edit">
                <div className="email-template-header">{header}</div>
                <div className="email-template-address">
                    <EmailAddressField
                        addresses={cc}
                        hintText="Cc"
                        label={undefined}
                        onChange={this.handleAddressChange('cc')}
                    />
                </div>
                <div className="email-template-address">
                    <EmailAddressField
                        addresses={bcc}
                        hintText="Bcc"
                        label={undefined}
                        onChange={this.handleAddressChange('bcc')}
                    />
                </div>
                <div className="email-template-subject">
                    <TextField
                        name={`template-${template.id}-subject`}
                        value={subject}
                        placeholder="Subject"
                        disabled={readonly || saving}
                        onChange={this.handleSubjectChange}
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        style={{ fontSize: 14 }}
                    />
                </div>
                <div className="email-template-content">
                    <Editor
                        value={body}
                        className={`email-template-editor ${readonly ? 'text-editor-toolbar-hidden' : ''}`}
                        onChange={this.handleBodyChange}
                        disabled={readonly || saving}
                        autoFocus={true}
                    />
                </div>
                {actions}
            </div>
        );
    }
}
