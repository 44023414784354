import { createContext, useContext } from 'react';

import { FieldPaths } from '../types/field-paths';

export interface FormContextProps<T extends {}> {
    data: T;
    disabled: boolean;
    getError: (key: FieldPaths<T>) => string | undefined;
    setError: (key: FieldPaths<T>, message: string) => void;
    onChange: (updated: Partial<T>) => void;
    onFieldChange: <K extends keyof T>(key: K) => (val: T[K]) => void;
    validateFieldExists: <K extends keyof T>(key: K) => (val: T[K]) => void;
    isDirty: boolean;
}

export const FormContext = createContext<FormContextProps<any> | null>(null);

export const useFormContext = <T extends {}>() => {
    const context = useContext(FormContext) as FormContextProps<T> | null;
    if (!context) {
        throw new Error('useFormContext must be used within a FormContext.Provider');
    }
    return context;
};
