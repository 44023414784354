import { TextField } from '@material-ui/core';
import { blue200, pink200, red200, red600 } from 'material-ui/styles/colors';
import React, { useMemo } from 'react';

import { SearchConfigInferenceGroup, SearchTerm, toggleSearchTerm } from 'shared/models/search';

import { TextfieldWithChips } from '../core-ui/textfield-with-chips';
import { useModal } from '../hooks/use-modal';
import { SearchStatus } from '../state';
import { renderOption } from './search-autocomplete-option';
import { SearchGroup, SearchGroupAction } from './search-group';
import { SearchRequirementsSection } from './search-requirements-section';
import { useSearch } from './use-search';
import { useSearchPresets } from './use-search-presets';
import { useSearchPresetsForm } from './use-search-presets-form';

export const SearchInferredSkills: React.FC = () => {
    const searchContext = useSearch();
    const inferenceGroups = searchContext.data.config.inferenceGroups;
    const onChange = searchContext.onConfigFieldChange('inferenceGroups');
    const readonly = searchContext.readonly;
    const savedSearch = searchContext.data.status && searchContext.data.status !== SearchStatus.Initial;

    const { requestPresets, updateSelectedPresets, searchPresets, getGroupSearchTerms } = useSearchPresets();
    const { requestPresetsSave } = useSearchPresetsForm();

    const { getConfirmation } = useModal();

    const handleUpdateKeywordGroup = (index: number) => <T extends keyof SearchConfigInferenceGroup>(field: T) => (
        value: SearchConfigInferenceGroup[T]
    ) => {
        const group = inferenceGroups[index];
        const updatedGroup = Object.assign({}, group, { [field]: value });
        const updatedGroups = inferenceGroups.slice(0, index).concat([updatedGroup], inferenceGroups.slice(index + 1));
        onChange(updatedGroups);
    };

    const handleToggleKeywordsNegation = (index: number) => () => {
        const group = inferenceGroups[index];
        handleUpdateKeywordGroup(index)('negative')(!group.negative);
    };

    const handleAddKeywordsGroup = () => {
        onChange(inferenceGroups.concat([{ keywords: [], negative: false, score: 1 }]));
    };

    const handleRemoveKeywordsGroup = (index: number) => () => {
        let updatedGroups = inferenceGroups.slice(0, index).concat(inferenceGroups.slice(index + 1));
        if (updatedGroups.length === 0) {
            updatedGroups = [{ keywords: [], negative: false, score: 1 }];
        }
        if (inferenceGroups[index].keywords.length > 0) {
            getConfirmation(
                () => onChange(updatedGroups),
                'Are you sure you want to remove a list of search terms?',
                'Remove Confirmation'
            );
        } else {
            onChange(updatedGroups);
        }
    };

    const handlePresetSaved = (index: number) => (preset: SearchTerm) => {
        const old = inferenceGroups[index];
        const updated = { ...old, keywords: [preset] };
        const newGroups = inferenceGroups.slice(0, index).concat([updated], inferenceGroups.slice(index + 1));
        onChange(newGroups);
    };

    const handleSaveAsPreset = (index: number, names: SearchTerm[]) => () => {
        requestPresetsSave({
            groups: ['skills'],
            postSave: handlePresetSaved(index),
            selectedGroup: 'skills',
            terms: names
        });
    };

    const getChipStyle = <T extends { negative: boolean }>(g: T) => (value: SearchTerm) => {
        const { isPreset } = value;
        const backgroundColor = g.negative && isPreset ? pink200 : g.negative ? red200 : isPreset ? blue200 : undefined;
        return { backgroundColor };
    };

    // stale closure fix
    const inferenceGroupsRef = React.useRef(inferenceGroups);
    inferenceGroupsRef.current = inferenceGroups;

    const handleToggleTerm = (index: number, term: SearchTerm) => {
        const old = inferenceGroupsRef.current[index];
        const newKeywords = toggleSearchTerm(term, old.keywords);
        const updated = Object.assign({}, old, { keywords: newKeywords });
        const newInferenceGroups = inferenceGroupsRef.current
            .slice(0, index)
            .concat([updated], inferenceGroupsRef.current.slice(index + 1));
        onChange(newInferenceGroups);
        updateSelectedPresets(newInferenceGroups[index].keywords.filter((kw) => kw.isPreset));
    };

    const handleTogglePresets = (index: number) => (label: string, _1: string[], id: string) => {
        handleToggleTerm(index, { isPreset: true, label, presetId: id });
    };

    const handleAddKeywordsPresetRequest = (index: number) => () => {
        requestPresets(
            handleTogglePresets(index),
            ['skills'],
            inferenceGroups[index].keywords.filter((kw) => kw.isPreset)
        );
    };

    const handleScoreChange = (index: number) => (event: { target: { value: string } }) => {
        const score = Number(event.target.value);
        if (!Number.isNaN(score)) {
            handleUpdateKeywordGroup(index)('score')(score);
        }
    };

    const getChipFromString = (label: string) => ({ isPreset: false, label });
    const getStringFromChip = (chip: SearchTerm) => chip.label;

    const keywordsOptions = useMemo(() => getGroupSearchTerms('skills'), [searchPresets]);

    const groups = inferenceGroups
        .filter((i) => !savedSearch || i.keywords.length > 0)
        .map((g, i) => {
            const group = (
                <TextfieldWithChips
                    readonly={readonly}
                    chips={g.keywords}
                    hintText="Add search term"
                    onChange={handleUpdateKeywordGroup(i)('keywords')}
                    getChipStyle={getChipStyle(g)}
                    getChipFromString={getChipFromString}
                    getStringFromChip={getStringFromChip}
                    options={keywordsOptions}
                    renderOption={renderOption}
                />
            );
            const searchGroupActions: SearchGroupAction[] = [{ name: 'Add', onSelect: handleAddKeywordsGroup }];
            searchGroupActions.push({ name: 'Remove', onSelect: handleRemoveKeywordsGroup(i) });
            searchGroupActions.push({
                checked: g.negative,
                name: 'Negative',
                onSelect: handleToggleKeywordsNegation(i),
                style: g.negative ? { color: red600 } : {}
            });
            searchGroupActions.push({
                name: 'Presets',
                onSelect: handleAddKeywordsPresetRequest(i)
            });
            if (g.keywords.length > 0) {
                searchGroupActions.push({ name: 'Save as Preset', onSelect: handleSaveAsPreset(i, g.keywords) });
            }

            return (
                <SearchGroup readonly={readonly} actions={searchGroupActions} key={i} className="and-group">
                    <div className="search-requirements-field with-context" key={i}>
                        <div style={{ flex: '1 1 auto' }}>
                            <label className="search-label">Group {i + 1}</label>
                            <TextField
                                key={i}
                                label="Score"
                                value={g.score}
                                onChange={handleScoreChange(i)}
                                style={{ margin: '10px 0 15px' }}
                                type="number"
                            />
                            <label className="search-label">Terms</label>
                            <div className="search-requirements-value">{group}</div>
                        </div>
                    </div>
                </SearchGroup>
            );
        });
    return (
        <SearchRequirementsSection section="inferred-skills" className="add-groups-section">
            <div>{groups}</div>
        </SearchRequirementsSection>
    );
};
