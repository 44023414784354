import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Dialog,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Theme,
    Tooltip,
    useTheme
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { capitalize, times } from 'lodash';
import moment from 'moment';
import { FC, useState } from 'react';
import DocumentTitle from 'react-document-title';

import { fullDate } from '../common/timestamp';
import { DayPicker } from '../core-ui/react-day-picker';
import { SCHEDULED_OUTREACHES, ScheduledOutreach, UPDATE_SCHEDULED_OUTREACHES } from '../graphql/queries/outreach';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { Header } from './header';

const skeletonRowsCount = 5;
const rowsPerPage = 10;

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .selectors {
        display: flex;
        flex: 0 0 auto;
        text-align: right;
        margin-bottom: 20px;
        width: 300px;
        justify-content: end;
        align-self: end;
        padding: 10px 0;

        .MuiFormControl-root {
            margin: 0 0 0 15px;
        }

        .MuiInputBase-root {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            .MuiOutlinedInput-input {
                background: white;
                padding: 14px 14px;
            }
        }
    }

    .table {
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
        background: white;

        .table-pagination {
            text-align: right;
            padding: 18px 25px;
            font-size: 13px;
            text-transform: uppercase;
        }

        .table-footer {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }

        .outreach-status {
            display: inline-flex;
            align-items: center;
        }

        .cancel-outreach-button {
            margin-left: 10px;
            opacity: 0;
        }

        tr:hover {
            background: ${theme.palette.action.hover};
            .cancel-outreach-button {
                opacity: 1;
            }
        }

        .table-header-cell {
            display: inline-flex;
            align-items: center;
        }

        .column-action-icon {
            display: inline-flex;
            align-items: center;
            opacity: 0;
            transition: opacity 200ms;
            margin-left: 5px;
            cursor: pointer;

            .MuiSvgIcon-root {
                font-size: 1.25rem;
                color: ${theme.palette.text.secondary};
            }

            &.visible {
                opacity: 1;
            }
        }

        th:hover {
            .column-action-icon {
                opacity: 1;
            }
        }
    }
`;

type Column = 'Client' | 'Job' | 'Sending Time' | 'Requested Time' | 'Status' | 'Requested' | 'Added';

export const Outreaches: FC<{ username: string }> = ({ username }) => {
    const theme = useTheme();
    const { getConfirmation } = useModal();
    const { setSnackbar } = useSnackbar();
    const { user } = useSession();

    const [page, setPage] = useState(0);

    const [startDateDialogState, setStartDateDialogState] = useState<boolean>(false);
    const [startDate, setStartDate] = useState(Date.now());

    const [endDateDialogState, setEndDateDialogState] = useState<boolean>(false);
    const [endDate, setEndDate] = useState(Date.now());

    const assignee = username === 'me' ? user.username : username;

    const columns: Column[] = ['Client', 'Job', 'Requested Time', 'Status', 'Requested', 'Sending Time', 'Added'];

    const [updateScheduledOutreach, { loading }] = useMutation<{}, { id: string; updates: Partial<ScheduledOutreach> }>(
        UPDATE_SCHEDULED_OUTREACHES
    );

    const { data: outreachesData, loading: fetchLoading, refetch } = useQuery<
        { scheduled_outreaches: ScheduledOutreach[] },
        { username: string; startDate: number; endDate: number }
    >(SCHEDULED_OUTREACHES, {
        variables: {
            endDate: moment(endDate).endOf('day').valueOf(),
            startDate: moment(startDate).startOf('day').valueOf(),
            username: assignee
        }
    });

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        /* no-op */
    };

    const headers = columns.map((col) => (
        <TableCell key={col}>
            <span className="table-header-cell">
                <span>{col}</span>
            </span>
        </TableCell>
    ));

    const skeletonRows = times(skeletonRowsCount).map((i) => (
        <TableRow key={i}>
            <TableCell colSpan={columns.length}>
                <Skeleton variant="rect" />
            </TableCell>
        </TableRow>
    ));

    const onConfirmCancelOutreach = (id: string) => async () => {
        await updateScheduledOutreach({
            variables: {
                id,
                updates: {
                    status: 'canceled'
                }
            }
        });
        setSnackbar('Scheduled Outreach Canceled');
        refetch();
    };

    const handleCancelOutreach = (id: string) => () => {
        getConfirmation(onConfirmCancelOutreach(id), 'This will cancel this scheduled outreach', 'Please confirm');
    };

    const outreaches = outreachesData?.scheduled_outreaches;
    const rows =
        outreaches === undefined
            ? skeletonRows
            : outreaches.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((outreach) => {
                  const cancelButton =
                      user.id === outreach.user.id && outreach.status === 'scheduled' ? (
                          <Tooltip title="Cancel Outreach">
                              <span className="cancel-outreach-button">
                                  <IconButton
                                      disabled={loading}
                                      onClick={handleCancelOutreach(outreach.id)}
                                      size="small"
                                  >
                                      <Cancel fontSize="small" />
                                  </IconButton>
                              </span>
                          </Tooltip>
                      ) : null;
                  const status = (
                      <span className="outreach-status">
                          {capitalize(outreach.status)} {cancelButton}
                      </span>
                  );
                  return (
                      <TableRow key={`${outreach.id}`}>
                          <TableCell>{outreach.job.client.name}</TableCell>
                          <TableCell>{outreach.job.title}</TableCell>
                          <TableCell>{moment(outreach.requestedAt).format('MMM DD, YYYY hh:mm A')}</TableCell>
                          <TableCell>{status}</TableCell>
                          <TableCell>{outreach.limit}</TableCell>
                          <TableCell>{moment(outreach.scheduledAt).format('MMM DD, YYYY hh:mm A')}</TableCell>
                          <TableCell>{outreach.addedCount}</TableCell>
                      </TableRow>
                  );
              });

    const pagination = fetchLoading ? (
        <div className="table-pagination">Loading...</div>
    ) : outreaches.length > rowsPerPage ? (
        <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            count={outreaches.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    ) : (
        <div className="table-pagination">{outreaches.length} Outreaches</div>
    );

    const handleDatePickerOpen = (start: boolean) => async () => {
        start ? setStartDateDialogState(true) : setEndDateDialogState(true);
    };

    const handleDateChange = (start: boolean) => async (date: Date) => {
        start ? setStartDate(date.valueOf()) : setEndDate(date.valueOf());
        start ? setStartDateDialogState(false) : setEndDateDialogState(false);
    };
    const handleCloseDateDialog = (start: boolean) => () =>
        start ? setStartDateDialogState(false) : setEndDateDialogState(false);

    const startDateDialog = startDateDialogState ? (
        <Dialog open={true} onClose={handleCloseDateDialog(true)} maxWidth="md">
            <DayPicker
                defaultMonth={startDate ? new Date(startDate) : new Date()}
                onDayClick={handleDateChange(true)}
            />
        </Dialog>
    ) : null;

    const endDateDialog = endDateDialogState ? (
        <Dialog open={true} onClose={handleCloseDateDialog(false)} maxWidth="md">
            <DayPicker defaultMonth={endDate ? new Date(endDate) : new Date()} onDayClick={handleDateChange(false)} />
        </Dialog>
    ) : null;

    return (
        <DocumentTitle title="Outreaches">
            <div id="container">
                <Header title="Outreaches" />
                <div css={styles(theme)}>
                    <div className="selectors">
                        <TextField
                            label="Start Date"
                            value={startDate ? fullDate(startDate) : ''}
                            onClick={handleDatePickerOpen(true)}
                            variant="outlined"
                        />
                        {startDateDialog}

                        <TextField
                            label="End Date"
                            value={endDate ? fullDate(endDate) : ''}
                            onClick={handleDatePickerOpen(false)}
                            variant="outlined"
                        />
                        {endDateDialog}
                    </div>
                    <TableContainer className="table">
                        <Table stickyHeader={true}>
                            <TableHead>
                                <TableRow>{headers}</TableRow>
                            </TableHead>
                            <TableBody>{rows}</TableBody>
                        </Table>
                        <div className="table-footer">{pagination}</div>
                    </TableContainer>
                </div>
            </div>
        </DocumentTitle>
    );
};
