import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import { css } from '@emotion/core';
import { MenuItem, Select } from '@material-ui/core';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { ClientCandidatesReminderEmails } from '../components/client-candidates-reminder-emails';
import { ClientPageHeader } from '../components/client-page-header';

import { Kanban } from '../components/kanban';
import { Loading } from '../core-ui/loading';
import { PrimaryToggle } from '../core-ui/primary-toggle';
import {
    CandidateModifiedAt,
    CANDIDATES_STAGE_COUNTS,
    LATEST_CLIENT_CANDIDATE,
    STAGE_FUNNEL_METRICS,
    StageCountsRecord,
    StageCountsRecordWithFunnel,
    StageCountsVars,
    StageFunnelData
} from '../graphql/queries/candidates-board';
import { CLIENT_WITH_JOBS, ClientWithJobs } from '../graphql/queries/clients';
import { CandidatesBoardProvider } from '../hooks/use-candidates-board';
import { useSession } from '../hooks/use-session';

const selectStyles = css`
    margin-left: 15px;
    color: white;
`;

export const ClientCandidates: React.FC<{ id: string }> = ({ id }) => {
    const { user } = useSession();
    const [selectedJob, setSelectedJob] = React.useState<string>('all');
    const [disqualified, setDisqualified] = React.useState(false);
    const [lastModifiedCandidate, setLastModifiedCandidate] = React.useState<number>(undefined);
    const [jobIds, setJobIds] = React.useState<string[]>([]);

    const { data: candidateModifiedAtData } = useSubscription<CandidateModifiedAt, { clientId: string }>(
        LATEST_CLIENT_CANDIDATE,
        { variables: { clientId: id } }
    );
    const { data: clientData } = useQuery<{ client: ClientWithJobs }, { id: string }>(CLIENT_WITH_JOBS, {
        variables: { id }
    });
    const [fetchStageCounts, { data: stageCountsData }] = useLazyQuery<
        { stageCounts: StageCountsRecord[] },
        StageCountsVars
    >(CANDIDATES_STAGE_COUNTS, { variables: { jobIds, disqualified } });
    const [fetchFunnelData, { data: funnelData }] = useLazyQuery<{ funnel: StageFunnelData[] }, { jobIds: string[] }>(
        STAGE_FUNNEL_METRICS,
        {
            variables: { jobIds }
        }
    );

    React.useEffect(() => {
        setJobIds(selectedJob === 'all' ? clientData?.client.jobs.map((j) => j.id) ?? [] : [selectedJob]);
    }, [clientData, selectedJob]);

    React.useEffect(() => {
        if (candidateModifiedAtData?.candidates_aggregate.aggregate.max.modifiedAt !== lastModifiedCandidate) {
            setLastModifiedCandidate(candidateModifiedAtData?.candidates_aggregate.aggregate.max.modifiedAt);
        }
    }, [candidateModifiedAtData]);

    React.useEffect(() => {
        if (candidateModifiedAtData) {
            fetchStageCounts();
            fetchFunnelData();
        }
    }, [lastModifiedCandidate, jobIds, disqualified]);

    const handleSelectJobs = (event: React.ChangeEvent<{ value: string }>) => {
        setSelectedJob(event.target.value);
    };

    const handleDisqualifyToggle = () => {
        setDisqualified(!disqualified);
    };

    let actions;
    let content = <Loading />;
    let title = '';
    if (clientData) {
        const jobOptions = clientData.client.jobs.map((job) => (
            <MenuItem key={job.id} value={job.id}>
                {job.title}
            </MenuItem>
        ));

        const clientCandidatesReminder =
            selectedJob === 'all' ||
            clientData.client.jobs.find((j) => j.id === selectedJob)?.accountManagerId === user.id ? (
                <ClientCandidatesReminderEmails clientId={id} key="client-reminder" />
            ) : null;

        actions = [
            <PrimaryToggle
                key="disqualified-toggle"
                checked={!disqualified}
                label={disqualified ? 'Disqualified' : 'Qualified'}
                onChange={handleDisqualifyToggle}
            />,
            <Select
                css={selectStyles}
                key="job-selector"
                value={selectedJob}
                onChange={handleSelectJobs}
                disableUnderline={true}
            >
                <MenuItem value="all">All Jobs</MenuItem>
                {jobOptions}
            </Select>,
            clientCandidatesReminder
        ];

        let stageCounts: StageCountsRecordWithFunnel[];
        if (stageCountsData) {
            stageCounts = [];
            for (const stage of stageCountsData.stageCounts) {
                const funnelCounts = funnelData?.funnel.find((s) => s.id === stage.id);
                stageCounts.push({
                    ...stage,
                    ...funnelCounts
                });
            }
        }

        const showJobName = selectedJob === 'all';
        title = clientData.client.name;
        if (stageCountsData) {
            content = (
                <CandidatesBoardProvider
                    showClientName={false}
                    showJobName={showJobName}
                    showAssigneeName={true}
                    assignee={undefined}
                    jobIds={jobIds}
                    disqualified={disqualified}
                >
                    <Kanban stageCounts={stageCounts} />
                </CandidatesBoardProvider>
            );
        }
    }

    return (
        <DocumentTitle title={title}>
            <div id="container">
                <ClientPageHeader actions={actions} clientId={id} activeTab="Candidates" />
                <div id="content" className="flex-fill">
                    {content}
                </div>
            </div>
        </DocumentTitle>
    );
};
