import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, ButtonGroup, Tooltip, Typography, useTheme } from '@material-ui/core';
import { Sort, SortByAlpha } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { sortBy } from 'lodash';
import React from 'react';
import Chart from 'react-apexcharts';
import DocumentTitle from 'react-document-title';

import { fontFamily } from '../common/css-variables';
import { Duration, getDurationOptions } from '../common/duration';
import {
    clientActivityFields,
    defaultAggregateViewSelected,
    Field,
    fieldLabels,
    FieldsSelected,
    funnelMetrics,
    getChartHeight,
    getFieldColors
} from '../common/metrics';
import { isoDate } from '../common/timestamp';
import { MetricsData, TEAM_METRICS } from '../graphql/queries/metrics';
import { TEAM, Team as TeamData } from '../graphql/queries/team';
import { useLocalStorage } from '../hooks/use-local-storage';
import { internalClientIds as excludeClientIds, JobFilterTypes, JobType, jobTypesForFilter } from '../state';
import { DurationSelector } from './duration-selector';
import { Header } from './header';
import { JobsTypeFilter } from './jobs-type-filter';
import { Legend } from './metrics/legend';
import { styles } from './metrics/styles';

export const TeamMetrics: React.FC<{ id: string }> = ({ id }) => {
    const theme = useTheme();
    const durationPeriods = { days: 2, weeks: 2, months: 2, quarters: 4, years: 2, allTime: true };
    const durationOpts = getDurationOptions(durationPeriods);
    const defaultOption = durationOpts.find((opt) => opt.label === 'This Week');
    const [duration, setDuration] = useLocalStorage<Duration>('metrics-duration', defaultOption);
    const [jobTypes, setJobTypes] = useLocalStorage<JobFilterTypes>('metrics-job-types', 'placement-fees');
    const { data: teamData } = useQuery<{ team: TeamData }, { id: string }>(TEAM, { variables: { id } });
    const [fetchMetrics, { data }] = useLazyQuery<
        { metrics: MetricsData[] },
        {
            userIds: string[];
            kinds: string[];
            jobTypes: JobType[];
            excludeClientIds: string[];
            startDate: string;
            endDate: string;
        }
    >(TEAM_METRICS, {
        variables: {
            endDate: isoDate(duration.end),
            excludeClientIds,
            jobTypes: jobTypesForFilter.get(jobTypes),
            kinds: clientActivityFields,
            startDate: isoDate(duration.start),
            userIds: teamData?.team.users.map((u) => u.user.id) ?? []
        }
    });
    const [selectedFields, setSelectedFields] = useLocalStorage<FieldsSelected>(
        'agg-metrics-fields',
        defaultAggregateViewSelected
    );
    const [hoveredField, setHoveredField] = React.useState<Field>(undefined);
    const [variant, setVariant] = useLocalStorage<'cost' | 'count'>('agg-metrics-variant', 'count');
    const [sort, setSort] = useLocalStorage<'alpha' | 'sum'>('agg-metrics-sort', 'alpha');

    const fields = clientActivityFields.filter((f) => selectedFields[f]);

    const handleVariantChange = (value: 'cost' | 'count') => () => {
        setVariant(value);
    };

    const handleSortChange = (value: 'alpha' | 'sum') => () => {
        setSort(value);
    };

    React.useEffect(() => {
        if (!data && teamData) {
            fetchMetrics();
        }
    }, [teamData]);

    let content;
    if (!teamData) {
        content = <Skeleton variant="rect" />;
    } else {
        let chart;
        if (data) {
            const dataByUser = teamData.team.users.map((u) => {
                const userMetrics = funnelMetrics(
                    data.metrics.filter((m) => m.user.id === u.user.id),
                    variant
                );
                return {
                    metrics: userMetrics,
                    sum: fields.reduce((acc, f) => acc + (userMetrics[f] ?? 0), 0),
                    user: u.user
                };
            });
            const users = sortBy(
                teamData.team.users.map((u) => u.user),
                [(u) => (sort === 'alpha' ? u.name : -dataByUser.find((d) => d.user.id === u.id).sum)]
            );
            const categories = users.map((u) => u.name);
            const series = fields.map((f) => ({
                data: users.map((u) => dataByUser.find((d) => d.user.id === u.id).metrics[f] ?? 0),
                name: fieldLabels.get(f)
            }));

            const colors = getFieldColors(fields, hoveredField);

            const chartOptions = {
                chart: { fontFamily, stacked: true, toolbar: { show: false } },
                colors,
                legend: { show: false },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true
                    }
                },
                xaxis: {
                    categories
                }
            };
            chart =
                categories.length > 0 ? (
                    <Chart
                        options={chartOptions}
                        height={getChartHeight(categories?.length ?? 0)}
                        series={series}
                        type="bar"
                    />
                ) : null;
        } else {
            chart = <Skeleton variant="rect" />;
        }

        const tabs = (
            <div className="view-selectors">
                <ButtonGroup size="small" variant="text">
                    <Tooltip title="Sort Alphabetical">
                        <Button className={sort === 'alpha' ? '' : 'inactive'} onClick={handleSortChange('alpha')}>
                            <SortByAlpha fontSize="small" />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Sort by Activity">
                        <Button className={sort === 'sum' ? '' : 'inactive'} onClick={handleSortChange('sum')}>
                            <Sort fontSize="small" />
                        </Button>
                    </Tooltip>
                </ButtonGroup>
                <ButtonGroup size="small" variant="text">
                    <Tooltip title="Individual Action Counts">
                        <Button
                            className={variant === 'count' ? '' : 'inactive'}
                            onClick={handleVariantChange('count')}
                        >
                            Raw Counts
                        </Button>
                    </Tooltip>
                    <Tooltip title="Weighted sums based on estimated action time">
                        <Button className={variant === 'cost' ? '' : 'inactive'} onClick={handleVariantChange('cost')}>
                            Weighted Sums
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            </div>
        );

        content = (
            <div className="chart-container">
                <div className="panel-header">
                    <div className="panel-title">
                        <Typography variant="h5">Team Metrics</Typography>
                    </div>
                    <div className="panel-options">{tabs}</div>
                </div>
                <div className="panel-content">
                    <div className="chart">{chart}</div>
                    <Legend
                        fields={clientActivityFields}
                        selected={selectedFields}
                        onFieldHover={setHoveredField}
                        onUpdateSelected={setSelectedFields}
                    />
                </div>
            </div>
        );
    }

    const title = `${teamData?.team.name ?? ''} Metrics`;

    return (
        <DocumentTitle title={title}>
            <div id="container">
                <Header title={title} />
                <div css={styles(theme)}>
                    <div className="metrics-container">
                        <div className="selectors">
                            <DurationSelector selected={duration} onSelect={setDuration} periods={durationPeriods} />
                            <JobsTypeFilter selectedFilter={jobTypes} onSelectFilter={setJobTypes} />
                        </div>
                        {content}
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};
