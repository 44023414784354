import { startCase } from 'lodash';
import { blue200, pink200, red200, red600 } from 'material-ui/styles/colors';
import React, { useEffect, useRef, useState } from 'react';

import { CrunchbaseCompanyFilters } from 'shared/common/crunchbase-filters';
import { SearchConfigCompanies, SearchTerm, toggleSearchTerm } from 'shared/models/search';

import { SimilaritySearch } from '../components/similarity-search';
import { ToggleButton } from '../core-ui/toggle-button';
import { useModal } from '../hooks/use-modal';
import { SearchStatus } from '../state';
import { CBFiltersChip } from './crunchbase/cb-filters-chip';
import { CBFiltersDialog } from './crunchbase/cb-filters-dialog';
import { SearchCompanyTextField } from './search-company-text-field';
import { SearchFieldContext } from './search-field-context';
import { SearchGroup, SearchGroupAction } from './search-group';
import { useSearch } from './use-search';
import { useSearchPresets } from './use-search-presets';
import { useSearchPresetsForm } from './use-search-presets-form';

export const SearchExperienceCompaniesRequirements: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config.experience;
    const onChange = searchContext.onConfigFieldChange('experience');
    const readonly = searchContext.readonly;
    const clientUrl = searchContext.client?.linkedinUrl;
    const savedSearch = searchContext.data.status && searchContext.data.status !== SearchStatus.Initial;

    const { requestPresets, updateSelectedPresets } = useSearchPresets();
    const { requestPresetsSave } = useSearchPresetsForm();

    const [searchingSimilarCompanies, setSearchingSimilarCompanies] = useState(-1);
    const [crunchbaseFiltersOpenIndex, setCrunchbaseFiltersOpenIndex] = useState<number | undefined>(undefined);
    const configRef = useRef(config);
    const { getConfirmation } = useModal();

    if (!config?.companies) return null;

    const { companies } = config;

    useEffect(() => {
        configRef.current = config;
    }, [config]);

    const handleToggleCompanyPreset = (index: number) => (label: string, _1: string[], id: string) => {
        const old = configRef.current.companies[index];
        const newNames = toggleSearchTerm({ isPreset: true, label, presetId: id }, old.names);
        const updated = Object.assign({}, old, { names: newNames });
        const newCompanies = companies.slice(0, index).concat([updated], companies.slice(index + 1));
        onChange(Object.assign({}, config, { companies: newCompanies }));
        updateSelectedPresets(newCompanies[index].names.filter((n) => n.isPreset));
    };

    const handleAddCompaniesPresetRequest = (index: number) => () => {
        requestPresets(
            handleToggleCompanyPreset(index),
            ['companies'],
            configRef.current.companies[index].names.filter((n) => n.isPreset)
        );
    };

    const handleUpdateExperienceCompanies = (index: number) => <T extends keyof SearchConfigCompanies>(field: T) => (
        value: SearchConfigCompanies[T]
    ) => {
        const updated = Object.assign({}, companies[index], { [field]: value });
        const newCompanies = companies.slice(0, index).concat([updated], companies.slice(index + 1));
        onChange(Object.assign({}, config, { companies: newCompanies }));
    };

    const handleToggleExperienceCompanies = (index: number) => () => {
        handleUpdateExperienceCompanies(index)('negative')(!companies[index].negative);
    };

    const handleToggleCompanyExactMatch = (index: number) => () => {
        handleUpdateExperienceCompanies(index)('exactMatch')(!companies[index].exactMatch);
    };

    const handleRemoveCompanies = (index: number) => () => {
        let newCompanies = companies.slice(0, index).concat(companies.slice(index + 1));
        if (newCompanies.length === 0) {
            newCompanies = [{ exactMatch: true, names: [], negative: false, when: 'latest' }];
        }
        if (companies[index].names.length > 0) {
            getConfirmation(
                () => onChange(Object.assign({}, config, { companies: newCompanies })),
                'Are you sure you want to remove a list of companies?',
                'Remove Confirmation'
            );
        } else {
            onChange(Object.assign({}, config, { companies: newCompanies }));
        }
    };

    const handleAddCompanies = () => {
        const newCompanies = config.companies.concat([
            { exactMatch: true, names: [], negative: false, when: 'latest' }
        ]);
        const updated = Object.assign({}, config, { companies: newCompanies });
        onChange(updated);
    };

    const getChipStyle = <T extends { negative: boolean }>(g: T) => (value: SearchTerm) => {
        const { isPreset } = value;
        const backgroundColor = g.negative && isPreset ? pink200 : g.negative ? red200 : isPreset ? blue200 : undefined;
        return { backgroundColor };
    };

    const handleAddSimilarCompaniesRequest = (index: number) => () => {
        setSearchingSimilarCompanies(index);
    };

    const handleCloseSimilarCompanies = () => {
        setSearchingSimilarCompanies(-1);
    };

    const handleSimilarityCompanyToggle = (value: { name: string; url: string }) => () => {
        const index = searchingSimilarCompanies;
        const old = companies[index];
        const newNames = toggleSearchTerm({ isPreset: false, label: value.name, value: value.url }, old.names);
        const updated = Object.assign({}, old, { names: newNames }) as SearchConfigCompanies;
        const newCompanies = companies.slice(0, index).concat([updated], companies.slice(index + 1));
        onChange(Object.assign({}, config, { companies: newCompanies }));
    };

    const handlePresetSaved = (index: number) => (preset: SearchTerm) => {
        const old = configRef.current.companies[index];
        const updated = { ...old, names: [preset] };
        const newCompanies = companies.slice(0, index).concat([updated], companies.slice(index + 1));
        onChange(Object.assign({}, config, { companies: newCompanies }));
    };

    const handleSaveAsPreset = (index: number, names: SearchTerm[]) => () => {
        requestPresetsSave({
            groups: ['companies'],
            postSave: handlePresetSaved(index),
            selectedGroup: 'companies',
            terms: names
        });
    };

    const handleOpenCBFilters = (index: number) => () => {
        setCrunchbaseFiltersOpenIndex(index);
    };

    const handleCloseCBFiltersDialog = () => {
        setCrunchbaseFiltersOpenIndex(undefined);
    };

    const handleUpdateCBFilters = (filters: CrunchbaseCompanyFilters) => {
        const index = crunchbaseFiltersOpenIndex;
        const old = companies[index];
        const updated = Object.assign({}, old, { crunchbaseFilters: filters }) as SearchConfigCompanies;
        const newCompanies = companies.slice(0, index).concat([updated], companies.slice(index + 1));
        onChange(Object.assign({}, config, { companies: newCompanies }));
    };

    const handleDeleteCBFilters = (index: number) => () => {
        const old = companies[index];
        const updated = Object.assign({}, old, { crunchbaseFilters: undefined }) as SearchConfigCompanies;
        const newCompanies = companies.slice(0, index).concat([updated], companies.slice(index + 1));
        onChange(Object.assign({}, config, { companies: newCompanies }));
    };

    const buttonForValue = (value: { name: string; url: string }) => {
        const alreadyAdded = config.companies[searchingSimilarCompanies].names.find(
            (v) => !v.isPreset && (v.label === value.name || v.label === value.url)
        );
        return (
            <ToggleButton
                initialValue={!alreadyAdded}
                onLabel={<span>Add</span>}
                offLabel={<span style={{ color: red600 }}>Remove</span>}
                onClick={handleSimilarityCompanyToggle(value)}
            />
        );
    };

    const companiesLists = companies
        .filter((list) => !savedSearch || list.crunchbaseFilters || list.names.length > 0)
        .map((c, i) => {
            const actions: SearchGroupAction[] = [{ name: 'Add', onSelect: handleAddCompanies }];
            actions.push({ name: 'Remove', onSelect: handleRemoveCompanies(i) });
            actions.push({
                checked: c.negative,
                name: 'Negative',
                onSelect: handleToggleExperienceCompanies(i),
                style: c.negative ? { color: red600 } : {}
            });
            actions.push({ name: 'Presets', onSelect: handleAddCompaniesPresetRequest(i) });
            actions.push({ name: 'Similar Companies', onSelect: handleAddSimilarCompaniesRequest(i) });
            actions.push({
                checked: c.exactMatch,
                name: 'Exact Match',
                onSelect: handleToggleCompanyExactMatch(i)
            });
            actions.push({
                name: 'Crunchbase Filters',
                onSelect: handleOpenCBFilters(i)
            });
            if (c.names.length > 0) {
                actions.push({ name: 'Save as Preset', onSelect: handleSaveAsPreset(i, c.names) });
            }
            const getChipFromString = (label: string) => ({ isPreset: false, label });
            const getStringFromChip = (chip: SearchTerm) => chip.label;

            const cbFiltersChip = c.crunchbaseFilters ? (
                <CBFiltersChip
                    filters={c.crunchbaseFilters}
                    negative={c.negative}
                    onClick={handleOpenCBFilters(i)}
                    onDelete={readonly ? null : handleDeleteCBFilters(i)}
                />
            ) : null;

            return (
                <SearchGroup readonly={readonly} actions={actions} key={i} className="and-group">
                    <div className="search-requirements-field with-context" key={i}>
                        <div style={{ flex: '1 1 auto' }}>
                            <label className="search-label">Company</label>
                            <div className="search-requirements-value">
                                <SearchCompanyTextField
                                    readonly={readonly}
                                    prefixChips={cbFiltersChip}
                                    chips={c.names}
                                    onChange={handleUpdateExperienceCompanies(i)('names')}
                                    getChipStyle={getChipStyle(c)}
                                    getChipFromString={getChipFromString}
                                    getStringFromChip={getStringFromChip}
                                />
                            </div>
                        </div>
                        <SearchFieldContext
                            disabled={readonly}
                            label="Kind"
                            onSelect={handleUpdateExperienceCompanies(i)('when')}
                            selected={c.when}
                            options={['latest', 'past', 'latestOrPast']}
                            getOptionLabel={startCase}
                        />
                    </div>
                </SearchGroup>
            );
        });

    const similaritySearch =
        searchingSimilarCompanies >= 0 ? (
            <SimilaritySearch
                onClose={handleCloseSimilarCompanies}
                searchType="companies"
                searchTypes={['companies']}
                searchText={clientUrl}
                buttonForValue={buttonForValue}
            />
        ) : null;

    const cbFiltersDialog = (
        <CBFiltersDialog
            key={crunchbaseFiltersOpenIndex ?? -1}
            open={crunchbaseFiltersOpenIndex !== undefined}
            readonly={readonly}
            initialFilters={companies[crunchbaseFiltersOpenIndex]?.crunchbaseFilters}
            onConfirm={handleUpdateCBFilters}
            onClose={handleCloseCBFiltersDialog}
        />
    );

    return (
        <>
            {companiesLists}
            {similaritySearch}
            {cbFiltersDialog}
        </>
    );
};
