import css from '@emotion/css';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { isEqual, orderBy } from 'lodash';
import React from 'react';

import { ResultSort, SearchConfig } from 'shared/models/search';
import { hasRole } from 'shared/models/user';

import { fullDate } from '../common/timestamp';
import { useModal } from '../hooks/use-modal';
import { useReduxState } from '../hooks/use-redux';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { SearchStatus } from '../state';
import { searchDefaults } from './search-defaults';
import { useSearch } from './use-search';
import { useSearchPresets } from './use-search-presets';

const styles = css`
    .MuiPaper-root {
        min-width: 640px;
    }

    .MuiDialogContent-root {
        padding: 0;

        .loading-data {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 40px;
        }

        .no-presets {
            text-align: center;
            padding: 40px 0 20px;
            font-size: 12px;
            text-transform: uppercase;
        }

        .preset-user {
            font-size: 12px;
            margin-left: 20px;
        }

        .delete-button {
            width: 0;
            opacity: 0;
            transition: all 0.2s;
        }

        .MuiList-root {
            padding: 0;
        }

        .MuiListItem-root {
            padding: 10px 30px;

            &:hover {
                .delete-button {
                    margin-left: 10px;
                    width: 26px;
                    opacity: 1;
                }
            }
        }
    }

    .MuiDialogTitle-root {
        padding: 20px 30px 0;
    }

    .filter-field {
        margin-top: 10px;
    }
`;

export const SearchLoadSavePreset: React.FC = () => {
    const { user, userPermissions } = useSession();
    const { setSnackbar } = useSnackbar();
    const { getConfirmation } = useModal();
    const { onChange, data: searchData, job, client } = useSearch();
    const { config, resultsSort: sort } = searchData;
    const [loadingSearchActive, setLoadingSearchActive] = React.useState(false);
    const [savingSearchActive, setSavingSearchActive] = React.useState(false);
    const [searchName, setSearchName] = React.useState('');
    const [filterText, setFilterText] = React.useState('');
    const [sharedSearch, setSharedSearch] = React.useState(hasRole(userPermissions, 'save_search_presets'));
    const { searchPresets, createPreset, updatePreset, loading: loadingPresets } = useSearchPresets();
    const users = useReduxState((state) => state.users);

    const defaultSearchConfig = searchDefaults(user, job, client).config;
    const isCurrentSearchDefault = isEqual(searchData.config, defaultSearchConfig);
    const savedSearch = searchData.status && searchData.status !== SearchStatus.Initial;

    const handleClickSavePreset = () => {
        setSavingSearchActive(true);
    };

    const handleClickLoadPreset = () => {
        setLoadingSearchActive(true);
    };

    const handleCloseLoadSearchDialog = () => {
        setLoadingSearchActive(false);
        setFilterText('');
    };

    const handlePresetDelete = (id: string) => async (e: React.MouseEvent) => {
        e.stopPropagation();
        getConfirmation(
            () => {
                setSnackbar('Deleting Preset');
                updatePreset(id, { deprecated: true }).then(() => {
                    setSnackbar('Preset Deleted');
                });
            },
            'This will remove this preset permanently',
            'Delete Preset'
        );
    };

    const handleLoadSearch = (data: { config: SearchConfig; sort: ResultSort }) => () => {
        const onConfirm = () => {
            onChange({ ...searchData, config: data.config, resultsSort: data.sort });
            setLoadingSearchActive(false);
            setSnackbar('Preset Loaded');
        };
        if (isCurrentSearchDefault) {
            onConfirm();
        } else {
            getConfirmation(
                onConfirm,
                'This will overwrite the search parameters completely with settings from the preset',
                'Overwrite search'
            );
        }
    };

    const handleCloseSavingSearchDialog = () => {
        setSavingSearchActive(false);
        setSearchName('');
    };

    const handleSaveSearch = async () => {
        setSavingSearchActive(false);
        setSearchName('');
        setSnackbar('Saving Preset');
        const group = sharedSearch ? 'search-shared' : 'search-personal';
        const list: any = { config, sort }; // type hack
        await createPreset({ group, groupLabel: 'Search', list, name: searchName });
        setSnackbar('Preset Saved');
    };

    const handleSearchNameChange = (event: React.ChangeEvent<{ value: string }>) => {
        setSearchName(event.target.value);
    };

    const handleSearchGroupChange = () => setSharedSearch(!sharedSearch);

    const handleChangeFilterText = (event: React.ChangeEvent<{ value: string }>) => {
        setFilterText(event.target.value);
    };

    let presetsSelectDialog;
    let filterField;
    if (loadingSearchActive) {
        let content;
        if (!searchPresets) {
            content = (
                <div className="loading-data">
                    <CircularProgress />
                </div>
            );
        } else {
            const presets = orderBy(
                searchPresets.filter(
                    (p) =>
                        ((p.group === 'search-personal' && p.createdBy === user.id) || p.group === 'search-shared') &&
                        (filterText.length === 0 ||
                            p.name.toLowerCase().includes(filterText.toLowerCase()) ||
                            users.get(p.createdBy)?.name?.full.toLowerCase().includes(filterText.toLowerCase()))
                ),
                [(p) => (p.createdBy === user.id ? 0 : 1), (p) => p.createdAt],
                ['asc', 'desc']
            );
            const list = presets.map((p) => {
                const deleteButton =
                    p.createdBy === user.id ? (
                        <IconButton className="delete-button" size="small" onClick={handlePresetDelete(p.id)}>
                            <Delete fontSize="small" />
                        </IconButton>
                    ) : null;
                const owner = users.get(p.createdBy);
                return (
                    // type hack for handling overridden field list in the db
                    <ListItem button={true} key={p.id} onClick={handleLoadSearch(p.list as any)}>
                        <ListItemText primary={p.name} secondary={fullDate(p.createdAt)} />
                        <div className="preset-user">{owner?.name?.full}</div>
                        {deleteButton}
                    </ListItem>
                );
            });
            const selectionContent =
                list.length === 0 ? <div className="no-presets">No Presets Found</div> : <List>{list}</List>;
            content = <div>{selectionContent}</div>;
            filterField =
                list.length > 0 ? (
                    <div className="filter-field">
                        <TextField
                            fullWidth={true}
                            value={filterText}
                            placeholder="Filter Presets"
                            onChange={handleChangeFilterText}
                        />
                    </div>
                ) : null;
        }
        presetsSelectDialog = (
            <Dialog open={true} onClose={handleCloseLoadSearchDialog} css={styles}>
                <DialogTitle>
                    <Typography variant="h4" component="span">
                        Load Search Preset
                    </Typography>
                    {filterField}
                </DialogTitle>
                <DialogContent>{content}</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseLoadSearchDialog} disabled={!searchData}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    let savingPresetsDialog;
    if (savingSearchActive) {
        const searchGroupToggle = hasRole(userPermissions, 'save_search_presets') ? (
            <div style={{ marginTop: '20px' }}>
                <FormControlLabel
                    control={<Checkbox checked={sharedSearch} onChange={handleSearchGroupChange} name="group-change" />}
                    label="Share Search Preset"
                />
            </div>
        ) : null;
        savingPresetsDialog = (
            <Dialog open={true} onClose={handleCloseSavingSearchDialog} maxWidth="sm" fullWidth={true}>
                <DialogTitle>
                    <Typography variant="h4" component="span">
                        Save Search Preset
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth={true}
                        value={searchName}
                        placeholder="Add a descriptive name for the search"
                        onChange={handleSearchNameChange}
                    />
                    {searchGroupToggle}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSavingSearchDialog} disabled={loadingPresets}>
                        Cancel
                    </Button>
                    <Button onClick={handleSaveSearch} disabled={loadingPresets}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    const loadSearchButton = savedSearch ? null : (
        <div className="job-search-ui-type-label" onClick={handleClickLoadPreset}>
            Load a Preset
        </div>
    );
    const saveSearchButton = isCurrentSearchDefault ? null : (
        <div className="job-search-ui-type-label" onClick={handleClickSavePreset}>
            Save as Preset
        </div>
    );

    return (
        <div>
            {loadSearchButton}
            {saveSearchButton}
            {presetsSelectDialog}
            {savingPresetsDialog}
        </div>
    );
};
