import { css } from '@emotion/core';
import React from 'react';

import { SearchStatus } from '../state';
import { useSearch } from './use-search';
import { SearchConfigSection, useSearchSections } from './use-search-sections';

interface SearchRequirementsSectionProps {
    section: SearchConfigSection;
    className?: string;
}

const styles = css`
    .search-requirements-section-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: -4px;

        .search-section-remove {
            width: 0;
            opacity: 0;
            margin-top: 2px;
            cursor: pointer;
        }
    }

    &:hover .search-requirements-section-actions .search-section-remove {
        width: 20px;
        opacity: 1;
        margin-right: 10px;
        transition: width, opacity 1s;
    }
`;

export const SearchRequirementsSection: React.FC<SearchRequirementsSectionProps> = ({
    section,
    className,
    children
}) => {
    const [collapsed, setCollapsed] = React.useState(false);
    const { data } = useSearch();
    const { enabledSections, removeSection, isSectionEmpty, sectionTitle } = useSearchSections();

    const handleToggle = () => {
        setCollapsed(!collapsed);
    };

    const handleRemoveSection = (e: React.MouseEvent) => {
        e.stopPropagation();
        removeSection(section);
    };

    const content = collapsed ? null : children;
    const toggle = (
        <div className="search-section-toggle">
            <i className="material-icons">{collapsed ? 'expand_more' : 'expand_less'}</i>
        </div>
    );
    const removeButton =
        !data.status || data.status === SearchStatus.Initial ? (
            <div className="search-section-remove" onClick={handleRemoveSection}>
                <i className="material-icons">highlight_off</i>
            </div>
        ) : null;

    const savedSearch = data.status && data?.status !== SearchStatus.Initial;
    if ((!enabledSections.includes(section) || savedSearch) && isSectionEmpty(section)) {
        return null;
    }

    return (
        <div className={`search-requirements-section ${className || ''}`} css={styles}>
            <div className="search-requirements-section-title" onClick={handleToggle}>
                <div>{sectionTitle(section)}</div>
                <div className="search-requirements-section-actions">
                    {removeButton}
                    {toggle}
                </div>
            </div>
            {content}
            <div className="section-border" />
        </div>
    );
};
