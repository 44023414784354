import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import React, { useEffect, useState } from 'react';

interface DurationProgressProps extends LinearProgressProps {
    duration: number;
}

const msInSecond = 1000;
const intervalMs = 100;

export const DurationProgress: React.FC<DurationProgressProps> = ({ duration, ...linearProgressProps }) => {
    const [progress, setProgress] = useState(0);
    const [switchToIndeterminate, setSwitchToIndeterminate] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    clearInterval(timer);
                    setSwitchToIndeterminate(true);
                } else {
                    const newProgress = oldProgress + 100 / ((duration * msInSecond) / intervalMs);
                    return Math.min(newProgress, 100);
                }
            });
        }, intervalMs);

        return () => {
            clearInterval(timer);
        };
    }, [duration]);

    return switchToIndeterminate ? (
        <LinearProgress variant="indeterminate" {...linearProgressProps} />
    ) : (
        <LinearProgress variant="determinate" value={progress} {...linearProgressProps} />
    );
};
