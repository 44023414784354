import { gql } from '@apollo/client';

export interface Candidate {
    personId: string;
    jobId: string;
    lastStageChangedAt: number;
    job: {
        title: string;
        client: {
            name: string;
        };
        contract: string;
    };
    assignee: {
        name: string;
        id: string;
    };
    accountManager: {
        id: string;
        name: string;
    };
    person: {
        name: string;
    };
    recruiterPercentage: number;
    accountManagerPercentage: number;
    hiredAt: number;
    hiredSalary: string;
    fees: number;
    startDate: number;
    paidAt: number;
    signOnBonus: string;
    prePaidFees: number;
    invoicedAt: number;
    recruiterCommission: number;
    amCommission: number;
    recruiterCommissionPaidAt: number;
    amCommissionPaidAt: number;
}

export const CANDIDATE_BILLINGS = gql`
    subscription Billings($jobType: [String!], $startTime: bigint!, $endTime: bigint!, $excludeClientIds: [String!]) {
        candidates(
            where: {
                job: { jobType: { _in: $jobType }, clientId: { _nin: $excludeClientIds } }
                stage: { _eq: "hired" }
                disqualified: { _eq: false }
                hiredAt: { _gte: $startTime, _lte: $endTime }
            }
            order_by: [{ lastStageChangedAt: desc }]
        ) {
            personId
            jobId
            lastStageChangedAt
            job {
                title
                client {
                    id
                    name
                }
                contract: details(path: "placementFees")
            }
            accountManager: userByAccountmanagerid {
                id
                name(path: "full")
            }
            assignee: userByAssignee {
                name(path: "full")
                id
            }
            person {
                name(path: "full")
            }
            recruiterPercentage
            accountManagerPercentage
            hiredAt
            hiredSalary
            fees
            startDate
            paidAt
            signOnBonus
            prePaidFees
            invoicedAt
            recruiterCommission
            amCommission
            recruiterCommissionPaidAt
            amCommissionPaidAt
        }
    }
`;
