import * as _ from 'lodash';

import { mixpanel } from './common/mixpanel';
import { config } from './config';
import { store } from './configure-store';
import { Candidate } from './state';

const MixpanelToken = '6c2afaf9ba8c45585623da5b773e97f9';

const candidateAddedToJob = 'sourcing_candidate_added';
const statusChangedEvent = 'status_changed';
const disqualifiedEvent = 'status_disqualified';
const requalifiedEvent = 'status_requalified';
const contactCreatedEvent = 'contact_created';
const csvDownloadedEvent = 'outreach_csv_downloaded';
const emailDownloadedEvent = 'outreach_email_downloaded';
const sourcingProfileViewEvent = 'sourcing_profile_view';
const tabAutoCloseRequestEvent = 'tab_auto_closed_requested';
const pageViewEvent = 'page_view';
const extensionJsonLoadedEvent = 'extension_json_loaded';
const extensionHtmlLoadedEvent = 'extension_html_loaded';
const extensionLoadRetryEvent = 'extension_load_retry';
const extensionHtmlParsingEvent = 'extension_parsing_html';
const extensionDataLoadErrorEvent = 'extension_data_load_error';
const extensionHtmlIncompleteLoadEvent = 'extension_html_incomplete_load';

const track = (
    eventName: string,
    params?: {
        [index: string]: any;
    }
) => {
    if (config.Env === 'production') {
        mixpanel.track(eventName, params);
    } else {
        // tslint:disable:no-console
        const secondary = 'color: rgba(255, 255, 255, 0.4); font-weight: normal';
        const em = 'font-weight: bold';
        const ts = new Date().toLocaleTimeString();
        console.groupCollapsed('%c track %c%s %c@ %s', secondary, em, eventName, secondary, ts);
        for (const key of _.sortBy(Object.keys(params))) {
            const val = params[key];
            if (val) {
                console.log(' %c%s %c%s', secondary, key, em, val);
            }
        }
        console.groupEnd();
        // tslint:enable:no-console
    }
};

export const initializeMixpanel = () => {
    if (config.Env === 'production') {
        mixpanel.init(MixpanelToken);
    }
};

export const identify = (email: string) => {
    if (config.Env === 'production') {
        mixpanel.people.set({ email });
        mixpanel.identify(email);
    }
};

export const trackCandidateStateChange = (candidate: Candidate, finalStage: string) => {
    const state = store.getState();
    const job = state.jobs.get(candidate.jobId);
    const event = {
        candidate_id: candidate.personId,
        candidate_source: candidate.source,
        client_id: job.clientId,
        client_name: state.clients.list.get(job.clientId).name,
        job_rec_id: job.id,
        job_rec_name: job.title,
        person_id: state.persons.list.get(candidate.personId).id,
        recruiter: state.users.get(state.jobs.get(candidate.jobId).assignee).email,
        session_user: state.users.get(state.session.user.id).email,
        stage_from: candidate.stage,
        stage_to: finalStage
    };
    track(statusChangedEvent, event);
};

export const trackCandidateQualification = (candidate: Candidate, qualified: boolean) => {
    const eventName = qualified ? requalifiedEvent : disqualifiedEvent;
    const state = store.getState();
    const job = state.jobs.get(candidate.jobId);
    const event = {
        candidate_id: candidate.personId,
        candidate_source: candidate.source,
        client_id: job.clientId,
        client_name: state.clients.list.get(job.clientId).name,
        job_rec_id: job.id,
        job_rec_name: job.title,
        person_id: state.persons.list.get(candidate.personId).id,
        recruiter: state.users.get(state.jobs.get(candidate.jobId).assignee).email,
        session_user: state.users.get(state.session.user.id).email,
        stage: candidate.stage
    };
    track(eventName, event);
};

// Metric To Measure: Candidates added to job rec (sourced)
// Event Name: sourcing_candidate_added
export const trackCandidateAddedToJob = (
    personId: string,
    profileUrl: string,
    jobId: string,
    source: string,
    stage: string
) => {
    const state = store.getState();
    const job = state.extension.jobs.find((j) => j.id === jobId);
    const recruiter = state.session.user.email;
    const jobRecName = job.title;
    const clientName = job.client;

    const event = {
        candidate_source: source,
        candidate_url: profileUrl,
        client_name: clientName,
        job_rec_id: jobId,
        job_rec_name: jobRecName,
        person_id: personId,
        recruiter,
        session_user: state.users.get(state.session.user.id).email,
        stage
    };
    track(candidateAddedToJob, event);
};

export const trackContactAdded = (personId: string, channel: string, value: string) => {
    const state = store.getState();
    const event = {
        contact_channel: channel,
        contact_value: value,
        person_id: personId,
        recruiter: state.session.user.email
    };
    track(contactCreatedEvent, event);
};

export const trackCSVDownloaded = (jobId: string, candidates: Candidate[]) => {
    const state = store.getState();
    const job = state.jobs.get(jobId);
    const common = {
        client_id: job.clientId,
        client_name: state.clients.list.get(job.clientId).name,
        job_rec_id: job.id,
        job_rec_name: job.title,
        recruiter: state.users.get(state.jobs.get(jobId).assignee).email,
        session_user: state.users.get(state.session.user.id).email
    };
    track(csvDownloadedEvent, Object.assign({}, common, { batch_size: candidates.length }));
    for (const candidate of candidates) {
        const event = Object.assign({}, common, {
            candidate_id: candidate.personId,
            candidate_source: candidate.source,
            person_id: candidate.personId
        });
        track(emailDownloadedEvent, event);
    }
};

export const trackCandidateProfileView = () => {
    const state = store.getState();
    const profile = state.extension.profile;
    const url = profile.publicLink || profile.recruiterLiteLink;
    const jobId = state.extension.selectedJobId;
    const job = jobId ? state.jobs.get(jobId) : undefined;
    const jobName = job ? job.title : undefined;
    const clientId = job ? job.clientId : undefined;
    const clientName = clientId && state.clients.list.get(clientId) ? state.clients.list.get(clientId).name : undefined;
    track(sourcingProfileViewEvent, {
        candidate_url: url,
        client_id: clientId,
        client_name: clientName,
        job_rec_id: state.extension.selectedJobId,
        job_rec_name: jobName,
        person_id: state.extension.person.id,
        recruiter: state.users.get(state.session.user.id).email,
        session_user: state.users.get(state.session.user.id).email
    });
};

export const trackTabAutoCloseRequest = (reason: string, params: { [key: string]: any }) => {
    const state = store.getState();
    const profile = state.extension.profile;
    const url = profile.publicLink || profile.recruiterLiteLink;
    const jobId = state.extension.selectedJobId;
    const job = jobId ? state.jobs.get(jobId) : undefined;
    const jobName = job ? job.title : undefined;
    const clientId = job ? job.clientId : undefined;
    const clientName = jobId ? state.clients.list.get(job.clientId).name : undefined;
    track(
        tabAutoCloseRequestEvent,
        Object.assign({}, params, {
            candidate_url: url,
            client_id: clientId,
            client_name: clientName,
            job_rec_id: state.extension.selectedJobId,
            job_rec_name: jobName,
            person_id: state.extension.person.id,
            reason,
            recruiter: state.users.get(state.session.user.id).email,
            session_user: state.users.get(state.session.user.id).email
        })
    );
};

export const trackPageView = (location: string) => {
    track(pageViewEvent, { location });
};

export const trackExtensionLoadRetry = (url: string, delay: number) => {
    track(extensionLoadRetryEvent, { url, delay });
};

export const trackExtensionJsonLoaded = (url: string, delay: number) => {
    track(extensionJsonLoadedEvent, { url, delay });
};

export const trackExtensionHtmlLoaded = (url: string, delay: number) => {
    track(extensionHtmlLoadedEvent, { url, delay });
};

export const trackExtensionHtmlParsing = (url: string, delay: number) => {
    track(extensionHtmlParsingEvent, { url, delay });
};

export const trackExtensionDataLoadError = (url: string, delay: number) => {
    track(extensionDataLoadErrorEvent, { url, delay });
};

export const trackExtensionHtmlProfileIncompleteLoad = (url: string, loadMoreCount: number, delay: number) => {
    track(extensionHtmlIncompleteLoadEvent, { url, loadMoreCount, delay });
};
