import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { isEqual } from 'lodash';
import React from 'react';

import { JobSequencesForm } from '../containers/job-sequences-form';
import { JobFormData, JobUpdateData } from '../graphql/queries/job-form';
import { useModal } from '../hooks/use-modal';
import { useSnackbar } from '../hooks/use-snackbar';
import { FieldPaths } from '../types/field-paths';
import { FormContext, FormContextProps } from './form-context';
import { JobCloneButton } from './job-clone-button';
import { JobFormAIContext } from './job-form-ai-context';
import { JobFormCompanyInfo } from './job-form-company-info';
import { JobFormContract } from './job-form-contract';
import { JobFormGeneral } from './job-form-general';
import { JobFormIntroInfo } from './job-form-intro-info';
import { JobFormJD } from './job-form-jd';
import { JobFormOutreach } from './job-form-outreach';
import { JobFormPanel } from './job-form-panel';
import { JobFormRecruiterPermissions } from './job-form-recruiter-permissions';
import { JobFormSubmissionInfo } from './job-form-submission-info';
import { JobFormTemplates } from './job-form-templates';
import { JobFunnelTargetsForm } from './job-funnel-targets-form';

interface JobFormProps {
    job: JobFormData;
    onSave: (data: JobUpdateData) => Promise<void>;
    disabled: boolean;
}

const styles = css`
    margin: 0 auto;
    width: 960px;
    padding-bottom: 40px;

    .job-form-buttons {
        display: flex;
        justify-content: space-between;
    }
`;

export const JobEditForm: React.FC<JobFormProps> = ({ job, disabled: propDisabled, onSave }) => {
    const [data, setData] = React.useState<JobFormData>(job);
    const [savedData, setSavedData] = React.useState<JobFormData>(job);
    const [saving, setSaving] = React.useState(false);
    const [isDirty, setIsDirty] = React.useState(false);
    const [errors, setErrors] = React.useState<Map<FieldPaths<JobFormData>, string>>(new Map());
    const { getConfirmation, showDialog, hideDialog } = useModal();
    const { setSnackbar } = useSnackbar();

    const disabled = propDisabled || saving;

    const getDataToSave = (input: JobFormData): JobUpdateData => {
        const {
            client: { id: _1, __typename: _2, name, ...updatedClient },
            description: { jobId, __typename: _3, _id: _4, ...updatedDescription },
            id: _5,
            __typename: _6,
            ...updatedJob
        } = input;
        return {
            client: updatedClient,
            clientId: input.clientId,
            description: updatedDescription,
            job: updatedJob,
            jobId: input.id
        };
    };

    const handleSubmit = async () => {
        try {
            setSnackbar('Saving...');
            setSaving(true);
            await onSave(getDataToSave(data));
            setSaving(false);
            setIsDirty(false);
            setErrors(new Map());
            setSnackbar('Saved');
        } catch {
            setSnackbar('Failed to save');
        }
    };

    const handleReset = () => {
        setSavedData(job);
        setData(job);
        setIsDirty(false);
        setErrors(new Map());
    };

    const handleResetButtonClick = () => {
        getConfirmation(handleReset, 'Are you sure you want to discard all changes?', 'Discard changes');
    };

    const handleReloadData = () => {
        handleReset();
        hideDialog();
        setSnackbar('Data updated to the latest version');
    };

    const reloadDialog = (
        <Dialog open={true}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Job Data Changed
                </Typography>
            </DialogTitle>
            <DialogContent style={{ lineHeight: '1.4rem' }}>
                Job data has been changed elsewhere. Press OK to reset to the latest data.
            </DialogContent>
            <DialogActions>
                <Button onClick={handleReloadData}>OK</Button>
            </DialogActions>
        </Dialog>
    );

    React.useEffect(() => {
        // check if the savedData we have is different from the data from the props
        if (!isEqual(savedData, job)) {
            if (!isDirty) {
                // if the form is not dirty, update the form data
                setData(job);
                setSavedData(job);
            } else if (isEqual(data, job)) {
                // if the data in the form is the same as the data from the props, update the saved data
                setSavedData(job);
            } else {
                showDialog(reloadDialog);
            }
        }
    }, [job]);

    const handleChange = (updated: Partial<JobFormData>) => {
        setIsDirty(true);
        setData((prev) => ({ ...prev, ...updated }));
    };

    const handleFieldChange = <K extends keyof JobFormData>(key: K) => (val: JobFormData[K]) => {
        handleChange({ [key]: val });
    };

    const handleOnePagerChange = (onePager: { key: string; size: number }) => {
        // one pager gets updated in the db directly - update the saved data and the form data directly
        // skip setting the dirty flag
        setSavedData((prev) => ({ ...prev, onePager }));
        setData((prev) => ({ ...prev, onePager }));
    };

    const validateFieldExists = <K extends keyof JobFormData>(key: K) => (val: JobFormData[K]) => {
        if ((typeof val === 'string' && val.trim().length === 0) || val === null || val === undefined) {
            setErrors((prev) => {
                const next = new Map(prev);
                next.set(key, 'Required');
                return next;
            });
        }
    };

    const handleSetError = (key: FieldPaths<JobFormData>, message: string) => {
        setErrors((prev) => {
            const next = new Map(prev);
            if (message) {
                next.set(key, message);
            } else {
                next.delete(key);
            }
            return next;
        });
    };

    const getError = (key: FieldPaths<JobFormData>) => errors.get(key);

    const context: FormContextProps<JobFormData> = {
        data,
        disabled,
        getError,
        isDirty,
        onChange: handleChange,
        onFieldChange: handleFieldChange,
        setError: handleSetError,
        validateFieldExists
    };

    const resetButton = isDirty ? (
        <Button onClick={handleResetButtonClick} disabled={disabled}>
            Discard
        </Button>
    ) : null;
    const cloneButton = isDirty ? null : <JobCloneButton data={data} disabled={disabled} />;

    return (
        <div css={styles}>
            <FormContext.Provider value={context}>
                <JobFormPanel>
                    <JobFormGeneral />
                </JobFormPanel>
                <JobFormPanel title="Job Description" subtitle="View/Edit Detailed JD">
                    <JobFormJD onOnePagerChange={handleOnePagerChange} />
                </JobFormPanel>
                <JobFormPanel title="Company Info" subtitle="View/Edit Company Info">
                    <JobFormCompanyInfo />
                </JobFormPanel>
                <JobFormPanel title="AI Context Settings" subtitle="View/Edit Context used by AI">
                    <JobFormAIContext />
                </JobFormPanel>
                <JobFormPanel
                    title="Allocations & Funnel Targets"
                    subtitle="View/Edit Funnel Targets & Allocations for a 14 day period"
                >
                    <JobFunnelTargetsForm jobId={data.id} disabled={disabled} />
                </JobFormPanel>
                <JobFormPanel title="Outreach Settings" subtitle="View/Edit Outreach Settings">
                    <JobFormOutreach />
                </JobFormPanel>
                <JobFormPanel title="Email Sequences" subtitle="View/Edit Email Sequences">
                    <JobSequencesForm jobId={data.id} role={data?.client?.name} isEditable={!disabled} />
                </JobFormPanel>
                <JobFormPanel title="Submission Settings" subtitle="View/Edit Submission Settings">
                    <JobFormSubmissionInfo />
                </JobFormPanel>
                <JobFormPanel title="Introduction Settings" subtitle="View/Edit Client-Candidate Intro Settings">
                    <JobFormIntroInfo />
                </JobFormPanel>
                <JobFormPanel title="Job Templates" subtitle="View/Edit Job Templates">
                    <JobFormTemplates />
                </JobFormPanel>
                <JobFormPanel title="Recruiter Permissions" subtitle="View/Edit Recruiter Permissions">
                    <JobFormRecruiterPermissions />
                </JobFormPanel>
                <JobFormPanel title="Contract" subtitle="View/Edit Job Contract Details">
                    <JobFormContract />
                </JobFormPanel>

                <div className="job-form-buttons">
                    <div>{cloneButton}</div>
                    <div>
                        {resetButton}
                        <Button type="submit" disabled={!isDirty || disabled} onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                </div>
            </FormContext.Provider>
        </div>
    );
};
