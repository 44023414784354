import { diff, flattenChangeset } from 'json-diff-ts';

import { RawJsonProfile } from '../types/li-json-profile';
import { PROFILE_ELEMENT_SELECTOR } from './message-listeners';

export function isJsonProfileDifferent(json1: RawJsonProfile, json2: RawJsonProfile) {
    return (
        flattenChangeset(diff(json1, json2)).filter(
            (c) => c.key !== 'html' && c.type !== 'UPDATE' && c.path !== '$.profile.html'
        ).length !== 0
    );
}

export function isHtmlProfileDifferent(html1: string, html2: string) {
    if (!html1 || !html2) return true;
    const parser = new DOMParser();
    const doc1 = parser.parseFromString(html1, 'text/html');
    const doc2 = parser.parseFromString(html2, 'text/html');
    const profileElement1 = doc1.querySelector(PROFILE_ELEMENT_SELECTOR);
    const profileElement2 = doc2.querySelector(PROFILE_ELEMENT_SELECTOR);
    return profileElement1.innerHTML !== profileElement2.innerHTML;
}
