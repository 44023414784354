import { gql } from '@apollo/client';

export interface ClientWithJobs {
    name: string;
    jobs: Array<{
        id: string;
        title: string;
        accountManagerId: string;
    }>;
}

export interface CompanyTag {
    id: string;
    source: string;
    tag: string;
    deleted: boolean;
    deletedBy: string;
}

export interface CompanyInvestment {
    id: string;
    investorName: string;
    investmentType: string;
    link: string;
    raisedAmount: number;
    deleted: boolean;
    deletedBy: string;
}

export interface JobSummary {
    id: string;
    title: string;
    createdAt: number;
}
export interface ClientWithJobCounts {
    id: string;
    name: string;
    createdAt: number;
    globalBlacklistForcedTill: number;
    activeJobs: JobSummary[];
    pausedJobs: JobSummary[];
    archivedJobs: JobSummary[];
    hiredCandidates: Array<{
        personId: string;
        jobId: string;
    }>;
    company: Company;
}

export interface Company {
    id: string;
    name: string;
    tags: CompanyTag[];
    investments: CompanyInvestment[];
}

export interface Client {
    id: string;
    name: string;
}

export const CLIENT_WITH_JOBS = gql`
    query ClientActiveJobs($id: String!) {
        client: clients_by_pk(id: $id) {
            name
            jobs(where: { status: { _neq: 3 } }) {
                id
                title
                accountManagerId
            }
        }
    }
`;

export const CLIENTS = gql`
    query Clients($excludeClientIds: [String!]) {
        clients(where: { id: { _nin: $excludeClientIds } }, order_by: { name: asc }) {
            id
            name
            createdAt
            globalBlacklistForcedTill
            activeJobs: jobs(where: { status: { _eq: 1 } }, order_by: { createdAt: desc }) {
                id
                title
                createdAt
            }
            pausedJobs: jobs(where: { status: { _eq: 2 } }, order_by: { createdAt: desc }) {
                id
                title
                createdAt
            }
            archivedJobs: jobs(where: { status: { _eq: 3 } }, order_by: { createdAt: desc }) {
                id
                title
                createdAt
            }
            hiredCandidates: candidates(
                where: { stage: { _eq: "hired" }, disqualified: { _eq: false } }
                order_by: { hiredAt: desc }
            ) {
                personId
                jobId
            }
            company {
                id
                name
                tags(where: { deleted: { _eq: false } }) {
                    id
                    source
                    tag
                    deleted
                    deletedBy
                }
                investments(where: { deleted: { _eq: false } }) {
                    id
                    investorName
                    investmentType
                    link
                    raisedAmount
                    deleted
                    deletedBy
                }
            }
        }
    }
`;

export const HOT_CLIENTS = gql`
    query HotClients {
        clients(order_by: { name: asc }, where: { hot: { _eq: true } }) {
            id
        }
    }
`;

export const ALL_CLIENTS = gql`
    query AllClients {
        clients(order_by: { name: asc }) {
            id
            name
        }
    }
`;

export const CLIENT_HIRING_MANAGERS = gql`
    query ClientHiringManagers($clientId: String!) {
        client: clients_by_pk(id: $clientId) {
            id
            hiringManagers
        }
    }
`;

export const CLIENT_UPDATE = gql`
    mutation UpdateClient($clientId: String!, $updates: clients_set_input!) {
        update_clients_by_pk(pk_columns: { id: $clientId }, _set: $updates) {
            id
        }
    }
`;

export const CREATE_CLIENT = gql`
    mutation CreateClient($client: clients_insert_input!) {
        client: insert_clients_one(object: $client) {
            id
        }
    }
`;
