import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Button, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { Add, AttachFile, Delete } from '@material-ui/icons';
import { Map } from 'immutable';
import React from 'react';
import Dropzone, { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { connect } from 'react-redux';

import { Permissions } from 'shared/models/permission';
import { hasRole } from 'shared/models/user';
import { HrefFilePayload } from 'shared/types/file-payload';

import { getConfirmation, removeClientFile, uploadClientFiles } from '../actions';
import { FileDownloadLink } from '../core-ui/file-download-link';
import { CLIENT_WITH_JOBS, ClientWithJobs } from '../graphql/queries/clients';
import { useSession } from '../hooks/use-session';
import { readFile } from '../lib/read-file-payload';
import { Client, List, RequestErrors, State } from '../state';

const styles = css`
    .client-contracts-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        label {
            color: #546e7a;
            font-size: 0.75rem;
        }
        .files {
            margin-top: 6px;
            .file {
                .delete-icon {
                    visibility: hidden;
                }
                &:hover {
                    .delete-icon {
                        visibility: visible;
                    }
                }
            }
        }

        .file {
            display: flex;
            align-items: center;
            margin-top: 6px;

            .delete-icon {
                margin-left: 10px;
            }
        }

        .upload-button {
            margin-top: 6px;
            border-radius: 4px;
            border: 1px solid #ccc;
            padding: 5px 15px;
            cursor: pointer;

            &:hover {
                background-color: rgba(38, 50, 56, 0.04);
            }
            .label {
                display: flex;
                color: #263238;
                font-size: 0.875rem;
                font-weight: 500;
                align-items: center;
                text-transform: uppercase;

                .startIcon {
                    display: flex;
                    margin-left: -4px;
                    margin-right: 4px;
                }
            }
        }
    }
`;

const spinnerSize = 20;
const spinnerThickness = 2;

interface ConnectedProps {
    clients: List<Client>;
    errors: RequestErrors;
    isCreating: boolean;
    pendingRequests: Map<string, RequestErrors>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    getConfirmation: (onConfirm: () => void, description?: string | JSX.Element, title?: string) => void;
    removeClientFile: (clientId: string, key: string) => void;
    uploadClientFiles: (id: string, payload: HrefFilePayload[]) => void;
}

interface OwnProps {
    clientId: string;
}

type ClientContractsProps = OwnProps & ConnectedProps & ConnectedDispatch;

export const ClientContractsComponent: React.FC<ClientContractsProps> = (props) => {
    const { clientId, clients, isCreating, pendingRequests, userPermissions } = props;
    const { user } = useSession();
    const { data: clientData } = useQuery<{ client: ClientWithJobs }, { id: string }>(CLIENT_WITH_JOBS, {
        variables: { id: clientId }
    });

    const isAM = clientData ? !!clientData.client.jobs.find((j) => j.accountManagerId === user.id) : false;

    const isUploadingContracts = () => {
        return (
            pendingRequests.has(`client-files-upload-${clientId}`) &&
            pendingRequests.get(`client-files-upload-${clientId}`).isEmpty()
        );
    };

    const handleDropContracts = (files: File[]) => {
        if (!isUploadingContracts()) {
            Promise.all(files.map(readFile)).then((result) => {
                props.uploadClientFiles(clientId, result);
            });
        }
    };

    const removeContract = (key: string) => {
        props.removeClientFile(clientId, key);
    };

    const handleRemoveContract = (key: string) => () => {
        props.getConfirmation(
            () => {
                removeContract(key);
            },
            'Are you sure you want to delete this contract?',
            'Confirm remove contract'
        );
    };

    const isBillingAdmin = hasRole(userPermissions, 'billing_admin');
    const isDisabled = isCreating || !isBillingAdmin;

    const clientContracts =
        clientId && clients.list.get(clientId)?.contracts
            ? clients.list
                  .get(clientId)
                  .contracts.filter((c) => !c.deleted)
                  .map((c) => {
                      return {
                          filename: c.key.split('/').reverse()[0],
                          key: c.key,
                          path: `media/files/${c.key}`,
                          size: c.size,
                          type: 's3Key'
                      };
                  })
            : [];

    const contractsList =
        isBillingAdmin || isAM
            ? clientContracts?.map((a) => {
                  const deleteButton = isBillingAdmin ? (
                      <Tooltip title="Remove contract">
                          <IconButton className="delete-icon" onClick={handleRemoveContract(a.key)}>
                              <Delete />
                          </IconButton>
                      </Tooltip>
                  ) : null;
                  return (
                      <div className="file" key={a.key}>
                          <FileDownloadLink path={a.path} filename={a.filename}>
                              <Button variant="outlined" startIcon={<AttachFile />}>
                                  {a.filename}
                              </Button>
                          </FileDownloadLink>
                          {deleteButton}
                      </div>
                  );
              })
            : null;

    const getDropZoneSection = (
        getRootProps: <T extends DropzoneRootProps>(props?: T) => T,
        getInputProps: <T extends DropzoneInputProps>(props?: T) => T
    ) => {
        return (
            <section className="upload-button">
                <div {...getRootProps()}>
                    <input {...getInputProps()} disabled={isDisabled || isUploadingContracts()} />
                    <span className="label">
                        <span className="startIcon">
                            <Add />
                        </span>
                        Add File
                    </span>
                </div>
            </section>
        );
    };

    const uploadZone = isUploadingContracts() ? (
        <div>
            <CircularProgress size={spinnerSize} thickness={spinnerThickness} />
        </div>
    ) : (
        <Dropzone onDrop={handleDropContracts}>
            {({ getRootProps, getInputProps }) => getDropZoneSection(getRootProps, getInputProps)}
        </Dropzone>
    );

    if (!isBillingAdmin && !isAM) return null;

    const contractsSection = (
        <div className="client-contracts-container">
            <label htmlFor="upload-button">Contract - MSA/SOW</label>
            <div className="files">{contractsList}</div>
            {uploadZone}
        </div>
    );

    return <div css={styles}>{contractsSection}</div>;
};

const mapStateToProps = (state: State): ConnectedProps => ({
    clients: state.clients,
    errors: state.clients.formErrors,
    isCreating: state.clients.isCreating,
    pendingRequests: state.pendingRequests,
    userPermissions: state.session.userPermissions
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    getConfirmation,
    removeClientFile,
    uploadClientFiles
};

export const ClientContracts = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ClientContractsComponent);
