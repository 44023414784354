import { isNil } from 'lodash';
import { Avatar, CircularProgress } from 'material-ui';
import { orange700, white } from 'material-ui/styles/colors';
import { CommunicationComment, CommunicationEmail, SocialPerson } from 'material-ui/svg-icons';
import * as React from 'react';
import { connect } from 'react-redux';

import { personVisaOptionLabels, VisaStatus } from 'shared/models/person';

import { updatePerson } from '../actions';
import { SelectField } from '../core-ui/simple-select-field';
import { Person, RequestErrors, State } from '../state';

interface OwnProps {
    person: Person;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
}

interface ConnectedDispatch {
    updatePerson: (id: string, updates: Partial<Person>) => void;
}

type PersonVisaStatusProps = OwnProps & ConnectedDispatch & ConnectedProps;

const avatarSize = 18;

class PersonVisaStatusComponent extends React.Component<PersonVisaStatusProps> {
    requestKey = () => {
        return `person-update-request-${this.props.person.id}`;
    };

    handleVisaSelect = (visaStatus: VisaStatus) => {
        if (isNil(visaStatus)) {
            this.props.updatePerson(this.props.person.id, { visaStatus: null, visaStatusSource: null });
        } else {
            this.props.updatePerson(this.props.person.id, { visaStatus, visaStatusSource: 'user_input' });
        }
    };

    render() {
        const { pendingRequests, person } = this.props;

        const isSaving = pendingRequests.has(this.requestKey()) && pendingRequests.get(this.requestKey()).isEmpty();
        const spinnerSize = 20;
        const spinnerThickness = 2;
        const spinner = (
            <div className="prop-actions">
                <CircularProgress size={spinnerSize} thickness={spinnerThickness} />
            </div>
        );
        const visaSourceUserIcon = <Avatar size={avatarSize} color={white} icon={<SocialPerson />} />;
        const visaSourceEmailIcon = (
            <Avatar size={avatarSize} backgroundColor={orange700} color={white} icon={<CommunicationEmail />} />
        );
        const visaSourceNoteIcon = <Avatar size={avatarSize} color={white} icon={<CommunicationComment />} />;
        const visaSourceIcon = person.visaStatus
            ? person.visaStatusSource === 'user_input'
                ? visaSourceUserIcon
                : person.visaStatusSource === 'inferred_communications'
                ? visaSourceEmailIcon
                : person.visaStatusSource === 'inferred_notes'
                ? visaSourceNoteIcon
                : null
            : null;
        const attributes = isSaving ? spinner : visaSourceIcon;

        const getOptionLabel = (s: VisaStatus) => personVisaOptionLabels.get(s) || s;

        const maxMenuHeight = 300;
        return (
            <div className="person-props person-props-visa">
                <i className="fas fa-id-card section-icon" />
                <div className="prop">
                    <div className="person-prop-row">
                        <div className="person-prop-row-spaced">
                            <SelectField
                                selected={person.visaStatus}
                                options={personVisaOptionLabels.keySeq().toArray()}
                                getOptionLabel={getOptionLabel}
                                onSelect={this.handleVisaSelect}
                                hintText="ADD VISA"
                                className="form-text"
                                disabled={isSaving}
                                maxMenuHeight={maxMenuHeight}
                                targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                                includeNoneOpt={true}
                            />
                            {attributes}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: State): any => ({
    pendingRequests: state.pendingRequests
});

const mapStateToDispatch = {
    updatePerson
};

export const PersonVisaStatus = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapStateToDispatch
)(PersonVisaStatusComponent);
