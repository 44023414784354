import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import {
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Theme,
    Toolbar,
    Typography,
    useTheme
} from '@material-ui/core';
import { escapeRegExp, orderBy } from 'lodash';
import * as React from 'react';

import { SearchTextField } from '../common/search-text-field';
import { timeRelativeDay } from '../common/timestamp';
import { EmailAccount, UPDATE_SYNC_STATUS } from '../graphql/queries/email-accounts-table';
import { EmailSyncStatus } from '../state';

interface EmailAccountsTableProps {
    emailAccounts: EmailAccount[];
}

const styles = (theme: Theme) => css`
    max-width: calc(100% - 100px);
    padding: 25px 50px;
    background: #f4f6f8;
    flex: 1 1 auto;

    .status-select-field {
        font-size: 0.875rem;
    }

    .MuiPaper-root {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        .section-title {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            border-bottom: thin solid ${theme.palette.divider};
            line-height: 2.75;
        }

        .MuiTableContainer-root {
            flex: 1 1 auto;

            &::-webkit-scrollbar:horizontal {
                border-top: thin solid ${theme.palette.divider};
            }

            &::-webkit-scrollbar:vertical {
                border-left: thin solid ${theme.palette.divider};
            }

            .MuiTableRow-root:last-child {
                td.MuiTableCell-root.MuiTableCell-body {
                    border-bottom: none;
                }
            }

            .MuiTableRow-root {
                .status-label {
                    cursor: pointer;

                    .needs_reauth {
                        cursor: pointer;
                        font-weight: 600;
                        color: ${theme.palette.error.main};
                    }

                    .disabled {
                        color: ${theme.palette.warning.main};
                    }

                    .pending_initial_sync {
                        color: ${theme.palette.error.light};
                    }
                }
            }
        }
    }

    .table-footer {
        display: flex;
        justify-content: space-between;
        border-top: thin solid ${theme.palette.divider};
        align-items: center;
        padding: 20px 15px;
        font-size: 14px;

        .pagination {
            flex: 0 0 auto;
        }
    }
`;

const rowsPerPage = 20;

enum EmailAccountSort {
    NeedsReAuth = 1,
    PendingInitialSync,
    Enabled,
    Disabled
}

export const EmailAccountsTable: React.FC<EmailAccountsTableProps> = (props) => {
    const theme = useTheme();

    const [sortCol, setSortCol] = React.useState(null);
    const [sortAsc, setSortAsc] = React.useState<'asc' | 'desc'>('asc');
    const [search, setSearch] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [updateSyncStatus] = useMutation<{}, { email: string; syncStatus: EmailSyncStatus }>(UPDATE_SYNC_STATUS);

    const handleSortChange = (col: string) => () => {
        if (col === sortCol) {
            const newSortAsc = sortAsc === 'asc' ? 'desc' : 'asc';
            setSortAsc(newSortAsc);
        } else {
            setSortCol(col);
            setSortAsc('asc');
        }
    };

    const handleSearchChange = (value: string) => {
        setSearch(value);
        setPage(0);
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        // no-op
    };

    const sortFunc = (account: EmailAccount) => {
        switch (sortCol) {
            case 'Email':
                return account.email.toLocaleLowerCase();
            case 'Status':
                return account.syncStatus === 'needs_reauth'
                    ? EmailAccountSort.NeedsReAuth
                    : account.syncStatus === 'pending_initial_sync'
                    ? EmailAccountSort.PendingInitialSync
                    : account.syncStatus === 'enabled'
                    ? EmailAccountSort.Enabled
                    : EmailAccountSort.Disabled;
            case 'Last Sync':
                return account.lastSyncAt;
            default:
                return undefined;
        }
    };

    const getSortedEmailAccounts = () => {
        if (sortCol) {
            return orderBy(props.emailAccounts, sortFunc, sortAsc);
        } else {
            // Default Sort Behavior
            return orderBy(
                props.emailAccounts,
                [
                    (a: EmailAccount) => {
                        return a.syncStatus === 'needs_reauth'
                            ? EmailAccountSort.NeedsReAuth
                            : a.syncStatus === 'pending_initial_sync'
                            ? EmailAccountSort.PendingInitialSync
                            : a.syncStatus === 'enabled'
                            ? EmailAccountSort.Enabled
                            : EmailAccountSort.Disabled;
                    },
                    (a: EmailAccount) => a.email
                ],
                ['asc', 'asc']
            );
        }
    };

    const headerColumns = ['Email', 'Status', 'Last Sync', 'Daily Capacity'].map((h) => {
        let column;
        column = (
            <TableSortLabel active={sortCol === h} direction={sortAsc} onClick={handleSortChange(h)}>
                {h}
            </TableSortLabel>
        );
        return <TableCell key={h}>{column}</TableCell>;
    });
    const header = <TableRow>{headerColumns}</TableRow>;

    const handleUpdateSyncStatus = (account: EmailAccount) => (
        event: React.ChangeEvent<{ value: EmailSyncStatus }>
    ) => {
        updateSyncStatus({
            variables: {
                email: account.email,
                syncStatus: event.target.value
            }
        });
    };

    const getStatusLabel = (account: EmailAccount) => {
        switch (account.syncStatus) {
            case 'pending_initial_sync':
                return <span className={account.syncStatus}>Pending Initial Sync</span>;
            case 'archived':
                return <span className={account.syncStatus}>Archived</span>;
            case 'needs_reauth':
                return <span className={account.syncStatus}>Needs Re-auth</span>;
            case 'enabled':
            case 'disabled': {
                return (
                    <Select
                        value={account.syncStatus}
                        onChange={handleUpdateSyncStatus(account)}
                        disableUnderline={true}
                        className="status-select-field"
                    >
                        <MenuItem value="enabled" dense={true}>
                            Sync Enabled
                        </MenuItem>
                        <MenuItem value="disabled" dense={true}>
                            Sync Disabled
                        </MenuItem>
                    </Select>
                );
            }
        }
    };

    const sortedEmailAccounts = getSortedEmailAccounts();
    const searchRegex = new RegExp(escapeRegExp(search), 'i');
    const filteredEmailAccounts = sortedEmailAccounts.filter((account) => {
        return account.name.match(searchRegex) || account.email.match(searchRegex);
    });
    const rows = filteredEmailAccounts.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((account) => {
        return (
            <TableRow key={account.email}>
                <TableCell>{account.email}</TableCell>
                <TableCell className="status-label">{getStatusLabel(account)}</TableCell>
                <TableCell>{timeRelativeDay(account.lastSyncAt)}</TableCell>
                <TableCell>{account.maxDailyOutreach}</TableCell>
            </TableRow>
        );
    });

    const pagination =
        filteredEmailAccounts.length > rowsPerPage ? (
            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={filteredEmailAccounts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className="pagination"
            />
        ) : null;

    const totalCapacity = filteredEmailAccounts.reduce((acc, a) => acc + a.maxDailyOutreach, 0);

    return (
        <div css={styles(theme)}>
            <Paper>
                <Toolbar className="section-title">
                    <Typography variant="h5" color="textPrimary" className="left">
                        Email Accounts
                    </Typography>
                    <div className="right">
                        <SearchTextField value={search} onValueChange={handleSearchChange} />
                    </div>
                </Toolbar>
                <TableContainer>
                    <Table stickyHeader={true}>
                        <TableHead>{header}</TableHead>
                        <TableBody>{rows}</TableBody>
                    </Table>
                </TableContainer>
                <div className="table-footer">
                    <div>Total Daily Capacity: {totalCapacity.toLocaleString()}</div>
                    {pagination}
                </div>
            </Paper>
        </div>
    );
};
