// cspell:ignore mkvw wogqvlky deykm zropkb jvk
import { gql } from '@apollo/client';

import { JobDiversity, JobStatus, JobVisa } from 'shared/models/job';
import { FunnelCounts } from 'shared/models/job-stages';

import { internalClientIds } from 'shared/models/client';
import { JobType } from '../../state';

export interface JobWithMetrics {
    id: string;
    title: string;
    createdAt: number;
    assignee: {
        id: string;
        name: string;
    };
    client: {
        id: string;
        name: string;
    };
    accountManager: {
        id: string;
        name: string;
    };
    jobType: JobType;
    status: JobStatus;
    pendingEmails: number;
    dailyEmailLimit: number;
    stageFunnelCounts: {
        [stage: string]: { qualified: FunnelCounts; disqualified: FunnelCounts };
    };
    metrics: {
        emailsSent: number;
        responseRate: number;
        movedPastResponseReceived: number;
        movedToRocketScreenScheduled: number;
        movedToRocketScreenComplete: number;
        submits: number;
        accepts: number;
    };
    targets: {
        awaiting_client_feedback: number;
        being_scheduled: number;
        client_first_round: number;
        outreach_sent: number;
        response_received: number;
        rocket_screen_complete: number;
    };
    description: {
        location: string;
    };
}

export interface JobWithDetails {
    id: string;
    title: string;
    createdAt: number;
    assignee: {
        id: string;
        name: string;
    };
    client: {
        id: string;
        name: string;
        hiredCandidates: {
            aggregate: { count: number };
        };
        salesPitch: string;
        founders: string;
        funding: string;
        hq: string;
        foundedDate: string;
        description: string;
        teamSize: string;
    };
    accountManager: {
        id: string;
        name: string;
    };
    jobType: JobType;
    status: JobStatus;
    description: {
        location: string;
        summary: string;
        minExperienceYears: number;
        maxExperienceYears: number;
        skills: string;
        diversity: JobDiversity;
        linkToJobDescription: string;
        visa: JobVisa[];
        compensation: string;
        description: string;
        interviewPrep: string;
        keywords: string[];
        hiringManager: string;
    };
    discipline: string;
    headcount: number;
    placementFees: string;
    recruiterPercentage: number;
    accountManagerPercentage: number;
}

// cspell:disable
export const JOBS_WITH_METRICS = gql`
    query Jobs($status: [Int!]) {
        jobStages: job_stages(order_by: { id: asc }) {
            id
            name
        }
        jobs(
            where: {
                status: { _in: $status }
                clientId: { _nin: ${JSON.stringify(internalClientIds)} }
            }
            order_by: [{ status: asc }, { client: { name: asc } }, { title: asc }]
        ) {
            id
            title
            createdAt
            assignee: userByAssignee {
                id
                name(path: "full")
            }
            client {
                id
                name
            }
            accountManager: userByAccountmanagerid {
                id
                name(path: "full")
            }
            jobType
            status
            dailyEmailLimit
            stageFunnelCounts
            metrics: job_metrics {
                emailsSent
                responseRate
                movedPastResponseReceived
                movedToRocketScreenScheduled
                movedToRocketScreenComplete
                submits
                accepts
            }
            targets: funnel_targets {
                awaiting_client_feedback
                being_scheduled
                client_first_round
                outreach_sent
                response_received
                rocket_screen_complete
            }
            description {
                id: jobId
                location
            }
        }
    }
`;

export const JOBS_WITH_DETAILS = gql`
    query JobsWithDetails($status: [Int!], $types: [String!]) {
        jobs(
            where: {
                status: { _in: $status }
                jobType: { _in: $types }
                clientId: { _nin: ${JSON.stringify(internalClientIds)} }
            }
            order_by: [{ status: asc }, { client: { name: asc } }, { title: asc }]
        ) {
            id
            title
            createdAt
            assignee: userByAssignee {
                id
                name(path: "full")
            }
            client {
                id
                name
                description
                hiredCandidates: candidates_aggregate(
                    where: { stage: { _eq: "hired" }, disqualified: { _eq: false } }
                ) {
                    aggregate {
                        count
                    }
                }
                salesPitch
                founders
                funding
                hq
                foundedDate
                teamSize
            }
            accountManager: userByAccountmanagerid {
                id
                name(path: "first")
            }
            jobType
            status
            description {
                id: jobId
                summary
                location
                minExperienceYears
                maxExperienceYears
                skills
                diversity
                linkToJobDescription
                visa
                compensation
                description
                interviewPrep
                keywords
                hiringManager
            }
            discipline
            headcount
            recruiterPercentage
            placementFees
            accountManagerPercentage
        }
    }
`;
// cspell:enable

export const NEW_JOBS = gql`
    query NewJobs($status: [Int!], $types: [String!], $createdAfter: bigint!) {
        jobs(
            where: {
                status: { _in: $status }
                jobType: { _in: $types }
                clientId: { _nin: ${JSON.stringify(internalClientIds)} }
                createdAt: { _gte: $createdAfter }
            }
        ) {
            id
        }
    }
`;
