import { Map, OrderedMap } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { getSchedulingLink } from 'shared/common/scheduling-link-utils';
import { UserData } from 'shared/models/user';

import { fetchUserInfo, getAllEmailAccountInfo } from '../../actions';
import { Tooltip } from '../../core-ui/over-email-tooltip';
import { Candidate, Client, EmailAccount, Job, List, State } from '../../state';

interface OwnProps {
    personId: string;
    jobId: string;
    account: string;
    onSelect: (link: string, text: string) => void;
}
interface ConnectedProps {
    candidates: Map<string, OrderedMap<string, Candidate>>;
    emailAccounts: Map<string, EmailAccount>;
    clients: List<Client>;
    jobs: Map<string, Job>;
    users: Map<string, UserData>;
}
interface ConnectedDispatch {
    getAllEmailAccountInfo: () => void;
    fetchUserInfo: (id: string) => void;
}

type PhoneCallCalendarLinkProps = OwnProps & ConnectedProps & ConnectedDispatch;

const PhoneCallCalendarLinkComponent: React.FunctionComponent<PhoneCallCalendarLinkProps> = (props) => {
    const { account, emailAccounts, personId, jobId, jobs, clients, users, candidates } = props;
    const candidate = candidates.get(jobId)?.get(personId);

    React.useEffect(() => {
        if (emailAccounts.isEmpty()) {
            props.getAllEmailAccountInfo();
        }
        if (!!candidate && !users.get(candidate.assignee)) {
            props.fetchUserInfo(candidate.assignee);
        }
    }, []);

    if (emailAccounts.isEmpty() || !emailAccounts.get(account) || (!!candidate && !users.get(candidate.assignee))) {
        return null;
    } else {
        const phoneCallSchedulingLink = candidate ? users.get(candidate.assignee).calendarLink : null;
        if (!phoneCallSchedulingLink) {
            return null;
        }
        const client = clients.list.get(jobs.get(jobId).clientId);
        const { link, text } = getSchedulingLink(phoneCallSchedulingLink, personId, jobId, client.name);

        const handleClick = () => {
            props.onSelect(link, text);
        };

        return (
            <Tooltip title="Phone Call Calendar Link">
                <button className="icon-label" onClick={handleClick}>
                    <i className="far fa-calendar-alt" />
                </button>
            </Tooltip>
        );
    }
};

const mapStateToProps = (state: State) => ({
    candidates: state.candidates,
    clients: state.clients,
    emailAccounts: state.emailAccounts,
    jobs: state.jobs,
    users: state.users
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchUserInfo,
    getAllEmailAccountInfo
};

export const PhoneCallCalendarLink = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(PhoneCallCalendarLinkComponent);
