import { Map, OrderedMap, OrderedSet, Set } from 'immutable';

import { internalClientIds, JobType, placementFeeJobTypes, sourcerJobTypes } from 'shared/common/job-constants';
import { CandidateInfo } from 'shared/models/account-manager';
import { ActionType } from 'shared/models/action';
import { CandidateData, CandidateSummary } from 'shared/models/candidate';
import { CandidateAssigneeCandidateInfo } from 'shared/models/candidate-assignee';
import { ClientData } from 'shared/models/client';
import { CommunicationData, CommunicationView } from 'shared/models/communication';
import { ContactChannel } from 'shared/models/contact';
import { EmailAccountData } from 'shared/models/email-account';
import { EmailTemplateView } from 'shared/models/email-templates';
import { InmailSendClientData } from 'shared/models/inmail-send';
import { ExtensionJobData, JobData as Job, JobTemplates } from 'shared/models/job';
import { JobAssigneeCandidateInfo } from 'shared/models/job-assignee';
import { NoteAttachment, NoteView } from 'shared/models/note';
import { Permissions } from 'shared/models/permission';
import { RecruitingActivity, VisaStatus, VisaStatusSource } from 'shared/models/person';
import { ScheduledMessageView } from 'shared/models/scheduled-messages';
import { JobSearchesSourcingStats, SearchData, SearchResultsViewType, SearchStatus } from 'shared/models/search';
import { SearchPresetData } from 'shared/models/search-preset';
import { SearchResultStatus } from 'shared/models/search-result';
import { UserData } from 'shared/models/user';
import { AppConstant } from 'shared/types/app-constant';
import { CrunchbaseData } from 'shared/types/crunchbase';
import { EmailAddress } from 'shared/types/email-compose';
import { FilePayload } from 'shared/types/file-payload';

import {
    disableAutoRedirectKey,
    jobSearchesTablePrefsKey,
    searchSourcingStatsTablePrefsKey,
    selectedJobIdKey,
    selectedProfileTabKey
} from './common/local-storage';
import { ComposeEmailWindowData } from './email-compose/types';
import { EmailInboxData } from './email-inbox/types';
import { defaultMetricsBenchmarks, MetricBenchMark } from './lib/job-metrics';
import { RawJsonProfile } from './types/li-json-profile';
import { Profile, ProfileRecord } from './types/profile';

interface Model {
    id: string;
    createdAt: number;
    modifiedAt: number;
}

export interface ConfirmModalState {
    open: boolean;
    title: string;
    description: string;
    onConfirm: () => void;
    nonCancelable?: boolean;
}

export interface Communication extends CommunicationData {
    rawHeaders?: Array<{ name: string; value: string }>;
}

export enum ContactType {
    Personal = 'personal',
    Work = 'work',
    Unknown = 'unknown'
}
export interface Contact {
    channel: ContactChannel;
    primary: boolean;
    personId: string;
    value: string;
    verified: boolean;
    invalid: boolean;
    createdAt: number;
    modifiedAt: number;
    contactType: ContactType;
}

export interface PersonFile {
    name: string;
    path: string;
    createdAt: number;
    size: number;
}

export interface Person extends Model {
    name: {
        edited: boolean;
        first: string;
        full: string;
        last: string;
    };
    profilePicUrl: string;
    files: PersonFile[];
    websites: string[];
    location: string;
    optOutClients: Array<{ clientId: string; ts: number }>;
    optOutGlobal: number; // snooze until this timestamp
    outreachesSent: Array<{
        clientId: string;
        jobId: string;
        ts: number;
        outreachDomain: string;
        recipientEmail: string;
    }>;
    lastPositiveResponseReceivedAt: number;
    remoteOk: boolean;
    visaStatus: VisaStatus;
    visaStatusSource: VisaStatusSource;
    salary: Array<{
        value: string;
        kind: string;
        jobId?: string;
    }>;
    willRelocate: boolean;
    memberId: number;
    jobLabels: Array<{
        jobId: string;
        rating: boolean;
        userId: string;
        createdAt: number;
    }>;
    lastProfileUpdateAt: number;
    blacklisted: boolean;
    recruitingActivity: RecruitingActivity[];
}

export interface ProfileView {
    personId: string;
    userId: string;
    createdAt: number;
    jobId: string;
}

export interface PersonDetails {
    person: Person;
    contacts: Contact[];
    communications: Communication[];
    companySimilarity: SimilarityScoreResult;
    candidates: Candidate[];
    profile: ProfileRecord;
    profileUrls: ProfileUrlRecord[];
    scheduledMessages: ScheduledMessageView[];
    userBlacklisted: boolean;
}

export interface ProfileUrlRecord extends Model {
    url: string;
    personId: string;
    invalid: boolean;
}

export type Candidate = CandidateData;
export interface Rule {
    color: {
        false: string;
        true: string;
    };
    description: string;
    rule: string;
}

export interface SkillKeywords {
    keywords: string[];
    multiplier: number;
}

export enum JobStatus {
    Active = 1,
    Paused,
    Archived
}

export const JobStatusLabels: Map<JobStatus, string> = Map([
    [JobStatus.Active, 'Active'],
    [JobStatus.Paused, 'Paused'],
    [JobStatus.Archived, 'Archived']
] as Array<[JobStatus, string]>);

interface JobTypeSettings {
    defaultSendingLimit: number;
    label: string;
    metricsBenchmarks: MetricBenchMark;
}

export const JobTypes: { [jobType in JobType]: JobTypeSettings } = {
    C: {
        defaultSendingLimit: 100,
        label: 'Contingency',
        metricsBenchmarks: defaultMetricsBenchmarks.highVolume
    },
    'C+$': {
        defaultSendingLimit: 100,
        label: 'Container',
        metricsBenchmarks: defaultMetricsBenchmarks.highVolume
    },
    CnC: {
        defaultSendingLimit: 75,
        label: 'Contingency, no calls',
        metricsBenchmarks: defaultMetricsBenchmarks.highVolume
    },
    'CnC+$': {
        defaultSendingLimit: 75,
        label: 'Container, no calls',
        metricsBenchmarks: defaultMetricsBenchmarks.highVolume
    },
    R: {
        defaultSendingLimit: 100,
        label: 'Retained',
        metricsBenchmarks: defaultMetricsBenchmarks.highVolume
    },
    RPO: {
        defaultSendingLimit: 100,
        label: 'RPO',
        metricsBenchmarks: defaultMetricsBenchmarks.lowVolume
    },
    S: {
        defaultSendingLimit: 25,
        label: 'Sourcer',
        metricsBenchmarks: defaultMetricsBenchmarks.lowVolume
    },
    Staffing: {
        defaultSendingLimit: 100,
        label: 'Staffing',
        metricsBenchmarks: defaultMetricsBenchmarks.lowVolume
    },
    SwC: {
        defaultSendingLimit: 25,
        label: 'Sourcer with calls',
        metricsBenchmarks: defaultMetricsBenchmarks.lowVolume
    }
};

export const DefaultJobType = 'C';

export type JobFilterTypes = 'placement-fees' | 'sourcer' | 'staffing' | 'rpo' | 'all';

export const jobTypesForFilter: Map<JobFilterTypes, JobType[]> = Map({
    all: placementFeeJobTypes.concat(sourcerJobTypes, ['RPO', 'Staffing']),
    'placement-fees': placementFeeJobTypes,
    rpo: ['RPO'],
    sourcer: sourcerJobTypes,
    staffing: ['Staffing']
}) as Map<JobFilterTypes, JobType[]>;

export const jobTypeFilterLabels: Map<JobFilterTypes, string> = Map({
    all: 'All',
    'placement-fees': 'Contingency/Retained',
    rpo: 'RPO',
    sourcer: 'Sourcer Jobs',
    staffing: 'Staffing'
}) as Map<JobFilterTypes, string>;

export const jobVisaOptionsLabels = [
    ['h1b_sponsor', 'H1B Sponsorship'],
    ['h1b_transfer', 'H1B Transfers'],
    ['no_visa_sponsor', 'No Visa Sponsorship']
];

export interface JobMetrics {
    emailsSent: number;
    movedPastResponseReceived?: number;
    movedToRocketScreenScheduled: number;
    movedToRocketScreenComplete?: number;
    responseRate?: number;
    submits: number;
    accepts: number;
}

export type Search = SearchData;

export interface JobSearchesState {
    searches: Array<Search & { deleted?: boolean }>;
    searchStats: JobSearchesSourcingStats[];
    progressCounts: JobProgressCounts[];
}

export interface SearchResultsState {
    results: SearchResult[];
    resultsType: SearchResultsViewType;
    profiles?: Map<string, Profile>;
    companySimilarity?: SimilarityScoreResult;
    error?: string;
}

export interface SearchResult extends Model {
    personId: string;
    searchId: string;
    profileId: string;
    scores: {
        profileAge: number;
        randomScore: number;
        totalScore: number;
        skillScore: number;
        similarityScore: number;
        similarityScores: Array<{ company: string; score: number }>;
    };
    status: SearchResultStatus;
}

export interface JobSearchesStats {
    available: number;
    ready: number;
    emailNotFound: number;
    expiredProfile: number;
    sourcedEmailCooldown: number;
    sourcedEmailNoCooldown: number;
    lastModifiedAt: number;
}
export interface JobProgressCounts {
    searchId: string;
    sourcedBy: 'ai_sourced' | 'recruiter_sourced';
    counts: Array<{
        stage: string;
        count: number;
    }>;
}

export type Client = ClientData;

export type EmailSyncStatus = 'pending_initial_sync' | 'enabled' | 'disabled' | 'needs_reauth' | 'archived';

export type EmailAccount = EmailAccountData;

export interface CandidateWithPerson {
    person: Person;
    candidate: Candidate;
    profileUrls: ProfileUrlRecord[];
}

export interface JobForClientUpdate {
    job: Job;
    candidates: CandidateWithPerson[];
}
export interface ClientUpdateData {
    jobStageCounts: { [jobId: string]: StageCounts };
    client: Client;
    jobs: JobForClientUpdate[];
}

export interface StageCounts {
    [stage: string]: number;
}

export interface Toast {
    autoHideDuration: number;
    message: string;
    open: boolean;
}

export interface NoteDraftState {
    content: string;
    saving: boolean;
    context: { [key: string]: any };
    modifiedAt: number;
    addingAttachment: boolean;
    initialAttachments: NoteAttachment[];
}

export interface UserAction extends Model {
    target: string;
    context: { [field: string]: any };
    createdBy: string;
    kind: ActionType;
}

export interface Session {
    appVersionOutdated: boolean;
    authToken: string;
    fetching: boolean;
    initialized: boolean;
    user: UserData;
    userPermissions: Permissions;
}

export type RequestErrors = Map<string, string>;

export interface List<T> {
    initialized?: boolean;
    isFetching?: boolean;
    isCreating?: boolean;
    formErrors?: RequestErrors;
    list: Map<string, T>;
}

export type ListState = 'initializing' | 'initialized';

export interface KanbanState {
    jobId: string;
    disqualifiedView: boolean;
    candidateId: string;
    candidateIdList: string[];
    boardAutoScroll: 'pending' | 'ready' | 'done';
}

export type PersonSearchResults = Array<{
    id: string;
    name: {
        first: string;
        last: string;
        full: string;
    };
    profilePicUrl: string;
    jobs: Array<{
        id: string;
        title: string;
        client: string;
        disqualified: boolean;
        stage: string;
        assignee: string;
    }>;
}>;

export interface UIState {
    drawerOpen: boolean;
    kanban: KanbanState;
    searchResults: PersonSearchResults;
}

export interface JobsTablePrefs {
    displayedColumns: { [column: string]: boolean };
    sortColumn: string;
    sortAsc: boolean;
    statusFilter: JobStatusFilter;
}

export interface JobsSearchesTablePref {
    sortColumn: string;
    sortAsc: boolean;
}

export interface JobSearchesTablePref {
    [jobId: string]: {
        status: 'active' | 'activeOrPaused' | 'paused';
    };
}

export type JobStatusFilter = 'active' | 'activeOrPaused' | 'paused' | 'archived' | 'pausedOrArchived' | 'all';

export const jobStatusForFilter: Map<JobStatusFilter, JobStatus[]> = Map({
    active: [JobStatus.Active],
    activeOrPaused: [JobStatus.Active, JobStatus.Paused],
    all: [JobStatus.Active, JobStatus.Paused, JobStatus.Archived],
    archived: [JobStatus.Archived],
    paused: [JobStatus.Paused],
    pausedOrArchived: [JobStatus.Paused, JobStatus.Archived]
}) as Map<JobStatusFilter, JobStatus[]>;

export const jobStatusFilterLabels: Map<JobStatusFilter, string> = Map({
    active: 'Active',
    activeOrPaused: 'Active / Paused',
    all: 'All',
    archived: 'Archived',
    paused: 'Paused',
    pausedOrArchived: 'Paused / Archived'
}) as Map<JobStatusFilter, string>;

export interface Preferences {
    [selectedProfileTabKey]: string;
    [selectedJobIdKey]: string;
    [searchSourcingStatsTablePrefsKey]: JobsSearchesTablePref;
    [jobSearchesTablePrefsKey]: JobSearchesTablePref;
    [disableAutoRedirectKey]: boolean;
}

export interface BlacklistCheck {
    presentedToClient: boolean;
    alreadyInJob: boolean;
    clientCompanyBlacklisted: boolean;
    clientPersonBlacklisted: boolean;
    globalCompanyBlacklisted: boolean;
    hired: boolean;
    profileBlacklisted: boolean;
    userBlacklisted: boolean;
}

export interface ExtensionState {
    containerElementId: string;
    firstLoadTime: number;
    selectedJobId: string;
    blacklistCheck: BlacklistCheck;
    candidates: Candidate[];
    parsingProfile: boolean;
    parsingError: string;
    profile: Profile;
    profileViews: ProfileView[];
    person: Person;
    jsonProfile: RawJsonProfile;
    htmlProfile: string;
    htmlPendingLoadTagsCount: number;
    autoScrollToPendingHtmlTags: boolean;
    autoExpandingPendingSections: boolean;
    url: string;
    tags: string[];
    outOfNetwork: boolean;
    jobs: ExtensionJobData[];
    nextSendTime: number;
    latestOutreach: {
        assignee: string;
        job: string;
        jobId: string;
        client: string;
        sentAt: number;
    };
    searchResults: Array<{
        jobId: string;
        clientName: string;
        jobName: string;
        searchName: string;
        searchId: string;
        recruiter: string;
    }>;
    inmailSends: InmailSendClientData[];
}

export interface EmailCountInfo {
    sent: number;
    scheduled: Map<string, number>;
}

export type SearchPreset = SearchPresetData;

export interface FlaggedEmails {
    total: number;
    threads: Map<string, Communication[]>;
}

export interface AccountManagerData {
    candidates: CandidateInfo[];
}

export interface JobAssigneeData {
    candidates: JobAssigneeCandidateInfo[];
}

export interface CandidateAssigneeData {
    candidates: CandidateAssigneeCandidateInfo[];
}

// Overall App State
export interface State {
    upcomingCalls: Interview[];
    session: Session;
    jobs: OrderedMap<string, Job>;
    jobMetrics: Map<string, JobMetrics>;
    candidates: Map<string, OrderedMap<string, Candidate>>;
    communications: Map<string, Communication[]>;
    contacts: Map<string, Contact[]>;
    clients: List<Client>;
    clientWeeklyUpdates: Map<string, ClientUpdateData>;
    notes: Map<string, OrderedSet<NoteView>>;
    userActions: Map<string, OrderedSet<UserAction>>;
    emailComposeWindows: OrderedMap<string, ComposeEmailWindowData>;
    emailInbox: EmailInboxData;
    emailInboxAccounts: Map<string, EmailAddress>;
    emailCounts: Map<string, EmailCountInfo>;
    emailAccounts: Map<string, EmailAccount>;
    emailTemplates: Map<string, EmailTemplateView[]>;
    preferences: Preferences;
    searchPresets: Map<string, SearchPreset>;
    persons: List<Person>;
    personsDetails: List<PersonDetails>;
    profileConflicts: [string, string];
    blacklists: Map<string, BlacklistCheck>;
    users: Map<string, UserData>;
    toast: Toast;
    pendingRequests: Map<string, RequestErrors>;
    listsState: Map<string, ListState>;
    tags: Map<string, Set<string>>;
    ui: UIState;
    extension: ExtensionState;
    confirmationModal: ConfirmModalState;
    searches: Map<string, JobSearchesState>;
    searchResults: Map<string, SearchResultsState>;
    jobsSearchesStats: List<JobSearchesStats>;
    jobEmails: Map<string, EmailAccount[]>;
    flaggedEmails: OrderedMap<string, FlaggedEmails>;
    accountManagerData: Map<string, AccountManagerData>;
    jobSearchSourcing: Map<string, { selected: Search; searches: Search[] }>;
    jobAssigneeData: Map<string, JobAssigneeData>;
    jobCandidatesSummary: Map<string, Map<string, CandidateSummary[]>>; // Map<jobId, Map<stage, CandidateSummary>>
    noteAttachments: Map<string, FilePayload[]>; // Map<note-draft-key, FilePayload[]>
    appConstants: AppConstantState;
    candidateAssigneeData: Map<string, CandidateAssigneeData>;
    crunchbase: Map<string, CrunchbaseData>;
    scheduledMessages: Map<string, ScheduledMessageView[]>;
}

export enum EmailSentiment {
    Negative,
    Unknown,
    Positive
}

export enum AvailableNotes {
    NoNotes,
    NotesForOtherJob,
    NotesForThisJob
}

export type InterviewSource = 'calendly' | 'mixmax' | 'titan';
export type InterviewKind = 'Rocket Screen' | 'Client First Round' | 'Client Middle Round' | 'Client Final Round';
export const interviewKinds: InterviewKind[] = [
    'Rocket Screen',
    'Client First Round',
    'Client Middle Round',
    'Client Final Round'
];

export interface Interview {
    kind: InterviewKind;
    startTime: number;
    endTime: number;
    personId?: string;
    jobId?: string;
    cancellationLink?: string;
    reschedulingLink?: string;
    source: InterviewSource;
}
export interface SimilarityScoreResult {
    [source: string]: Array<{ similarityScore: number; url: string; name: string }>;
}

export interface AppConstantState {
    initialized: boolean;
    constants: AppConstant;
}

export { CommunicationView };

export interface CampaignData {
    campaignId: string;
    createdAt: number;
    maxProspects: number;
    aiSourcingStrategy: string;
    title: string;
    userName: string;
    client: string;
}
export interface SourcingCampaign extends CampaignData {
    count: number;
    ready: number;
}

export interface SourcerData {
    sourcerId: string;
    createdAt: number;
    maxToAdd: number;
    aiSourcingStrategy: string;
    title: string;
    userName: string;
}

export { Job, JobTemplates, JobType, internalClientIds, placementFeeJobTypes, sourcerJobTypes, SearchStatus };
