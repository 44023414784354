import { blue200, pink200, red200, red600 } from 'material-ui/styles/colors';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import {
    SearchConfigKeywordGroup,
    SearchProfileSection,
    searchSectionName,
    SearchTerm,
    toggleSearchTerm
} from 'shared/models/search';
import { PresetGroup } from 'shared/models/search-preset';

import { SimilaritySearch } from '../components/similarity-search';
import { TextfieldWithChips } from '../core-ui/textfield-with-chips';
import { ToggleButton } from '../core-ui/toggle-button';
import { useModal } from '../hooks/use-modal';
import { SearchStatus } from '../state';
import { renderOption } from './search-autocomplete-option';
import { SearchFieldContext } from './search-field-context';
import { SearchGroup, SearchGroupAction } from './search-group';
import { SearchRequirementsSection } from './search-requirements-section';
import { useSearch } from './use-search';
import { useSearchPresets } from './use-search-presets';
import { useSearchPresetsForm } from './use-search-presets-form';

export const keywordSearchPresetGroups: PresetGroup[] = ['skills', 'companies', 'schools', 'titles'];

export const SearchKeywordsRequirements: FC = () => {
    const searchContext = useSearch();
    const keywordGroups = searchContext.data.config.keywordGroups;
    const onChange = searchContext.onConfigFieldChange('keywordGroups');
    const readonly = searchContext.readonly;

    const { requestPresets, updateSelectedPresets, searchPresets, getGroupSearchTerms } = useSearchPresets();
    const { requestPresetsSave } = useSearchPresetsForm();

    const [searchingSimilarKeywords, setSearchingSimilarKeywords] = useState(-1);
    const { getConfirmation } = useModal();

    const savedSearch = searchContext.data.status && searchContext.data.status !== SearchStatus.Initial;

    const handleUpdateKeywordGroup = useCallback(
        (index) => <T extends keyof SearchConfigKeywordGroup>(field: T) => (value: SearchConfigKeywordGroup[T]) => {
            const group = keywordGroups[index];
            const updatedGroup = { ...group, [field]: value };
            const updatedGroups = [...keywordGroups.slice(0, index), updatedGroup, ...keywordGroups.slice(index + 1)];
            onChange(updatedGroups);
        },
        [keywordGroups, onChange]
    );

    const handleToggleKeywordsNegation = useCallback(
        (index) => () => {
            const group = keywordGroups[index];
            handleUpdateKeywordGroup(index)('negative')(!group.negative);
        },
        [handleUpdateKeywordGroup, keywordGroups]
    );

    const handleAddKeywordsGroup = useCallback(() => {
        onChange([...keywordGroups, { keywords: [], searchIn: 'anywhere', negative: false, ignore: false }]);
    }, [keywordGroups, onChange]);

    const handleIgnoreKeywordsGroup = useCallback(
        (index) => () => {
            const group = keywordGroups[index];
            handleUpdateKeywordGroup(index)('ignore')(!group.ignore);
        },
        [handleUpdateKeywordGroup, keywordGroups]
    );

    const handleRemoveKeywordsGroup = useCallback(
        (index) => () => {
            let updatedGroups = keywordGroups.slice(0, index).concat(keywordGroups.slice(index + 1));
            if (updatedGroups.length === 0) {
                updatedGroups = [{ keywords: [], searchIn: 'anywhere', negative: false, ignore: false }];
            }
            if (keywordGroups[index].keywords.length > 0) {
                getConfirmation(
                    () => onChange(updatedGroups),
                    'Are you sure you want to remove a list of search terms?',
                    'Remove Confirmation'
                );
            } else {
                onChange(updatedGroups);
            }
        },
        [keywordGroups, getConfirmation, onChange]
    );

    // fix for stale closure
    const keywordGroupsRef = useRef(keywordGroups);
    keywordGroupsRef.current = keywordGroups;

    const handleToggleTerm = useCallback(
        (index, term) => {
            const old = keywordGroupsRef.current[index];
            const newKeywords = toggleSearchTerm(term, old.keywords);
            const updated = { ...old, keywords: newKeywords };
            const newKeywordGroups = keywordGroups.slice(0, index).concat([updated], keywordGroups.slice(index + 1));
            onChange(newKeywordGroups);
            updateSelectedPresets(newKeywordGroups[index].keywords.filter((kw) => kw.isPreset));
        },
        [keywordGroups, onChange, updateSelectedPresets]
    );

    const handleTogglePresets = useCallback(
        (index) => (label: string, _1: string[], id: string) => {
            handleToggleTerm(index, { isPreset: true, label, presetId: id });
        },
        [handleToggleTerm, keywordGroups]
    );

    const handleAddKeywordsPresetRequest = useCallback(
        (index) => () => {
            requestPresets(
                handleTogglePresets(index),
                keywordSearchPresetGroups,
                keywordGroups[index].keywords.filter((kw) => kw.isPreset)
            );
        },
        [requestPresets, handleTogglePresets, keywordGroups]
    );

    const handleRequestSimilarSkills = useCallback(
        (index) => () => {
            setSearchingSimilarKeywords(index);
        },
        [setSearchingSimilarKeywords]
    );

    const handleCloseSimilarSkills = useCallback(() => {
        setSearchingSimilarKeywords(-1);
    }, [setSearchingSimilarKeywords]);

    const handleToggleSimilarKeyword = useCallback(
        (label) => () => {
            handleToggleTerm(searchingSimilarKeywords, { isPreset: false, label });
        },
        [handleToggleTerm, searchingSimilarKeywords]
    );

    const handlePresetSaved = (index: number) => (preset: SearchTerm) => {
        const old = keywordGroupsRef.current[index];
        const updated = { ...old, keywords: [preset] };
        const newKeywordGroups = keywordGroups.slice(0, index).concat([updated], keywordGroups.slice(index + 1));
        onChange(newKeywordGroups);
    };

    const handleSaveAsPreset = (index: number, names: SearchTerm[]) => () => {
        requestPresetsSave({
            groups: ['skills'],
            postSave: handlePresetSaved(index),
            selectedGroup: 'skills',
            terms: names
        });
    };

    const getChipFromString = (label: string) => ({ isPreset: false, label });
    const getStringFromChip = (chip: SearchTerm) => chip.label;
    const getChipStyle = <T extends { negative: boolean }>(g: T) => (value: SearchTerm) => {
        const { isPreset } = value;
        const backgroundColor = g.negative && isPreset ? pink200 : g.negative ? red200 : isPreset ? blue200 : undefined;
        return { backgroundColor };
    };

    const buttonForValue = (value: { name: string; url: string }) => {
        const alreadyAdded = keywordGroups[searchingSimilarKeywords].keywords.find(
            (v) => !v.isPreset && v.label === value.name
        );
        return (
            <ToggleButton
                initialValue={!alreadyAdded}
                onLabel={<span>Add</span>}
                offLabel={<span style={{ color: red600 }}>Remove</span>}
                onClick={handleToggleSimilarKeyword(value.name)}
            />
        );
    };

    const searchInOptions: SearchProfileSection[] = [
        'latestPosition',
        'latestPositionAndSummary',
        'last1YearAndSummary',
        'last3YearAndSummary',
        'last5YearAndSummary',
        'anyPositionAndSummary',
        'anyPositionAndSummaryAndSkills',
        'anywhere',
        'softwareSmartScope'
    ];

    const keywordsOptions = useMemo(() => getGroupSearchTerms('skills'), [searchPresets]);

    const getOptionLabel = (o: SearchProfileSection) => searchSectionName[o];
    const groups = keywordGroups
        .filter((g) => !savedSearch || g.keywords.length > 0)
        .map((g, i) => {
            const group = (
                <TextfieldWithChips
                    readonly={readonly || g.ignore}
                    chips={g.keywords}
                    hintText="Add search term"
                    onChange={handleUpdateKeywordGroup(i)('keywords')}
                    getChipStyle={getChipStyle(g)}
                    getChipFromString={getChipFromString}
                    getStringFromChip={getStringFromChip}
                    options={keywordsOptions}
                    renderOption={renderOption}
                />
            );
            const searchGroupActions: SearchGroupAction[] = [{ name: 'Add', onSelect: handleAddKeywordsGroup }];
            searchGroupActions.push({ name: 'Remove', onSelect: handleRemoveKeywordsGroup(i) });
            searchGroupActions.push({ name: 'Similar Skills', onSelect: handleRequestSimilarSkills(i) });
            searchGroupActions.push({
                checked: g.negative,
                name: 'Negative',
                onSelect: handleToggleKeywordsNegation(i),
                style: g.negative ? { color: red600 } : {}
            });
            searchGroupActions.push({
                name: 'Presets',
                onSelect: handleAddKeywordsPresetRequest(i)
            });

            searchGroupActions.push({
                checked: g.ignore,
                name: 'Ignore',
                onSelect: handleIgnoreKeywordsGroup(i)
            });
            if (g.keywords.length > 0) {
                searchGroupActions.push({ name: 'Save as Preset', onSelect: handleSaveAsPreset(i, g.keywords) });
            }

            const similaritySearch =
                searchingSimilarKeywords >= 0 ? (
                    <SimilaritySearch
                        onClose={handleCloseSimilarSkills}
                        searchType="skills"
                        searchTypes={['skills']}
                        searchText=""
                        buttonForValue={buttonForValue}
                    />
                ) : null;

            return (
                <SearchGroup readonly={readonly} actions={searchGroupActions} key={i} className="and-group">
                    <div className={`search-requirements-field with-context${g.ignore ? ' ignore' : ''}`} key={i}>
                        <div style={{ flex: '1 1 auto' }}>
                            <label className="search-label">Group {i + 1}</label>
                            <div className="search-requirements-value">{group}</div>
                        </div>
                        <SearchFieldContext
                            disabled={readonly || g.ignore}
                            label="Search In"
                            options={searchInOptions}
                            getOptionLabel={getOptionLabel}
                            onSelect={handleUpdateKeywordGroup(i)('searchIn')}
                            selected={g.searchIn}
                        />
                    </div>
                    {similaritySearch}
                </SearchGroup>
            );
        });
    return (
        <SearchRequirementsSection section="keywords" className="add-groups-section">
            <div>{groups}</div>
        </SearchRequirementsSection>
    );
};
