// cspell:disable
declare const APP_ENV: string;
declare const APP_VERSION: string;

const common = {
    SystemUserId: 'l5kpzgr1'
};

const dev = {
    ApiEndpoint: 'http://localhost:8080/v1',
    AppUrl: 'http://localhost:3000',
    AppVersion: APP_VERSION,
    AssetHost: 'https://media.getrocket.com',
    Env: 'development',
    ExtensionId: 'aieoppnljbghnkgmahnoldmlllkifcee',
    GoogleClientId: '117904634803-m9ilvlp66qpmu15k3uhh34g22m3l1qqd.apps.googleusercontent.com',
    GraphAPIEndpoint: 'localhost:8282/v1/graphql'
};

const stage = {
    ApiEndpoint: 'https://api-stage.saturn.gobopo.com/v1',
    AppUrl: 'https://titan-stage.getrocket.com',
    AppVersion: APP_VERSION,
    AssetHost: 'https://media.getrocket.com',
    Env: 'stage',
    ExtensionId: 'ijnmlijopjlciekakddgihkefbldbgda',
    GoogleClientId: '117904634803-fr3pvi7654upita0v9b8u4oe06vebtdg.apps.googleusercontent.com',
    GraphAPIEndpoint: 'graph-stage.saturn.gobopo.com/v1/graphql'
};

const prod = {
    ApiEndpoint: 'https://api.saturn.gobopo.com/v1',
    AppUrl: 'https://titan.getrocket.com',
    AppVersion: APP_VERSION,
    AssetHost: 'https://media.getrocket.com',
    Env: 'production',
    ExtensionId: 'nbnodchgpmfohefjjcgaogplimdghieo',
    GoogleClientId: '117904634803-dq4osrtlvkjk4li9mtsgutrkinvt8pbn.apps.googleusercontent.com',
    GraphAPIEndpoint: 'graph.saturn.gobopo.com/v1/graphql'
};

const config = { ...common, ...(APP_ENV === 'production' ? prod : APP_ENV === 'stage' ? stage : dev) };

export { config };
