import { Add, Clear, Edit } from '@material-ui/icons';
import * as _ from 'lodash';
import * as React from 'react';

import { HiringManagerData } from 'shared/models/client';

import { HiringManagerForm } from './hiring-manager-form';

const styles = {
    actionButton: {
        color: 'grey',
        cursor: 'pointer',
        height: '18px',
        paddingRight: '17px',
        width: '18px'
    }
};

interface ClientHiringManagerProps {
    clientDomains: string[];
    hiringManagers: HiringManagerData[];
    onHiringManagerChange: (hiringManager: HiringManagerData) => void;
    onHiringManagerRemove: (id: string) => void;
    isEditable: boolean;
    errors: Array<{ index: number; message: string }>;
}

interface ClientHiringManagerState {
    hiringManagerFormOpen: boolean;
    editingHiringManagerId: string;
}

export class ClientHiringManagers extends React.Component<ClientHiringManagerProps, ClientHiringManagerState> {
    constructor(props: ClientHiringManagerProps) {
        super(props);
        this.state = { editingHiringManagerId: null, hiringManagerFormOpen: false };
    }

    handleEditManager = (id: string) => () => {
        this.setState({ editingHiringManagerId: id, hiringManagerFormOpen: true });
    };

    handleHiringManagerFormCancel = () => {
        this.setState({ editingHiringManagerId: null, hiringManagerFormOpen: false });
    };

    handleAddHiringManager = () => {
        this.setState({ editingHiringManagerId: null, hiringManagerFormOpen: true });
    };

    handleHiringManagerSave = (hiringManager: HiringManagerData) => {
        this.props.onHiringManagerChange(hiringManager);
        this.handleHiringManagerFormCancel();
    };

    handleRemoveManager = (id: string) => () => {
        this.props.onHiringManagerRemove(id);
    };

    render() {
        const { hiringManagers, isEditable } = this.props;
        const hiringManagerSummaries = _.sortBy(hiringManagers, (hm) => hm.name.full).map((hiringManager, index) => {
            const editHiringManager = isEditable ? (
                <div className="hiring-manager-action">
                    <div className="edit-action" onClick={this.handleEditManager(hiringManager.id)}>
                        <Edit style={styles.actionButton} />
                    </div>
                    <div className="remove-action" onClick={this.handleRemoveManager(hiringManager.id)}>
                        <Clear style={styles.actionButton} />
                    </div>
                </div>
            ) : null;

            const error = this.props.errors.find((e) => e.index === index);
            return (
                <div className="hiring-manager-card" key={`hiring-manager-${index}`}>
                    <div className="hiring-manager-primary">
                        <div className="hiring-manager-info">
                            <div className="hiring-manager-card-line hiring-manager-name">
                                {hiringManager.name.full}
                            </div>
                            <div className="hiring-manager-card-line">{hiringManager.linkedIn}</div>
                            <div className="hiring-manager-card-line">
                                <div className="item">{hiringManager.phoneNumber} </div>
                                <div className="item">{hiringManager.email}</div>
                            </div>
                        </div>
                        {editHiringManager}
                    </div>
                    <div className="hiring-manager-secondary">{error ? error.message : null}</div>
                </div>
            );
        });

        const hiringManagerInForm = !_.isNil(this.state.editingHiringManagerId)
            ? hiringManagers.find((hm) => hm.id === this.state.editingHiringManagerId)
            : null;
        const title = !_.isNil(this.state.editingHiringManagerId)
            ? 'Edit Hiring Manager Information'
            : 'Add Hiring Manager';
        const hiringManagerForm =
            isEditable && this.state.hiringManagerFormOpen ? (
                <HiringManagerForm
                    clientDomains={this.props.clientDomains}
                    hiringManager={hiringManagerInForm}
                    title={title}
                    onSave={this.handleHiringManagerSave}
                    onCancel={this.handleHiringManagerFormCancel}
                />
            ) : null;
        return (
            <div>
                {hiringManagerSummaries}
                <div
                    className="card-action-button add-client-person-blacklist-button"
                    style={{ marginLeft: 'auto' }}
                    onClick={this.handleAddHiringManager}
                >
                    <Add />
                </div>
                {hiringManagerForm}
            </div>
        );
    }
}
