import React from 'react';
import { JobIntroductionSettings } from 'shared/models/job';

import { RichTextField, SelectField } from '../core-ui/form-fields';
import { JobFormData } from '../graphql/queries/job-form';
import { ClientContactsSelector } from './client-contacts-selector';
import { useFormContext } from './form-context';

export const JobFormIntroInfo: React.FC = () => {
    const {
        data: { clientId, introSettings },
        onFieldChange,
        disabled
    } = useFormContext<JobFormData>();

    const handleFieldChange = <T extends keyof JobIntroductionSettings>(field: T) => (
        value: JobIntroductionSettings[T]
    ) => {
        onFieldChange('introSettings')({ ...introSettings, [field]: value });
    };

    const handleIntroEmailToggled = (updated: 'true' | 'false') => {
        onFieldChange('introSettings')({
            ...introSettings,
            introEmailDisabled: updated === 'false'
        });
    };

    let emailFields = null;
    if (!introSettings?.introEmailDisabled) {
        emailFields = (
            <>
                <div className="job-field-row">
                    <ClientContactsSelector
                        value={introSettings?.emailTo}
                        onChange={handleFieldChange('emailTo')}
                        clientId={clientId}
                        label="Introduction To"
                        disabled={disabled}
                    />
                    <ClientContactsSelector
                        value={introSettings?.emailCC}
                        onChange={handleFieldChange('emailCC')}
                        clientId={clientId}
                        label="Introduction CC"
                        disabled={disabled}
                    />
                </div>
            </>
        );
    }

    const introEmailOpts = [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' }
    ];

    return (
        <div>
            <div className="job-field-row">
                <SelectField
                    label="Send Client <> Candidate Intro Emails"
                    value={introSettings?.introEmailDisabled ? 'false' : 'true'}
                    onChange={handleIntroEmailToggled}
                    options={introEmailOpts}
                    disabled={disabled}
                />
            </div>
            {emailFields}
            <div className="job-field-row">
                <RichTextField
                    value={introSettings?.directions}
                    label="Introduction Directions"
                    onChange={handleFieldChange('directions')}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
