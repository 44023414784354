import { useQuery } from '@apollo/client';
import css from '@emotion/css';
import { Theme, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment-timezone';
import React from 'react';

import { UserActivity, userActivityHtml } from 'shared/models/user';

import { DAILY_ACTIVITY } from '../graphql/queries/user';

const styles = (theme: Theme) => css`
    min-width: 600px;

    .activity-header {
        padding: 15px 25px;
        font-size: 20px;
        font-weight: 500;
        border-bottom: thin solid ${theme.palette.divider};
    }

    .activity-content {
        padding: 15px 25px 20px 15px;

        .MuiSkeleton-root {
            margin-bottom: 10px;
        }

        ul {
            margin-block-start: 0;
            margin-block-end: 0;
            padding-inline-start: 30px;

            li {
                line-height: 21px;
                margin: 2px 0;
            }
        }
    }
`;

export const UserDailyActivity: React.FC<{ user: { id: string; name: { first: string } }; time: number }> = ({
    user,
    time
}) => {
    const theme = useTheme();
    const [startTime] = React.useState(moment(time).startOf('day').valueOf());
    const [endTime] = React.useState(moment(time).endOf('day').valueOf());
    const { data } = useQuery<{ activity: UserActivity[] }, { userId: string; startTime: number; endTime: number }>(
        DAILY_ACTIVITY,
        {
            fetchPolicy: 'no-cache',
            variables: { userId: user.id, startTime, endTime }
        }
    );
    const content = data ? (
        <div dangerouslySetInnerHTML={{ __html: userActivityHtml(data.activity[0]) }} />
    ) : (
        <>
            <Skeleton variant="rect" />
            <Skeleton variant="rect" />
            <Skeleton variant="rect" />
        </>
    );
    return (
        <div css={styles(theme)}>
            <div className="activity-header">
                {user.name.first}'s Activity for {moment(time).format('MMM DD, YYYY')}
            </div>
            <div className="activity-content">{content}</div>
        </div>
    );
};
