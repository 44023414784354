import { Map } from 'immutable';

import {
    Action,
    ExtensionProfileParsed,
    ReceiveInitialOutreachForJob,
    ReceiveJobInfo,
    ReceiveJobOnePagerResult,
    ReceiveJobsList,
    ReceiveJobUpdate,
    ReceiveNewJob,
    ReceivePersonDetails,
    ReceiveUpdateJobPrimaryEmail
} from '../actions';
import { mergeArrayToMap } from '../common/lang/immutable-utils';
import { Job } from '../state';

const initialState: Map<string, Job> = Map();

export function jobs(state = initialState, action: Action): Map<string, Job> {
    switch (action.type) {
        case ExtensionProfileParsed:
        case ReceiveJobsList:
            return action.payload.jobs?.length > 0 ? mergeArrayToMap(state, action.payload.jobs) : state;
        case ReceivePersonDetails:
            return mergeArrayToMap(state, action.payload.jobs);
        case ReceiveJobInfo:
            return state.set(action.payload.job.id, action.payload.job);
        case ReceiveNewJob:
            if (!action.error) {
                const job = action.payload;
                return state.set(job.id, job);
            } else {
                return state;
            }
        case ReceiveJobOnePagerResult:
            return state.set(action.payload.job.id, action.payload.job);
        case ReceiveInitialOutreachForJob:
            if (action.payload.job) {
                return state.set(action.payload.job.id, action.payload.job);
            } else {
                return state;
            }
        case ReceiveJobUpdate:
            return state.set(action.payload.id, action.payload.job);
        case ReceiveUpdateJobPrimaryEmail:
            return state.set(action.payload.job.id, action.payload.job);
        default:
            return state;
    }
}
