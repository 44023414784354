export function parseCompensation(
    value: string
): { valid: boolean; formatted: string; rangeLowNum?: number; rangeHighNum?: number } {
    // tslint:disable:no-magic-numbers
    const match = value
        .replace(/,/g, '')
        .trim()
        .match(/^(\d+)(k|K)?\s*(?:-)?\s*(\d+)?(k|K)?$/);
    if (!match) return { valid: false, formatted: value };
    const formattedParts = [];
    const rangeLow = match[1];
    const rangeLowThousand = match[2] ? 'K' : '';
    const rangeHigh = match[3];
    const rangeHighThousand = match[4] ? 'K' : '';
    let rangeLowNum = Number(rangeLow);
    let rangeHighNum = Number(rangeHigh);
    if (isNaN(rangeLowNum)) {
        return { valid: false, formatted: value };
    }
    if (rangeLowThousand === 'K' || (rangeHigh && rangeHighThousand === 'K' && rangeLowNum < 1000)) {
        rangeLowNum = rangeLowNum * 1000;
    }
    formattedParts.push(`${Math.round(rangeLowNum / 1000)}K`);
    if (rangeHigh) {
        if (isNaN(rangeHighNum)) {
            return { valid: false, formatted: value };
        }
        if (rangeHighThousand === 'K' || (rangeLow && rangeLowThousand === 'K' && rangeHighNum < 1000)) {
            rangeHighNum = rangeHighNum * 1000;
        }
        if (rangeHighNum < rangeLowNum || rangeHighNum === 0) {
            return { valid: false, formatted: value };
        }
        formattedParts.push(`${Math.round(rangeHighNum / 1000)}K`);
    } else {
        rangeHighNum = rangeLowNum;
        rangeLowNum = 0;
    }

    return { valid: true, formatted: formattedParts.join(' - '), rangeLowNum, rangeHighNum };
    // tslint:enable:no-magic-numbers
}
