import { Dialog, FlatButton } from 'material-ui';
import * as React from 'react';

import { rocketScreenCompleteStage } from 'shared/models/job-stages';

import { AvailableNotes, Candidate } from '../state';

interface MandatoryWarningProps {
    candidate: Candidate;
    requestClose: () => void;
    moveCandidateToStage: (candidate: Candidate, stage: string) => void;
    open: boolean;
    warningReason: AvailableNotes | 'visa-required';
}

export const MandatoryWarning: React.SFC<MandatoryWarningProps> = ({
    candidate,
    open,
    requestClose,
    warningReason,
    moveCandidateToStage
}) => {
    let actions: JSX.Element[] = [];
    let title = '';
    let warningText;
    if (open) {
        switch (warningReason) {
            case AvailableNotes.NoNotes:
                actions = [<FlatButton key="mandatory-notes-warning-okay" onClick={requestClose} label="Ok" />];
                title = 'Phone screen notes missing';
                warningText = (
                    <span className="dialog-text-body">
                        You must have phone screen notes to move this candidate to Rocket Screen Complete.
                    </span>
                );
                break;
            case AvailableNotes.NotesForOtherJob:
                const proceedAnyway = () => {
                    moveCandidateToStage(candidate, rocketScreenCompleteStage);
                    requestClose();
                };
                actions = [
                    <FlatButton key="mandatory-notes-warning-cancel" onClick={requestClose} label="Cancel" />,
                    <FlatButton
                        key="mandatory-notes-warning-proceed"
                        onClick={proceedAnyway}
                        label="Proceed without notes"
                    />
                ];
                title = 'Phone screen notes missing for this candidate';
                warningText = (
                    <span className="dialog-text-body">
                        There are no phonescreen notes for this candidate. Please add phone screen notes.
                    </span>
                );
                break;
            case 'visa-required':
                title = 'Visa required';
                actions = [<FlatButton key="mandatory-visa-required-cancel" onClick={requestClose} label="Ok" />];
                warningText = (
                    <span className="dialog-text-body">
                        You have not set a visa for this person. Please set or confirm the person's visa status.
                    </span>
                );
                break;
        }

        return (
            <Dialog title={title} open={open} actions={actions} modal={false} onRequestClose={requestClose}>
                {warningText}
            </Dialog>
        );
    } else {
        return null;
    }
};
