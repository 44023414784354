import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Divider, FormControl, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { sortBy } from 'lodash';
import React from 'react';

import { ClientData, HiringManagerData, mergeHiringManagers } from 'shared/models/client';
import { generateUniqueId } from '../core-ui/form-fields';

import { CLIENT_HIRING_MANAGERS, CLIENT_UPDATE } from '../graphql/queries/clients';
import { useSnackbar } from '../hooks/use-snackbar';
import { HiringManagerForm } from './hiring-manager-form';

interface ClientContactsSelectorProps<T = string | string[]> {
    clientId: string;
    value: T;
    label: string;
    onChange: (value: T) => void;
    disabled: boolean;
}

const menuItemStyles = css`
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;

    .edit-button {
        opacity: 0;
        transition: opacity 0.2s;
    }

    &:hover {
        .edit-button {
            opacity: 1;
        }
    }
`;

export const ClientContactsSelector: React.FC<ClientContactsSelectorProps> = ({
    clientId,
    value,
    onChange,
    label,
    disabled
}) => {
    const [selectLabelId] = React.useState(generateUniqueId('select-client-contact-label-'));
    const [addingContact, setAddingContact] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [editingContact, setEditingContact] = React.useState<HiringManagerData | null>(null);
    const { data, refetch } = useQuery<
        { client: { hiringManagers: HiringManagerData[]; domains: string[] } },
        { clientId: string }
    >(CLIENT_HIRING_MANAGERS, { variables: { clientId } });
    const [updateClient] = useMutation<{}, { clientId: string; updates: Partial<ClientData> }>(CLIENT_UPDATE);
    const { setSnackbar } = useSnackbar();
    const multiple = Array.isArray(value);

    const handleOpenAddHMForm = (e?: React.MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();
        setSelectOpen(false);
        setAddingContact(true);
        setEditingContact(null);
    };

    const handleCloseHMForm = () => {
        setAddingContact(false);
        setEditingContact(null);
    };

    const handleSelectChange = (event: React.ChangeEvent<{ value: string | string[] }>) => {
        onChange(event.target.value);
    };

    const handleOpenSelect = () => setSelectOpen(true);
    const handleCloseSelect = () => setSelectOpen(false);

    const handleEditHiringManager = (hiringManager: HiringManagerData) => (e: React.MouseEvent) => {
        e.stopPropagation();
        setEditingContact(hiringManager);
        setAddingContact(false);
        setSelectOpen(false);
    };

    const handleAddManagerToClient = async (hiringManagerData: HiringManagerData) => {
        try {
            setSnackbar(`${editingContact ? 'Updating' : 'Adding'} hiring manager`);
            setAddingContact(false);
            setEditingContact(null);
            const { hiringManagers } = data.client;
            const updated = mergeHiringManagers(hiringManagers, hiringManagerData);
            await updateClient({ variables: { clientId, updates: { hiringManagers: updated } } });
            await refetch();
            setSnackbar(`Hiring manager ${editingContact ? 'updated' : 'added'}`);
        } catch (e) {
            setSnackbar(`Error ${editingContact ? 'updating' : 'adding'} hiring manager`);
        }
    };

    const renderValue = (selected: string | string[]) => {
        if (multiple) {
            return (selected as string[])
                .map((email) => {
                    const hm = data?.client?.hiringManagers.find((h) => h.email === email);
                    return hm ? hm.name.full : email;
                })
                .join(', ');
        } else {
            const hm = data?.client?.hiringManagers.find((h) => h.email === selected);
            return hm ? hm.name.full : selected;
        }
    };

    const options = sortBy(data?.client?.hiringManagers, (hm) => hm.name.full).map((hm) => (
        <MenuItem key={hm.email} value={hm.email}>
            <span css={menuItemStyles}>
                <span>{hm.name.full}</span>
                <span className="edit-button">
                    <IconButton size="small" onClick={handleEditHiringManager(hm)}>
                        <Edit fontSize="small" />
                    </IconButton>
                </span>
            </span>
        </MenuItem>
    ));

    const addHMMenuOpt = disabled ? null : <MenuItem onMouseDown={handleOpenAddHMForm}>Add New Contact</MenuItem>;
    const divider = data?.client.hiringManagers.length > 0 ? <Divider /> : null;

    const selectValue = data?.client?.hiringManagers?.find((hm) =>
        multiple ? value.includes(hm.email) : hm.email === value
    )
        ? value
        : multiple
        ? []
        : '';

    const selectField = (
        <FormControl fullWidth={true}>
            <InputLabel id={selectLabelId}>{label}</InputLabel>
            <Select
                labelId={selectLabelId}
                multiple={multiple}
                value={selectValue}
                onChange={handleSelectChange}
                open={selectOpen}
                onOpen={handleOpenSelect}
                onClose={handleCloseSelect}
                disabled={disabled}
                renderValue={renderValue}
            >
                {options}
                {divider}
                {addHMMenuOpt}
            </Select>
        </FormControl>
    );

    const addHMForm =
        addingContact || editingContact ? (
            <HiringManagerForm
                hiringManager={editingContact}
                clientDomains={data?.client?.domains}
                title="Add New Client Contact"
                onCancel={handleCloseHMForm}
                onSave={handleAddManagerToClient}
            />
        ) : null;

    return (
        <>
            {selectField}
            {addHMForm}
        </>
    );
};
