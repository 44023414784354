import { gql } from '@apollo/client';
import { JobDiversity, JobStatus, JobVisa } from 'shared/models/job';
import { SearchStatus } from '../../state';

export interface LinkedinSearchTag {
    tag: string;
    count: number;
}

export interface SearchPerson {
    url: string;
    person: {
        id: string;
        name: { first: string; full: string; last: string };
        profilePicUrl: string;
    };
}

export interface LinkedinSearch {
    tag: string;
    count: number;
    results: SearchPerson[];
    results_aggregate: {
        aggregate: {
            min: {
                viewedAt: number;
            };
        };
    };
}

export interface Search {
    id: string;
    name: string;
    userId: string;
    status: SearchStatus;
    job: {
        id: string;
        description: string;
        status: JobStatus;
        client: {
            description: string;
            salesPitch: string;
        };
    };
}

export interface JobData {
    id: string;
    title: string;
    clientId: string;
    accountManagerId: string;
    status: JobStatus;
    discipline: string;
    outreachAIContext: {
        directions: string;
        keyCriteria: string;
        highlights: string;
    };
    scoringAIContext: {
        directions: string;
        weights: {
            mustHave: number;
            niceToHave: number;
            seniorityFit: number;
            positiveOutliers: number;
            negativeOutliers: number;
        };
    };
    description: {
        location: string;
        description: string;
        summary: string;
        minExperienceYears: number;
        maxExperienceYears: number;
        skills: string;
        compensation: string;
        visa: JobVisa[];
        mustHaves: string;
        niceToHaves: string;
        responsibilities: string;
        positiveOutliers: string;
        negativeOutliers: string;
        hiringManager: string;
        interviewPrep: string;
        keywords: string[];
        diversity: JobDiversity;
    };
}

export interface JobSearch {
    id: string;
    status: SearchStatus;
    searchProfilesScoreId: string;
}

export const SEARCH_LINKEDIN_TAGS = gql`
    query SearchLinkedinTags($search: String!) {
        tags: search_linkedin_search_tag(args: { search: $search }, where: { count: { _gte: "5" } }) {
            tag
            count
        }
    }
`;

export const LINKEDIN_SEARCHES = gql`
    query LinkedinSearches($tag: String, $profileUrl: String, $limit: Int, $offset: Int) {
        searchesCount: linkedin_search_tag_count(args: { search: $tag, profileUrl: $profileUrl }) {
            result
        }
        searches: search_linkedin_search_tag(
            args: { search: $tag, profileUrl: $profileUrl, limit: $limit, offset: $offset }
        ) {
            tag
            count
            results(limit: 5, order_by: [{ personId: desc }], distinct_on: [personId]) {
                url
                person {
                    id
                    name
                    profilePicUrl
                }
            }
            results_aggregate {
                aggregate {
                    min {
                        viewedAt
                    }
                }
            }
        }
        urls: profile_urls(where: { url: { _eq: $profileUrl } }) {
            url
            person {
                id
                name
                profilePicUrl
            }
        }
    }
`;

export const LINKEDIN_SEARCH_RESULTS = gql`
    query LinkedinSearchResults($tag: String!) {
        results: profile_scraping_extension_scraping_tracker(
            where: { tag: { _eq: $tag } }
            order_by: [{ personId: desc }]
            distinct_on: [personId]
        ) {
            personId
        }
    }
`;

export const UPDATE_LINKEDIN_SEARCH_TAG = gql`
    mutation UpdateLinkedinSearchTag($oldTag: String!, $newTag: String!) {
        update_profile_scraping_extension_scraping_tracker(where: { tag: { _eq: $oldTag } }, _set: { tag: $newTag }) {
            affected_rows
        }
    }
`;

export const SEARCH = gql`
    query Search($searchId: String!) {
        search: searches_by_pk(id: $searchId) {
            id
            name
            status
            userId
            job {
                id
                status
                description: details(path: "description")
                client {
                    id
                    description
                    salesPitch
                }
            }
        }
    }
`;

// do not add more data to this subscription
// add another query for additional data needed if this subscription fires
export const JOB_SEARCHES = gql`
    subscription JobSearches($where: searches_bool_exp!) {
        searches(where: $where) {
            id
            jobId
            status
            searchProfilesScoreId
        }
    }
`;

export const JOB_SEARCH_USERS = gql`
    query SearchUsers($jobId: String!) {
        searches(where: { jobId: { _eq: $jobId } }) {
            id
            status
            user {
                id
                name(path: "full")
            }
        }
    }
`;

export const JOB_DATA = gql`
    query JobData($jobId: String!) {
        job: jobs_by_pk(id: $jobId) {
            id
            title
            clientId
            status
            discipline
            scoringAIContext
            outreachAIContext
            accountManagerId
            description {
                id: jobId
                description
                location
                summary
                minExperienceYears
                maxExperienceYears
                skills
                compensation
                visa
                mustHaves
                niceToHaves
                responsibilities
                positiveOutliers
                negativeOutliers
                hiringManager
                interviewPrep
                keywords
                diversity
            }
        }
    }
`;
