import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import { css } from '@emotion/core';
import { MenuItem, Select } from '@material-ui/core';
import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { Header } from '../components/header';
import { Kanban } from '../components/kanban';
import { Loading } from '../core-ui/loading';
import { PrimaryToggle } from '../core-ui/primary-toggle';
import {
    CANDIDATES_STAGE_COUNTS,
    LATEST_USER_CANDIDATE,
    LatestUserCandidateData,
    STAGE_FUNNEL_METRICS,
    StageCountsRecord,
    StageCountsRecordWithFunnel,
    StageCountsVars,
    StageFunnelData,
    USER_WITH_AM_JOBS,
    UserWithJobs
} from '../graphql/queries/candidates-board';
import { CandidatesBoardProvider } from '../hooks/use-candidates-board';
import { useSession } from '../hooks/use-session';

const selectStyles = css`
    margin-left: 15px;
    color: white;
`;

const rocketScreenScheduledId = 12;
const offerStageId = 23;

export const AMActiveCandidates: React.FC<{ username: string }> = ({ username }) => {
    const session = useSession();
    const assignee = username === 'me' ? session.user.username : username;
    const [selectedClient, setSelectedClient] = React.useState<string>('all');
    const [selectedJob, setSelectedJob] = React.useState<string>('all');
    const [disqualified, setDisqualified] = React.useState(false);
    const [lastModifiedCandidate, setLastModifiedCandidate] = React.useState<number>(undefined);
    const [jobIds, setJobIds] = React.useState<string[]>([]);

    const { data: jobsData, refetch: refetchJobsData, loading: loadingJobs } = useQuery<
        { users: UserWithJobs[] },
        { username: string }
    >(USER_WITH_AM_JOBS, {
        variables: { username: assignee }
    });
    const { data: latestCandidateData } = useSubscription<LatestUserCandidateData, { username: string }>(
        LATEST_USER_CANDIDATE,
        { variables: { username: assignee } }
    );

    const jobs = jobsData?.users[0].jobs ?? [];

    const [fetchStageCounts, { data: stageCountsData }] = useLazyQuery<
        { stageCounts: StageCountsRecord[] },
        StageCountsVars
    >(CANDIDATES_STAGE_COUNTS, { variables: { jobIds, disqualified } });
    const [fetchFunnelData, { data: funnelData }] = useLazyQuery<{ funnel: StageFunnelData[] }, { jobIds: string[] }>(
        STAGE_FUNNEL_METRICS,
        {
            variables: { jobIds }
        }
    );

    React.useEffect(() => {
        setJobIds(
            selectedJob !== 'all'
                ? [selectedJob]
                : selectedClient !== 'all'
                ? jobs?.filter(({ job }) => job.client.id === selectedClient).map(({ job }) => job.id)
                : jobs?.map(({ job }) => job.id)
        );
    }, [jobsData, selectedClient, selectedJob]);

    React.useEffect(() => {
        if (latestCandidateData?.users[0].lastCandidateModifiedAt !== lastModifiedCandidate) {
            setLastModifiedCandidate(latestCandidateData?.users[0].lastCandidateModifiedAt);
        }
    }, [latestCandidateData]);

    React.useEffect(() => {
        fetchStageCounts();
        fetchFunnelData();
    }, [lastModifiedCandidate, jobIds, disqualified]);

    React.useEffect(() => {
        if (!loadingJobs) {
            refetchJobsData();
        }
    }, [lastModifiedCandidate]);

    const handleSelectClients = (event: React.ChangeEvent<{ value: string }>) => {
        setSelectedClient(event.target.value);
        setSelectedJob('all');
    };

    const handleSelectJobs = (event: React.ChangeEvent<{ value: string }>) => {
        setSelectedJob(event.target.value);
    };

    const handleDisqualifyToggle = () => {
        setDisqualified(!disqualified);
    };

    let actions;
    let content = <Loading />;
    let title = 'Active AM Candidates';
    if (jobsData) {
        const clients = jobs
            ?.map(({ job }) => job.client)
            .filter(
                (client, index, self) => index === self.findIndex((c) => c.id === client.id && c.name === client.name)
            );

        const selectedJobs =
            selectedClient !== 'all'
                ? selectedJob !== 'all'
                    ? jobs?.filter(({ job }) => job.client.id === selectedClient && job.id === selectedJob)
                    : jobs?.filter(({ job }) => job.client.id === selectedClient)
                : selectedJob !== 'all'
                ? jobs?.filter(({ job }) => job.id === selectedJob)
                : jobs;

        if (selectedClient === 'all' && selectedJob !== 'all') setSelectedClient(selectedJobs[0].job.client.id);

        const clientOptions = clients?.map((client) => (
            <MenuItem key={client.id} value={client.id}>
                {client.name}
            </MenuItem>
        ));

        const jobOptions = selectedJobs?.map(({ job }) => (
            <MenuItem key={job.id} value={job.id}>
                {job.client.name} - {job.title}
            </MenuItem>
        ));

        actions = [
            <PrimaryToggle
                key="disqualified-toggle"
                checked={!disqualified}
                label={disqualified ? 'Disqualified' : 'Qualified'}
                onChange={handleDisqualifyToggle}
            />,
            <Select
                css={selectStyles}
                key="client-selector"
                value={selectedClient}
                onChange={handleSelectClients}
                disableUnderline={true}
            >
                <MenuItem value="all">All Clients</MenuItem>
                {clientOptions}
            </Select>,
            <Select
                css={selectStyles}
                key="job-selector"
                value={selectedJob}
                onChange={handleSelectJobs}
                disableUnderline={true}
            >
                <MenuItem value="all">All Jobs</MenuItem>
                {jobOptions}
            </Select>
        ];

        const showJobName = selectedJob === 'all';
        const user = jobsData.users[0];

        title = username !== 'me' ? `${user.firstName}'s Active AM Candidates` : title;
        if (stageCountsData) {
            const filteredCounts = stageCountsData.stageCounts.filter(
                (s) => s.candidatesCount > 0 && s.id >= rocketScreenScheduledId && s.id <= offerStageId
            );

            const stageCounts: StageCountsRecordWithFunnel[] = [];
            for (const stage of filteredCounts) {
                const funnelCounts = funnelData?.funnel.find((s) => s.id === stage.id);
                stageCounts.push({
                    ...stage,
                    ...funnelCounts
                });
            }
            content = (
                <CandidatesBoardProvider
                    showClientName={showJobName}
                    showJobName={showJobName}
                    showAssigneeName={true}
                    assignee={undefined}
                    jobIds={jobIds}
                    disqualified={disqualified}
                >
                    <Kanban stageCounts={stageCounts} />
                </CandidatesBoardProvider>
            );
        }
    }

    return (
        <DocumentTitle title={title}>
            <div id="container">
                <Header title={title} actions={actions} />
                <div id="content" className="flex-fill">
                    {content}
                </div>
            </div>
        </DocumentTitle>
    );
};
