import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Avatar,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { clamp } from 'lodash';
import React from 'react';

import { JOB_FUNNEL_TARGETS_DATA, JobFunnelTargetData, UPDATE_JOB_FUNNEL_DATA } from '../graphql/queries/job';
import { useModal } from '../hooks/use-modal';
import { useSnackbar } from '../hooks/use-snackbar';

const minAccepts = 0;
const maxAccepts = 15;

const styles = (theme: Theme) => css`
    .target-form-button {
        margin: 20px 0 0;
        text-align: right;
    }

    .allocations {
        margin-top: 20px;
        margin-bottom: 5px;

        .allocations-content {
            padding-bottom: 3px;
        }

        .allocations-label {
            color: ${theme.palette.primary.main};
            margin-bottom: 8px;
            font-size: 12px;
        }

        .allocations-list {
            margin: -3px;
            display: flex;
            flex-wrap: wrap;
            border-bottom: thin solid ${theme.palette.primary.main};
            padding-bottom: 5px;
            margin-bottom: 0;

            .MuiChip-root {
                margin: 3px;
            }
        }

        .allocations-helper {
            color: ${theme.palette.text.secondary};
            margin: 0;
            font-size: 0.75rem;
            margin-top: 3px;
            text-align: left;
            font-weight: 400;
            line-height: 1.66;
            letter-spacing: 0.03333em;
        }

        &:hover {
            .allocations-list {
                padding-bottom: 4px;
                border-bottom: 2px solid ${theme.palette.primary.main};
            }
        }

        &.error {
            .allocations-list {
                padding-bottom: 4px;
                border-bottom: 2px solid ${theme.palette.error.main} !important;
            }

            .allocations-helper {
                color: ${theme.palette.error.main};
            }
        }
    }
`;

const dialogStyles = css`
    .form-row {
        display: flex;
        justify-content: space-between;

        .form-col-user {
            flex: 1 1 auto;
            margin-right: 40px;
        }

        .form-col-target {
            flex: 0 0 100px;
        }
    }
`;

export const JobFunnelTargetsForm: React.FC<{ jobId: string; disabled: boolean; onChange?: () => void }> = ({
    jobId,
    disabled: formDisabled,
    onChange
}) => {
    const theme = useTheme();
    const { data, refetch, loading } = useQuery<JobFunnelTargetData, { jobId: string }>(JOB_FUNNEL_TARGETS_DATA, {
        variables: { jobId }
    });
    const [updateData] = useMutation<
        {},
        {
            jobId: string;
            stage: string;
            goal: number;
            allocations: Array<{ jobId: string; acceptsGoal: number; userId: string }>;
        }
    >(UPDATE_JOB_FUNNEL_DATA);
    const [target, setTarget] = React.useState<number>(undefined);
    const [saving, setSaving] = React.useState(false);
    const [addAllocation, setAddAllocation] = React.useState(false);
    const [allocationForm, setAllocationForm] = React.useState<{ userId: string; acceptsGoal: number }>(undefined);
    const [allocations, setAllocations] = React.useState<Array<{ userId: string; acceptsGoal: number; jobId: string }>>(
        undefined
    );
    const [isDirty, setIsDirty] = React.useState(false);
    const { setSnackbar } = useSnackbar();
    const { getConfirmation } = useModal();
    const disabled = formDisabled || saving || loading;

    React.useEffect(() => {
        const savedTarget = data?.targets.find((t) => t.stage === 'client_first_round')?.goal;
        setTarget(savedTarget);
        setAllocations(data?.allocations.map((a) => ({ userId: a.userId, acceptsGoal: a.acceptsGoal, jobId })));
        setIsDirty(false);
    }, [data]);

    const handleSave = async () => {
        setSaving(true);
        setSnackbar('Updating target and allocations');
        await updateData({ variables: { jobId, stage: 'client_first_round', goal: target, allocations } });
        onChange?.();
        await refetch();
        setSnackbar('Target and allocations updated');
        setSaving(false);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        setTarget(clamp(value, minAccepts, maxAccepts));
        setIsDirty(true);
    };

    const handleToggleAddAllocation = () => setAddAllocation(!addAllocation);

    const handleAllocationFormChange = (field: 'userId' | 'acceptsGoal') => (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const goalChange = field === 'userId' ? { acceptsGoal: undefined } : {};
        setAllocationForm({ ...allocationForm, [field]: e.target.value, ...goalChange });
    };

    const handleAddAllocation = () => {
        setAddAllocation(false);
        setAllocations([...allocations, { ...allocationForm, jobId }]);
        setAllocationForm(undefined);
        setIsDirty(true);
    };

    const handleRemoveAllocation = (userId: string) => () => {
        getConfirmation(
            () => {
                setAllocations(allocations.filter((a) => a.userId !== userId));
                setIsDirty(true);
            },
            'Are you sure you want to delete this allocation?',
            'Delete Allocation'
        );
    };

    const saveButtonDisabled = disabled || saving || isDirty === false;

    const saveButton = disabled ? null : (
        <div className="target-form-button">
            <Button onClick={handleSave} disabled={saveButtonDisabled}>
                Save
            </Button>
        </div>
    );

    const allocationsList = allocations
        ?.sort(
            (a, b) =>
                data?.users
                    .find((u) => u.id === a.userId)
                    ?.name.localeCompare(data?.users.find((u) => u.id === b.userId)?.name) ?? 0
        )
        .map((allocation) => {
            const user = data?.users.find((u) => u.id === allocation.userId);
            return (
                <Chip
                    key={allocation.userId}
                    label={user?.name ?? '??'}
                    variant="outlined"
                    avatar={<Avatar>{allocation.acceptsGoal}</Avatar>}
                    onDelete={disabled ? undefined : handleRemoveAllocation(allocation.userId)}
                />
            );
        });

    const unAllocatedTarget = target - allocations?.reduce((acc, a) => acc + a.acceptsGoal, 0);
    const addAllocationButton = disabled ? null : (
        <Chip
            className="add-allocation-chip"
            key="add"
            label="Add"
            variant="outlined"
            avatar={<Avatar>+</Avatar>}
            onClick={handleToggleAddAllocation}
            disabled={unAllocatedTarget <= 0 || saving || disabled}
        />
    );

    let addAllocationDialog;
    if (addAllocation) {
        const userOpts = data?.users
            .filter((u) => allocations.findIndex((a) => a.userId === u.id) === -1)
            .map((u) => (
                <MenuItem key={u.id} value={u.id}>
                    {u.name}
                </MenuItem>
            ));
        const acceptsOpts = [];
        const selectedUser = data.users.find((u) => u.id === allocationForm?.userId);
        const userAllocationsSum = selectedUser?.allocations_aggregate.aggregate.sum.acceptsGoal ?? 0;
        const userGoal = selectedUser?.targets?.[0]?.goal ?? 0;
        const availableCapacity = userGoal - userAllocationsSum;
        const last14DayUserAccepts = selectedUser?.last14dFunnel?.[0]?.count ?? 0;
        for (let i = 1; i <= Math.min(unAllocatedTarget, availableCapacity); i++) {
            acceptsOpts.push(
                <MenuItem key={i} value={i}>
                    {i}
                </MenuItem>
            );
        }
        const helperText = selectedUser
            ? `Available capacity: ${availableCapacity} (Last 14 days: ${last14DayUserAccepts} accepts, target ${userGoal})`
            : 'Select a user';
        const addDisabled = allocationForm?.userId === undefined || allocationForm?.acceptsGoal === undefined;
        addAllocationDialog = (
            <Dialog open={true} onClose={handleToggleAddAllocation} maxWidth="sm" fullWidth={true} css={dialogStyles}>
                <DialogTitle>
                    <Typography variant="h4" component="div">
                        Add Allocation
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <div className="form-row">
                        <div className="form-col-user">
                            <TextField
                                label="User"
                                select={true}
                                fullWidth={true}
                                onChange={handleAllocationFormChange('userId')}
                                helperText={helperText}
                                value={allocationForm?.userId ?? ''}
                            >
                                {userOpts}
                            </TextField>
                        </div>
                        <div className="form-col-target">
                            <TextField
                                label="Target"
                                select={true}
                                fullWidth={true}
                                onChange={handleAllocationFormChange('acceptsGoal')}
                                value={allocationForm?.acceptsGoal ?? ''}
                            >
                                {acceptsOpts}
                            </TextField>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleToggleAddAllocation}>Cancel</Button>
                    <Button onClick={handleAddAllocation} disabled={addDisabled}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    const totalCurrent = allocations?.reduce((total, allocation) => total + allocation.acceptsGoal, 0);
    const helperTextSuffix =
        totalCurrent > target
            ? `(${totalCurrent - target} over)`
            : totalCurrent < target
            ? `(${target - totalCurrent} under)`
            : '';

    return (
        <div css={styles(theme)}>
            <div className="target-form-textfield">
                <TextField
                    label="Client Accepts"
                    disabled={disabled}
                    value={target ?? ''}
                    fullWidth={true}
                    onChange={handleChange}
                    helperText={`${data?.metrics?.[0]?.accepts ?? ''} accepts in last 14 days`}
                    type="number"
                />
            </div>
            <div className={`allocations ${totalCurrent !== target ? 'error' : ''}`}>
                <Typography variant="subtitle1" component="div" className="allocations-label">
                    Allocations
                </Typography>
                <div className="allocations-content">
                    <div className="allocations-list">
                        {allocationsList}
                        {addAllocationButton}
                    </div>
                </div>
                <Typography variant="subtitle1" component="div" className="allocations-helper">
                    {totalCurrent} total accepts allocated {helperTextSuffix}
                </Typography>
            </div>
            {saveButton}
            {addAllocationDialog}
        </div>
    );
};
