import { css } from '@emotion/core';
import { Button, Dialog, MenuItem, TextField } from '@material-ui/core';
import * as React from 'react';

import { EmailTemplateView } from 'shared/models/email-templates';
import { getEmailTemplates } from '../actions';

import { JobFormData } from '../graphql/queries/job-form';
import { useModal } from '../hooks/use-modal';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { JobTemplates } from '../state';
import { EmailTemplate } from './email-template';
import { useFormContext } from './form-context';

const dialogStyles = css`
    .MuiDialog-paper {
        width: 60%;
    }
`;

const menuWithButtonStyles = css`
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: space-between;
`;

const templateTypeLabel: { [type in keyof JobTemplates]: string } = {
    introduction: 'Introduction',
    phonescreen: 'Phonescreen',
    preIntroduction: 'Pre Introduction',
    submission: 'Submission'
};

export const JobFormTemplates: React.FC = () => {
    const { data, disabled, onFieldChange } = useFormContext<JobFormData>();
    const dispatch = useReduxDispatch();
    const emailTemplates = useReduxState((state) => state.emailTemplates);
    const { setAlert } = useModal();
    const jobTemplates = data.templates;
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [editingTemplate, setEditingTemplate] = React.useState<{
        type: keyof JobTemplates;
        template: EmailTemplateView;
    }>(null);

    React.useEffect(() => {
        // load email templates
        const templateGroups = ['phonescreen', 'introduction', 'submission'];
        for (const group of templateGroups) {
            if (!emailTemplates.get(group)) {
                dispatch(getEmailTemplates(group));
            }
        }
    }, []);

    const handleSetMenuOpen = (val: boolean) => () => setMenuOpen(val);

    const showModalAlert = (description: string | JSX.Element, title: string) => setAlert(title, description);

    const defaultTemplate = (type: keyof JobTemplates) => {
        const typeDefault: {
            [t in keyof JobTemplates]: {
                group: string;
                kind: string;
            };
        } = {
            introduction: {
                group: 'introduction',
                kind: 'Intro Client - Candidate'
            },
            phonescreen: {
                group: 'phonescreen',
                kind: 'Phonescreen'
            },
            preIntroduction: {
                group: 'introduction',
                kind: 'Pre - intro email to candidate'
            },
            submission: {
                group: 'submission',
                kind: data.jobType === 'S' ? 'Client Submission - Sourcer' : 'Client Submission - Default'
            }
        };

        let template = emailTemplates.get(typeDefault[type].group).find((t) => t.kind === typeDefault[type].kind);
        template = { ...template, id: null, createdAt: null, modifiedAt: null };
        return template;
    };

    const handleOpenEmailTemplateEditor = (type: keyof JobTemplates) => () => {
        const templateToEdit = jobTemplates[type] || defaultTemplate(type);
        setEditingTemplate({
            template: templateToEdit,
            type
        });
    };

    const handleTemplateSelect = (type: keyof JobTemplates) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as 'default' | 'custom';
        if (value === 'default') {
            onFieldChange('templates')({ ...jobTemplates, [type]: null });
        } else if (value === 'custom') {
            onFieldChange('templates')({
                ...jobTemplates,
                [type]: jobTemplates[type] ?? defaultTemplate(type)
            });
            handleOpenEmailTemplateEditor(type)();
        }
    };

    const closeEmailTemplateEditor = () => {
        setEditingTemplate(null);
    };

    const handleEditTemplate = (type: keyof JobTemplates) => (updated: Partial<EmailTemplateView>) => {
        onFieldChange('templates')({
            ...jobTemplates,
            [type]: { ...jobTemplates[type], ...updated }
        });
        closeEmailTemplateEditor();
    };

    const templates = (['phonescreen', 'submission', 'preIntroduction', 'introduction'] as Array<
        keyof JobTemplates
    >).map((type) => {
        const value = jobTemplates[type] ? 'custom' : 'default';
        const editButton = menuOpen ? (
            <Button size="small" onClick={handleOpenEmailTemplateEditor(type)}>
                Edit
            </Button>
        ) : null;

        return (
            <div className="job-field-row" key={type}>
                <TextField
                    select={true}
                    value={value}
                    label={templateTypeLabel[type]}
                    disabled={disabled}
                    fullWidth={true}
                    onChange={handleTemplateSelect(type)}
                    SelectProps={{ onOpen: handleSetMenuOpen(true), onClose: handleSetMenuOpen(false) }}
                >
                    <MenuItem value="default">Default</MenuItem>
                    <MenuItem value="custom">
                        <div css={menuWithButtonStyles}>
                            <div>Custom</div>
                            <div>{editButton}</div>
                        </div>
                    </MenuItem>
                </TextField>
            </div>
        );
    });

    let dialog;
    if (editingTemplate) {
        dialog = (
            <Dialog open={true} maxWidth="md" css={dialogStyles}>
                <EmailTemplate
                    template={editingTemplate.template}
                    onSave={handleEditTemplate(editingTemplate.type)}
                    onCancel={closeEmailTemplateEditor}
                    onDelete={null}
                    showModalAlert={showModalAlert}
                    saving={false}
                    readonly={disabled}
                />
            </Dialog>
        );
    }
    return (
        <div style={{ paddingBottom: '16px' }}>
            {templates}
            {dialog}
        </div>
    );
};
