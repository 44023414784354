import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import { orderBy } from 'lodash';
import React from 'react';

import { getPendingCountsByView } from '../../common/communication-utils';
import { config } from '../../config';
import { Candidate } from '../../graphql/queries/home';
import { useSlides } from '../../hooks/use-candidate-slides';
import { useUserContext } from '../../hooks/use-user-context';
import { HomePagePanel } from './home-page-panel';
import { InfoIcon } from './info-icon';

interface PendingEmailsProps {
    title: string;
    candidates: Candidate[];
}

const Content: React.FC<{ rows: JSX.Element[] }> = ({ rows }) => <List disablePadding={true}>{rows}</List>;

const PendingEmailListItem: React.FC<{ data: Candidate; list: Candidate[] }> = ({ data, list }) => {
    const { user } = useUserContext();
    const { setList } = useSlides();

    const handleSelect = (candidate: Candidate) => () => {
        const selected = {
            jobId: candidate.jobId,
            personId: candidate.personId,
            selectedTab: 'communications'
        };
        const ids = list.map((c) => {
            return {
                jobId: c.jobId,
                personId: c.personId,
                selectedTab: 'communications'
            };
        });
        setList(ids, selected);
    };

    const {
        person: { name, profilePicUrl },
        job,
        stage
    } = data;
    const primary = <Typography variant="h6">{name}</Typography>;
    const assignee = user?.id === data.assignee.id ? null : <span className="assignee">{data.assignee.name}</span>;
    const secondary = (
        <Typography variant="body2" color="textSecondary" component="div">
            <div>
                {job.client.name} - {job.title}
            </div>
            <div>
                {stage.label} {assignee}
            </div>
        </Typography>
    );

    const countsByView = getPendingCountsByView(data.pendingEmails);
    const hasCandidateEmails = countsByView.get('candidate') > 0;
    const hasClientEmails = countsByView.get('client') > 0;
    const hasSharedEmails = countsByView.get('candidate-and-client') > 0;

    const iconColor =
        (hasCandidateEmails && hasClientEmails) || hasSharedEmails
            ? '#691b99'
            : hasClientEmails
            ? '#367cf7'
            : '#f44336';
    const tooltip =
        (hasClientEmails && hasCandidateEmails) || hasSharedEmails
            ? 'Client and Candidate Emails'
            : hasClientEmails
            ? 'Client Emails'
            : 'Candidate Emails';
    const avatar = profilePicUrl ? (
        <Avatar src={`${config.AssetHost}/${profilePicUrl}`} />
    ) : (
        <Avatar>{name.charAt(0).toLocaleUpperCase()}</Avatar>
    );
    return (
        <ListItem key={data._id} button={true} onClick={handleSelect(data)}>
            <ListItemAvatar>{avatar}</ListItemAvatar>
            <ListItemText disableTypography={true} primary={primary} secondary={secondary} />
            <InfoIcon tooltip={tooltip} icon={<Email />} color={iconColor} />
        </ListItem>
    );
};

export const PendingEmails: React.FC<PendingEmailsProps> = ({ title, candidates }) => {
    const records = orderBy(
        candidates,
        [
            (c) => c.stage.id,
            (c) => !!c.pendingEmails.find((e) => e.isClientComm),
            (c) => c.job.status,
            (c) => c.job.client.name,
            (c) => c.job.title,
            (c) => c.person.name
        ],
        ['desc', 'desc', 'asc', 'asc', 'asc', 'asc']
    );

    if (candidates && candidates.length === 0) return null;

    return (
        <HomePagePanel
            RowComponent={PendingEmailListItem}
            ContentComponent={Content}
            records={records}
            title={title}
            containerClass=""
            collapseKeyId={`panel-${title}`}
        />
    );
};
