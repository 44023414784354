import { css } from '@emotion/core';
import { Button, Theme, useTheme } from '@material-ui/core';
import * as React from 'react';
import Dropzone, { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

import { uploadJobOnePager } from '../api';
import { config } from '../config';
import { Pdf } from '../core-ui/pdf';
import { useSnackbar } from '../hooks/use-snackbar';
import { readFile } from '../lib/read-file-payload';

interface JobOnePagerProps {
    onePager: {
        key: string;
        size: number;
    };
    jobId: string;
    onChange: (onePager: { key: string; size: number }) => void;
}

const onePagerHeightRatio = 1.76;
const onePagerHeightPadding = 64;
const onePagerWidth = 888;
const onePagerHeight = onePagerWidth / onePagerHeightRatio + onePagerHeightPadding;

const styles = (theme: Theme) => css`
    width: 100%;
    position: relative;
    padding-top: 24px;

    .one-pager-label {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
        transform: translate(0, 0) scale(0.75);
        transform-origin: top left;
        transition: all 200ms ease-out;
        color: ${theme.palette.text.secondary};
    }

    .job-one-pager-pdf {
        width: 100%;
        height: 100%;
    }

    .onepager-placeholder {
        padding: 32px;
        background: ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
    }

    .one-pager-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 18px;

        .MuiButtonBase-root {
            margin-right: 8px;
            min-width: 100px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

export const JobOnePager: React.FC<JobOnePagerProps> = ({ jobId, onePager, onChange }) => {
    const [saving, setSaving] = React.useState(false);
    const theme = useTheme();
    const { setSnackbar } = useSnackbar();

    const handleDrop = async (files: File[]) => {
        if (!saving) {
            try {
                setSaving(true);
                setSnackbar('Uploading one pager...');
                const content = await readFile(files[0]);
                const res = await uploadJobOnePager(jobId, content);
                const job = res.data.job;
                onChange(job.onePager);
                setSnackbar('One pager uploaded');
            } catch {
                setSnackbar('Failed to upload one pager');
            } finally {
                setSaving(false);
            }
        }
    };

    const onePagerLink = onePager
        ? `${config.AssetHost}/files/` + onePager.key.split('/').map(encodeURIComponent).join('/')
        : null;
    const fileDisplay =
        onePager && !saving ? (
            <div style={{ width: onePagerWidth, height: onePagerHeight }}>
                <Pdf url={onePagerLink} className="job-one-pager-pdf" />
            </div>
        ) : saving ? null : (
            <div className="onepager-placeholder">Please upload a one pager for this job.</div>
        );

    const getDropzoneSection = (
        getRootProps: <T extends DropzoneRootProps>(props?: T) => T,
        getInputProps: <T extends DropzoneInputProps>(props?: T) => T
    ) => {
        return (
            <section>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button>Upload</Button>
                </div>
            </section>
        );
    };

    const uploadButton = saving ? (
        <Button disabled={true}>Saving</Button>
    ) : (
        <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => getDropzoneSection(getRootProps, getInputProps)}
        </Dropzone>
    );

    const downloadButton = onePagerLink ? (
        <a href={onePagerLink} target="_blank" download={true}>
            <Button disabled={saving}>Download</Button>
        </a>
    ) : null;

    return (
        <div css={styles(theme)}>
            <div className="one-pager-label">One Pager</div>
            {fileDisplay}
            <div className="one-pager-footer">
                {downloadButton}
                {uploadButton}
            </div>
        </div>
    );
};
