import { gql } from '@apollo/client';
import { internalClientIds } from 'shared/common/job-constants';
import { JobDiversity, JobVisa } from 'shared/models/job';
import { NoteData } from 'shared/models/note';

export interface NoteDataWithUser extends NoteData {
    user: {
        name: {
            first: string;
            last: string;
            full: string;
        };
        id: string;
        profilePicUrl: string;
    };
}

export interface Job {
    client: {
        id: string;
        name: string;
        description: string;
        salesPitch: string;
        founders: string;
        funding: string;
        hq: string;
        foundedDate: string;
        hiredCandidates: {
            aggregate: { count: number };
        };
        teamSize: string;
        notes?: Array<{ note: NoteDataWithUser }>;
    };
    id: string;
    title: string;
    description: {
        location: string;
        summary: string;
        minExperienceYears: number;
        maxExperienceYears: number;
        skills: string;
        diversity: JobDiversity;
        linkToJobDescription: string;
        visa: JobVisa[];
        compensation: string;
        description: string;
        interviewPrep: string;
        keywords: string[];
        hiringManager: string;
    };
    placementFees: string;
    recruiterPercentage: number;
    accountManagerPercentage: number;
    headcount: number;
}

export interface CrosspitchableJob {
    personId?: string;
    job: Job;
}

export interface PhoneScreenJobs {
    jobs: Job[];
    cpJobs: Array<{
        job: Job;
    }>;
}

export interface PersonBlacklistStatus {
    global: {
        blacklisted: boolean;
    };
    user: {
        userId: string;
        personId: string;
    };
}

export const jobFields = gql`
    fragment jobFields on jobs {
        client {
            id
            name
            hiredCandidates: candidates_aggregate(where: { stage: { _eq: "hired" }, disqualified: { _eq: false } }) {
                aggregate {
                    count
                }
            }
            description
            salesPitch
            founders
            funding
            hq
            foundedDate
            teamSize
            notes(order_by: { note: { createdAt: desc } }) {
                note {
                    notable
                    modifiedBy
                    modifiedAt
                    id
                    history
                    format
                    deleted
                    createdBy
                    createdAt
                    context
                    content
                    attachments
                    user {
                        name
                        id
                        profilePicUrl
                    }
                }
            }
        }
        id
        title
        description {
            id: jobId
            summary
            minExperienceYears
            maxExperienceYears
            skills
            diversity
            linkToJobDescription
            visa
            compensation
            description
            interviewPrep
            keywords
            hiringManager
            location
        }
        placementFees
        recruiterPercentage
        accountManagerPercentage
        headcount
        status
        discipline
    }
`;

export const CROSSPITCHABLE_JOBS = gql`
    ${jobFields}
    query CrosspitchableJobs($personId: String!, $clientId: String) {
        jobs: crosspitchable_jobs(where: { personId: { _eq: $personId }, clientId: { _neq: $clientId } }) {
            job {
                ...jobFields
            }
        }
    }
`;

export const CROSSPITCHABLE_JOBS_BY_PERSONS = gql`
    ${jobFields}
    query CrosspitchableJobs($personIds: [String!]) {
        jobs: crosspitchable_jobs(where: { personId: { _in: $personIds } }) {
            personId
            job {
                ...jobFields
            }
        }
    }
`;

export const PHONESCREEN_JOBS = gql`
    ${jobFields}
    query PhoneScreenJobs($personId: String!, $jobId: String!, $clientId: String!) {
        jobs: jobs(where: { id: { _eq: $jobId } }) {
            ...jobFields
        }
        cpJobs: crosspitchable_jobs(where: { personId: { _eq: $personId }, clientId: { _neq: $clientId } }) {
            job {
                ...jobFields
            }
        }
    }
`;

// cspell:disable
export const PHONESCREEN_SEARCH_JOBS = gql`
    ${jobFields}
    query PhoneScreenSearchJobs {
        jobs(
            where: {
                status: { _eq: 1 }
                jobType: { _in: ["C", "C+$", "CnC", "R"] }
                clientId: { _nin: ${JSON.stringify(internalClientIds)} }
            }
            order_by: [{ status: asc }, { client: { name: asc } }, { title: asc }]
        ) {
            ...jobFields
            createdAt
            assignee: userByAssignee {
                id
                name(path: "full")
            }
            accountManager: userByAccountmanagerid {
                id
                name(path: "full")
            }
            jobType
        }
    }
`;
// cspell:enable

export const SAVE_PHONESCREEN_DATA = gql`
    mutation SavePhonescreenData($personId: String!, $note: notes_insert_input!, $personUpdates: persons_set_input!) {
        insert_notes(objects: [$note]) {
            affected_rows
        }
        update_persons(where: { id: { _eq: $personId } }, _set: $personUpdates) {
            affected_rows
        }
    }
`;

export const NOTES = gql`
    query PersonNotes($notable: String!) {
        notes(where: { notable: { _eq: $notable }, deleted: { _eq: false } }, order_by: { createdAt: desc }) {
            notable
            modifiedBy
            modifiedAt
            id
            history
            format
            deleted
            createdBy
            createdAt
            context
            content
            attachments
            user {
                name
                id
                profilePicUrl
            }
        }
    }
`;

export const PROFILE = gql`
    query Profile($personId: String!) {
        profile: profile_contents(where: { personId: { _eq: $personId } }) {
            content
            viewedAt
        }
    }
`;

export const GET_PERSON_BLACKLIST = gql`
    query Blacklist($personId: String!, $userId: String!) {
        global: persons_by_pk(id: $personId) {
            blacklisted
        }
        user: users_blacklist_by_pk(personId: $personId, userId: $userId) {
            userId
            personId
        }
    }
`;

export const ADD_USER_BLACKLIST = gql`
    mutation AddUserBlacklist($personId: String!, $userId: String!) {
        insert_users_blacklist(
            objects: { personId: $personId, userId: $userId }
            on_conflict: { constraint: users_blacklist_pkey, update_columns: [] }
        ) {
            affected_rows
        }
    }
`;

export const DELETE_USER_BLACKLIST = gql`
    mutation DeleteUserBlacklist($personId: String!, $userId: String!) {
        delete_users_blacklist_by_pk(personId: $personId, userId: $userId) {
            userId
            personId
        }
    }
`;

export const UPDATE_GLOBAL_BLACKLIST = gql`
    mutation AddGlobalBlacklist($personId: String!, $blacklisted: Boolean!) {
        update_persons_by_pk(pk_columns: { id: $personId }, _set: { blacklisted: $blacklisted }) {
            id
            blacklisted
        }
    }
`;

export const PERSON_PROFILE_VIEWED_AT = gql`
    subscription PersonProfileViewedAt($personId: String!) {
        profile: profile_contents_by_pk(personId: $personId) {
            viewedAt
        }
    }
`;
