import { css } from '@emotion/core';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Theme, useTheme } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';

import { Editor } from 'react-ce';

import { interpolate, SequenceVariables } from 'shared/models/sequence';

import { Sequence } from '../graphql/queries/outreach';

interface SequencePreviewProps {
    sequence: Sequence;
    variables: SequenceVariables;
    custom?: {
        stages: Array<{ subject: string; body: string }>;
        onChange: (stages: Array<{ subject: string; body: string }>) => void;
    };
}

const styles = (theme: Theme) => css`
    .panel {
        box-shadow: none;
        border: thin solid ${theme.palette.divider};
    }

    .body {
        opacity: 0.85;
        border-top: thin solid ${theme.palette.divider};
        flex-direction: column;
        padding: 16px;
    }

    .subject {
        font-size: 14px;
    }
`;

export const SequencePreview: React.FC<SequencePreviewProps> = ({ sequence, variables, custom }) => {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(0);

    if (!sequence) return null;

    const handleExpandToggle = (index: number) => (_1: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? index : null);
    };

    const getStageSubject = (index: number) => {
        const subject = interpolate(sequence.stages[index].subject || sequence.stages[0].subject, variables);
        return subject.match(/^Re:/i) || index === 0 ? subject : `Re: ${subject}`;
    };

    const handleStageContentChange = (stageIndex: number) => (value: string) => {
        const customStages = sequence.stages.map((s, i) => {
            const subject = getStageSubject(i);
            const body =
                stageIndex === i
                    ? value
                    : custom.stages[i]?.body
                    ? custom.stages[i].body
                    : interpolate(s.body, variables);
            return { subject, body };
        });
        custom.onChange(customStages);
    };

    const stages = sequence.stages.map((stage, i) => {
        let expansionPanelHeader;
        let body;

        if (!variables) {
            expansionPanelHeader = <Skeleton style={{ flex: '1 1 auto' }} />;
            body = (
                <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </>
            );
        } else if (custom?.stages[i]) {
            expansionPanelHeader = <span>{custom.stages[i].subject}</span>;
            body = (
                <Editor value={custom.stages[i].body ?? ''} autoFocus={true} onChange={handleStageContentChange(i)} />
            );
        } else {
            const subject = getStageSubject(i);
            expansionPanelHeader = <span>{interpolate(subject, variables)}</span>;
            const html = interpolate(stage.body, variables);
            body = custom?.onChange ? (
                <Editor value={html ?? ''} autoFocus={true} onChange={handleStageContentChange(i)} />
            ) : (
                <div dangerouslySetInnerHTML={{ __html: html }} />
            );
        }

        return (
            <ExpansionPanel expanded={expanded === i} key={i} onChange={handleExpandToggle(i)} className="panel">
                <ExpansionPanelSummary expandIcon={<ExpandMore />} className="subject">
                    {expansionPanelHeader}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="body">{body}</ExpansionPanelDetails>
            </ExpansionPanel>
        );
    });

    return <div css={styles(theme)}>{stages}</div>;
};
