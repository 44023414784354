import { css } from '@emotion/core';
import { Button, Dialog, DialogActions } from '@material-ui/core';
import { Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { downloadFile } from '../actions';
import { tableBorderColor } from '../common/css-variables';
import { RequestErrors, State } from '../state';
import { Pdf } from './pdf';

const styles = css`
    .MuiDialog-paper {
        width: 70%;
    }
`;

interface OwnProps {
    path: string;
    filename: string;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
}

interface ConnectedDispatch {
    downloadFile: (path: string, filename: string) => void;
}

type FileDownloadProps = OwnProps & ConnectedProps & ConnectedDispatch;

const FileDownloadComponent: React.FC<FileDownloadProps> = (props) => {
    const { path, filename, children, pendingRequests } = props;
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (path) {
            setDialogOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleDownloadClick = () => {
        props.downloadFile(path, filename);
    };

    const inProgress = pendingRequests.has(`download-file-${path}`);
    let dialog;
    if (dialogOpen) {
        const publicUrl = `https://files.getrocket.com/${path.split('/').map(encodeURIComponent).join('/')}`;
        const actions = [
            <Button onClick={handleCloseDialog} key="close">
                Close
            </Button>,
            <Button onClick={handleDownloadClick} key="download" disabled={inProgress}>
                Download
            </Button>
        ];

        const preview = path.match(/\.pdf$/i) ? (
            <Pdf url={publicUrl} height="100%" width="100%" />
        ) : (
            <iframe
                src={`https://docs.google.com/viewer?embedded=true&url=${publicUrl}`}
                frameBorder="0"
                style={{ width: '100%', height: '100%' }}
            />
        );

        dialog = (
            <Dialog css={styles} open={true} onClose={handleCloseDialog} maxWidth="md">
                <div style={{ height: '75vh', margin: '0', borderBottom: `thin solid ${tableBorderColor}` }}>
                    {preview}
                </div>
                <DialogActions>{actions}</DialogActions>
            </Dialog>
        );
    }

    return (
        <div>
            <div className={`file-download-link ${inProgress || !path ? 'disabled' : ''}`} onClick={handleClick}>
                {children}
            </div>
            {dialog}
        </div>
    );
};

const mapStateToProps = (state: State): ConnectedProps => ({
    pendingRequests: state.pendingRequests
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    downloadFile
};

export const FileDownloadLink = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(FileDownloadComponent);
