import { startCase } from 'lodash';
import * as React from 'react';

import { anyValue, SearchConfigDiversity } from 'shared/models/search';

import { SelectField } from '../core-ui/simple-select-field';
import { RadioButtons } from './search-radio-buttons';
import { SearchRequirementsSection } from './search-requirements-section';
import { useSearch } from './use-search';

export const SearchDiversityRequirements: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config.diversity;
    const onChange = searchContext.onConfigFieldChange('diversity');
    const readonly = searchContext.readonly;

    const handleUpdate = <T extends keyof SearchConfigDiversity>(field: T) => (value: SearchConfigDiversity[T]) => {
        const updated = Object.assign({}, config, { [field]: value });
        onChange(updated);
    };

    if (!config) return null;

    const { gender, race, operator } = config;
    const genderOptions = [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Any', value: anyValue }
    ];
    const raceOptions = [
        'asian',
        'african-american',
        'middle-eastern',
        'pacific-islander',
        'native-american',
        'hispanic',
        'undefined'
    ];
    const operatorOptions = [
        { label: 'And', value: 'and' },
        { label: 'Or', value: 'or' }
    ];
    return (
        <SearchRequirementsSection section="diversity">
            <div className="search-requirements-field">
                <label className="search-label">Diversity</label>
                <div className="search-requirements-value radio-buttons">
                    <RadioButtons
                        readonly={readonly}
                        name="gender"
                        selected={gender}
                        onChange={handleUpdate('gender')}
                        options={genderOptions}
                    />
                </div>
            </div>
            <div className="search-requirements-field">
                <label className="search-label">Operator</label>
                <div className="search-requirements-value radio-buttons">
                    <RadioButtons
                        readonly={readonly}
                        name="operator"
                        selected={operator}
                        onChange={handleUpdate('operator')}
                        options={operatorOptions}
                    />
                </div>
            </div>
            <div className="search-requirements-field">
                <div className="search-requirements-value radio-buttons">
                    <SelectField
                        className="search-requirements-field"
                        selected={race || []}
                        onSelect={handleUpdate('race')}
                        options={raceOptions}
                        getOptionLabel={startCase}
                        label="Race"
                        multiple={true}
                    />
                </div>
            </div>
        </SearchRequirementsSection>
    );
};
