import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import React from 'react';

import { Editor } from 'react-ce';
import { UserData } from 'shared/models/user';

import { ExpandMore } from '@material-ui/icons';
import { logger } from '../common/logger';
import { UPDATE_USER, User, USER_BY_USERNAME } from '../graphql/queries/user';
import { useSnackbar } from '../hooks/use-snackbar';
import { RecruiterFunnelTargetsForm } from './recruiter-funnel-targets-form';

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .account-data-container {
        overflow: auto;
        padding: 30px 60px 20px;
        display: flex;
        flex-direction: column;

        .setting-section {
            .section-header {
                padding: 5px 20px;

                &.Mui-expanded {
                    border-bottom: thin solid ${theme.palette.divider};
                }
            }

            .section-content {
                display: block;
                padding: 20px;

                .home-page-settings {
                    .MuiFormControl-root {
                        margin: 10px 0;
                    }
                }
            }
        }

        .text-editor-container {
            padding-bottom: 10px;
            border-bottom: thin solid ${theme.palette.divider};
        }

        .sub-text {
            color: ${theme.palette.text.secondary};
            font-size: 12px;
            margin: 10px 0;
        }

        .actions {
            text-align: right;

            .MuiButtonBase-root {
                margin-left: 15px;
            }
        }
    }
`;

export const Account: React.FC<{ username: string }> = ({ username }) => {
    const theme = useTheme();
    const { setSnackbar } = useSnackbar();
    const [signature, setSignature] = React.useState<string>(undefined);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(0);
    const [showAssigneeNameInSubmissions, setShowAssigneeNameInSubmissions] = React.useState<boolean>(false);
    const [emailDelayMs, setEmailDelayMs] = React.useState<number>(0);

    const { data: userData, refetch } = useQuery<
        { users: User[]; userPermissions: Permissions[] },
        { username: string }
    >(USER_BY_USERNAME, {
        variables: { username }
    });
    const [updateUser, { loading }] = useMutation<{}, { id: string; updates: Partial<UserData> }>(UPDATE_USER);

    const user = userData?.users[0];

    React.useEffect(() => {
        if (userData) {
            setSignature(user.signature);
            setRowsPerPage(user.settings.homePage.rowsPerPage);
            setShowAssigneeNameInSubmissions(user.settings.homePage.showAssigneeNameInSubmissions);
            setEmailDelayMs(user.settings.emailSendDelayMs ?? 0);
        }
    }, [userData]);

    const handleSaveSignature = async (updated: string) => {
        setSnackbar('Saving...');
        try {
            await updateUser({ variables: { id: user.id, updates: { signature: updated } } });
            refetch();
            setSnackbar('Saved');
        } catch (err) {
            setSnackbar('Error updating');
            logger.error(err, { message: 'Error saving user updates' });
        }
    };

    const handleSave = async () => {
        setSnackbar('Saving...');
        try {
            const settings = user?.settings;
            await updateUser({
                variables: {
                    id: user.id,
                    updates: {
                        settings: {
                            ...settings,
                            emailSendDelayMs: emailDelayMs,
                            homePage: {
                                ...settings.homePage,
                                rowsPerPage,
                                showAssigneeNameInSubmissions
                            }
                        }
                    }
                }
            });
            refetch();
            setSnackbar('Saved');
        } catch (err) {
            setSnackbar('Error updating');
            logger.error(err, { message: 'Error saving user updates' });
        }
    };

    const setRowsPerPageData = (event: React.ChangeEvent<{ value: string }>) => {
        setRowsPerPage(Number(event.target.value) ?? 0);
    };

    const setShowAssigneeNameInSubmissionsData = (event: React.ChangeEvent<{ value: boolean }>) => {
        setShowAssigneeNameInSubmissions(event.target.value);
    };

    const handleEmailDelayChange = (event: React.ChangeEvent<{ value: number }>) => {
        setEmailDelayMs(event.target.value);
    };

    const handleSaveSignatureClick = async () => {
        handleSaveSignature(signature);
    };

    const handleClearSignatureClick = async () => {
        setSignature(null);
        handleSaveSignature(null);
    };

    const clearSignatureButton = user?.signature ? (
        <Tooltip title="Remove Signature">
            <Button variant="contained" disabled={!userData || loading} onClick={handleClearSignatureClick}>
                Clear
            </Button>
        </Tooltip>
    ) : null;

    const disabled =
        loading ||
        !userData ||
        (rowsPerPage === user?.settings.homePage.rowsPerPage &&
            showAssigneeNameInSubmissions === user?.settings.homePage.showAssigneeNameInSubmissions &&
            emailDelayMs === user?.settings?.emailSendDelayMs);

    const emailSendDelayOptions = [
        { label: 'Send Immediately', value: 0 },
        { label: '30 Seconds', value: 30000 },
        { label: '1 Minute', value: 60000 },
        { label: '2 Minutes', value: 120000 },
        { label: '5 Minutes', value: 300000 }
    ];

    const emailSendDelayMenuItems = emailSendDelayOptions.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
            {label}
        </MenuItem>
    ));

    return (
        <div css={styles(theme)}>
            <div className="account-data-container">
                <ExpansionPanel className="setting-section" defaultExpanded={true}>
                    <ExpansionPanelSummary className="section-header" expandIcon={<ExpandMore />}>
                        <Typography variant="h5">Client Accepts Target</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="section-content">
                        <RecruiterFunnelTargetsForm username={username} />
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel className="setting-section">
                    <ExpansionPanelSummary className="section-header" expandIcon={<ExpandMore />}>
                        <Typography variant="h5">Email Signature</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="section-content">
                        <div>
                            <Editor
                                value={signature}
                                onChange={setSignature}
                                placeholder="Signature"
                                disabled={loading || !userData}
                            />
                            <div className="sub-text">
                                If the email signature is specified, it is used to replace the variable{' '}
                                <i>{'{{Sender Signature}}'}</i> in email templates. Once set, it is also available to
                                insert in the email compose window.
                            </div>
                            <div className="actions">
                                {clearSignatureButton}
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={signature === user?.signature || !userData || loading}
                                    onClick={handleSaveSignatureClick}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel className="setting-section">
                    <ExpansionPanelSummary className="section-header" expandIcon={<ExpandMore />}>
                        <Typography variant="h5">Home Page Settings</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="section-content">
                        <div className="home-page-settings">
                            <FormControl fullWidth={true}>
                                <TextField
                                    label="Number of items per section"
                                    value={rowsPerPage}
                                    onChange={setRowsPerPageData}
                                    placeholder="Rows per Page"
                                    disabled={loading || !userData}
                                    type="number"
                                    InputProps={{ inputProps: { min: 1 } }}
                                />
                            </FormControl>
                            <FormControl fullWidth={true}>
                                <InputLabel htmlFor="show-assignee">Show assignee name in submissions</InputLabel>
                                <Select
                                    labelId="show-assignee"
                                    id="show-assignee"
                                    value={showAssigneeNameInSubmissions}
                                    onChange={setShowAssigneeNameInSubmissionsData}
                                    disabled={loading || !userData}
                                >
                                    <MenuItem selected={!showAssigneeNameInSubmissions} value={false as any}>
                                        False
                                    </MenuItem>
                                    <MenuItem selected={showAssigneeNameInSubmissions} value={true as any}>
                                        True
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="actions">
                            <Button color="primary" variant="contained" disabled={disabled} onClick={handleSave}>
                                Save
                            </Button>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel className="setting-section">
                    <ExpansionPanelSummary className="section-header" expandIcon={<ExpandMore />}>
                        <Typography variant="h5">Email Sending Undo Period</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="section-content">
                        <div className="home-page-settings">
                            <FormControl fullWidth={true}>
                                <InputLabel htmlFor="email-delay">Allow Undo Send For</InputLabel>
                                <Select
                                    labelId="email-delay"
                                    id="email-delay"
                                    value={emailDelayMs}
                                    onChange={handleEmailDelayChange}
                                    disabled={loading || !userData}
                                >
                                    {emailSendDelayMenuItems}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="actions">
                            <Button color="primary" variant="contained" disabled={disabled} onClick={handleSave}>
                                Save
                            </Button>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        </div>
    );
};
