import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { css } from '@emotion/core';
import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { JobStatus } from 'shared/models/job';
import { hasRole } from 'shared/models/user';
import { JobEditForm } from '../components/job-edit-form';
import { JobLLMEditsHistory } from '../components/job-llm-edits-history';
import { JobPageHeader } from '../components/job-page-header';
import {
    JOB_FORM_DATA,
    JOB_LAST_MODIFICATION_AT,
    JobFormData,
    JobLastModificationAtData,
    JobUpdateData,
    UPDATE_JOB_WITH_CLIENT_AND_DESCRIPTION
} from '../graphql/queries/job-form';
import { useSession } from '../hooks/use-session';

const styles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    overflow-y: auto;
`;

export const JobEdit: React.FC<{ id: string }> = ({ id }) => {
    const { data: modificationData } = useSubscription<{ job: JobLastModificationAtData }, { jobId: string }>(
        JOB_LAST_MODIFICATION_AT,
        {
            variables: { jobId: id }
        }
    );
    const { data, refetch } = useQuery<{ job: JobFormData }, { jobId: string }>(JOB_FORM_DATA, {
        variables: { jobId: id }
    });
    const [updateJob] = useMutation<{}, JobUpdateData>(UPDATE_JOB_WITH_CLIENT_AND_DESCRIPTION);
    const { user, userPermissions } = useSession();

    const handleSave = async (updated: JobUpdateData) => {
        await updateJob({ variables: updated });
        refetch();
    };

    React.useEffect(() => {
        refetch();
    }, [modificationData]);

    const job = data?.job;
    const client = job?.client;

    const canEdit =
        job?.assignee === user?.id || job?.accountManagerId === user?.id || hasRole(userPermissions, 'settings_editor');
    const jobArchived = job?.status === JobStatus.Archived;

    const content = job ? <JobEditForm job={data?.job} disabled={!canEdit || jobArchived} onSave={handleSave} /> : null;

    return (
        <DocumentTitle title={`Editing Job - ${client?.name} - ${job?.title}`}>
            <div id="container">
                <JobPageHeader jobId={id} actions={[]} activeTab="Settings" />
                <div id="content" className="flex-fill">
                    <div css={styles}>{content}</div>
                </div>
                <JobLLMEditsHistory jobId={id} />
            </div>
        </DocumentTitle>
    );
};
