import css from '@emotion/css';
import React from 'react';

import { SearchClientEmployeesRequirements } from './search-client-employees-requirements';
import { SearchConfigCompanies } from './search-config-companies';
import { SearchConfigEducation } from './search-config-education';
import { SearchConfigLinkedinSearch } from './search-config-linkedin-search';
import { SearchConfigLogistics } from './search-config-logistics';
import { SearchConfigWork } from './search-config-work';
import { SearchDiversityRequirements } from './search-diversity-requirements';
import { SearchEducationBootcamp } from './search-education-bootcamp';
import { SearchInferredSkills } from './search-inference-requirements';
import { SearchKeywordsRequirements } from './search-keywords-requirements';
import { SearchRocketProgress } from './search-rocket-progress';
import { useSearch } from './use-search';

const styles = css`
    .search-requirements-section .search-requirements-section-title {
        font-size: 16px;
        font-weight: 600;
    }
`;

export const SearchConfigUI = () => {
    const { data } = useSearch();
    const { config } = data;

    if (!config) {
        return (
            <div className="job-search-body">
                <div className="job-search-no-config">Unable to display search config</div>
            </div>
        );
    }

    return (
        <div className="job-search-body">
            <div css={styles}>
                <SearchConfigLogistics />
                <SearchConfigWork />
                <SearchConfigCompanies />
                <SearchConfigEducation />
                <SearchEducationBootcamp />
                <SearchKeywordsRequirements />
                <SearchInferredSkills />
                <SearchDiversityRequirements />
                <SearchRocketProgress />
                <SearchClientEmployeesRequirements />
                <SearchConfigLinkedinSearch />
            </div>
        </div>
    );
};
