import { gql } from '@apollo/client';

import { ClientData } from 'shared/models/client';
import { JDUpdateContext } from 'shared/models/jd-llm-updates';
import { JobData } from 'shared/models/job';
import { JobDescriptionData } from 'shared/models/job-description';

export interface JDLLMUpdate {
    input: string;
    resultsFile: string;
    updatedAt: number;
    context: JDUpdateContext;
    updates: {
        client?: { old: ClientData; new: ClientData };
        job?: { old: JobData; new: JobData };
        jobDescription?: { old: JobDescriptionData; new: JobDescriptionData };
    };
    job: {
        id: string;
        title: string;
        client: { id: string; name: string };
    };
}

type TypedNotification =
    | {
          type: 'JDLLMUpdate';
          jdLLMUpdate: JDLLMUpdate[];
      }
    | {
          type: never;
      };

export type Notification = { id: string; userId: string } & TypedNotification;

export const USER_NOTIFICATIONS = gql`
    query UserNotifications($userId: String!) {
        notifications(where: { userId: { _eq: $userId }, completedAt: { _is_null: true } }) {
            id
            type
            userId
            jdLLMUpdate {
                input
                resultsFile
                updatedAt
                updates
                context
                job {
                    id
                    title
                    client {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const UPDATE_NOTIFICATION = gql`
    mutation UpdateNotification($id: String!, $updates: notifications_set_input!) {
        update_notifications_by_pk(pk_columns: { id: $id }, _set: $updates) {
            id
        }
    }
`;
