import { fromPairs } from 'lodash';

import { JobMetrics, JobType, JobTypes } from '../state';

export interface MetricBenchMark {
    [metric: string]: { green: number; yellow: number };
}

export interface MetricDisplayInfo {
    color: string;
    count: number;
    percentageDenominator?: number;
    description: string;
    otherStats: string[];
    targetBenchmark: number;
}

const metricInfo: { [metric: string]: string } = {
    accepts: 'candidates accepted',
    disqualifiedAddedByMistake: 'added by mistake',
    disqualifiedNotQualifiedOnReview: 'not qualified on review',
    emailsSent: 'emails sent',
    movedPastResponseReceived: 'candidates advanced to this stage',
    movedToRocketScreenComplete: 'candidates advanced to this stage',
    movedToRocketScreenScheduled: 'candidates advanced to this stage',
    responseRate: 'responded',
    submits: 'candidates submitted'
};

function jobSpecificMetricsBenchmarks(dailyEmailLimit: number): MetricBenchMark {
    const greenPercentage = 0.7;
    const yellowPercentage = 0.5;

    return {
        emailsSent: {
            green: dailyEmailLimit * 5 * greenPercentage, // tslint:disable-line:no-magic-numbers
            yellow: dailyEmailLimit * 5 * yellowPercentage // tslint:disable-line:no-magic-numbers
        }
    };
}

export const defaultMetricsBenchmarks: { [level: string]: MetricBenchMark } = {
    highVolume: {
        accepts: {
            green: 3,
            yellow: 2
        },
        movedPastResponseReceived: {
            green: 9,
            yellow: 6
        },
        responseRate: {
            green: 40,
            yellow: 30
        },
        submits: {
            green: 4,
            yellow: 2
        }
    },
    lowVolume: {
        accepts: {
            green: 3,
            yellow: 2
        },
        movedPastResponseReceived: {
            green: 5,
            yellow: 3
        },
        responseRate: {
            green: 30,
            yellow: 20
        },
        submits: {
            green: 4,
            yellow: 2
        }
    }
};

export const compareMetricsToBenchmark = (
    metrics: JobMetrics,
    job: {
        jobType: JobType;
        dailyEmailLimit?: number;
    }
): { [metric: string]: MetricDisplayInfo } => {
    const benchmarks = {
        ...JobTypes[job.jobType].metricsBenchmarks,
        ...jobSpecificMetricsBenchmarks(job.dailyEmailLimit)
    };
    const list: Array<[string, number]> = [
        ['accepts', metrics.accepts],
        ['emailsSent', metrics.emailsSent],
        ['responseRate', metrics.responseRate],
        ['movedPastResponseReceived', metrics.movedPastResponseReceived],
        ['submits', metrics.submits],
        ['movedToRocketScreenScheduled', metrics.movedToRocketScreenScheduled],
        ['movedToRocketScreenComplete', metrics.movedToRocketScreenComplete]
    ];
    const unicornThreshold = 1.25;
    return fromPairs(
        list.map(([metric, count]) => {
            const color = !benchmarks[metric]
                ? ''
                : count >= unicornThreshold * benchmarks[metric].green
                ? '' // 'unicorn'
                : count >= benchmarks[metric].green
                ? '' // 'green'
                : count >= benchmarks[metric].yellow
                ? '' // 'yellow'
                : ''; // 'red';
            let percentageDenominator: number;
            if (metric === 'responseRate') {
                percentageDenominator = metrics.emailsSent;
            }
            if (metric === 'movedPastResponseReceived') {
                percentageDenominator = metrics.responseRate;
            }
            return [
                metric,
                {
                    color,
                    count,
                    description: metricInfo[metric],
                    otherStats: [],
                    percentageDenominator,
                    targetBenchmark: benchmarks[metric] ? benchmarks[metric].green : null
                }
            ];
        })
    );
};
