import { Map, OrderedMap, OrderedSet } from 'immutable';
import { Dialog, FlatButton } from 'material-ui';
import * as React from 'react';
import Dropzone, { DropzoneInputProps, DropzoneRef, DropzoneRootProps } from 'react-dropzone';
import { connect } from 'react-redux';

import { JobSubmissionType } from 'shared/models/job';
import { NoteView } from 'shared/models/note';
import { HrefFilePayload } from 'shared/types/file-payload';

import { Editor } from 'react-ce';

import { createNewNote } from '../actions';
import { readFile } from '../lib/read-file-payload';
import { Job, State } from '../state';

interface OwnProps {
    jobId: string;
    personId: string;
    onRequestClose: () => void;
    requestCreateSubmission: () => void;
    onATSSubmission: () => void;
}

interface ConnectedDispatch {
    createNewNote: (note: Partial<NoteView>) => void;
}

interface ConnectedProps {
    jobs: OrderedMap<string, Job>;
    notes: Map<string, OrderedSet<NoteView>>;
}

type SubmissionATSDialogProps = OwnProps & ConnectedProps & ConnectedDispatch;

const SubmissionATSDialogComponent: React.FC<SubmissionATSDialogProps> = (props) => {
    const dropzoneRef = React.createRef<DropzoneRef>();
    const [submissionAttachment, setSubmissionAttachment] = React.useState<HrefFilePayload>(null);
    const { jobId, personId } = props;
    const job = props.jobs.get(jobId);
    const {
        submissionSettings: { username, password, type, description, atsLink }
    } = job;

    const noteContent = 'ATS Submission Note';

    const handleConfirm = () => {
        props.onRequestClose();
        const newNote: Partial<NoteView> = {
            attachments: [],
            content: noteContent,
            context: {
                isATSSubmissionNote: true,
                isSubmissionNote: true,
                jobId
            },
            format: 'html',
            newAttachments: [submissionAttachment],
            notable: `persons-${personId}`
        };
        props.createNewNote(newNote);
        if (type.includes(JobSubmissionType.Email)) {
            props.requestCreateSubmission();
        } else {
            props.onATSSubmission();
        }
    };

    const handleSkip = () => {
        props.onRequestClose();
        if (type.includes(JobSubmissionType.Email)) {
            props.requestCreateSubmission();
        } else {
            props.onATSSubmission();
        }
    };

    const onDrop = (files: File[]) => {
        Promise.all(files.map(readFile)).then((result) => {
            setSubmissionAttachment(result[0]);
        });
    };

    const openDropzone = () => {
        dropzoneRef.current.open();
    };

    const handleRequestClose = () => {
        props.onRequestClose();
    };

    const uploadButton = (
        <FlatButton
            className="submission-ats-upload-button"
            label="Upload Screenshot"
            onClick={openDropzone}
            key="upload"
        />
    );

    const attachment = submissionAttachment ? (
        <a
            href={submissionAttachment.href}
            key={submissionAttachment.filename}
            download={submissionAttachment.filename}
            className="href-container"
        >
            <div className="email-attachment">
                <div className="list-centered-icon">
                    <i className="fas fa-file-alt attachment-icon" />
                    <span>{submissionAttachment.filename}</span>
                </div>
            </div>
        </a>
    ) : null;

    const actions: JSX.Element[] = [];
    const alreadySubmitted =
        props.notes.get(`persons-${personId}`) &&
        props.notes.get(`persons-${personId}`).find((n) => n.context.isATSSubmissionNote && n.context.jobId === jobId);
    if (alreadySubmitted) {
        actions.push(<FlatButton onClick={handleSkip} label="Skip ATS Submission" key="skip" />);
    }
    const nextButton = attachment ? (
        <FlatButton onClick={handleConfirm} label="Continue" key="save" disabled={!attachment} />
    ) : (
        uploadButton
    );
    actions.push(<FlatButton onClick={handleRequestClose} label="Cancel" key="cancel" />, nextButton);

    const usernameInfo = !!username ? (
        <div className="ats-submission-field">
            <label className="ats-submission-field-label">Username</label>
            {username}
        </div>
    ) : null;
    const passwordInfo = !!password ? (
        <div className="ats-submission-field">
            <label className="ats-submission-field-label">Password</label>
            {password}
        </div>
    ) : null;

    const getDropzoneSection = (
        getRootProps: <T extends DropzoneRootProps>(props?: T) => T,
        getInputProps: <T extends DropzoneInputProps>(props?: T) => T
    ) => {
        return (
            <section className="email-compose-attach-dropzone">
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
            </section>
        );
    };

    return (
        <Dialog
            title="Submit to Client"
            open={true}
            actions={actions}
            onRequestClose={props.onRequestClose}
            autoScrollBodyContent={true}
        >
            <div className="ats-submission-body">
                <Editor
                    value={description}
                    className="ats-submission-description"
                    label="Submission Steps"
                    disabled={true}
                />
                <div className="ats-submission-fields">
                    <div className="ats-submission-field">
                        <label className="ats-submission-field-label">ATS Link</label>
                        <a href={atsLink} target="_blank">
                            {atsLink}
                        </a>
                    </div>
                    {usernameInfo}
                    {passwordInfo}
                </div>
                <div className="ats-submission-attachment">
                    <div className="email-attachments">{attachment}</div>
                </div>
            </div>

            <Dropzone onDrop={onDrop} ref={dropzoneRef}>
                {({ getRootProps, getInputProps }) => getDropzoneSection(getRootProps, getInputProps)}
            </Dropzone>
        </Dialog>
    );
};

const mapStateToProps = (state: State) => ({
    jobs: state.jobs,
    notes: state.notes
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    createNewNote
};

export const SubmissionATSDialog = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(SubmissionATSDialogComponent);
