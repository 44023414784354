import { Delete } from '@material-ui/icons';
import { Avatar, CircularProgress, Divider, IconButton, IconMenu, MenuItem } from 'material-ui';
import { red400, red600, white } from 'material-ui/styles/colors';
import NotInterested from 'material-ui/svg-icons/av/not-interested';
import Check from 'material-ui/svg-icons/navigation/check';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import * as React from 'react';

import { hasRole } from 'shared/models/user';

import { useLazyQuery, useMutation } from '@apollo/client';
import { deleteProfileLink, receiveProfileLinkUpdate } from '../actions';
import { GET_PERSON_PROFILE_LINKS, MARK_INVALID_LINK } from '../graphql/queries/profiles';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { useSession } from '../hooks/use-session';
import { State } from '../state';

const avatarSize = 18;

interface ProfileUrlAttributesProps {
    url: string;
    personId: string;
    invalid: boolean;
}

export const ProfileUrlAttributes: React.FC<ProfileUrlAttributesProps> = ({ url, personId, invalid }) => {
    const pendingRequests = useReduxState((state: State) => state.pendingRequests);
    const { userPermissions } = useSession();
    const updateRequestKey = `website-update-request-${url}`;
    const disabled = pendingRequests.has(updateRequestKey) && pendingRequests.get(updateRequestKey).isEmpty();
    const [updateLink] = useMutation<{}, { url: string; invalid: boolean }>(MARK_INVALID_LINK);
    const [getLinks] = useLazyQuery<
        {
            person: {
                personId: string;
                profileUrls: Array<{
                    id: string;
                    personId: string;
                    url: string;
                    createdAt: number;
                    modifiedAt: number;
                    invalid: boolean;
                }>;
            };
        },
        { personId: string }
    >(GET_PERSON_PROFILE_LINKS, { variables: { personId } });
    const dispatch = useReduxDispatch();

    const handleInvalid = async () => {
        if (!disabled) {
            await updateLink({ variables: { url, invalid: !invalid } });
            const result = await getLinks();
            dispatch(receiveProfileLinkUpdate({ ...result.data.person, url }));
        }
    };

    const handleLinkDelete = () => {
        if (!disabled) {
            dispatch(deleteProfileLink(personId, url));
        }
    };

    let content: JSX.Element;
    if (disabled) {
        const spinnerSize = 20;
        const spinnerThickness = 2;
        content = <CircularProgress size={spinnerSize} thickness={spinnerThickness} />;
    } else {
        const invalidIcon = (
            <Avatar
                size={avatarSize}
                backgroundColor={red400}
                color={white}
                className="avatar-contact-attribute"
                icon={<NotInterested />}
            />
        );

        const removeIcon = (
            <Avatar
                size={avatarSize}
                backgroundColor={red600}
                color={white}
                className="avatar-contact-attribute"
                icon={<Delete />}
            />
        );

        const button = (
            <IconButton
                disableTouchRipple={true}
                style={{ width: 16, height: 16, padding: 0 }}
                iconStyle={{ height: 16, width: 16 }}
            >
                <MoreVertIcon className="icon-button-more" />
            </IconButton>
        );

        let removeMenuItem: JSX.Element;
        let divider: JSX.Element;

        if (hasRole(userPermissions, 'profile_links_editor')) {
            divider = <Divider />;
            removeMenuItem = (
                <MenuItem
                    primaryText="Delete"
                    insetChildren={true}
                    onClick={handleLinkDelete}
                    leftAvatar={removeIcon}
                    disabled={!hasRole(userPermissions, 'profile_links_editor')}
                />
            );
        }

        content = (
            <div className="contact-attributes">
                <div className="list-item-menu-button">
                    <IconMenu
                        iconButtonElement={button}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                        targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                        desktop={true}
                    >
                        <MenuItem
                            primaryText="Mark as invalid"
                            insetChildren={true}
                            onClick={handleInvalid}
                            leftAvatar={invalidIcon}
                            rightIcon={invalid ? <Check /> : null}
                        />
                        {divider}
                        {removeMenuItem}
                    </IconMenu>
                </div>
                {invalid ? invalidIcon : null}
            </div>
        );
    }

    return content;
};
