import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Theme,
    useTheme
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Map } from 'immutable';
import { escapeRegExp, groupBy, map, orderBy, sumBy, times } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

import { Duration, getDurationOptions } from '../common/duration';
import { PageDialogLink } from '../common/page-dialog-link';
import { JobCandidatesBoard } from '../containers/job-candidates-board';
import {
    EmailDeliveryStatsEnriched,
    GET_EMAIL_DELIVERY_STATS_BY_TIME_PERIOD
} from '../graphql/queries/email-delivery-stats';
import { DurationSelector } from './duration-selector';
import { Header } from './header';
import { Range, RangeFilter } from './table-header-range-filter';

type Column = 'Email Accounts' | 'Client' | 'Job' | 'Sent' | 'Opens' | 'Open Rate' | 'Response' | 'Response Rate';

const skeletonRowsCount = 10;
const rowsPerPage = 20;
const factor = 100;
const dateFormat = 'YYYY-MM-DD';

const styles = (theme: Theme) => css`
    max-width: calc(100% - 100px);
    padding: 25px 50px;
    background: #f4f6f8;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    .selectors {
        flex: 0 0 auto;
        text-align: right;
        margin-bottom: 20px;

        .search-field,
        > .MuiInputBase-root {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            .MuiOutlinedInput-input {
                padding-top: 14px;
                padding-bottom: 14px;
                background: white;
            }
        }
    }

    .MuiPaper-root {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .MuiTableContainer-root {
            flex: 1 1 auto;

            &::-webkit-scrollbar:horizontal {
                border-top: thin solid ${theme.palette.divider};
            }

            &::-webkit-scrollbar:vertical {
                border-left: thin solid ${theme.palette.divider};
            }

            .MuiTableRow-root:last-child {
                td.MuiTableCell-root.MuiTableCell-body {
                    border-bottom: none;
                }
            }

            .MuiTableRow-root {
                .status-label {
                    cursor: pointer;

                    .needs_reauth {
                        cursor: pointer;
                        font-weight: 600;
                        color: ${theme.palette.error.main};
                    }

                    .disabled {
                        color: ${theme.palette.warning.main};
                    }

                    .pending_initial_sync {
                        color: ${theme.palette.error.light};
                    }
                }
            }

            .column-action-icon {
                display: inline-flex;
                align-items: center;
                opacity: 0;
                transition: opacity 200ms;
                margin-left: 5px;
                cursor: pointer;

                .MuiSvgIcon-root {
                    font-size: 1.25rem;
                    color: ${theme.palette.text.secondary};
                }

                &.visible {
                    opacity: 1;
                }
            }

            .table-header-cell {
                display: inline-flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    .column-action-filter.column-action-icon {
                        opacity: 1;
                    }
                }
            }
        }

        .pagination {
            flex: 0 0 auto;
            border-top: thin solid ${theme.palette.divider};
        }
    }
`;

const jobColumns: Column[] = ['Client', 'Job', 'Sent', 'Opens', 'Open Rate', 'Response', 'Response Rate'];
const accountColumns: Column[] = ['Email Accounts', 'Sent', 'Opens', 'Open Rate', 'Response', 'Response Rate'];
const filterableColumns: Column[] = ['Sent', 'Opens', 'Open Rate', 'Response', 'Response Rate'];

export const EmailAccountsStats: React.FC<{}> = () => {
    const theme = useTheme();

    const durationPeriods = { days: 2, weeks: 2, months: 2, quarters: 4, years: 2, allTime: true };
    const durationOpts = getDurationOptions(durationPeriods);
    const defaultOption = durationOpts.find((opt) => opt.label === 'This Week');

    const [duration, setDuration] = useState<Duration>(defaultOption);
    const [sortCol, setSortCol] = useState<Column>('Email Accounts');
    const [rangeFilters, setRangeFilters] = useState<Map<Column, Range>>(Map());
    const [sortAsc, setSortAsc] = useState<'asc' | 'desc'>('asc');
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState('');
    const [view, setView] = React.useState<'job' | 'account'>('account');

    const translateDurationToStartAndEndDates = (timePeriod: Duration) => {
        const startDate = moment(timePeriod.start).format(dateFormat);
        const endDate = moment(timePeriod.end).format(dateFormat);
        return { startDate, endDate };
    };

    const { loading, data } = useQuery<
        {
            emailDeliveryStats: EmailDeliveryStatsEnriched[];
        },
        { startDate: string }
    >(GET_EMAIL_DELIVERY_STATS_BY_TIME_PERIOD, {
        variables: translateDurationToStartAndEndDates(duration)
    });

    const valueFunc = (col: Column) => (record: {
        account: string;
        clientName: string;
        jobName: string;
        jobId: string;
        opens: number;
        sent: number;
        openRate: number;
        response: number;
        responseRate: number;
    }) => {
        switch (col) {
            case 'Email Accounts':
                return record.account;
            case 'Client':
                return record.clientName;
            case 'Job':
                return record.jobName;
            case 'Sent':
                return record.sent;
            case 'Opens':
                return record.opens;
            case 'Open Rate':
                return record.openRate;
            case 'Response':
                return record.response;
            case 'Response Rate':
                return record.responseRate;
            default:
                return null;
        }
    };

    const handleSortChange = (col: Column) => () => {
        if (col === sortCol) {
            if (sortAsc === 'asc') {
                setSortAsc('desc');
            } else {
                setSortAsc('asc');
            }
        } else {
            setSortCol(col);
            setSortAsc('desc');
        }
    };

    const handleDurationChange = (timePeriod: Duration) => {
        setDuration(timePeriod);
        setPage(0);
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleSearchTextChange = (event: React.ChangeEvent<{ value: string }>) => {
        setSearch(event.target.value);
        setPage(0);
    };

    const handleViewChange = (event: React.ChangeEvent<{ value: any }>) => {
        setView(event.target.value);
        setSearch('');
        setPage(0);
        setSortCol(event.target.value === 'job' ? 'Client' : 'Email Accounts');
        setSortAsc('asc');
    };

    const handleRangeFilterChange = (col: Column) => (range: Range) => {
        setRangeFilters(rangeFilters.set(col, range));
    };

    let rows: JSX.Element[];
    let pagination: JSX.Element;
    const columns = view === 'job' ? jobColumns : accountColumns;

    if (loading) {
        const skeletonRows = times(skeletonRowsCount).map((i) => {
            const cells = times(columns.length).map((j) => (
                <TableCell key={j}>
                    <Skeleton variant="rect" width="120px" />
                </TableCell>
            ));
            return <TableRow key={i}>{cells}</TableRow>;
        });
        rows = skeletonRows;
    } else {
        const { emailDeliveryStats } = data;
        const formattedEmailDeliveryStats = emailDeliveryStats.map((stats) => {
            return { ...stats, jobId: stats.job.id, jobName: stats.job.title, clientName: stats.job.client.name };
        });

        let allAccountsStats: Array<{
            account: string;
            clientName: string;
            jobName: string;
            jobId: string;
            opens: number;
            sent: number;
            openRate: number;
            response: number;
            responseRate: number;
        }>;
        if (view === 'account') {
            const statsByAccount = groupBy(formattedEmailDeliveryStats, 'account');
            allAccountsStats = map(statsByAccount, (stats) => {
                const totalOpens = sumBy(stats, (s) => s.emailsOpened);
                const totalSent = sumBy(stats, (s) => s.emailsSent);
                const totalResponse = sumBy(stats, (s) => s.emailsReplied);
                return {
                    account: stats[0].account,
                    clientName: '',
                    jobId: '',
                    jobName: '',
                    openRate: Math.round((totalOpens * factor) / totalSent),
                    opens: totalOpens,
                    response: totalResponse,
                    responseRate: Math.round((totalResponse * factor) / totalSent),
                    sent: totalSent
                };
            });
        } else {
            const statsByJob = groupBy(formattedEmailDeliveryStats, 'jobId');
            allAccountsStats = map(statsByJob, (stats) => {
                const totalOpens = sumBy(stats, (s) => s.recipientsReached);
                const totalSent = sumBy(stats, (s) => s.recipientsAdded);
                const totalResponse = sumBy(stats, (s) => s.recipientsResponded);
                return {
                    account: '',
                    clientName: stats[0].clientName,
                    jobId: stats[0].jobId,
                    jobName: stats[0].jobName,
                    openRate: Math.round((totalOpens * factor) / totalSent),
                    opens: totalOpens,
                    response: totalResponse,
                    responseRate: Math.round((totalResponse * factor) / totalSent),
                    sent: totalSent
                };
            });
        }

        const meaningfulStats = allAccountsStats.filter((stats) => {
            return stats.sent || stats.opens || stats.response;
        });

        const sortedRows = orderBy(
            meaningfulStats,
            [
                (stats) => {
                    const value = valueFunc(sortCol)(stats);
                    if (typeof value !== 'number' || sortAsc === 'asc') {
                        return value;
                    } else if (Number.isFinite(value)) {
                        return value;
                    } else {
                        // desc sort on numeric values
                        // This will put the NaNs and Infinity values at the end
                        return -Infinity;
                    }
                }
            ],
            [sortAsc]
        );
        const searchRegex = new RegExp(escapeRegExp(search), 'i');
        const filteredRows = sortedRows
            .filter((r) => {
                if (view === 'account') {
                    return r.account.match(searchRegex);
                } else {
                    return `${r.clientName} ${r.jobName}`.match(searchRegex);
                }
            })
            .filter((r) =>
                rangeFilters.reduce(
                    (acc, range, col) =>
                        acc &&
                        (range?.max === undefined || valueFunc(col)(r) <= range.max) &&
                        (range?.min === undefined || valueFunc(col)(r) >= range.min),
                    true
                )
            );
        rows = filteredRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((record) => {
            const openRate = Number.isFinite(record.openRate) ? `${record.openRate}%` : '-';
            const responseRate = Number.isFinite(record.responseRate) ? `${record.responseRate}%` : '-';

            if (view === 'job') {
                const jobLink = (
                    <PageDialogLink
                        url={`/job/${record.jobId}/board`}
                        Component={JobCandidatesBoard}
                        componentProps={{ jobId: record.jobId }}
                    >
                        <Link to={`/job/${record.jobId}/board`}>{record.jobName}</Link>
                    </PageDialogLink>
                );
                return (
                    <TableRow key={`${record.clientName}-${record.jobId}`}>
                        <TableCell>{record.clientName}</TableCell>
                        <TableCell>{jobLink}</TableCell>
                        <TableCell>{record.sent}</TableCell>
                        <TableCell>{record.opens}</TableCell>
                        <TableCell>{openRate}</TableCell>
                        <TableCell>{record.response}</TableCell>
                        <TableCell>{responseRate}</TableCell>
                    </TableRow>
                );
            } else {
                return (
                    <TableRow key={`${record.account}`}>
                        <TableCell>{record.account}</TableCell>
                        <TableCell>{record.sent}</TableCell>
                        <TableCell>{record.opens}</TableCell>
                        <TableCell>{openRate}</TableCell>
                        <TableCell>{record.response}</TableCell>
                        <TableCell>{responseRate}</TableCell>
                    </TableRow>
                );
            }
        });
        pagination =
            filteredRows.length > rowsPerPage ? (
                <TablePagination
                    rowsPerPageOptions={[rowsPerPage]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    className="pagination"
                />
            ) : null;
    }

    const headers = columns.map((col) => {
        const isFilterVisible = rangeFilters.get(col)?.max !== undefined || rangeFilters.get(col)?.min !== undefined;
        const filterLabel = filterableColumns.includes(col) ? (
            <span className={`column-action-icon column-action-filter ${isFilterVisible ? 'visible' : ''}`}>
                <RangeFilter range={rangeFilters.get(col)} onChange={handleRangeFilterChange(col)} />
            </span>
        ) : null;

        const sortIcon = sortAsc === 'desc' ? <ArrowDownward /> : <ArrowUpward />;
        const sort = (
            <span className={`column-action-icon ${sortCol === col ? 'visible' : ''}`} onClick={handleSortChange(col)}>
                {sortIcon}
            </span>
        );
        return (
            <TableCell key={col}>
                <span className="table-header-cell">
                    <span onClick={handleSortChange(col)}>{col}</span>
                    {filterLabel}
                    {sort}
                </span>
            </TableCell>
        );
    });

    return (
        <DocumentTitle title="Email Stats">
            <div id="container">
                <Header title="Email Stats" />
                <div id="content" className="flex-fill">
                    <div css={styles(theme)}>
                        <div className="selectors">
                            <TextField
                                className="search-field"
                                variant="outlined"
                                placeholder="Search"
                                value={search}
                                onChange={handleSearchTextChange}
                            />
                            <Select value={view} onChange={handleViewChange} variant="outlined">
                                <MenuItem value={'job'}>Jobs View</MenuItem>
                                <MenuItem value={'account'}>Accounts View</MenuItem>
                            </Select>
                            <DurationSelector
                                selected={duration}
                                onSelect={handleDurationChange}
                                periods={durationPeriods}
                            />
                        </div>
                        <Paper>
                            <TableContainer>
                                <Table stickyHeader={true}>
                                    <TableHead>
                                        <TableRow>{headers}</TableRow>
                                    </TableHead>
                                    <TableBody>{rows}</TableBody>
                                </Table>
                            </TableContainer>
                            {pagination}
                        </Paper>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};
