import { useMutation } from '@apollo/client';
import { Button, List } from '@material-ui/core';
import React from 'react';

import { Notification, UPDATE_NOTIFICATION } from '../../graphql/queries/notifications';
import { useNotificationsData } from '../../hooks/use-notifications-data';
import { useSnackbar } from '../../hooks/use-snackbar';
import { HomePagePanel } from './home-page-panel';
import { JDLLMUpdate } from './jd-llm-update';

const Content: React.FC<{ rows: JSX.Element[] }> = ({ rows }) => <List disablePadding={true}>{rows}</List>;

const NotificationComponent: React.FC<{ data: Notification; list: Notification[] }> = ({ data }) => {
    const [markedComplete, setMarkedComplete] = React.useState(false);
    const { setSnackbar } = useSnackbar();
    const [updateNotification] = useMutation<{}, { id: string; updates: { completedAt: null | number } }>(
        UPDATE_NOTIFICATION
    );

    const handleUndo = async () => {
        setMarkedComplete(false);
        setSnackbar('Notification marked as incomplete');
        await updateNotification({ variables: { id: data.id, updates: { completedAt: null } } });
    };

    const handleMarkComplete = async () => {
        const undoButton = (
            <Button onClick={handleUndo} style={{ color: 'white' }}>
                Undo
            </Button>
        );
        setSnackbar('Notification marked as complete', undoButton);
        setMarkedComplete(true);
        await updateNotification({ variables: { id: data.id, updates: { completedAt: Date.now() } } });
    };

    switch (data.type) {
        case 'JDLLMUpdate':
            return markedComplete ? null : (
                <JDLLMUpdate
                    key={data.id}
                    data={data.jdLLMUpdate[0]}
                    userId={data.userId}
                    onComplete={handleMarkComplete}
                />
            );
        default:
            return null;
    }
};

export const UserNotifications: React.FC = () => {
    const { notifications } = useNotificationsData();
    if (!notifications?.length) {
        return null;
    }

    return (
        <HomePagePanel
            RowComponent={NotificationComponent}
            ContentComponent={Content}
            records={notifications}
            title="Notifications"
            containerClass="list"
            collapseKeyId="user-notifications"
        />
    );
};
