import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import * as classNames from 'classnames';
import * as React from 'react';
import { BASELINE_SEARCH_SORT_RANK, JobSearchesSourcingStats, SearchResultsViewType } from 'shared/models/search';

import { Permissions } from 'shared/models/permission';
import { UserData } from 'shared/models/user';

import { getStatusLabel } from '../common/searches';
import { timeRelativeDay } from '../common/timestamp';
import { User } from '../sfc/user';
import { Job, Search, SearchStatus } from '../state';
import { JobSearchesTitle } from './job-searches-title';

const styles = {
    headerCol: { height: 48 }
};

interface JobSearchesStatsProps {
    displayDetail: boolean;
    searches: Search[];
    job: Job;
    searchStats: JobSearchesSourcingStats[];
    user: UserData;
    userPermissions: Permissions;
    outreachedCandidatesCount: number;
    onDisplaySearchInfo: (searchId: string) => void;
    onViewResults: (view: { searchId: string; resultsType: SearchResultsViewType }) => () => void;
    onCloneSearch: (data: Search) => void;
    onUpdateSearch: (id: string, updates: Partial<Search>, refetchSearches: boolean) => void;
    onDeleteSearch: (data: Search) => void;
}
export class JobSearchesStats extends React.Component<JobSearchesStatsProps> {
    handleViewSearchInfo = (searchId: string) => () => {
        this.props.onDisplaySearchInfo(searchId);
    };

    handleViewSearchResults = (searchId: string, resultsType: SearchResultsViewType) => () => {
        this.props.onViewResults({ searchId, resultsType })();
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ sourcingDetail: event.target.checked });
    };

    render() {
        const { searches, searchStats, job, displayDetail, userPermissions, outreachedCandidatesCount } = this.props;
        const statsRows = searches
            .filter((s) => s.status !== SearchStatus.Initial)
            .map((s) => {
                const stats = searchStats.find((st) => st.id === s.id);
                const readyCount =
                    s.status === SearchStatus.Active ? (
                        <span
                            className="search-results-count-link"
                            onClick={this.handleViewSearchResults(s.id, 'ready')}
                        >
                            {s.status !== SearchStatus.Active ? '-' : stats ? stats.ready : '-'}
                        </span>
                    ) : (
                        '-'
                    );
                const lowScoreCount =
                    s.status === SearchStatus.Active ? (
                        <span
                            className="search-results-count-link"
                            onClick={this.handleViewSearchResults(s.id, 'low_score')}
                        >
                            {stats ? stats.lowScore : '-'}
                        </span>
                    ) : (
                        '-'
                    );
                const blockedCount =
                    s.status === SearchStatus.Active ? (
                        <span
                            className="search-results-count-link"
                            onClick={this.handleViewSearchResults(s.id, 'blocked')}
                        >
                            {stats ? stats.blocked : '-'}
                        </span>
                    ) : (
                        '-'
                    );
                const addedCount =
                    s.status === SearchStatus.Active ? (
                        <span
                            className="search-results-count-link"
                            onClick={this.handleViewSearchResults(s.id, 'added')}
                        >
                            {stats ? stats.added : '-'}
                        </span>
                    ) : (
                        '-'
                    );
                const noScoreCount =
                    s.status === SearchStatus.Active ? (
                        <span
                            className="search-results-count-link"
                            onClick={this.handleViewSearchResults(s.id, 'no_score')}
                        >
                            {stats ? stats.noScore : '-'}
                        </span>
                    ) : (
                        '-'
                    );
                const alreadyAddedCount =
                    s.status === SearchStatus.Active ? (
                        <span
                            className="search-results-count-link"
                            onClick={this.handleViewSearchResults(s.id, 'already_added')}
                        >
                            {stats ? stats.alreadyAdded : '-'}
                        </span>
                    ) : (
                        '-'
                    );
                const newCount =
                    s.status === SearchStatus.Active ? (
                        <span
                            className="search-results-count-link"
                            onClick={this.handleViewSearchResults(s.id, 'total_new')}
                        >
                            {stats ? stats.totalNew : '-'}
                        </span>
                    ) : (
                        '-'
                    );
                const findingEmailCount =
                    s.status === SearchStatus.Active ? (
                        <span
                            className="search-results-count-link"
                            onClick={this.handleViewSearchResults(s.id, 'finding_email')}
                        >
                            {stats ? stats.findingEmail : '-'}
                        </span>
                    ) : (
                        '-'
                    );
                const inCoolDownCount =
                    s.status === SearchStatus.Active ? (
                        <span
                            className="search-results-count-link"
                            onClick={this.handleViewSearchResults(s.id, 'in_cooldown')}
                        >
                            {stats ? stats.inCooldown : '-'}
                        </span>
                    ) : (
                        '-'
                    );

                const expiredProfileCount =
                    s.status === SearchStatus.Active ? (
                        <span
                            className="search-results-count-link"
                            onClick={this.handleViewSearchResults(s.id, 'expired_profile')}
                        >
                            {stats ? stats.expiredProfile : '-'}
                        </span>
                    ) : (
                        '-'
                    );
                const rejectedCount =
                    s.status === SearchStatus.Active ? (
                        <span
                            className="search-results-count-link"
                            onClick={this.handleViewSearchResults(s.id, 'rejected')}
                        >
                            {stats ? stats.rejected : '-'}
                        </span>
                    ) : (
                        '-'
                    );

                const createdByUser = <User id={s.createdBy} />;

                const reviewRequiredTooltip =
                    s.sortRank === BASELINE_SEARCH_SORT_RANK || outreachedCandidatesCount === undefined ? (
                        ''
                    ) : (
                        <span>
                            Job overall outreach without required review: {job.mlAutoOutreachLimit} <br />
                            Job already outreached: {outreachedCandidatesCount} <br />
                            Job search size limit: {job.mlMaxResultsAutoOutreach} <br />
                            Search total new results count: {stats?.totalNew ?? '-'} <br />
                        </span>
                    );
                const reviewRequiredText =
                    s.sortRank === BASELINE_SEARCH_SORT_RANK
                        ? '-'
                        : s.reviewRequiredForOutreach || outreachedCandidatesCount > job.mlAutoOutreachLimit
                        ? 'Y'
                        : 'N';
                const reviewRequired =
                    s.status === SearchStatus.Active ? (
                        <Tooltip title={reviewRequiredTooltip}>
                            <span>{reviewRequiredText}</span>
                        </Tooltip>
                    ) : (
                        '-'
                    );
                const title = (
                    <JobSearchesTitle
                        user={this.props.user}
                        onCloneSearch={this.props.onCloneSearch}
                        onUpdateSearch={this.props.onUpdateSearch}
                        onDeleteSearch={this.props.onDeleteSearch}
                        search={s}
                        job={job}
                        userPermissions={userPermissions}
                    />
                );

                const classes = classNames({
                    baseline: s.sortRank === BASELINE_SEARCH_SORT_RANK,
                    inactive: s.status !== SearchStatus.Active
                });

                return (
                    <TableRow key={s.id} className={classes} hover={true}>
                        <TableCell>{title}</TableCell>
                        <TableCell>{getStatusLabel(s, s.status)}</TableCell>
                        <TableCell>{readyCount}</TableCell>
                        <TableCell>{lowScoreCount}</TableCell>
                        {displayDetail ? <TableCell>{noScoreCount}</TableCell> : null}
                        <TableCell>{inCoolDownCount}</TableCell>
                        {displayDetail ? <TableCell>{addedCount}</TableCell> : null}
                        {displayDetail ? <TableCell>{alreadyAddedCount}</TableCell> : null}
                        {displayDetail ? <TableCell>{blockedCount}</TableCell> : null}
                        {displayDetail ? <TableCell>{findingEmailCount}</TableCell> : null}
                        {displayDetail ? <TableCell>{expiredProfileCount}</TableCell> : null}
                        {displayDetail ? <TableCell>{rejectedCount}</TableCell> : null}
                        <TableCell>{newCount}</TableCell>
                        {displayDetail ? <TableCell>{reviewRequired}</TableCell> : null}
                        <TableCell>{timeRelativeDay(s.createdAt)}</TableCell>
                        <TableCell>
                            <User id={s.userId} />
                        </TableCell>
                        {displayDetail ? <TableCell>{createdByUser}</TableCell> : null}
                    </TableRow>
                );
            });

        const header = displayDetail ? (
            <TableRow style={styles.headerCol}>
                <TableCell>Title</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Ready</TableCell>
                <TableCell>Low Score</TableCell>
                <TableCell>No Score</TableCell>
                <TableCell>In Cooldown</TableCell>
                <TableCell>Added</TableCell>
                <TableCell>Already Added</TableCell>
                <TableCell>Blocked</TableCell>
                <TableCell>Finding Email</TableCell>
                <TableCell>Expired Profile</TableCell>
                <TableCell>Rejected</TableCell>
                <TableCell>Total New</TableCell>
                <TableCell>Review Required</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Created By</TableCell>
            </TableRow>
        ) : (
            <TableRow style={styles.headerCol}>
                <TableCell>Title</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Ready</TableCell>
                <TableCell>Low Score</TableCell>
                <TableCell>In Cooldown</TableCell>
                <TableCell>Total New</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Owner</TableCell>
            </TableRow>
        );

        return (
            <Paper>
                <TableContainer>
                    <Table stickyHeader={true} size="small">
                        <TableHead className="jobs-table-header">{header}</TableHead>
                        <TableBody>{statsRows}</TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }
}
