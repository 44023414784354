import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Theme,
    useTheme
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Map, Set } from 'immutable';
import { orderBy, times } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';

import { currencyFormat } from 'shared/common/string-format-utils';
import { internalClientIds } from 'shared/models/client';
import {
    clientFinalRoundStage,
    clientFirstRoundStage,
    clientMiddleRoundStage,
    getDisqualReasonLabel,
    offerStage
} from 'shared/models/job-stages';
import { hasRole } from 'shared/models/user';

import { Duration } from '../common/duration';
import {
    Candidate as CandidateType,
    DISQUALIFIED_CANDIDATES,
    PLACEMENTS,
    STAGE_CANDIDATES
} from '../graphql/queries/candidates';
import { useSlides } from '../hooks/use-candidate-slides';
import { useLocalStorage } from '../hooks/use-local-storage';
import { useSession } from '../hooks/use-session';
import { JobFilterTypes, jobTypesForFilter } from '../state';
import { DurationSelector } from './duration-selector';
import { Header } from './header';
import { JobsTypeFilter } from './jobs-type-filter';
import { PlacementTrends } from './metrics/placement-trends';
import { TableColumnFilterHeader } from './table-column-filter-header';

const skeletonRowsCount = 5;
const rowsPerPage = 15;

type Candidate = CandidateType & { hiredAt: number; fees: number };

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .selectors {
        flex: 0 0 auto;
        text-align: right;
        margin-bottom: 20px;

        .search-field,
        > .MuiInputBase-root {
            margin-left: 15px;

            &:first-child {
                margin-left: 0;
            }

            .MuiOutlinedInput-input {
                padding-top: 14px;
                padding-bottom: 14px;
                background: white;
            }
        }
    }

    .data-panel {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: space-between;
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
        background: white;

        .MuiTableContainer-root {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            overflow: auto;

            &::-webkit-scrollbar {
                border-left: thin solid ${theme.palette.divider};
                border-top: thin solid ${theme.palette.divider};
            }
            &::-webkit-scrollbar:vertical {
                border-left: thin solid ${theme.palette.divider};
            }
            .MuiTableRow-root:last-child {
                td.MuiTableCell-root.MuiTableCell-body {
                    border-bottom: none;
                }
            }
        }
    }

    .table {
        tr:hover {
            background: ${theme.palette.action.hover};
        }

        .candidate-name {
            color: ${theme.palette.primary.main};
            cursor: pointer;
        }

        .table-header-cell {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
        }

        .column-action-icon {
            display: inline-flex;
            align-items: center;
            opacity: 0;
            transition: opacity 200ms;
            margin-left: 5px;
            cursor: pointer;

            .MuiSvgIcon-root {
                font-size: 1.25rem;
                color: ${theme.palette.text.secondary};
            }

            &.visible {
                opacity: 1;
            }
        }

        th:hover {
            .column-action-icon {
                opacity: 1;
            }
        }
    }

    .table-footer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        border-top: thin solid ${theme.palette.divider};

        .billing-summary {
            margin-left: 15px;
            font-size: 14px;
        }
        .pagination-text {
            text-align: right;
            padding: 18px 25px;
            font-size: 13px;
            text-transform: uppercase;
        }
        .pagination {
            flex: 0 0 auto;
        }
    }
`;

type Column =
    | 'Client'
    | 'Job'
    | 'Candidate'
    | 'Rating'
    | 'Assignee'
    | 'Account Manager'
    | 'Last Stage Change'
    | 'Placement Date'
    | 'Disqualified Date'
    | 'Reason'
    | 'Billing';

type View = 'placements' | 'offers' | 'final-rounds' | 'second-rounds' | 'first-rounds' | 'trends';

const filterableColumns: Column[] = ['Client', 'Assignee', 'Account Manager'];

export const Candidates: React.FC<{}> = () => {
    const theme = useTheme();
    const { setList } = useSlides();
    const { userPermissions } = useSession();
    const [sortCol, setSortCol] = React.useState<Column>('Placement Date');
    const [sortAsc, setSortAsc] = React.useState<'asc' | 'desc'>('desc');
    const [page, setPage] = React.useState(0);
    const [filters, setFilters] = React.useState<Map<Column, Set<string | number>>>(Map());

    const [viewType, setViewType] = useLocalStorage<View>('placements-page-view-type', 'placements');
    const [dqView, setDqView] = useState<0 | 1>(0);
    const [searchText, setSearchText] = useState('');
    const [startTime, setStartTime] = useState(moment().startOf('month').valueOf());
    const [endTime, setEndTime] = useState(moment().endOf('day').valueOf());
    const [clientTypes, setClientTypes] = useState<'external-clients' | 'all'>('external-clients');
    const [jobTypes, setJobTypes] = useState<JobFilterTypes>('placement-fees');
    const [fetchPlacements, { data: placementsData }] = useLazyQuery<
        { candidates: Candidate[] },
        { jobType: string[]; startTime: number; endTime: number; excludeClientIds: string[] }
    >(PLACEMENTS);
    const [fetchCandidates, { data: stageCandidatesData }] = useLazyQuery<
        { candidates: Candidate[] },
        { jobType: string[]; excludeClientIds: string[]; stages: string[] }
    >(STAGE_CANDIDATES);
    const [fetchDQCandidates, { data: stageDQCandidatesData }] = useLazyQuery<
        { candidates: Candidate[] },
        { jobType: string[]; excludeClientIds: string[]; stages: string[]; startTime: number; endTime: number }
    >(DISQUALIFIED_CANDIDATES);

    let columns: Column[] =
        viewType === 'placements'
            ? ['Client', 'Job', 'Candidate', 'Assignee', 'Account Manager', 'Placement Date', 'Billing']
            : dqView === 1
            ? ['Client', 'Job', 'Candidate', 'Assignee', 'Account Manager', 'Disqualified Date', 'Reason']
            : ['Client', 'Job', 'Candidate', 'Assignee', 'Account Manager', 'Last Stage Change'];

    columns = hasRole(userPermissions, 'prompts_editor')
        ? [
              ...columns.slice(0, columns.indexOf('Candidate') + 1),
              'Rating',
              ...columns.slice(columns.indexOf('Candidate') + 1)
          ]
        : columns;

    const getExcludedClientIds = () => (clientTypes === 'external-clients' ? internalClientIds : []);

    const valueFunc = (col: Column) => (c: Candidate) => {
        switch (col) {
            case 'Client':
                return c.job.client.name;
            case 'Job':
                return c.job.title;
            case 'Candidate':
                return c.person.name;
            case 'Assignee':
                return c.assignee.name;
            case 'Account Manager':
                return c.accountManager?.name;
            case 'Placement Date':
                return c.hiredAt;
            case 'Last Stage Change':
                return c.lastStageChangedAt;
            case 'Billing':
                return c.fees;
            case 'Disqualified Date':
                return c.disqualifiedAt;
            case 'Reason':
                return getDisqualReasonLabel(c.disqualReason);
            case 'Rating':
                // the latest non search specific score
                return c.scores?.filter((s) => !s.searchProfilesScoreId)?.sort((a, b) => b.createdAt - a.createdAt)?.[0]
                    ?.score;
            default:
                return null;
        }
    };

    const allCandidates =
        viewType === 'placements'
            ? placementsData?.candidates
            : dqView === 1
            ? stageDQCandidatesData?.candidates
            : stageCandidatesData?.candidates;

    const candidates = allCandidates?.filter((c) => {
        const filtersMatch = filters.reduce((res, val, col) => res && val.has(valueFunc(col)(c)), true);
        const searchRegex = new RegExp(searchText, 'i');
        const searchTextMatch =
            !searchText ||
            c.assignee.name.match(searchRegex) ||
            c.job.client.name.match(searchRegex) ||
            c.job.title.match(searchRegex) ||
            c.person.name.match(searchRegex) ||
            c.accountManager?.name.match(searchRegex);
        return filtersMatch && searchTextMatch;
    });

    const sortedCandidates = orderBy(candidates, [valueFunc(sortCol), 'lastStageChangedAt'], [sortAsc, 'desc']);

    useEffect(() => {
        if (viewType === 'placements') {
            fetchPlacements({
                variables: {
                    endTime,
                    excludeClientIds: getExcludedClientIds(),
                    jobType: jobTypesForFilter.get(jobTypes),
                    startTime
                }
            });
        } else if (
            viewType === 'final-rounds' ||
            viewType === 'first-rounds' ||
            viewType === 'second-rounds' ||
            viewType === 'offers'
        ) {
            const stages =
                viewType === 'final-rounds'
                    ? [clientFinalRoundStage]
                    : viewType === 'second-rounds'
                    ? [clientMiddleRoundStage]
                    : viewType === 'first-rounds'
                    ? [clientFirstRoundStage]
                    : [offerStage];
            if (dqView === 1) {
                fetchDQCandidates({
                    variables: {
                        endTime,
                        excludeClientIds: getExcludedClientIds(),
                        jobType: jobTypesForFilter.get(jobTypes),
                        stages,
                        startTime
                    }
                });
            } else {
                fetchCandidates({
                    variables: {
                        excludeClientIds: getExcludedClientIds(),
                        jobType: jobTypesForFilter.get(jobTypes),
                        stages
                    }
                });
            }
        }
    }, [viewType, startTime, endTime, clientTypes, jobTypes, dqView]);

    const handleSearchTextChange = (event: React.ChangeEvent<{ value: string }>) => {
        setSearchText(event.target.value);
    };

    const handleViewTypeChange = (event: React.ChangeEvent<{ value: any }>) => {
        setPage(0);
        setSearchText('');
        setViewType(event.target.value);
        if (event.target.value === 'placements' && sortCol === 'Last Stage Change') {
            setSortCol('Placement Date');
        }
        if (event.target.value !== 'placements' && sortCol === 'Placement Date') {
            setSortCol('Last Stage Change');
        }
    };

    const handleDQViewChange = (event: React.ChangeEvent<{ value: number }>) => {
        setPage(0);
        setSearchText('');
        setDqView(event.target.value === 0 ? 0 : 1);
    };

    const handleJobTypesChange = (updated: JobFilterTypes) => {
        setPage(0);
        setSearchText('');
        setJobTypes(updated);
    };

    const handleClientsTypeChange = (event: React.ChangeEvent<{ value: any }>) => {
        setPage(0);
        setSearchText('');
        setClientTypes(event.target.value);
    };

    const handleSetDuration = ({ start, end: end }: Duration) => {
        setPage(0);
        setSearchText('');
        setStartTime(start);
        setEndTime(end);
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        /* no-op */
    };

    const handleSelectPerson = (personId: string, jobId: string) => () => {
        const list = sortedCandidates.map((c) => ({
            jobId: c.jobId,
            personId: c.personId
        }));
        const selected = {
            jobId,
            personId
        };
        setList(list, selected);
    };

    const handleColumnFilterChange = (filterMenuColumn: Column) => (selected: string[]) => {
        setFilters(
            selected.length === 0 ? filters.remove(filterMenuColumn) : filters.set(filterMenuColumn, Set(selected))
        );
        setPage(0);
    };

    const handleSortChange = (col: Column) => () => {
        if (col === sortCol) {
            if (sortAsc === 'asc') {
                setSortAsc('desc');
            } else {
                setSortAsc('asc');
            }
        } else {
            setSortCol(col);
            setSortAsc('asc');
        }
    };

    const durationSelector =
        viewType === 'placements' || viewType === 'trends' || dqView === 1 ? (
            <DurationSelector selected={{ start: startTime, end: endTime }} onSelect={handleSetDuration} />
        ) : null;

    const dqViewTypeSelector =
        viewType === 'offers' ||
        viewType === 'final-rounds' ||
        viewType === 'second-rounds' ||
        viewType === 'first-rounds' ? (
            <Select value={dqView} onChange={handleDQViewChange} variant="outlined">
                <MenuItem value={0}>Active</MenuItem>
                <MenuItem value={1}>Lost</MenuItem>
            </Select>
        ) : null;

    let content;
    if (viewType === 'trends') {
        content = (
            <PlacementTrends startTime={startTime} endTime={endTime} jobTypes={jobTypesForFilter.get(jobTypes)} />
        );
    } else {
        const headers = columns.map((col) => {
            const selected = filters.get(col)?.toArray() ?? [];

            const filterIcon =
                filterableColumns.indexOf(col) === -1 ? null : (
                    <TableColumnFilterHeader
                        values={allCandidates?.map(valueFunc(col)) ?? []}
                        selected={selected}
                        onSelect={handleColumnFilterChange(col)}
                    />
                );
            const sortIcon = sortAsc === 'desc' ? <ArrowDownward /> : <ArrowUpward />;
            const sort = (
                <span
                    className={`column-action-icon ${sortCol === col ? 'visible' : ''}`}
                    onClick={handleSortChange(col)}
                >
                    {sortIcon}
                </span>
            );
            return (
                <TableCell key={col}>
                    <span className="table-header-cell">
                        <span onClick={handleSortChange(col)}>{col}</span>
                        {sort}
                        {filterIcon}
                    </span>
                </TableCell>
            );
        });

        const skeletonRows = times(skeletonRowsCount).map((i) => (
            <TableRow key={i}>
                <TableCell colSpan={columns.length}>
                    <Skeleton variant="rect" />
                </TableCell>
            </TableRow>
        ));

        const rows =
            candidates === undefined
                ? skeletonRows
                : sortedCandidates.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((candidate) => {
                      const cells = columns.map((col) => {
                          const value = valueFunc(col)(candidate);
                          if (col === 'Disqualified Date' || col === 'Placement Date' || col === 'Last Stage Change') {
                              return <TableCell key={col}>{moment(value).format('MMM DD, YYYY')}</TableCell>;
                          }
                          if (col === 'Billing') {
                              return <TableCell key={col}>${value.toLocaleString() ?? 0}</TableCell>;
                          }
                          if (col === 'Candidate') {
                              return (
                                  <TableCell
                                      className="candidate-name"
                                      onClick={handleSelectPerson(candidate.personId, candidate.jobId)}
                                  >
                                      {candidate.person.name}
                                  </TableCell>
                              );
                          }
                          if (col === 'Rating') {
                              return <TableCell key={col}>{(value as number)?.toFixed(1) ?? '-'}</TableCell>;
                          }
                          return <TableCell key={col}>{value}</TableCell>;
                      });
                      return <TableRow key={`${candidate.personId}-${candidate.jobId}`}>{cells}</TableRow>;
                  });

        const pagination =
            candidates === undefined ? (
                <div className="pagination-text">Loading...</div>
            ) : candidates.length > rowsPerPage ? (
                <TablePagination
                    rowsPerPageOptions={[rowsPerPage]}
                    component="div"
                    count={candidates.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    className="pagination"
                />
            ) : (
                <div className="pagination-text">{candidates.length} Candidates</div>
            );

        const totalBilling = candidates?.reduce((acc, candidate) => acc + candidate.fees, 0);
        const billingSummary =
            viewType === 'placements' && candidates ? (
                <div className="billing-summary">Total Billing: {currencyFormat(totalBilling)}</div>
            ) : null;

        content = (
            <>
                <TableContainer className="table">
                    <Table stickyHeader={true}>
                        <TableHead>
                            <TableRow>{headers}</TableRow>
                        </TableHead>
                        <TableBody>{rows}</TableBody>
                    </Table>
                </TableContainer>
                <div className="table-footer">
                    {pagination}
                    {billingSummary}
                </div>
            </>
        );
    }

    return (
        <DocumentTitle title="Candidates">
            <div id="container">
                <Header title="Candidates" />
                <div css={styles(theme)}>
                    <div className="selectors">
                        <TextField
                            className="search-field"
                            variant="outlined"
                            placeholder="Search"
                            value={searchText}
                            onChange={handleSearchTextChange}
                        />
                        <Select value={viewType} onChange={handleViewTypeChange} variant="outlined">
                            <MenuItem value={'placements'}>Placements</MenuItem>
                            <MenuItem value={'offers'}>Offers</MenuItem>
                            <MenuItem value={'final-rounds'}>Final Rounds</MenuItem>
                            <MenuItem value={'second-rounds'}>Second Rounds</MenuItem>
                            <MenuItem value={'first-rounds'}>First Rounds</MenuItem>
                            <MenuItem value={'trends'}>Trends</MenuItem>
                        </Select>
                        {dqViewTypeSelector}
                        {durationSelector}
                        <JobsTypeFilter selectedFilter={jobTypes} onSelectFilter={handleJobTypesChange} />
                        <Select value={clientTypes} onChange={handleClientsTypeChange} variant="outlined">
                            <MenuItem value={'external-clients'}>External Clients</MenuItem>
                            <MenuItem value={'all'}>Include Rocket Jobs</MenuItem>
                        </Select>
                    </div>
                    <div className="data-panel">{content}</div>
                </div>
            </div>
        </DocumentTitle>
    );
};
