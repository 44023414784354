import { useQuery, useSubscription } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { Description, PhoneCallback, PlayArrow, Voicemail } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

import { PhoneCallTranscriptEvent } from 'shared/models/phone-call';

import { callDuration, callTime } from '../common/timestamp';

import {
    LATEST_PHONE_CALL,
    LATEST_TEXT_MESSAGE,
    PHONE_CALLS,
    PhoneCall,
    TEXT_SESSIONS,
    TextSession
} from '../graphql/queries/phone';

// tslint:disable:no-magic-numbers
const styles = (theme: Theme) => css`
    .phone-nav {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        text-transform: uppercase;
        white-space: nowrap;
        margin: 4px 0 25px;

        .select-tab {
            padding: 2px 20px;
            margin: 0;
            font-weight: 500;
            cursor: pointer;
            color: ${theme.palette.text.secondary};
            border-right: thin solid ${theme.palette.divider};
            display: inline-flex;

            &.active {
                color: ${theme.palette.text.primary};
                font-weight: 600;
            }

            &:last-child {
                border-right: none;
                padding-right: 0;
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }

    .phone-content {
        background: white;
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
    }

    .call {
        display: flex;
        align-items: center;
        padding: 5px 0;
        border-bottom: thin solid ${theme.palette.divider};
        font-size: 13px;
        color: ${theme.palette.text.primary};

        &:last-child {
            border-bottom: none;
        }

        .call-icon,
        .duration,
        .timestamp,
        .recording,
        .transcript,
        .user {
            flex: 0 0 auto;
            padding: 0 10px;
            display: flex;
            align-items: center;
        }

        .call-icon {
            width: 48px;
        }

        .user {
            flex: 1 1 auto;
            font-size: 15px;
            font-weight: 600;
            padding: 0 18px;
        }

        .MuiSvgIcon-root {
            font-size: 1.2rem;
        }

        .MuiIconButton-root {
            color: ${theme.palette.text.primary};
            &.Mui-disabled {
                color: ${theme.palette.text.disabled};
            }
        }
    }

    .text-sessions {
        display: flex;

        .sessions-list {
            flex: 0 0 auto;
            width: 200px;
            overflow: hidden;

            .session {
                padding: 10px 15px;
                border-bottom: thin solid ${theme.palette.divider};
                cursor: pointer;

                &.active {
                    background: ${theme.palette.grey[100]};
                }

                .user-name {
                    font-size: 15px;
                    font-weight: 500;
                }

                .phone-number {
                    font-size: 12px;
                }
            }
        }

        .session-messages {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column-reverse;
            border-left: thin solid ${theme.palette.divider};
            padding: 15px;

            .text-message {
                border: thin solid ${theme.palette.divider};
                border-radius: ${theme.shape.borderRadius}px;
                align-self: flex-start;
                margin-bottom: 15px;
                max-width: 300px;

                &:last-child {
                    margin-bottom: none;
                }

                &.Out {
                    align-self: flex-end;
                }

                .message-sender {
                    font-size: 12px;
                    border-bottom: thin solid ${theme.palette.divider};
                    padding: 8px 12px;
                    background: ${theme.palette.grey[100]};
                    border-top-left-radius: ${theme.shape.borderRadius}px;
                    border-top-right-radius: ${theme.shape.borderRadius}px;
                }

                .message-body {
                    padding: 10px 12px;
                    font-size: 14px;
                }

                .message-attachment img {
                    max-width: 100%;
                }

                .message-date {
                    font-size: 10px;
                    padding: 2px 12px 10px 12px;
                }
            }
        }
    }
`;
// tslint:enable:no-magic-numbers

const dialogStyles = (theme: Theme) => css`
    .header-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .audio-container {
        margin: 0 -20px;

        audio {
            width: 100%;
        }

        audio::-webkit-media-controls-play-button,
        audio::-webkit-media-controls-panel {
            background-color: white;
            color: white;
        }
    }

    .header-subtext {
        font-size: 13px;
        .call-direction {
            padding-right: 10px;
        }
    }

    .transcript-event {
        display: flex;
        margin-bottom: 18px;

        .timestamp {
            flex: 0 0 auto;
            width: 65px;
            font-size: 15px;
        }
        .users-text {
            .users {
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 8px;
            }
            .event-text {
                font-size: 14px;
            }
        }
    }

    .unavailable {
        text-transform: uppercase;
        font-size: 12px;
        text-align: center;
        color: ${theme.palette.text.secondary};
    }
`;

const CallTranscriptEvent: React.FC<{ event: PhoneCallTranscriptEvent }> = ({ event }) => {
    const users = event.speakers?.length > 0 ? <div className="users">{event.speakers.join(', ')}</div> : null;
    return (
        <div className="transcript-event">
            <div className="timestamp">{event.startTs.replace(/^00:(.*)$/, '$1').replace(/\.\d\d\d$/, '')}</div>
            <div className="users-text">
                {users}
                <div className="event-text">{event.text}</div>
            </div>
        </div>
    );
};

const PersonCall: React.FC<{ call: PhoneCall }> = ({ call }) => {
    const theme = useTheme();
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleToggleDialog = () => setDialogOpen(!dialogOpen);
    const getRecordingUrl = (key: string) => `https://files.getrocket.com/${key}`; // TODO: fix

    const icon =
        call.direction === 'Voicemail' ? (
            <Tooltip title="Voicemail">
                <Voicemail />
            </Tooltip>
        ) : call.direction === 'In' ? (
            <Tooltip title="Incoming call">
                <PhoneCallback />
            </Tooltip>
        ) : null;
    const recording = call.recordingLink ? (
        <Tooltip title="Play call recording">
            <IconButton onClick={handleToggleDialog}>
                <PlayArrow />
            </IconButton>
        </Tooltip>
    ) : (
        <Tooltip title="Call recording unavailable">
            <span>
                <IconButton disabled={true}>
                    <PlayArrow />
                </IconButton>
            </span>
        </Tooltip>
    );
    const transcript = call.transcript ? (
        <Tooltip title="Show call transcript" onClick={handleToggleDialog}>
            <IconButton>
                <Description />
            </IconButton>
        </Tooltip>
    ) : (
        <Tooltip title="Call transcript unavailable">
            <span>
                <IconButton disabled={true}>
                    <Description />
                </IconButton>
            </span>
        </Tooltip>
    );

    let transcriptDialog;
    if (dialogOpen) {
        const events = call.transcript?.map((e, i) => <CallTranscriptEvent event={e} key={i} />) ?? (
            <div className="unavailable">Transcript not available</div>
        );
        transcriptDialog = (
            <Dialog open={true} onClose={handleToggleDialog} css={dialogStyles(theme)} fullWidth={true} maxWidth="sm">
                <DialogTitle>
                    <div className="header-flex">
                        <div>
                            <Typography variant="h4">{call.user.name}</Typography>
                            <div className="header-subtext">
                                <span className="call-direction">
                                    {call.direction === 'In' ? 'Incoming Call' : 'Outgoing Call'}
                                </span>
                                <span className="call-duration">{callDuration(call.duration)} minutes</span>
                            </div>
                        </div>
                        <div className="header-call-date">{callTime(call.timestamp)}</div>
                    </div>
                    <div className="audio-container">
                        <audio controls={true} src={getRecordingUrl(call.recordingLink)}>
                            Audio playback not supported
                        </audio>
                    </div>
                </DialogTitle>
                <DialogContent>{events}</DialogContent>
                <DialogActions>
                    <Button onClick={handleToggleDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <div className="call">
            <div className="user">{call.user.name}</div>
            <div className="call-icon">{icon}</div>
            <div className="duration">{callDuration(call.duration)}</div>
            <div className="timestamp">{callTime(call.timestamp)}</div>
            <div className="recording">{recording}</div>
            <div className="transcript">{transcript}</div>
            {transcriptDialog}
        </div>
    );
};

const PersonTextSessions: React.FC<{ sessions: TextSession[] }> = ({ sessions }) => {
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const selected = sessions[selectedIndex];
    if (!sessions || !selected) return null;

    const handleSetSelected = (index: number) => () => setSelectedIndex(index);

    const sessionsList = sessions.map((s, i) => (
        <div
            key={s.sessionId}
            className={`session ${i === selectedIndex ? 'active' : ''}`}
            onClick={handleSetSelected(i)}
        >
            <div className="user-name">{s.user.name}</div>
            <div className="phone-number">{s.phoneNumber}</div>
        </div>
    ));

    const messages = selected.messages.map((message) => {
        const attachments = message.attachments.map((a, i) => {
            return (
                <div className="message-attachment" key={i}>
                    <img src={`https://files.getrocket.com/${a.key}`} />
                </div>
            );
        });
        return (
            <div key={message.messageId} className={`text-message ${message.direction}`}>
                <div className="message-sender">{message.sender.name ?? message.sender.phoneNumber}</div>
                <div className="message-body">
                    {attachments}
                    <div className="message-text">{message.message}</div>
                </div>
                <div className="message-date">{callTime(message.timestamp)}</div>
            </div>
        );
    });

    return (
        <div className="text-sessions">
            <div className="sessions-list">{sessionsList}</div>
            <div className="session-messages">{messages}</div>
        </div>
    );
};

export const PersonCallsTexts: React.FC<{ personId: string; jobId?: string }> = ({ personId, jobId }) => {
    const theme = useTheme();
    const [view, setView] = React.useState<'calls' | 'texts'>('calls');
    const [allJobs, setAllJobs] = React.useState(false);

    const { data: callsData, refetch: refetchCalls } = useQuery<{ calls: PhoneCall[] }, { personId: string }>(
        PHONE_CALLS,
        {
            variables: { personId }
        }
    );
    const { data: textMessagesData, refetch: refetchTexts } = useQuery<{ texts: TextSession[] }, { personId: string }>(
        TEXT_SESSIONS,
        {
            variables: { personId }
        }
    );

    const { data: callsSubData } = useSubscription<{ calls: Array<{ timestamp: number }> }>(LATEST_PHONE_CALL, {
        variables: { personId }
    });
    const { data: textsSubData } = useSubscription<{ texts: Array<{ timestamp: string }> }>(LATEST_TEXT_MESSAGE, {
        variables: { personId }
    });

    React.useEffect(() => {
        if (callsSubData?.calls?.[0]?.timestamp) {
            refetchCalls();
        }
    }, [callsSubData?.calls?.[0]?.timestamp]);

    React.useEffect(() => {
        if (textsSubData?.texts?.[0]?.timestamp) {
            refetchTexts();
        }
    }, [textsSubData?.texts?.[0]?.timestamp]);

    const handleViewTabClick = (v: 'calls' | 'texts') => () => setView(v);
    const handleJobTabClick = (all: boolean) => () => setAllJobs(all);

    const viewTabs = (
        <div className="view-select">
            <div className={`select-tab ${view === 'calls' ? 'active' : ''}`} onClick={handleViewTabClick('calls')}>
                Phone Calls
            </div>
            <div className={`select-tab ${view === 'texts' ? 'active' : ''}`} onClick={handleViewTabClick('texts')}>
                Text Messages
            </div>
        </div>
    );

    const jobTabs = !!jobId ? (
        <div className="job-select">
            <div className={`select-tab ${allJobs ? 'active' : ''}`} onClick={handleJobTabClick(true)}>
                All Jobs
            </div>
            <div className={`select-tab ${!allJobs ? 'active' : ''}`} onClick={handleJobTabClick(false)}>
                Current Job
            </div>
        </div>
    ) : null;

    let content: JSX.Element;
    if (callsData && view === 'calls') {
        const rows = callsData.calls
            .filter((r) => !jobId || allJobs || r.jobIds.indexOf(jobId) !== -1)
            .map((c) => <PersonCall call={c} key={c.callId} />);
        if (rows.length > 0) {
            content = <div className="phone-content">{rows}</div>;
        }
    } else if (textMessagesData && view === 'texts') {
        const filtered = textMessagesData.texts.filter((r) => !jobId || allJobs || r.jobIds.indexOf(jobId) !== -1);
        if (filtered.length > 0) {
            content = (
                <div className="phone-content">
                    <PersonTextSessions sessions={filtered} />
                </div>
            );
        }
    }
    if (!content && ((!callsData && view === 'calls') || (!textMessagesData && view === 'texts'))) {
        content = <Skeleton variant="rect" />;
    }

    return (
        <div css={styles(theme)}>
            <div className="phone-nav">
                {viewTabs}
                {jobTabs}
            </div>
            {content}
        </div>
    );
};
