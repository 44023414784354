import { gql } from '@apollo/client';

import { JobType } from 'shared/common/job-constants';
import { ClientData } from 'shared/models/client';
import { ContactType } from 'shared/models/contact';
import { JDUpdateContext } from 'shared/models/jd-llm-updates';
import {
    JobData,
    JobDiversity,
    JobIntroductionSettings,
    JobRecruiterPermissions,
    JobSubmissionSettings,
    JobTemplates,
    JobVisa
} from 'shared/models/job';
import { JobDescriptionData } from 'shared/models/job-description';

export interface JobFormData {
    __typename?: string;
    id: string;
    clientId: string;
    title: string;
    discipline: string;
    assignee: string;
    accountManagerId: string;
    status: number;
    jobType: JobType;
    placementFees: string;
    accountManagerPercentage: number;
    recruiterPercentage: number;
    billingInfo: { prepayment: { invoiceSentAt: number; invoiceAmount: number } };
    contactTypes: ContactType[];
    dailyEmailLimit: number;
    dailyPerUserEmailLimit: number;
    mlAutoOutreachLimit: number;
    mlMaxResultsAutoOutreach: number;
    outreachAIContext: {
        directions: string;
        keyCriteria: string;
        highlights: string;
    };
    scoringAIContext: {
        directions: string;
        weights: {
            mustHave: number;
            niceToHave: number;
            seniorityFit: number;
            positiveOutliers: number;
            negativeOutliers: number;
        };
    };
    submissionGenAIContext: {
        directions: string;
    };
    recruiterPermissions: JobRecruiterPermissions;
    submissionSettings: JobSubmissionSettings;
    introSettings: JobIntroductionSettings;
    templates: JobTemplates;
    onePager: { key: string; size: number };
    description: {
        __typename?: string;
        _id: string;
        jobId: string;
        summary: string;
        minExperienceYears: number;
        maxExperienceYears: number;
        skills: string;
        diversity: JobDiversity;
        linkToJobDescription: string;
        visa: JobVisa[];
        compensation: string;
        description: string;
        interviewPrep: string;
        keywords: string[];
        hiringManager: string;
        location: string;
        mustHaves: string;
        niceToHaves: string;
        responsibilities: string;
        positiveOutliers: string;
        negativeOutliers: string;
    };
    client: {
        __typename?: string;
        id: string;
        name: string;
        description: string;
        foundedDate: string;
        founders: string;
        funding: string;
        hq: string;
        salesPitch: string;
        teamSize: string;
        website: string;
    };
}

export interface JobLastModificationAtData {
    modifiedAt: number;
    client: {
        modifiedAt: number;
    };
    description: {
        modifiedAt: number;
    };
}

export interface JobUpdateData {
    jobId: string;
    clientId: string;
    job: Omit<JobFormData, 'client' | 'id' | 'description'>;
    description: Omit<JobFormData['description'], '__typename' | 'jobId' | '_id'>;
    client: Omit<JobFormData['client'], '__typename' | 'id' | 'name'>;
}

export interface JDLLMUpdate {
    input: string;
    resultsFile: string;
    updatedAt: number;
    context: JDUpdateContext;
    updates: {
        client?: { old: ClientData; new: ClientData };
        job?: { old: JobData; new: JobData };
        jobDescription?: { old: JobDescriptionData; new: JobDescriptionData };
    };
}

export const CREATE_JOB = gql`
    mutation CreateJob($job: jobs_insert_input!) {
        job: insert_jobs_one(object: $job) {
            id
        }
    }
`;

export const UPDATE_JOB_WITH_CLIENT_AND_DESCRIPTION = gql`
    mutation UpdateJob(
        $jobId: String!
        $clientId: String!
        $job: jobs_set_input!
        $description: job_descriptions_set_input!
        $client: clients_set_input!
    ) {
        update_jobs_by_pk(pk_columns: { id: $jobId }, _set: $job) {
            id
        }
        update_job_descriptions_by_pk(pk_columns: { jobId: $jobId }, _set: $description) {
            jobId
        }
        update_clients_by_pk(pk_columns: { id: $clientId }, _set: $client) {
            id
        }
    }
`;

export const UPDATE_JOB_DESCRIPTION = gql`
    mutation UpdateJobDescription($jobId: String!, $description: job_descriptions_set_input!) {
        update_job_descriptions_by_pk(pk_columns: { jobId: $jobId }, _set: $description) {
            jobId
        }
    }
`;

export const JOB_FORM_DATA = gql`
    query JobFormData($jobId: String!) {
        job: jobs_by_pk(id: $jobId) {
            id
            clientId
            title
            discipline
            assignee
            accountManagerId
            status
            jobType
            placementFees
            accountManagerPercentage
            recruiterPercentage
            billingInfo
            contactTypes
            dailyEmailLimit
            dailyPerUserEmailLimit
            mlAutoOutreachLimit
            mlMaxResultsAutoOutreach
            outreachAIContext
            scoringAIContext
            submissionGenAIContext
            recruiterPermissions
            submissionSettings
            introSettings
            templates
            onePager
            description {
                _id: jobId
                jobId
                summary
                minExperienceYears
                maxExperienceYears
                skills
                mustHaves
                positiveOutliers
                negativeOutliers
                niceToHaves
                responsibilities
                diversity
                linkToJobDescription
                visa
                compensation
                description
                interviewPrep
                keywords
                hiringManager
                location
            }
            client {
                id
                name
                description
                founders
                funding
                hq
                salesPitch
                teamSize
                foundedDate
                website
            }
        }
    }
`;

export const JOB_LAST_MODIFICATION_AT = gql`
    subscription JobLastModificationAt($jobId: String!) {
        job: jobs_by_pk(id: $jobId) {
            modifiedAt
            client {
                id
                modifiedAt
            }
            description {
                jobId
                modifiedAt
            }
        }
    }
`;

export const JOB_LLM_UPDATES_HISTORY = gql`
    query JobLLMUpdatesHistory($jobId: String!) {
        job: jobs_by_pk(id: $jobId) {
            id
            clientId
            title
            client {
                id
                name
            }
        }
        updates: history_jd_llm_updates(where: { job: { id: { _eq: $jobId } } }, order_by: { updatedAt: desc }) {
            input
            resultsFile
            updatedAt
            context
            updates
        }
    }
`;
