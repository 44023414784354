import { css } from '@emotion/core';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    MenuItem,
    Slider,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { startCase } from 'lodash';
import React from 'react';

import { LLMPromptBaseData, LLMPromptCommonData, ModelParameters } from 'shared/common/llm';

import { hasRole } from 'shared/models/user';
import { useSession } from '../hooks/use-session';

const sliderStep = 0.01;
const defaultMaxTokens = 4095;

const styles = (theme: Theme) => css`
    .MuiExpansionPanel-root {
        box-shadow: none;
        border: thin solid ${theme.palette.divider};

        &:not(:last-child) {
            border-bottom: none;
        }

        &.Mui-expanded {
            border-bottom: thin solid ${theme.palette.divider};
        }

        .MuiExpansionPanelSummary-root {
            background: ${theme.palette.action.hover};
            border-bottom: thin solid ${theme.palette.divider};
            margin-bottom: -1px;
            min-height: 56px;

            &.Mui-expanded {
                min-height: 56px;
            }
        }

        &:last-child .MuiExpansionPanelSummary-root {
            border-bottom: none;

            &.Mui-expanded {
                border-bottom: thin solid ${theme.palette.divider};
            }
        }

        .MuiExpansionPanelSummary-content {
            &.Mui-expanded {
                margin: 12px 0;
            }
        }

        .MuiExpansionPanelDetails-root {
            padding: ${theme.spacing(2)}px;
        }
    }

    .model-params {
        flex: 1 1 auto;

        .MuiTextField-root {
            margin-bottom: 12px;
        }

        .model-params-slider {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            margin-top: 16px;
            margin-right: 5px;

            .label {
                color: ${theme.palette.primary.main};
            }
        }
    }
`;

interface LLMPromptFormProps<T extends LLMPromptBaseData & Partial<LLMPromptCommonData>> {
    data: T;
    defaults: {
        systemPrompt: string;
        userPromptTemplate: string;
        modelParameters: ModelParameters;
    };
    onChange: (data: T) => void;
    modelOptions: ModelParameters[];
    isEditable: boolean;
}

type LLMPromptTextFields = keyof LLMPromptCommonData;

export const LLMPromptForm = <T extends LLMPromptBaseData & Partial<LLMPromptCommonData>>({
    data,
    defaults,
    onChange,
    modelOptions,
    isEditable
}: LLMPromptFormProps<T>): React.ReactElement => {
    const theme = useTheme();
    const { userPermissions } = useSession();

    const handleSystemPromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ ...data, systemPrompt: event.target.value });
    };

    const handleUserPromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ ...data, userPromptTemplate: event.target.value });
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ ...data, title: event.target.value });
    };

    const handleModelParamsChange = <U extends keyof ModelParameters>(field: U) => (
        _1: React.ChangeEvent<HTMLInputElement>,
        newValue: number | number[]
    ) => {
        onChange({ ...data, modelParameters: { ...data.modelParameters, [field]: newValue } });
    };

    const handleModelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const modelParameters = modelOptions?.find((modelOpt) => modelOpt.model === event.target.value);
        if (!modelParameters) {
            throw new Error(`Model data not found for model: ${event.target.value}`);
        }
        if (modelParameters.model !== data?.modelParameters?.model) {
            onChange({ ...data, modelParameters });
        }
    };

    const handleVariableChange = (field: LLMPromptTextFields) => (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ ...data, [field]: event.target.value });
    };

    const fields: LLMPromptTextFields[] = ['companyInformation', 'jobDescription', 'additionalContext'];
    const variableFields = fields
        .filter((f) => data.hasOwnProperty(f))
        .map((variable) => {
            return (
                <ExpansionPanel key={variable} defaultExpanded={variable === 'additionalContext'}>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                        <Typography variant="h6" component="div">
                            {startCase(variable)}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            value={data[variable] ?? ''}
                            placeholder={`${startCase(variable)} for AI to use when generating a response.`}
                            onChange={handleVariableChange(variable)}
                            fullWidth={true}
                            multiline={true}
                            disabled={!isEditable}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );
        });

    const systemPromptField = hasRole(userPermissions, 'prompts_editor') ? (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6" component="div">
                    System Prompt
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    value={data?.systemPrompt ?? defaults?.systemPrompt ?? ''}
                    onChange={handleSystemPromptChange}
                    placeholder="System prompt for AI to use when generating a response."
                    fullWidth={true}
                    multiline={true}
                    disabled={!isEditable}
                />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    ) : null;

    const modelOptionsMenuItems = modelOptions?.map((modelOpt) => (
        <MenuItem key={modelOpt.model} value={modelOpt.model}>
            {modelOpt.model}
        </MenuItem>
    ));

    let maxTokens = modelOptions?.map((modelOpt) => modelOpt.max_tokens).reduce((acc, val) => Math.max(acc, val), 0);
    maxTokens = maxTokens ? maxTokens : defaultMaxTokens;

    const userPromptTemplateField = hasRole(userPermissions, 'prompts_editor') ? (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6" component="div">
                    User Prompt Template
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    value={data?.userPromptTemplate ?? defaults?.userPromptTemplate ?? ''}
                    onChange={handleUserPromptChange}
                    placeholder="User prompt template for AI to use when generating a response."
                    fullWidth={true}
                    multiline={true}
                    disabled={!isEditable}
                />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    ) : null;

    let modelParametersField;
    if (hasRole(userPermissions, 'prompts_editor')) {
        const numericFields: Array<{
            field: keyof Omit<ModelParameters, 'model'>;
            max?: number;
            step?: number;
            defaultValue?: number;
        }> = [
            { field: 'top_p' },
            { field: 'max_tokens', step: 1, max: maxTokens, defaultValue: 1 },
            { field: 'temperature' },
            { field: 'presence_penalty' },
            { field: 'frequency_penalty' }
        ];
        const model = data?.modelParameters?.model ?? defaults?.modelParameters?.model ?? '';
        const filteredFields = model?.match(/^claude/)
            ? numericFields.filter(({ field }) => field === 'temperature' || field === 'max_tokens')
            : numericFields;
        const sliders = filteredFields.map(({ field, max, step, defaultValue }) => {
            const value = data?.modelParameters?.[field] ?? defaults?.modelParameters?.[field] ?? defaultValue ?? 0;
            return (
                <div key={field}>
                    <div className="model-params-slider" key={field}>
                        <div className="label">{startCase(field)}</div>
                        <div className="value">{value}</div>
                    </div>
                    <Slider
                        value={value}
                        onChange={handleModelParamsChange(field)}
                        min={0}
                        max={max ?? 1}
                        step={step ?? sliderStep}
                        disabled={!isEditable}
                    />
                </div>
            );
        });
        modelParametersField = (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" component="div">
                        Model Parameters
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className="model-params">
                        <TextField
                            label="Model"
                            fullWidth={true}
                            value={data?.modelParameters?.model ?? defaults?.modelParameters?.model ?? ''}
                            onChange={handleModelChange}
                            select={true}
                            disabled={!isEditable}
                        >
                            {modelOptionsMenuItems}
                        </TextField>
                        {sliders}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    return (
        <div css={styles(theme)}>
            <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" component="div">
                        Prompt Title
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        value={data?.title ?? ''}
                        placeholder="Title for the prompt"
                        onChange={handleTitleChange}
                        fullWidth={true}
                        disabled={!isEditable}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            {variableFields}
            {systemPromptField}
            {userPromptTemplateField}
            {modelParametersField}
        </div>
    );
};
