import { Button, Dialog, DialogContent, IconButton, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Map } from 'immutable';
import { AsYouType, format, isValidNumber, parse } from 'libphonenumber-js';
import * as _ from 'lodash';
import { CircularProgress, FlatButton, TextField } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { isEmail } from 'validator';

import { checkSendAccess } from 'shared/common/email-access';
import { ContactChannel, formatNumberForGoogleVoice, isValidUSNumber } from 'shared/models/contact';
import { Permissions } from 'shared/models/permission';
import { UserData } from 'shared/models/user';

import { addPersonContact, fetchUsers, getAllEmailAccountInfo, requestToasterOpen } from '../actions';
import { getAccountOptions } from '../common/email/account-options';
import { EmailKind } from '../common/email/header-validator';
import { openGoogleVoiceWindow } from '../common/open-google-voice-window';
import { composeEmailWithValidation, EmailValidationData } from '../email-compose/actions';
import { ComposeEmailWindowData } from '../email-compose/types';
import { getLastCommunicationWithCandidate } from '../lib/last-communication-with-candidate';
import {
    Candidate,
    Client,
    Contact,
    EmailAccount,
    Job,
    ListState,
    PersonDetails,
    RequestErrors,
    State
} from '../state';
import { ContactAttributes } from './contact-attributes';

interface DefaultProps {
    job: Job;
    client: Client;
}
interface OwnProps extends Partial<DefaultProps> {
    channel: ContactChannel;
    contacts: Contact[];
    personId: string;
    details: PersonDetails;
}

interface ChannelContactsState {
    active: boolean;
    value: string;
    errorText: string;
    callDialogOpen: boolean;
    callNumber: string;
}

interface ConnectedProps {
    user: UserData;
    users: Map<string, UserData>;
    pendingRequests: Map<string, RequestErrors>;
    emailAccounts: Map<string, EmailAccount>;
    listsState: Map<string, ListState>;
    jobs: Map<string, Job>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    addPersonContact: (payload: { personId: string; channel: ContactChannel; value: string }) => void;
    getAllEmailAccountInfo: () => void;
    composeEmailWithValidation: (
        payload: ComposeEmailWindowData,
        emailKind: EmailKind,
        validationData: EmailValidationData
    ) => void;
    fetchUsers: () => void;
    requestToasterOpen: (message: string, autoHideDuration?: number) => void;
}

const defaultCountry = 'US';
type ChannelContactProps = OwnProps & ConnectedDispatch & ConnectedProps;

class ChannelContactsComponent extends React.Component<ChannelContactProps, ChannelContactsState> {
    static defaultProps: DefaultProps = {
        client: null,
        job: null
    };

    private textInput: HTMLInputElement;

    constructor(props: ChannelContactProps) {
        super(props);
        this.state = { active: false, value: '', errorText: '', callDialogOpen: false, callNumber: null };
        this.ensureDataExists();
    }

    componentDidUpdate(prevProps: ChannelContactProps, prevState: ChannelContactsState) {
        if (!prevState.active && this.state.active) {
            this.textInput.focus();
        }
        const requestKey = this.requestKey();
        if (prevProps.pendingRequests.has(requestKey)) {
            if (!this.props.pendingRequests.has(requestKey)) {
                this.setState({ value: '', errorText: '', active: false });
            } else if (
                prevProps.pendingRequests.get(requestKey) !== this.props.pendingRequests.get(requestKey) &&
                this.state.value !== ''
            ) {
                const errors = this.props.pendingRequests.get(requestKey);
                if (!errors.isEmpty()) {
                    this.setState({ errorText: errors.get('value'), active: true });
                }
            }
        }
    }

    ensureDataExists = () => {
        if (this.props.emailAccounts.isEmpty()) {
            this.props.getAllEmailAccountInfo();
        }
        if (!this.props.listsState.get('users')) this.props.fetchUsers();
    };

    getAccountForNewEmail = () => {
        let account: EmailAccount;
        let sendAccessTooltip = '';
        const { user, job, details, emailAccounts, userPermissions } = this.props;
        if (job) {
            const communications = details.communications;
            const candidate = details.candidates.find((c) => c.jobId === job.id);
            if (candidate.assignee === user.id || job.accountManagerId === user.id) {
                const lastEmail = getLastCommunicationWithCandidate(communications, emailAccounts, candidate);
                if (lastEmail) {
                    const lastEmailAccount = emailAccounts.get(lastEmail.account);
                    if (lastEmailAccount.syncStatus === 'enabled') {
                        account = lastEmailAccount;
                    }
                }
                if (!account) {
                    const accounts = getAccountOptions(emailAccounts, user, job.id);
                    if (accounts.length > 0) {
                        account = emailAccounts.get(accounts[0].address);
                    }
                }
                if (account) {
                    let personsCandidates: Candidate[] = [];
                    let jobs: Job[];
                    if (candidate.crossSubmitJobIds) {
                        personsCandidates = details.candidates.filter((c) =>
                            candidate.crossSubmitJobIds.includes(c.jobId)
                        );
                        jobs = this.props.jobs
                            .valueSeq()
                            .toArray()
                            .filter((j) => candidate.crossSubmitJobIds.includes(j.id));
                    } else {
                        personsCandidates = [candidate];
                        jobs = [job];
                    }
                    if (
                        !checkSendAccess(user, userPermissions, account, {
                            jobs,
                            personsCandidates
                        })
                    ) {
                        if (account.syncStatus !== 'enabled') {
                            sendAccessTooltip = `The email account ${account.email} is not currently enabled`;
                        } else {
                            sendAccessTooltip = `You do not have access to send emails from ${account.email}`;
                        }
                        account = null;
                    }
                } else {
                    sendAccessTooltip = 'An email account to start a new thread from could not be found';
                }
            } else {
                sendAccessTooltip = 'Cannot start a new thread with a candidate not assigned to you';
            }
        }

        return {
            account,
            sendAccessTooltip
        };
    };

    onContactSelect = (value: string, channel: ContactChannel) => () => {
        const { details, personId, job, client, emailAccounts, users, user } = this.props;
        if (job && client && channel === 'email') {
            const jobId = job.id;
            const defaultAccount = this.getAccountForNewEmail();
            if (defaultAccount?.account) {
                const account = { address: defaultAccount.account.email, name: defaultAccount.account.name.full };
                const accountOptions = getAccountOptions(emailAccounts, user, jobId, account);
                this.props.composeEmailWithValidation(
                    {
                        account,
                        accountOptions,
                        attachments: [],
                        body: '',
                        canEditToRecipients: true,
                        draftSavedAt: null,
                        headers: {
                            bcc: [],
                            cc: [],
                            subject: '',
                            to: [{ name: details.person.name.full, address: value }]
                        },
                        isClientComm: false,
                        jobId,
                        personId,
                        threadId: null,
                        windowId: `new-email-${personId}-${jobId}`
                    },
                    'candidate',
                    {
                        client,
                        emailAccounts: emailAccounts.valueSeq().toArray(),
                        job,
                        personContacts: details.contacts,
                        users: users.valueSeq().toArray()
                    }
                );
            }
        } else if (channel === 'phone' && isValidUSNumber(value)) {
            this.setState({ callNumber: value, callDialogOpen: true });
        }
    };

    handleCloseCallDialog = () => {
        this.setState({ callDialogOpen: false, callNumber: null });
    };

    handleCallNumber = () => {
        openGoogleVoiceWindow(
            `https://voice.google.com/u/0/calls?a=nc,%2B${formatNumberForGoogleVoice(this.state.callNumber)}`
        );
        this.setState({ callDialogOpen: false, callNumber: null });
    };

    handleCopyNumber = () => {
        navigator.clipboard.writeText(formatNumberForGoogleVoice(this.state.callNumber));
        this.setState({ callDialogOpen: false, callNumber: null });
        this.props.requestToasterOpen('Phone number copied to the clipboard');
    };

    textRef = (input: any) => {
        this.textInput = input;
    };

    validateInput = (value: string, channel: ContactChannel): boolean => {
        if (channel === 'email') {
            return isEmail(value);
        } else if (channel === ContactChannel.Phone) {
            return isValidNumber(value, defaultCountry);
        }
    };

    formatValue = (value: string, channel: ContactChannel): string => {
        if (channel === 'email') {
            return value.trim();
        } else if (channel === ContactChannel.Phone) {
            const phoneNumber = parse(value, { defaultCountry });
            const formatLocale = phoneNumber.country === defaultCountry ? 'National' : 'International';
            return format(phoneNumber, formatLocale);
        }
    };

    formatPartial = (value: string, channel: ContactChannel): string => {
        if (channel === 'email') {
            return value.trim();
        } else if (channel === ContactChannel.Phone) {
            return new AsYouType(defaultCountry).input(value);
        }
    };

    handleTextChange = (event: React.FormEvent<{}>, newValue: string) => {
        event.preventDefault();
        if (newValue === '') {
            this.setState({ value: newValue, errorText: '' });
        } else {
            const { channel } = this.props;
            const value = this.state.value.length < newValue.length ? this.formatPartial(newValue, channel) : newValue;
            this.setState({ value, errorText: '' });
        }
    };

    handleKeyPress = (event: any) => {
        const enterKeyCode = 13;
        if (event.charCode === enterKeyCode) {
            this.addContact();
        }
    };

    setActive = () => {
        this.setState({ active: true });
    };

    cancelEdits = () => {
        this.setState({ active: false, value: '', errorText: '' });
    };

    addContact = () => {
        const { channel, personId } = this.props;
        const { value } = this.state;
        if (!this.validateInput(value, channel)) {
            this.setState({ errorText: `Not a valid ${channel}` });
        } else {
            const formattedValue = this.formatValue(this.state.value, channel);
            this.setState({ errorText: '' });
            this.props.addPersonContact({
                channel,
                personId,
                value: formattedValue
            });
        }
    };

    requestKey = () => {
        return `contact-update-request-${this.props.personId}-${this.props.channel}`;
    };

    render() {
        const { channel, contacts, personId, pendingRequests, emailAccounts, listsState } = this.props;
        const { active, value, errorText, callDialogOpen, callNumber } = this.state;
        const spinnerSize = 20;
        const spinnerThickness = 2;
        if (emailAccounts.isEmpty() || listsState.get('users') !== 'initialized') {
            return null;
        }

        const isSaving = pendingRequests.has(this.requestKey()) && pendingRequests.get(this.requestKey()).isEmpty();

        const actions = isSaving ? (
            <div>
                <CircularProgress size={spinnerSize} thickness={spinnerThickness} />
            </div>
        ) : active ? (
            <div>
                <FlatButton label="Cancel" disabled={isSaving} onClick={this.cancelEdits} />
                <FlatButton label="Save" disabled={isSaving} onClick={this.addContact} />
            </div>
        ) : null;

        const placeholder = active ? null : (
            <div className="placeholder" onClick={this.setActive}>
                Add {channel}
            </div>
        );

        const form = (
            <div className={`person-prop-row auto-hide ${active ? 'not-hidden' : 'hidden'}`}>
                <div className="person-prop-form person-contact-form">
                    <TextField
                        hintText={`Add ${channel}`}
                        ref={this.textRef}
                        value={value}
                        onChange={this.handleTextChange}
                        errorText={errorText}
                        onKeyPress={this.handleKeyPress}
                        disabled={isSaving}
                        fullWidth={true}
                    />
                    <div className="form-actions">{actions}</div>
                </div>
            </div>
        );

        const { account, sendAccessTooltip } = this.getAccountForNewEmail();
        const canStartNewEmailThread = channel === ContactChannel.Email && account;

        const sortedContacts = _.orderBy(
            contacts || [],
            ['primary', 'verified', 'invalid', 'createdAt'],
            ['desc', 'desc', 'asc', 'asc']
        );
        const items = sortedContacts.map((c) => {
            const className =
                channel === ContactChannel.Phone
                    ? isValidUSNumber(c.value)
                        ? 'person-props-contact-phone'
                        : ''
                    : canStartNewEmailThread && !c.invalid
                    ? 'person-props-contact-new-email-thread'
                    : 'person-props-contact-new-email-thread-not-allowed';
            return (
                <div className="person-prop-row" key={c.value + c.channel}>
                    <div className="person-prop-row-spaced">
                        <div onClick={this.onContactSelect(c.value, c.channel)} className={className}>
                            {this.formatValue(c.value, channel)}
                        </div>
                        <ContactAttributes contact={c} personId={personId} />
                    </div>
                </div>
            );
        });

        const contactItems = sendAccessTooltip ? (
            <Tooltip title={sendAccessTooltip} placement="top">
                <div>{items}</div>
            </Tooltip>
        ) : (
            items
        );

        const dialogHeaderStyle = {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
            minWidth: '300px'
        };
        const callDialog = callDialogOpen ? (
            <Dialog open={true} onClose={this.handleCloseCallDialog}>
                <DialogContent style={{ padding: '15px 20px 25px 30px' }}>
                    <div style={dialogHeaderStyle}>
                        <h4>{callNumber}</h4>
                        <IconButton onClick={this.handleCloseCallDialog}>
                            <Close />
                        </IconButton>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Button href={`callto:${formatNumberForGoogleVoice(callNumber)}`}>Call Number</Button>
                        <Button onClick={this.handleCopyNumber}>Copy Number</Button>
                    </div>
                </DialogContent>
            </Dialog>
        ) : null;

        return (
            <>
                <div
                    className={`person-props person-props-contacts ${items.length > 0 ? 'person-props-not-empty' : ''}`}
                >
                    <i className="material-icons section-icon">{channel}</i>
                    <div className="prop">
                        {contactItems}
                        {placeholder}
                        {form}
                    </div>
                </div>
                {callDialog}
            </>
        );
    }
}

const mapStateToProps = (state: State) => ({
    emailAccounts: state.emailAccounts,
    jobs: state.jobs,
    listsState: state.listsState,
    pendingRequests: state.pendingRequests,
    user: state.session.user,
    userPermissions: state.session.userPermissions,
    users: state.users
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    addPersonContact,
    composeEmailWithValidation,
    fetchUsers,
    getAllEmailAccountInfo,
    requestToasterOpen
};

export const ChannelContacts = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ChannelContactsComponent);
