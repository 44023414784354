import { pickBy } from 'lodash';
import PDFObject from 'pdfobject';
import * as React from 'react';

interface DefaultProps {
    className: string;
    width: string;
    height: string;
}
interface PdfProps extends Partial<DefaultProps> {
    url: string;
}

interface PdfState {
    id: string;
}

export class Pdf extends React.Component<PdfProps, PdfState> {
    constructor(props: PdfProps) {
        super(props);
        this.state = { id: props.url.replace(/[\W_]+/g, '-') };
    }

    componentDidMount() {
        PDFObject.embed(this.props.url, `#${this.state.id}`);
    }

    render() {
        const { className, width, height } = this.props;
        const style: React.CSSProperties = pickBy({ width, height });
        return <div id={this.state.id} className={className} style={style} />;
    }
}
