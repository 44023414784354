import * as _ from 'lodash';
import * as React from 'react';
import { config } from '../config';

import { pair } from '../core-ui/profile/utils';
import { Person } from '../state';
import { Profile } from '../types/profile';

const decimals = 2;

const Row: React.SFC<{ title: string; value: any; link?: string }> = (props) => {
    if (props.value === undefined || props.value === null) return null;
    if (Array.isArray(props.value)) {
        const rows = props.value.map((v) => <Row title={props.title} value={v} key={v} />);
        return <div className="rows-group">{rows}</div>;
    } else {
        const value =
            props.value === true
                ? 'True'
                : props.value === false
                ? 'False'
                : _.trim(props.value) === ''
                ? props.value
                : isNaN(props.value)
                ? props.value
                : Number.isInteger(Number(props.value))
                ? Number(props.value)
                : Number(props.value).toFixed(decimals);

        const content = (
            <div className="row">
                <div className="cell">{props.title}</div>
                <div className="cell">{value}</div>
            </div>
        );

        return !props.link ? (
            content
        ) : (
            <a href={props.link} target="_blank">
                {content}
            </a>
        );
    }
};

const SubSection: React.SFC<{ title: string }> = (props) => {
    const { title, children } = props;
    if ((Array.isArray(children) && children.length === 0) || !children) return null;
    return (
        <div>
            <div className="header2">
                <i className="material-icons">arrow_forward_ios</i>
                {title}
            </div>
            {children}
        </div>
    );
};

interface ProfileComponentProps {
    person: Person;
    profile: Profile;
    searchResults?: Array<{
        jobId: string;
        clientName: string;
        jobName: string;
        searchName: string;
        searchId: string;
        recruiter: string;
    }>;
}

export const ProfileDebugInfoComponent: React.FC<ProfileComponentProps> = (props) => {
    const { person, profile, searchResults } = props;
    const { meta } = profile;
    const { education, experience, skillCounts, summarySkillCounts } = meta;

    let diversityInfo;
    if (profile.diversityDetail) {
        const {
            africanAmericanGreekHouses,
            africanAmericanKeywords,
            africanAmericanOrganizations,
            africanAmericanSchools,
            firstNameAfricanAmerican,
            firstNameHispanic,
            hispanicGreekHouses,
            hispanicKeywords,
            hispanicOrganizations,
            lastNameAfricanAmerican,
            lastNameNativeAmerican,
            lastNameHispanic,
            lgbtqGreekHouses,
            lgbtqKeyword,
            lgbtqOrganizations,
            militaryBranch,
            nativeAmericanGroups,
            nativeAmericanKeywords,
            pacificIslanderKeywords,
            pacificIslanderOrganizations,
            pacificIslanderSchools,
            tribalUniversities,
            veteranGroups,
            veteranKeywords,
            veteranSchools
        } = profile.diversityDetail;
        diversityInfo = (
            <div className="section-group">
                <Row title="AA: HBCU" value={africanAmericanSchools ? 'YES' : 'NO'} />
                <Row title="AA: Frat/Sorority" value={africanAmericanGreekHouses ? 'YES' : 'NO'} />
                <Row title="AA: Keywords" value={africanAmericanKeywords ? 'YES' : 'NO'} />
                <Row title="AA: Org" value={africanAmericanOrganizations ? 'YES' : 'NO'} />
                <Row title="Hispanic: Frat/Sorority" value={hispanicGreekHouses ? 'YES' : 'NO'} />
                <Row title="Hispanic: Keywords" value={hispanicKeywords ? 'YES' : 'NO'} />
                <Row title="Hispanic: Org" value={hispanicOrganizations ? 'YES' : 'NO'} />
                <Row title="Veteran: Schools" value={veteranSchools ? 'YES' : 'NO'} />
                <Row title="Veteran: Companies" value={militaryBranch ? 'YES' : 'NO'} />
                <Row title="Veteran: Keywords" value={veteranKeywords ? 'YES' : 'NO'} />
                <Row title="Veteran: Groups" value={veteranGroups ? 'YES' : 'NO'} />
                <Row title="Pacific Islander: Schools" value={pacificIslanderSchools ? 'YES' : 'NO'} />
                <Row title="Pacific Islander: Org" value={pacificIslanderOrganizations ? 'YES' : 'NO'} />
                <Row title="Pacific Islander: Keywords" value={pacificIslanderKeywords ? 'YES' : 'NO'} />
                <Row title="Native American: Groups" value={nativeAmericanGroups ? 'YES' : 'NO'} />
                <Row title="Native American: Keywords" value={nativeAmericanKeywords ? 'YES' : 'NO'} />
                <Row title="Tribal: Schools" value={tribalUniversities ? 'YES' : 'NO'} />
                <Row title="LGBTQ: Frat/Sorority" value={lgbtqGreekHouses ? 'YES' : 'NO'} />
                <Row title="LGBTQ: Org" value={lgbtqOrganizations ? 'YES' : 'NO'} />
                <Row title="LGBTQ: Keywords" value={lgbtqKeyword ? 'YES' : 'NO'} />
                <Row title="LastName: AA" value={lastNameAfricanAmerican ? 'YES' : 'NO'} />
                <Row title="LastName: Hispanic" value={lastNameHispanic ? 'YES' : 'NO'} />
                <Row title="LastName: NA" value={lastNameNativeAmerican ? 'YES' : 'NO'} />
                <Row title="FirstName: AA" value={firstNameAfricanAmerican ? 'YES' : 'NO'} />
                <Row title="FirstName: Hispanic" value={firstNameHispanic ? 'YES' : 'NO'} />
            </div>
        );
    }

    const experienceByDisciplines = experience.totalExperienceByJobDiscipline
        .filter((d) => d.years !== 0)
        .map((d) => <Row key={d.discipline} title={d.discipline} value={d.years} />);

    const educationsMeta = profile.educations.map((edu, index) => {
        const degreeInfo = _.flatMap(edu.meta.degreeInfo, (d, i) => {
            return [
                <Row title={`Degree ${i + 1}`} value={d.mappedDegree} key={`degree-${i}`} />,
                <Row title={`Group ${i + 1}`} value={d.mappedGroup} key={`group-${i}`} />,
                <Row title={`Field ${i + 1}`} value={d.mappedFieldOfStudy} key={`field-${i}`} />
            ];
        });
        return (
            <div key={index} className="section-group">
                <Row title="School" value={edu.schoolName} />
                <Row title="Duration" value={pair(edu.startDate, edu.endDate, ' – ')} />
                <Row title="Country" value={edu.meta.schoolCountry} />
                <Row title="Rank" value={edu.meta.schoolOverallRank} />
                <Row title="CS Rank" value={edu.meta.schoolCSRank} />
                {degreeInfo}
            </div>
        );
    });

    const positionsMeta = profile.positions.map((pos, i) => {
        const companyData = pos.meta.rocketCompanyData ? (
            <div>
                <Row title="Crunchbase Rank" value={pos.meta.rocketCompanyData.cbRankCompany} />
                <Row title="Hot Startup" value={pos.meta.rocketCompanyData.isHotStartup} />
                <Row title="Series A +" value={pos.meta.rocketCompanyData.isSeriesAPlusStartup} />
                <Row title="Seed Startup" value={pos.meta.rocketCompanyData.isSeriesSeedStartup} />
                <Row title="Unfunded Startup" value={pos.meta.rocketCompanyData.isUnfundedStartup} />
                <Row title="Enterprise" value={pos.meta.rocketCompanyData.isBigTechEnterprise} />
                <Row title="On Demand" value={pos.meta.rocketCompanyData.isOnDemand} />
                <Row title="Industries" value={pos.meta.rocketCompanyData.industries} />
                <Row title="Business Type" value={pos.meta.rocketCompanyData.businessTypes} />
            </div>
        ) : null;
        return (
            <div key={i} className="section-group">
                <Row title="Company" value={pos.companyName} />
                <Row title="Location" value={pos.meta.mappedLocations} />
                <Row title="Title" value={pos.debug.title} />
                <Row title="Group" value={pos.meta.group} />
                <Row title="Rocket Discipline" value={pos.meta.rocketDiscipline || 'Null'} />
                <Row title="Level" value={pos.meta.level} />
                <Row title="Exact Match" value={pos.debug.mappedByExactMatch} />
                <Row title="Regex Match" value={pos.debug.regexMatchedDisciplines} />
                <Row title="Mobile Dev" value={pos.meta.mobileDeveloper} />
                <Row title="Fullstack Dev" value={pos.meta.fullstackDeveloper} />
                <Row title="Frontend Dev" value={pos.meta.frontendDeveloper} />
                <Row title="Backend Dev" value={pos.meta.backendDeveloper} />
                <Row title="AI ML Dev" value={pos.meta.aimlDeveloper} />
                <Row title="3D Dev" value={pos.meta._3DDeveloper} />
                <Row title="Skill" value={pos.meta.skillCounts.map((s) => `${s.name} (${s.count})`)} />
                {companyData}
            </div>
        );
    });

    const skillCountRows = _.orderBy(
        skillCounts.filter((v) => v.count !== 0),
        ['count', (s) => s.name.toLowerCase()],
        ['desc', 'asc']
    ).map((s) => <Row key={s.name} title={s.name} value={s.count} />);

    const summarySkillCountRows = _.orderBy(
        summarySkillCounts.filter((v) => v.count !== 0),
        ['count', (s) => s.name.toLowerCase()],
        ['desc', 'asc']
    ).map((s) => <Row key={s.name} title={s.name} value={s.count} />);

    let searchResultsSection;
    if (searchResults && searchResults.length > 0) {
        const groupedResults = _.map(
            _.groupBy(searchResults, (sr) => sr.jobId),
            (v, k) => {
                const jobName = v[0].jobName;
                const clientName = v[0].clientName;
                const rows = v.map((s) => {
                    const link = `https://titan.getrocket.com/job/${k}/search/${s.searchId}/total`;
                    return <Row key={s.searchId} title={s.searchName} value={s.recruiter} link={link} />;
                });
                return (
                    <SubSection title={`${clientName} - ${jobName}`} key={k}>
                        {rows}
                    </SubSection>
                );
            }
        );
        searchResultsSection = (
            <div className="debug-section">
                <div className="header1">
                    <i className="fas fa-wrench" />
                    Searches
                </div>
                {groupedResults}
            </div>
        );
    }

    return (
        <div className="extension-profile-debug">
            <div className="debug-section">
                <div className="header1">
                    <i className="material-icons">description</i>
                    Summary
                </div>
                <Row title="Person ID" value={person.id} link={`${config.AppUrl}/person/${person.id}`} />
                <Row title="Member ID" value={profile.memberId} />
                <Row title="Location" value={meta.mappedLocations} />
                <Row title="Software Engineer Score" value={meta.softwareEngineerScore} />
            </div>

            <div className="debug-section">
                <div className="header1">Open To Work</div>
                <Row title="Open" value={profile.isJobSeeker} />
            </div>

            <div className="debug-section">
                <div className="header1">
                    <i className="fas fa-building" />
                    Experience
                </div>
                <Row title="Total Years" value={experience.totalExperience} />
                <Row title="Latest company" value={experience.latestCompanyName} />
                <Row title="Years at latest company" value={experience.totalExperienceAtLatestCompany} />
                <Row title="Discipline" value={experience.latestJobDiscipline} />
                <Row title="Group" value={experience.latestJobGroup} />
                <Row title="Seniority" value={experience.latestSeniorityLevel} />
                <Row title="CCFSE" value={experience.isCurrentlyCCFSE} />
                <SubSection title="Years by discipline">{experienceByDisciplines}</SubSection>
                <SubSection title="Positions">{positionsMeta}</SubSection>
            </div>

            <div className="debug-section">
                <div className="header1">
                    <i className="material-icons">school</i>
                    Education
                </div>
                <Row title="Best US School Rank" value={education.highestSchoolRankUS} />
                <Row title="India School Rank (Undergrad)" value={education.undergradSchoolRankIndia} />
                <Row title="China School Rank (Undergrad)" value={education.undergradSchoolRankChina} />
                <Row title="Bootcamp" value={education.hasBootcamp} />
                <Row title="Bachelors" value={education.hasUndergrad} />
                <Row title="Masters" value={education.hasMasters} />
                <Row title="PhD" value={education.hasPhd} />
                <Row title="CS Degree" value={education.hasCSDegree} />
                <Row title="Currently in School" value={education.isCurrentlyInUndergrad} />
                <SubSection title="Degrees">{educationsMeta}</SubSection>
            </div>

            <div className="debug-section">
                <div className="header1">Diversity</div>
                {diversityInfo}
            </div>

            <div className="debug-section">
                <div className="header1">
                    <i className="fas fa-wrench" />
                    Skills
                </div>
                {skillCountRows}
                <SubSection title="Summary section skills">{summarySkillCountRows}</SubSection>
            </div>
            {searchResultsSection}
        </div>
    );
};
