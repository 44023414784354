import { Map } from 'immutable';
import { Checkbox, Chip, CircularProgress, FlatButton, TextField } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { updatePerson } from '../actions';
import { Person, PersonDetails, RequestErrors, State } from '../state';

interface OwnProps {
    details: PersonDetails;
}

interface PersonLocationState {
    location: string;
    remoteOk: boolean;
    willRelocate: boolean;
    active: boolean;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
}

interface ConnectedDispatch {
    updatePerson: (id: string, updates: Partial<Person>) => void;
}

type PersonLocationProps = OwnProps & ConnectedDispatch & ConnectedProps;

const primaryColor = '#596D85';
const focusedColor = 'rgba(0, 0, 0, 0.3)';
const styles: { [prop: string]: React.CSSProperties } = {
    chip: { marginLeft: '5px' },
    label: { fontSize: '10px', lineHeight: '20px', paddingLeft: '10px', paddingRight: '10px' }
};

class PersonLocationComponent extends React.Component<PersonLocationProps, PersonLocationState> {
    constructor(props: PersonLocationProps) {
        super(props);
        this.state = this.getStateFromProps(props);
    }

    getStateFromProps = (props: PersonLocationProps): PersonLocationState => {
        const { details } = props;
        const { willRelocate, remoteOk } = details.person;
        const location = details.person.location
            ? details.person.location
            : details.profile
            ? details.profile.content.location
            : '';
        return { location, active: false, willRelocate, remoteOk };
    };

    requestKey = () => {
        return `person-update-request-${this.props.details.person.id}`;
    };

    componentDidUpdate(prevProps: PersonLocationProps) {
        if (prevProps.details.person.id !== this.props.details.person.id) {
            this.setState(this.getStateFromProps(this.props));
        }
        const requestKey = this.requestKey();
        if (prevProps.pendingRequests.has(requestKey) && !this.props.pendingRequests.has(requestKey)) {
            this.setState(this.getStateFromProps(this.props));
        }
    }

    handleTextChange = (event: React.FormEvent<{}>, location: string) => {
        event.preventDefault();
        this.setState({ location });
    };

    handleUpdateWillRelocate = (_: any, checked: boolean) => {
        this.setState({ willRelocate: checked });
    };

    handleUpdateRemoteOk = (_: any, checked: boolean) => {
        this.setState({ remoteOk: checked });
    };

    handleKeyPress = (event: any) => {
        const enterKeyCode = 13;
        if (event.charCode === enterKeyCode) {
            this.updateLocation();
        }
    };

    setActive = () => {
        this.setState({ active: true });
    };

    hasEdits = () => {
        const { details } = this.props;
        const { willRelocate, remoteOk } = details.person;
        const location = details.person.location
            ? details.person.location
            : details.profile
            ? details.profile.content.location
            : '';
        return (
            location !== this.state.location ||
            willRelocate !== this.state.willRelocate ||
            remoteOk !== this.state.remoteOk
        );
    };

    cancelEdits = () => {
        this.setState(this.getStateFromProps(this.props));
    };

    updateLocation = () => {
        const { location, willRelocate, remoteOk } = this.state;
        this.props.updatePerson(this.props.details.person.id, { location, willRelocate, remoteOk });
    };

    render() {
        const { pendingRequests } = this.props;
        const { location, active, willRelocate, remoteOk } = this.state;
        const spinnerSize = 20;
        const spinnerThickness = 2;
        const isSaving = pendingRequests.has(this.requestKey()) && pendingRequests.get(this.requestKey()).isEmpty();

        const hintColor = active ? focusedColor : primaryColor;

        const remoteOkChip = (
            <Chip style={styles.chip} labelStyle={styles.label}>
                Remote
            </Chip>
        );
        const relocateOkChip = (
            <Chip style={styles.chip} labelStyle={styles.label}>
                Relocate
            </Chip>
        );
        const locationAttrsMenu = active ? (
            <div className="location-attributes">
                <Checkbox label="Remote OK" checked={remoteOk} onCheck={this.handleUpdateRemoteOk} />
                <Checkbox label="Will Relocate" checked={willRelocate} onCheck={this.handleUpdateWillRelocate} />
            </div>
        ) : null;

        const actions = isSaving ? (
            <div className="prop-actions">
                <CircularProgress size={spinnerSize} thickness={spinnerThickness} />
            </div>
        ) : (
            <div className="prop-actions">
                <FlatButton onClick={this.cancelEdits} label="Cancel" disableTouchRipple={true} />
                <FlatButton
                    onClick={this.updateLocation}
                    label="Save"
                    disabled={!this.hasEdits()}
                    disableTouchRipple={true}
                />
            </div>
        );

        const chips = active ? null : (
            <div className="person-prop-chips">
                {remoteOk ? remoteOkChip : null}
                {willRelocate ? relocateOkChip : null}
            </div>
        );

        return (
            <div className="person-props person-props-location">
                <i className="material-icons section-icon">location_on</i>
                <div className="prop">
                    <div className="person-prop-row">
                        <div className="person-prop-row-spaced">
                            <TextField
                                hintText={`ADD LOCATION`}
                                hintStyle={{ fontSize: '14px', color: hintColor, bottom: 0 }}
                                value={location}
                                onChange={this.handleTextChange}
                                className="form-text"
                                onKeyPress={this.handleKeyPress}
                                disabled={isSaving}
                                underlineShow={false}
                                onFocus={this.setActive}
                                fullWidth={true}
                            />
                            {chips}
                        </div>
                        <div className={`auto-hide ${active ? 'not-hidden' : 'hidden'}`}>
                            {locationAttrsMenu}
                            {actions}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: State): any => ({
    pendingRequests: state.pendingRequests
});

const mapStateToDispatch = {
    updatePerson
};

export const PersonLocation = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapStateToDispatch
)(PersonLocationComponent);
