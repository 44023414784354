import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { AddClient } from '../components/add-client';
import { Header } from '../components/header';
import { ClientsTable } from '../containers/clients-table';

export const ClientsPage = () => (
    <DocumentTitle title="Clients">
        <div id="container">
            <Header title="Clients" actions={[<AddClient key="add-client" />]} />
            <div id="content" className="flex-fill">
                <ClientsTable />
            </div>
        </div>
    </DocumentTitle>
);
