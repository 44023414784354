import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Collapse, IconButton, MenuItem, TextField, Theme, useTheme } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import * as React from 'react';

import { Editor } from 'react-ce';

import { interpolateEmailTemplate } from 'shared/common/interpolate-template';
import { EmailTemplateData } from 'shared/models/email-templates';
import { personVisaOptionLabels, VisaStatus } from 'shared/models/person';

import { getDraft, updateDraft } from '../common/draft-storage';
import { GET_TEMPLATES } from '../graphql/queries/templates';
import { Job, NoteDraftState, PersonDetails } from '../state';

export interface PhoneScreenFormData {
    note: { content: string; context: {} };
    location: string;
    visa: VisaStatus;
    salary: string;
    activity: string;
}

interface PhoneScreenFormProps {
    personDetails: PersonDetails;
    job: Job;
    data: PhoneScreenFormData;
    noteDraftKey: string;
    onChange: (data: PhoneScreenFormData) => void;
}

// tslint:disable: no-magic-numbers
const styles = (theme: Theme) => css`
    flex-grow: 1;
    flex-basis: 0;
    margin: 11px 5px 11px 4px;
    overflow: hidden;
    padding: 3px;

    .phonescreen-notes-panel {
        margin: -3.5px;
        padding: 3.5px;
        transform: scale(0.85);
        transform-origin: top left;
        width: 117%;
        height: 118%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .notes-panel-editor {
        flex-grow: 1;
        overflow: hidden;
        background: white;
        border-top-left-radius: ${theme.shape.borderRadius}px;
        border-top-right-radius: ${theme.shape.borderRadius}px;
        box-shadow: ${theme.shadows[1]};
        margin-bottom: 20px;

        .text-editor-container {
            overflow: hidden;
            min-height: auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 0;

            .text-editor-content {
                flex-grow: 1;
                padding-right: 5px;
                overflow: auto;
                padding: 15px 15px 0;
            }

            .text-editor-toolbar {
                flex-grow: 0;
                margin: 15px;
            }
        }
    }

    .notes-panel-form {
        flex: 0 0 auto;
        padding: 30px 20px 20px;
        position: relative;
        background: white;
        border-bottom-left-radius: ${theme.shape.borderRadius}px;
        border-bottom-right-radius: ${theme.shape.borderRadius}px;
        box-shadow: ${theme.shadows[1]};

        .form-header {
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 500;
            margin: -25px 0 -15px;
        }
    }

    .collapse-button {
        position: absolute;
        top: 5px;
        right: 7px;
        z-index: 2;
    }

    .form-field {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 5px;
        }
    }
`;
// tslint:enable: no-magic-numbers

export const PhoneScreenForm: React.FC<PhoneScreenFormProps> = ({
    personDetails,
    job,
    data,
    noteDraftKey,
    onChange
}) => {
    const theme = useTheme();
    const { data: templateData } = useQuery<{ templates: EmailTemplateData[] }>(GET_TEMPLATES, {
        variables: { group: 'phonescreen', kind: 'Phonescreen' }
    });

    const [draft, setDraft] = React.useState<NoteDraftState>(undefined);
    const [localNoteFetched, setLocalNoteFetched] = React.useState(false);
    const [formExpanded, setFormExpanded] = React.useState(true);

    const context = { jobId: job.id, isPhonescreenTemplate: true };

    const toggleFormExpanded = () => setFormExpanded(!formExpanded);

    const handleUpdateNoteContent = (content: string) => {
        const newDraft = { ...draft, content, modifiedAt: Date.now() };
        updateDraft(noteDraftKey, newDraft);
        setDraft(newDraft);
        onChange({ ...data, note: { content, context: data.note?.context } });
    };

    const handleFieldChange = <K extends keyof PhoneScreenFormData>(key: K) => (event: {
        target: { value: PhoneScreenFormData[K] };
    }) => {
        onChange({ ...data, [key]: event.target.value });
    };

    const handleActivityChange = (_1: React.ChangeEvent, value: string) => {
        onChange({ ...data, activity: value });
    };

    React.useEffect(() => {
        getDraft<NoteDraftState>(noteDraftKey).then((savedDraft) => {
            setLocalNoteFetched(true);
            if (savedDraft) {
                setDraft({
                    addingAttachment: false,
                    content: savedDraft.content,
                    context: savedDraft.context ?? context,
                    initialAttachments: [],
                    modifiedAt: Date.now(),
                    saving: false
                });
                onChange({ ...data, note: { content: savedDraft.content, context: savedDraft.context ?? context } });
            }
        });
    }, []);

    React.useEffect(() => {
        if (templateData && !draft && localNoteFetched) {
            const templateToUse = job.templates.phonescreen ?? templateData.templates[0];
            const content = interpolateEmailTemplate(templateToUse.body, {
                person: personDetails.person,
                profile: personDetails.profile,
                profileUrls: personDetails.profileUrls
            });
            setDraft({
                addingAttachment: false,
                content,
                context,
                initialAttachments: [],
                modifiedAt: Date.now(),
                saving: false
            });
            onChange({ ...data, note: { content, context } });
        }
    }, [templateData, localNoteFetched]);

    const visaOpts = personVisaOptionLabels.keySeq().map((opt) => (
        <MenuItem value={opt} key={opt}>
            {personVisaOptionLabels.get(opt)}
        </MenuItem>
    ));

    const editor = draft ? (
        <Editor
            value={draft.content}
            onChange={handleUpdateNoteContent}
            autoFocus={true}
            disabled={false}
            placeholder="Add note"
            toolbar="medium"
        />
    ) : null;

    const renderInput = (label: string) => (params: RenderInputParams) => (
        <TextField {...params} label={label} size="small" value={data.activity} fullWidth={true} />
    );
    const activityOptions = ['Passive', 'Active - Early stages', 'Active - Final rounds', 'Active - Offer in hand'];

    const formHeader = formExpanded ? (
        <div className="collapse-button">
            <IconButton onClick={toggleFormExpanded}>
                <ExpandLess />
            </IconButton>
        </div>
    ) : (
        <div className="form-header" onClick={toggleFormExpanded}>
            <div>Logistics</div>
            <IconButton>
                <ExpandMore />
            </IconButton>
        </div>
    );

    return (
        <div css={styles(theme)}>
            <div className="phonescreen-notes-panel">
                <div className="notes-panel-editor">{editor}</div>
                <div className="notes-panel-form">
                    {formHeader}
                    <Collapse in={formExpanded}>
                        <div>
                            <TextField
                                value={data.location ?? ''}
                                onChange={handleFieldChange('location')}
                                label="Location"
                                fullWidth={true}
                                size="small"
                                className="form-field"
                            />
                            <TextField
                                value={data.visa ?? ''}
                                label="US Work Status"
                                select={true}
                                fullWidth={true}
                                onChange={handleFieldChange('visa') as any}
                                size="small"
                                className="form-field"
                            >
                                {visaOpts}
                            </TextField>
                            <TextField
                                value={data.salary ?? ''}
                                onChange={handleFieldChange('salary')}
                                label="Desired Salary"
                                fullWidth={true}
                                size="small"
                                className="form-field"
                            />
                            <Autocomplete
                                renderInput={renderInput('Recruiting Activity')}
                                options={activityOptions}
                                onChange={handleActivityChange}
                            />
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    );
};
