import { gql, useLazyQuery } from '@apollo/client';
import React, { createContext, useContext } from 'react';

import { UserStatus } from 'shared/models/user';

interface User {
    id: string;
    name: string;
    email: string;
    status: UserStatus;
}

interface UsersContextType {
    users: User[];
    loading: boolean;
    error: any | null;
    fetchUsers: () => void;
}

const UsersContext = createContext<UsersContextType | undefined>(undefined);

const USERS_LIMITED_DATA = gql`
    query UsersLimitedData {
        users(where: { status: { _eq: "active" } }, order_by: { name: asc }) {
            id
            name(path: "full")
            email
            status
        }
    }
`;

const UsersProvider: React.FC = ({ children }) => {
    const [fetchUsers, { data, loading, error }] = useLazyQuery<{ users: User[] }>(USERS_LIMITED_DATA);
    const users = data?.users;

    return <UsersContext.Provider value={{ users, loading, error, fetchUsers }}>{children}</UsersContext.Provider>;
};

const useUsers = (): UsersContextType => {
    const context = useContext(UsersContext);
    if (context === undefined) {
        throw new Error('useUsers must be used within a UsersProvider');
    }

    // Trigger the fetchUsers function when useUsers is called
    if (!context.users && !context.loading && !context.error) {
        context.fetchUsers();
    }

    return context;
};

export { UsersProvider, useUsers };
