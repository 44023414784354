import { entries, isNil, sortBy } from 'lodash';
import React from 'react';

import { jobPrepayRequired } from 'shared/models/job';
import { hasRole } from 'shared/models/user';

import { DateTextField, SelectField, TextField } from '../core-ui/form-fields';
import { JobFormData } from '../graphql/queries/job-form';
import { useSession } from '../hooks/use-session';
import { JobTypes } from '../state';
import { useFormContext } from './form-context';

export const JobFormContract: React.FC = () => {
    const { userPermissions } = useSession();
    const { data, onChange, onFieldChange, validateFieldExists, setError, getError, disabled } = useFormContext<
        JobFormData
    >();

    const handleBillingChange = <T extends keyof JobFormData['billingInfo']['prepayment']>(key: T) => (
        val: JobFormData['billingInfo']['prepayment'][T]
    ) => {
        const updated = { ...data.billingInfo?.prepayment, [key]: val };
        onChange({ billingInfo: { prepayment: updated } });
        const otherKey = key === 'invoiceSentAt' ? 'invoiceAmount' : 'invoiceSentAt';
        if (!isNil(val) && isNil(updated[otherKey])) {
            setError(`billingInfo.prepayment.${otherKey}`, 'Required');
        } else if (isNil(val) && !isNil(updated[otherKey])) {
            setError(`billingInfo.prepayment.${key}`, 'Required');
        } else if ((isNil(val) && isNil(updated[otherKey])) || (!isNil(val) && !isNil(updated[otherKey]))) {
            setError(`billingInfo.prepayment.${key}`, undefined);
            setError(`billingInfo.prepayment.${otherKey}`, undefined);
        }
    };

    const jobTypeOpts =
        sortBy(entries(JobTypes), (j) => j[1].label).map((j) => ({
            label: j[1].label,
            value: j[0]
        })) ?? [];

    // Define the maximum total percentage
    const TOTAL_PERCENTAGE = 15;

    const handlePercentageChange = (key: 'accountManagerPercentage' | 'recruiterPercentage') => (value: number) => {
        const adjustedValue = Math.max(0, Math.min(TOTAL_PERCENTAGE, value));
        const otherKey = key === 'accountManagerPercentage' ? 'recruiterPercentage' : 'accountManagerPercentage';
        const adjustedOtherValue = TOTAL_PERCENTAGE - adjustedValue;
        const finalOtherValue = Math.max(0, Math.min(TOTAL_PERCENTAGE, adjustedOtherValue));
        onChange({ [otherKey]: finalOtherValue, [key]: adjustedValue });
    };

    const billingInfoFields = jobPrepayRequired(data.jobType) ? (
        <div className="job-field-row">
            <DateTextField
                label="Invoice Sent At"
                value={data.billingInfo?.prepayment?.invoiceSentAt}
                onChange={handleBillingChange('invoiceSentAt')}
                disabled={disabled || !hasRole(userPermissions, 'billing_admin')}
                errorText={getError('billingInfo.prepayment.invoiceSentAt')}
            />
            <TextField
                label="Invoice Amount"
                value={data.billingInfo?.prepayment?.invoiceAmount}
                onChange={handleBillingChange('invoiceAmount')}
                type="number"
                disabled={disabled || !hasRole(userPermissions, 'billing_admin')}
                errorText={getError('billingInfo.prepayment.invoiceAmount')}
            />
        </div>
    ) : null;

    return (
        <div>
            <div className="job-field-row">
                <SelectField
                    label="Job Type"
                    value={data.jobType}
                    onChange={onFieldChange('jobType')}
                    validate={validateFieldExists('jobType')}
                    errorText={getError('jobType')}
                    disabled={disabled}
                    options={jobTypeOpts}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    label="Placement Fees"
                    value={data.placementFees}
                    onChange={onFieldChange('placementFees')}
                    validate={validateFieldExists('placementFees')}
                    errorText={getError('placementFees')}
                    disabled={disabled}
                />
                <TextField
                    label="Account Manager Percentage"
                    value={data.accountManagerPercentage}
                    onChange={handlePercentageChange('accountManagerPercentage')}
                    errorText={getError('accountManagerPercentage')}
                    disabled={disabled}
                    type="number"
                />
                <TextField
                    label="Recruiter Percentage"
                    value={data.recruiterPercentage}
                    onChange={handlePercentageChange('recruiterPercentage')}
                    errorText={getError('recruiterPercentage')}
                    disabled={disabled}
                    type="number"
                />
            </div>
            {billingInfoFields}
        </div>
    );
};
