import React from 'react';
import { JobSubmissionSettings, JobSubmissionType } from 'shared/models/job';

import { MultiSelectField, RichTextField, TextField } from '../core-ui/form-fields';
import { JobFormData } from '../graphql/queries/job-form';
import { ClientContactsSelector } from './client-contacts-selector';
import { useFormContext } from './form-context';

export const JobFormSubmissionInfo: React.FC = () => {
    const {
        data: { clientId, submissionSettings },
        onFieldChange,
        disabled
    } = useFormContext<JobFormData>();

    const handleFieldChange = <T extends keyof JobSubmissionSettings>(field: T) => (
        value: JobSubmissionSettings[T]
    ) => {
        onFieldChange('submissionSettings')({ ...submissionSettings, [field]: value });
    };

    const submissionSettingTypeOptions = [
        { value: JobSubmissionType.ATS, label: 'ATS' },
        { value: JobSubmissionType.Email, label: 'Email' },
        { value: JobSubmissionType.Slack, label: 'Slack' }
    ];

    let atsFields = null;
    if (submissionSettings?.type?.includes(JobSubmissionType.ATS)) {
        atsFields = (
            <>
                <div className="job-field-row">
                    <TextField
                        value={submissionSettings?.atsLink}
                        label="ATS Link"
                        onChange={handleFieldChange('atsLink')}
                        disabled={disabled}
                    />
                </div>
                <div className="job-field-row">
                    <TextField
                        value={submissionSettings?.username}
                        label="ATS Username"
                        onChange={handleFieldChange('username')}
                        disabled={disabled}
                    />
                    <TextField
                        value={submissionSettings?.password}
                        label="ATS Password"
                        onChange={handleFieldChange('password')}
                        disabled={disabled}
                    />
                </div>
            </>
        );
    }

    let emailFields = null;
    if (submissionSettings?.type?.includes(JobSubmissionType.Email)) {
        emailFields = (
            <>
                <div className="job-field-row">
                    <ClientContactsSelector
                        value={submissionSettings?.emailTo}
                        onChange={handleFieldChange('emailTo')}
                        clientId={clientId}
                        label="Submission To"
                        disabled={disabled}
                    />
                    <ClientContactsSelector
                        value={submissionSettings?.emailCC}
                        onChange={handleFieldChange('emailCC')}
                        clientId={clientId}
                        label="Submission CC"
                        disabled={disabled}
                    />
                </div>
            </>
        );
    }

    return (
        <div>
            <div className="job-field-row">
                <MultiSelectField
                    value={submissionSettings?.type}
                    options={submissionSettingTypeOptions}
                    label="Submission Method"
                    onChange={handleFieldChange('type')}
                    disabled={disabled}
                />
            </div>
            {emailFields}
            {atsFields}
            <div className="job-field-row">
                <RichTextField
                    value={submissionSettings?.description}
                    label="Submission Steps Description"
                    onChange={handleFieldChange('description')}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
