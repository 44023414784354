import {
    extensionContainerElementIdChange,
    extensionProfileDataChange,
    extensionUpdateSelectedJobId,
    processExtensionHtmlPendingData,
    updateUserPrefs
} from '../actions';
import { trackCandidateProfileView } from '../analytics';
import { getLocalStorageKey, selectedJobIdKey } from '../common/local-storage';
import { logger } from '../common/logger';
import { store } from '../configure-store';
import { addWindowMessageListener, requestPageData, requestPendingElements, updatePageStyles } from './messaging';
import { sidebarContainerPageStyles, sidebarContainerStyles } from './page-styles';

let initialized = false;
let jobId: string;
let parsingProfile = false;

// handle updates about profile content - messages from chrome extension
export const handlePageDataChange = (tabData: { html: string; url: string; containerElementId: string }) => {
    if (tabData) {
        const state = store.getState();
        if (state.extension.containerElementId !== tabData.containerElementId) {
            updatePageStyles([{ selector: `#${tabData.containerElementId}`, styles: sidebarContainerStyles }]);
            store.dispatch(extensionContainerElementIdChange(tabData.containerElementId));
        }
        if (state.session.initialized) {
            // only start profile processing is session is initialized
            store.dispatch(extensionProfileDataChange(tabData));
        }
    }
};

// listen for updates from local storage - messages originated by changes in search config
const localStorageChangeListener = () => {
    window.addEventListener('storage', () => {
        const selectedJobId = getLocalStorageKey(selectedJobIdKey, undefined);
        const prefs = {
            selectedJobId
        };
        store.dispatch(updateUserPrefs(prefs));
        if (selectedJobId !== jobId) {
            jobId = selectedJobId;
            store.dispatch(extensionUpdateSelectedJobId(jobId));
        }
    });
};

// state change listener to fire tracking events
const reduxStoreListener = () => {
    const state = store.getState();
    if (state.extension.parsingProfile !== parsingProfile && state.jobs.size > 0) {
        parsingProfile = state.extension.parsingProfile;
        if (parsingProfile === false && !state.extension.parsingError && !!state.extension.person?.id) {
            trackCandidateProfileView();
        }
    }
};

// listen for content script updates about pending sections that need to be expanded
export const handleElementsCheckResponse = (message: {
    html: string;
    pendingLoad: Array<{ tag: string; text: string }>;
}) => {
    store.dispatch(processExtensionHtmlPendingData(message));
};

export const setupExtensionListeners = () => {
    addWindowMessageListener();
    updatePageStyles(sidebarContainerPageStyles);
};

// set up listeners that are relevant after session is established
export const setupPostAuthListeners = () => {
    if (!initialized) {
        localStorageChangeListener();
        // request page data again post auth
        requestPageData();
        store.subscribe(reduxStoreListener);
        initialized = true;
    } else {
        logger.warn('trying to re-initialize tab info change listener');
    }
};

// elements that must be expanded before attempting profile parsing
const PROFILE_HTML_EXPAND_SELECTORS = [
    'button[data-test-expand-more-lower-button]',
    'a.lt-line-clamp__more',
    'button[data-test-decorated-line-clamp-see-more-button]'
];
// target element containing the profile data
export const PROFILE_ELEMENT_SELECTOR = '#profile-container';

// how to scroll a pending element in to view - set null to disable
const SCROLL_INTO_VIEW_OPTIONS: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center', inline: 'center' };

// selectors to exclude within the DOM tree for checking on needs to be expanded
const PROFILE_HTML_EXPAND_EXCLUDED_SELECTORS = ['.shared-connections__list'];

// delay between clicks when auto expanding
const autoClickDelayMs = 500;

const htmlLoadCheckMessageBody = {
    excludedTreeSelectors: PROFILE_HTML_EXPAND_EXCLUDED_SELECTORS,
    includedTreeSelectors: PROFILE_HTML_EXPAND_SELECTORS,
    targetElementSelector: PROFILE_ELEMENT_SELECTOR
};

// send a message requesting pending html sections that need to be expanded
export const requestHtmlPendingLoadCounts = (
    containerElementId: string,
    useAutoScroll: boolean,
    autoClick: boolean
) => {
    const scrollOptions = useAutoScroll ? SCROLL_INTO_VIEW_OPTIONS : null;
    requestPendingElements({
        containerElementId,
        options: { ...htmlLoadCheckMessageBody, scrollOptions, autoClick, autoClickDelayMs }
    });
};
