import { Theme, Tooltip as MUITooltip, withStyles } from '@material-ui/core';
import { parseOneAddress } from 'email-addresses';
import * as filesize from 'filesize';
import { pickBy, uniqBy } from 'lodash';
import { Dialog, FlatButton, Menu, MenuItem, Popover, TextField } from 'material-ui';
import * as moment from 'moment-timezone';
import * as React from 'react';
import * as striptags from 'striptags';

import { Editor } from 'react-ce';

import { EmailTemplateView } from 'shared/models/email-templates';
import { EmailAddress } from 'shared/types/email-compose';
import { FilePayload, S3FilePayload } from 'shared/types/file-payload';

import { zIndexes } from '../../common/css-variables';
import { getDraft, updateDraft } from '../../common/draft-storage';
import { getDisabledHours, getNearestValidEmailTs, isDayDisabled } from '../../common/email-sending-times';
import { styledEmail } from '../../common/emails-style';
import { logger } from '../../common/logger';
import { Attachment } from '../../components/attachment';
import { AttachmentButton } from '../../components/attachment-button';
import { DateTimePicker } from '../../core-ui/date-time-picker';
import { Tooltip } from '../../core-ui/over-email-tooltip';
import { addErrorHighlights, removeErrorHighlights } from '../../lib/html-markup';
import { readFile } from '../../lib/read-file-payload';
import { styles } from '../styles';
import { ComposeEmailWindowData } from '../types';
import { EmailAddressField } from './email-address-field';
import { EmailComposeInsertTemplate } from './email-compose-insert-template';
import { PhoneCallCalendarLink } from './phone-call-calendar-link';
import { UserSignatureInsertButton } from './user-signature-insert-button';

interface EmailComposeWindowProps {
    data: ComposeEmailWindowData;
    onRequestSend: (payload: ComposeEmailWindowData, sendAt: number) => void;
    onRequestDelete: (payload: ComposeEmailWindowData, getConfirmation: boolean) => void;
    onRequestCloseWindow: (payload: ComposeEmailWindowData) => void;
}

export type AddressField = 'to' | 'bcc' | 'cc';
const fields: AddressField[] = ['to', 'cc', 'bcc'];

interface EmailComposeWindowState {
    account: EmailAddress;
    addingAttachments: Promise<any>;
    attachments: FilePayload[];
    bcc: EmailAddress[];
    cc: EmailAddress[];
    collapsed: boolean;
    to: EmailAddress[];
    body: string;
    otherRecipientFieldsActive: boolean;
    recipientFieldsActive: boolean;
    sendLaterTimeDialogOpen: boolean;
    sendLaterTs: number;
    subject: string;
    errorString: string;
    errorsHighlighted: boolean;
    editorToolbarOpen: boolean;
    headerErrors: string[];
    sendLaterMenuOpen: boolean;
    sendLaterPopoverAnchor: React.ReactInstance;
    accountSelectorOpen: boolean;
    accountSelectorPopoverAnchor: React.ReactInstance;
}

const maxAttachmentSize = 26214400;

const LightTooltip = withStyles((theme: Theme) => ({
    popper: {
        zIndex: zIndexes.overEmailDialog
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        color: theme.palette.text.primary
    }
}))(MUITooltip);

export class EmailComposeWindow extends React.Component<EmailComposeWindowProps, EmailComposeWindowState> {
    private attachmentsRef: React.RefObject<HTMLDivElement>;
    private editorRef: React.RefObject<Editor>;

    constructor(props: EmailComposeWindowProps) {
        super(props);
        this.attachmentsRef = React.createRef<HTMLDivElement>();
        this.editorRef = React.createRef<Editor>();

        const body = props.data.body;
        const subject = props.data.headers.subject;

        this.state = {
            account: props.data.account,
            accountSelectorOpen: false,
            accountSelectorPopoverAnchor: null,
            addingAttachments: null,
            attachments: props.data.attachments,
            bcc: uniqBy(props.data.headers.bcc, 'address'),
            body: addErrorHighlights(body),
            cc: uniqBy(props.data.headers.cc, 'address'),
            collapsed: false,
            editorToolbarOpen: true,
            errorString: null,
            errorsHighlighted: true,
            headerErrors: [],
            otherRecipientFieldsActive: props.data.headers.cc.length > 0 || props.data.headers.bcc.length > 0,
            recipientFieldsActive: !this.props.data.headers.subject,
            sendLaterMenuOpen: false,
            sendLaterPopoverAnchor: null,
            sendLaterTimeDialogOpen: false,
            sendLaterTs: null,
            subject,
            to: uniqBy(props.data.headers.to, 'address')
        };
    }

    componentDidMount() {
        getDraft<{
            body: string;
            subject: string;
        }>(`email-compose-${this.props.data.windowId}`).then((val) => {
            if (val) {
                this.setState({
                    body: addErrorHighlights(val.body),
                    subject: val.subject
                });
            }
        });
    }

    componentDidUpdate(_1: EmailComposeWindowProps, prevState: EmailComposeWindowState) {
        if (prevState.attachments.length < this.state.attachments.length) {
            this.attachmentsRef.current.scrollIntoView({ block: 'end' });
        }
    }

    isBodyEmpty = () => {
        const { body } = this.state;
        return striptags(body).trim().length === 0;
    };

    isBodyValid = () => {
        const { body } = this.state;
        const text = removeErrorHighlights(striptags(body).trim());
        if (text.length === 0) {
            return 'Please add content to email body';
        } else {
            if (text.match(/{[\s\S]+?}/)) {
                this.setState({ body: addErrorHighlights(removeErrorHighlights(body)) });
                return 'Please fill in the variables marked by curly {} braces in the body';
            }
        }
        return null;
    };

    getEmailPayload = () => {
        const { to, cc, bcc, subject, body, attachments, addingAttachments, account } = this.state;
        const addingPromise = addingAttachments || Promise.resolve();
        const threadId =
            subject === this.props.data.headers.subject && account.address === this.props.data.account.address
                ? this.props.data.threadId
                : undefined;
        const inReplyTo = subject === this.props.data.headers.subject ? this.props.data.headers.inReplyTo : undefined;
        const references = subject === this.props.data.headers.subject ? this.props.data.headers.references : undefined;
        return addingPromise.then(() => {
            const cleanedBody = styledEmail(removeErrorHighlights(body));
            return Object.assign({}, this.props.data, {
                account,
                attachments,
                body: cleanedBody,
                headers: pickBy({
                    bcc: bcc.filter((v) => this.addressValid('bcc')(v.address)),
                    cc: cc.filter((v) => this.addressValid('cc')(v.address)),
                    inReplyTo,
                    references,
                    subject,
                    to: to.filter((v) => this.addressValid('to')(v.address))
                }),
                threadId
            });
        });
    };

    handleToggleCollapse = (e: React.SyntheticEvent<{}>) => {
        e.stopPropagation();
        this.setState({ collapsed: !this.state.collapsed });
    };

    handleAddressFieldChange = (field: AddressField) => (addresses: EmailAddress[]) => {
        this.setState({ [field]: addresses } as any, this.showHeaderErrors);
    };

    handleToggleOtherRecipientFields = () => {
        const { otherRecipientFieldsActive, recipientFieldsActive } = this.state;
        if (recipientFieldsActive) {
            if (otherRecipientFieldsActive) {
                this.setState({ otherRecipientFieldsActive: false, recipientFieldsActive: false });
            } else {
                this.setState({ otherRecipientFieldsActive: true });
            }
        } else {
            this.setState({ recipientFieldsActive: true, otherRecipientFieldsActive: true });
        }
    };

    handleSubjectChange = (_1: any, subject: string) => {
        this.setState({ subject }, () => this.saveDraft());
    };

    handleRecipientFieldsBlur = () => {
        this.setState({ recipientFieldsActive: false });
    };

    handleRecipientFieldsFocus = () => {
        this.setState({ recipientFieldsActive: true });
    };

    handleBodyChange = (body: string) => {
        this.setState({ recipientFieldsActive: false, body }, () => this.saveDraft());
    };

    showHeaderErrors = () => {
        const errors: string[] = [];
        const emails = this.state.to
            .map((email) => ({ field: 'to', address: email.address }))
            .concat(this.state.cc.map((email) => ({ field: 'cc', address: email.address })))
            .concat(this.state.bcc.map((email) => ({ field: 'bcc', address: email.address })));
        if (this.props.data.validator) {
            const { requirementsErrors, notAllowed, valid } = this.props.data.validator(emails);
            if (!valid) {
                errors.push(...requirementsErrors);
                if (notAllowed.length > 0) {
                    errors.push(
                        `The following addresses are not allowed: ${notAllowed.map((a) => `"${a}"`).join(', ')}`
                    );
                }
            }
        }
        if (emails.length === 0 && errors.length === 0) {
            errors.push('You must have at least one recipient');
        }
        this.setState({ headerErrors: errors });
    };

    hasErrors = () => {
        if (!this.state.subject) {
            return 'Please enter a subject';
        }
        return this.isBodyValid();
    };

    handleSendNowClick = () => {
        const errors = this.hasErrors();
        if (errors) {
            this.setState({ errorString: errors });
            if (!this.state.errorsHighlighted) {
                this.handleToggleErrorHighlights();
            }
        } else {
            this.getEmailPayload().then((payload) => {
                this.props.onRequestSend(payload, null);
            });
        }
    };

    handleSendLaterClick = (event: React.SyntheticEvent<{}>) => {
        event.preventDefault();
        const errors = this.hasErrors();
        if (errors) {
            this.setState({ errorString: errors });
        } else {
            const anchorTarget = event.target as React.ReactInstance;
            this.setState({ sendLaterMenuOpen: true, sendLaterPopoverAnchor: anchorTarget });
        }
    };

    handleCloseSendLaterTimeDialog = () => {
        this.setState({ sendLaterTimeDialogOpen: false });
    };

    handleSendLaterConfirmation = () => {
        this.getEmailPayload().then((payload) => {
            // tslint:disable-next-line:no-magic-numbers
            const randomOffsetBefore = Math.round(Math.random() * 30 * 60 * 1000); // 30 mins before
            const ts = this.state.sendLaterTs - randomOffsetBefore;
            this.props.onRequestSend(payload, ts);
        });
    };

    handleSendLaterTimeChange = (ts: number) => {
        this.setState({ sendLaterTs: ts });
    };

    handleSendLaterMenuRequestClose = () => {
        this.setState({ sendLaterMenuOpen: false });
    };

    handleSendLaterMenuClick = (
        selection: 'end_of_day' | 'end_of_week' | 'in_24_hours' | 'in_48_hours' | 'custom'
    ) => () => {
        this.handleSendLaterMenuRequestClose();
        if (selection === 'custom') {
            this.setState({ sendLaterTimeDialogOpen: true, sendLaterTs: Date.now() });
        } else {
            // tslint:disable:no-magic-numbers
            let timeToSend = moment();
            if (selection === 'end_of_day') {
                timeToSend = timeToSend.startOf('hour').hours(18);
            } else if (selection === 'end_of_week') {
                timeToSend = timeToSend.startOf('hour').day('Friday').hours(18);
            } else if (selection === 'in_24_hours') {
                timeToSend = timeToSend.add(1, 'days');
            } else {
                timeToSend = timeToSend.add(2, 'days');
            }
            // tslint:enable:no-magic-numbers
            const timeToSendTs = timeToSend.valueOf();
            const validTs = getNearestValidEmailTs(timeToSend.startOf('day').valueOf(), undefined, false)(timeToSendTs);
            this.getEmailPayload().then((payload) => {
                // tslint:disable-next-line:no-magic-numbers
                const randomOffsetBefore = Math.round(Math.random() * 30 * 60 * 1000); // 30 mins before
                const ts = validTs - randomOffsetBefore;
                this.props.onRequestSend(payload, ts);
            });
        }
    };

    handleErrorDialogClose = () => {
        this.setState({ errorString: null });
    };

    saveDraft = () => {
        const { subject, body } = this.state;
        updateDraft(`email-compose-${this.props.data.windowId}`, { subject, body }).catch((err) => {
            logger.warn(err, { body, message: 'error saving email draft', subject });
        });
    };

    handleDelete = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        const empty =
            this.state.to.length === 0 &&
            this.state.bcc.length === 0 &&
            this.state.cc.length === 0 &&
            !this.state.subject &&
            this.isBodyEmpty();
        this.props.onRequestDelete(this.props.data, !empty);
    };

    handleClose = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        this.props.onRequestCloseWindow(this.props.data);
    };

    isAttachmentUnderSizeLimit = (files: Array<{ size: number }>) => {
        let totalSize = this.state.attachments.map((a) => a.size).reduce((acc, s) => s + acc, 0);
        for (const file of files) {
            totalSize += file.size;
        }
        if (totalSize > maxAttachmentSize) {
            return false;
        } else {
            return true;
        }
    };

    setAttachmentsSizeError = () => {
        const errorString = `Maximum total size for attachments is ${filesize(maxAttachmentSize, { round: 0 })}`;
        this.setState({ errorString });
    };

    handleAddPayloadAttachments = (payloads: S3FilePayload[]) => {
        if (this.isAttachmentUnderSizeLimit(payloads)) {
            this.setState({ attachments: this.state.attachments.concat(payloads) });
        } else {
            this.setAttachmentsSizeError();
        }
    };

    handleAddAttachments = (files: File[]) => {
        if (this.isAttachmentUnderSizeLimit(files)) {
            const addingAttachments = Promise.all(files.map(readFile))
                .then((result) => {
                    this.setState({ attachments: this.state.attachments.concat(result), addingAttachments: null });
                })
                .catch((err) => {
                    logger.warn(err, { files, message: 'error adding attachments to email' });
                    this.setState({ addingAttachments: null });
                });
            this.setState({ addingAttachments });
        } else {
            this.setAttachmentsSizeError();
        }
    };

    handleRemoveAttachment = (index: number) => () => {
        const { attachments } = this.state;
        this.setState({ attachments: attachments.slice(0, index).concat(attachments.slice(index + 1)) });
    };

    handleAttachmentFileNameUpdate = (index: number) => (filename: string) => {
        const { attachments } = this.state;
        const newAttachments = attachments.map((a, i) => {
            if (i === index) {
                return {
                    ...a,
                    filename
                };
            } else {
                return a;
            }
        });
        this.setState({ attachments: newAttachments });
    };

    handleEditorToolbarToggle = () => {
        this.setState({ editorToolbarOpen: !this.state.editorToolbarOpen });
    };

    handleToggleErrorHighlights = () => {
        const isCurrentlyHighlighted = this.state.errorsHighlighted;
        const body = isCurrentlyHighlighted
            ? removeErrorHighlights(this.state.body)
            : addErrorHighlights(this.state.body);
        this.setState({ errorsHighlighted: !isCurrentlyHighlighted, body });
    };

    handleInsertTemplate = (template: EmailTemplateView) => {
        this.editorRef.current.insertHtml(addErrorHighlights(template.body));
        const cc = this.state.cc.concat(template.cc || []);
        const bcc = this.state.bcc.concat(template.bcc || []);
        const subject = this.state.subject || template.subject;
        this.setState({ cc, bcc, subject, errorsHighlighted: true });
    };

    handleInsertSchedulingLink = (link: string, text: string) => {
        const hyperlink = `<a href="${link}">${text} </a>`;
        this.editorRef.current.insertHtml(hyperlink);
    };

    handleInsertSignature = (signature: string) => {
        this.editorRef.current.insertHtml(signature);
    };

    addressValid = (field: AddressField) => (address: string) => {
        let allEmailsAllowed = true;
        if (this.props.data.validator) {
            const { notAllowed } = this.props.data.validator([{ field, address }]);
            allEmailsAllowed = notAllowed.length === 0;
        }
        return !!(parseOneAddress(address) && allEmailsAllowed);
    };

    handleAccountClick = (e: React.SyntheticEvent<{}>) => {
        if (this.props.data.accountOptions) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                accountSelectorOpen: true,
                accountSelectorPopoverAnchor: e.currentTarget as React.ReactInstance
            });
        }
    };

    handleCloseAccountSelector = () => {
        this.setState({ accountSelectorOpen: false, accountSelectorPopoverAnchor: null });
    };

    handleSelectAccount = (account: EmailAddress) => () => {
        this.setState({ account, accountSelectorOpen: false, accountSelectorPopoverAnchor: null });
    };

    render() {
        const {
            account,
            accountSelectorOpen,
            accountSelectorPopoverAnchor,
            attachments,
            body,
            editorToolbarOpen,
            errorsHighlighted,
            sendLaterTs,
            sendLaterTimeDialogOpen,
            subject,
            otherRecipientFieldsActive,
            recipientFieldsActive,
            errorString,
            collapsed,
            headerErrors,
            sendLaterMenuOpen,
            sendLaterPopoverAnchor
        } = this.state;
        const title = subject?.trim() ? subject?.trim() : 'New Message';

        const titleBar = (
            <div className="email-compose-window-title-bar" onClick={this.handleToggleCollapse}>
                <div className="email-compose-window-title-subject">
                    <span className="email-compose-window-title">{title}</span>
                    <LightTooltip title={`${account.name} <${account.address}>`}>
                        <span className="email-compose-window-address" onClick={this.handleAccountClick}>
                            {account.address}
                        </span>
                    </LightTooltip>
                </div>
                <div className="email-compose-window-title-actions">
                    <div className="email-compose-window-title-action" onClick={this.handleToggleCollapse}>
                        <i className="material-icons">{collapsed ? 'maximize' : 'minimize'}</i>
                    </div>
                    <div className="email-compose-window-title-action" onClick={this.handleClose}>
                        <i className="material-icons">close</i>
                    </div>
                </div>
            </div>
        );

        const accountMenuOptions = this.props.data.accountOptions?.map((opt) => (
            <MenuItem
                key={opt.address}
                value={opt.address}
                primaryText={opt.name ? `${opt.name} <${opt.address}>` : opt.address}
                onClick={this.handleSelectAccount(opt)}
                insetChildren={true}
                checked={account.address === opt.address}
            />
        ));

        const addressFields: JSX.Element[] = [];
        if (recipientFieldsActive) {
            for (const field of fields) {
                const hintText = otherRecipientFieldsActive || this.state[field].length > 0 ? '' : 'Recipients';
                if (otherRecipientFieldsActive || field === 'to' || this.state[field].length > 0) {
                    const disabled = field === 'to' && !this.props.data.canEditToRecipients;
                    const label =
                        otherRecipientFieldsActive || this.state.cc.length > 0 || this.state.bcc.length > 0 ? (
                            <div className="recipients-label">{field}</div>
                        ) : null;
                    addressFields.push(
                        <EmailAddressField
                            key={field}
                            label={label}
                            hintText={hintText}
                            addresses={this.state[field]}
                            onChange={this.handleAddressFieldChange(field)}
                            disabled={disabled}
                            addressValid={this.addressValid(field)}
                        />
                    );
                }
            }
        } else {
            for (const field of fields) {
                const prefix = field === 'bcc' ? 'Bcc: ' : '';
                for (const rec of this.state[field]) {
                    const invalid = !this.addressValid(field)(rec.address);
                    addressFields.push(
                        <span
                            className={`recipient-address ${invalid ? 'invalid' : ''}`}
                            key={`${field}-${rec.address}`}
                        >
                            {prefix}
                            {rec.name ? `${rec.name} (${rec.address.split('@')[1]})` : rec.address}
                        </span>
                    );
                }
            }
        }
        if (addressFields.length === 0) {
            addressFields.push(
                <div
                    className="recipient-address-hint-text"
                    key="recipient-address-hint"
                    onClick={this.handleRecipientFieldsFocus}
                >
                    Recipients
                </div>
            );
        }
        const otherFieldToggleIcon =
            recipientFieldsActive && otherRecipientFieldsActive ? 'expand_less' : 'expand_more';
        const otherFieldsToggle = (
            <div className="email-compose-other-field-toggle" onClick={this.handleToggleOtherRecipientFields}>
                <i className="material-icons">{otherFieldToggleIcon}</i>
            </div>
        );
        const subjectField = (
            <TextField
                hintText="Subject"
                value={subject}
                onChange={this.handleSubjectChange}
                onFocus={this.handleRecipientFieldsBlur}
                inputStyle={styles.input}
                hintStyle={styles.hint}
                style={styles.textFieldRoot}
                underlineShow={false}
                fullWidth={true}
            />
        );

        const errorText = headerErrors.map((s) => <span key={s}>{s}</span>);
        const errorElement = this.state.recipientFieldsActive ? (
            <div className="email-compose-headers-error-text">{errorText}</div>
        ) : null;
        const headersSection = (
            <div className="email-compose-headers">
                <div className="recipient-fields">
                    <div className="recipient-text-fields" onClick={this.handleRecipientFieldsFocus}>
                        {addressFields}
                        {errorElement}
                    </div>
                    {otherFieldsToggle}
                </div>
                <div className="recipient-fields">{subjectField}</div>
            </div>
        );
        const errorDialog = errorString ? (
            <Dialog
                open={true}
                onRequestClose={this.handleErrorDialogClose}
                title="Error"
                actions={[<FlatButton label="Ok" onClick={this.handleErrorDialogClose} key="close-button" />]}
                style={{ zIndex: zIndexes.overEmailDialog }}
            >
                {errorString}
            </Dialog>
        ) : null;

        let attachmentsList;
        if (attachments.length > 0) {
            attachmentsList = attachments.map((f, i) => (
                <Attachment
                    key={i}
                    file={f}
                    onRemove={this.handleRemoveAttachment(i)}
                    onFileNameUpdate={this.handleAttachmentFileNameUpdate(i)}
                />
            ));
        }
        const attachmentsSection = (
            <div className="email-compose-attachments" ref={this.attachmentsRef}>
                <div className="email-compose-attachments-list">{attachmentsList}</div>
            </div>
        );

        let sendLaterTimeDialog;
        if (sendLaterTimeDialogOpen) {
            const actions = [
                <FlatButton label="Cancel" onClick={this.handleCloseSendLaterTimeDialog} key="cancel" />,
                <FlatButton label="Send" onClick={this.handleSendLaterConfirmation} key="confirm" />
            ];
            const now = Date.now();
            sendLaterTimeDialog = (
                <Dialog
                    open={true}
                    onRequestClose={this.handleCloseSendLaterTimeDialog}
                    style={{ zIndex: zIndexes.overEmailDialog }}
                    contentStyle={{ width: 360 }}
                    bodyStyle={{ padding: 0, height: 360 }}
                    actions={actions}
                    actionsContainerClassName="email-compose-send-later-actions"
                >
                    <DateTimePicker
                        onChange={this.handleSendLaterTimeChange}
                        value={sendLaterTs}
                        minDate={Date.now()}
                        getNearestValidTime={getNearestValidEmailTs(now, undefined, false)}
                        getDisabledHours={getDisabledHours}
                        isDayDisabled={isDayDisabled(now, undefined, false)}
                    />
                </Dialog>
            );
        }

        const templateContext = {
            account: this.props.data.account,
            bcc: this.state.bcc,
            cc: this.state.cc,
            jobId: this.props.data.jobId,
            personId: this.props.data.personId,
            to: this.state.to
        };

        const disabledLayer = this.props.data.disabled ? <div className="email-compose-window-disabled-layer" /> : null;

        const sendDisabled = this.state.headerErrors.length > 0;
        const windowBody = (
            <div className="email-compose-window-body">
                {disabledLayer}
                {headersSection}
                <div className={`email-compose-body ${editorToolbarOpen ? '' : 'editor-toolbar-hidden'}`}>
                    <Editor
                        value={body}
                        onChange={this.handleBodyChange}
                        className={`email-editor ${editorToolbarOpen ? '' : 'text-editor-toolbar-hidden'}`}
                        autoFocus={!recipientFieldsActive}
                        onFocus={this.handleRecipientFieldsBlur}
                        postEditorContent={attachmentsSection}
                        ref={this.editorRef}
                    />
                </div>
                <div className="email-compose-actions">
                    <div className="email-compose-actions-primary">
                        <button
                            className="send-now text-label"
                            onClick={this.handleSendNowClick}
                            disabled={sendDisabled}
                        >
                            Send Now
                        </button>
                        <button
                            className="send-later text-label"
                            onClick={this.handleSendLaterClick}
                            disabled={sendDisabled}
                        >
                            Send Later
                        </button>
                        <Tooltip title="Toggle Editor Toolbar">
                            <button
                                className={`icon-label ${editorToolbarOpen ? 'button-active' : ''}`}
                                onClick={this.handleEditorToolbarToggle}
                            >
                                <i className="fas fa-font" />
                            </button>
                        </Tooltip>
                        <AttachmentButton
                            personId={this.props.data.personId}
                            jobId={this.props.data.jobId}
                            onDrop={this.handleAddAttachments}
                            onUploadedFilesSelect={this.handleAddPayloadAttachments}
                        />
                        <EmailComposeInsertTemplate onSelect={this.handleInsertTemplate} context={templateContext} />
                        <Tooltip title="Toggle Error Highlights">
                            <button
                                className={`icon-label ${errorsHighlighted ? 'button-active' : ''}`}
                                onClick={this.handleToggleErrorHighlights}
                            >
                                <i className="material-icons">border_color</i>
                            </button>
                        </Tooltip>
                        <PhoneCallCalendarLink
                            personId={this.props.data.personId}
                            jobId={this.props.data.jobId}
                            account={this.props.data.account.address}
                            onSelect={this.handleInsertSchedulingLink}
                        />
                        <UserSignatureInsertButton onSelect={this.handleInsertSignature} />
                    </div>
                    <div className="email-compose-actions-secondary">
                        <Tooltip title="Delete">
                            <button className="delete-button icon-label" onClick={this.handleDelete}>
                                <i className="material-icons">delete</i>
                            </button>
                        </Tooltip>
                    </div>
                </div>
                <Popover
                    open={sendLaterMenuOpen}
                    anchorEl={sendLaterPopoverAnchor}
                    onRequestClose={this.handleSendLaterMenuRequestClose}
                >
                    <Menu desktop={true}>
                        <MenuItem primaryText="Custom" onClick={this.handleSendLaterMenuClick('custom')} />
                        <MenuItem primaryText="At End Of Week" onClick={this.handleSendLaterMenuClick('end_of_week')} />
                        <MenuItem
                            primaryText="Send in 48 Hours"
                            onClick={this.handleSendLaterMenuClick('in_48_hours')}
                        />
                        <MenuItem
                            primaryText="Send in 24 Hours"
                            onClick={this.handleSendLaterMenuClick('in_24_hours')}
                        />
                        <MenuItem primaryText="At End Of Day" onClick={this.handleSendLaterMenuClick('end_of_day')} />
                    </Menu>
                </Popover>
                <Popover
                    open={accountSelectorOpen}
                    anchorEl={accountSelectorPopoverAnchor}
                    onRequestClose={this.handleCloseAccountSelector}
                >
                    <Menu desktop={true} value={account.address}>
                        {accountMenuOptions}
                    </Menu>
                </Popover>
            </div>
        );
        return (
            <div className={`email-compose-window ${collapsed ? 'collapsed' : ''}`}>
                {titleBar}
                {windowBody}
                {errorDialog}
                {sendLaterTimeDialog}
            </div>
        );
    }
}
