import React from 'react';

import { MultiAutocomplete, MultiSelectField, RichTextField, SelectField, TextField } from '../core-ui/form-fields';
import { JobFormData } from '../graphql/queries/job-form';
import { useFormContext } from './form-context';
import { JobOnePager } from './job-one-pager';

export const JobFormJD: React.FC<{ onOnePagerChange: (onePager: { key: string; size: number }) => void }> = ({
    onOnePagerChange
}) => {
    const {
        data: { description, id, onePager },
        onFieldChange,
        disabled
    } = useFormContext<JobFormData>();

    const handleFieldChange = <T extends keyof JobFormData['description']>(field: T) => (
        value: JobFormData['description'][T]
    ) => {
        onFieldChange('description')({ ...description, [field]: value });
    };

    const visaOptions = [
        { label: 'No Visa Sponsorship', value: 'no_visa' },
        { label: 'Visa Transfers OK', value: 'h1_transfers' },
        { label: 'TN Visa OK', value: 'tn_visa' },
        { label: 'New Visa Sponsorship OK', value: 'h1_sponsorships' }
    ];

    const diversityOptions = [
        { label: 'Diversity Only', value: 'diversity_only' },
        { label: 'Diversity Preferred', value: 'diversity_preferred' },
        { label: 'Not Relevant', value: 'not_relevant' }
    ];

    return (
        <div>
            <div className="job-field-row">
                <TextField
                    value={description.summary}
                    label="Role Summary"
                    onChange={handleFieldChange('summary')}
                    disabled={disabled}
                    multiline={true}
                />
            </div>
            <div className="job-field-row">
                <MultiSelectField
                    value={description.visa}
                    label="Visa"
                    onChange={handleFieldChange('visa')}
                    options={visaOptions}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={description.location}
                    label="Location"
                    onChange={handleFieldChange('location')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={description.hiringManager}
                    label="Hiring Manager"
                    onChange={handleFieldChange('hiringManager')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={description.mustHaves}
                    label="Must Haves"
                    onChange={handleFieldChange('mustHaves')}
                    disabled={disabled}
                    multiline={true}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={description.niceToHaves}
                    label="Nice To Haves"
                    onChange={handleFieldChange('niceToHaves')}
                    disabled={disabled}
                    multiline={true}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={description.positiveOutliers}
                    label="Positive Outliers"
                    onChange={handleFieldChange('positiveOutliers')}
                    disabled={disabled}
                    multiline={true}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={description.negativeOutliers}
                    label="Negative Outliers"
                    onChange={handleFieldChange('negativeOutliers')}
                    disabled={disabled}
                    multiline={true}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={description.responsibilities}
                    label="Responsibilities"
                    onChange={handleFieldChange('responsibilities')}
                    disabled={disabled}
                    multiline={true}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={description.minExperienceYears}
                    label="Min Experience Years"
                    onChange={handleFieldChange('minExperienceYears')}
                    disabled={disabled}
                    type="number"
                />
                <TextField
                    value={description.maxExperienceYears}
                    label="Max Experience Years"
                    onChange={handleFieldChange('maxExperienceYears')}
                    disabled={disabled}
                    type="number"
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={description.compensation}
                    label="Compensation"
                    onChange={handleFieldChange('compensation')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <RichTextField
                    value={description.interviewPrep}
                    label="Interview Prep"
                    onChange={handleFieldChange('interviewPrep')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={description.skills}
                    label="Skills"
                    onChange={handleFieldChange('skills')}
                    disabled={disabled}
                    multiline={true}
                />
            </div>
            <div className="job-field-row">
                <MultiAutocomplete
                    value={description.keywords}
                    label="Keywords"
                    onChange={handleFieldChange('keywords')}
                    disabled={disabled}
                    options={[]}
                    freeSolo={true}
                />
            </div>
            <div className="job-field-row">
                <RichTextField
                    value={description.description}
                    label="Detailed Job Description"
                    onChange={handleFieldChange('description')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={description.linkToJobDescription}
                    label="Link to Job Description"
                    onChange={handleFieldChange('linkToJobDescription')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <SelectField
                    value={description.diversity}
                    label="Diversity"
                    onChange={handleFieldChange('diversity')}
                    options={diversityOptions}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <JobOnePager jobId={id} onePager={onePager} onChange={onOnePagerChange} />
            </div>
        </div>
    );
};
