import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Theme,
    useTheme
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import moment from 'moment-timezone';
import React from 'react';

import { GUARANTEE_PERIOD_DAYS } from 'shared/models/candidate';
import { hiredStage } from 'shared/models/job-stages';
import { hasRole } from 'shared/models/user';

import { fullDate } from '../common/timestamp';
import { DayPicker } from '../core-ui/react-day-picker';
import { UPDATE_CANDIDATE_HIRING_INFO } from '../graphql/queries/candidates';
import { usePrevious } from '../hooks/use-previous';
import { useSession } from '../hooks/use-session';
import { Candidate, Job } from '../state';

const dialogStyles = css`
    .dialog-title h2 {
        font-size: 20px;
    }

    .dialog-content {
        > * {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const styles = (theme: Theme) => css`
    border: thin solid ${theme.palette.divider};
    border-radius: 2px;
    background: white;
    margin-bottom: 20px;
    padding: 15px 18px;
    position: relative;

    .edit-button {
        position: absolute;
        bottom: 5px;
        right: 5px;
        opacity: 0;
        transition: opacity 0.2ms;
    }

    &:hover {
        .edit-button {
            opacity: 1;
        }
    }

    .info-label {
        text-transform: uppercase;
        font-size: 10px;
        color: ${theme.palette.primary.light};
        margin-bottom: 4px;
        padding: 0 2px;
    }

    .info-value {
        font-size: 14px;
        margin-bottom: 12px;
        border-bottom: thin solid ${theme.palette.divider};
        padding: 0 2px 10px;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }
`;

export const CandidateBillingInfo: React.FC<{ candidate: Candidate; job: Job }> = (props) => {
    const theme = useTheme();
    const { user, userPermissions } = useSession();
    const [updateRecord, { loading }] = useMutation<
        {
            update_candidates: {
                returning: Array<{
                    hiredAt: number;
                    hiredSalary: string;
                    paidAt: number;
                    invoiced: boolean;
                    fees: number;
                }>;
            };
        },
        { personId: string; jobId: string; updates: Partial<Candidate> }
    >(UPDATE_CANDIDATE_HIRING_INFO);
    const { job } = props;
    const [candidate, setCandidate] = React.useState(props.candidate);
    const prevStage = usePrevious(candidate?.stage);
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const [dayDialogState, setDayDialogState] = React.useState<{ ts: number; handler: (date: Date) => void }>(null);
    const [signOnBonus, setSignOnBonus] = React.useState(candidate?.signOnBonus ?? '');
    const [prepaidFees, setPrepaidFees] = React.useState(candidate?.prePaidFees);
    const [amCommission, setAMCommission] = React.useState(candidate?.amCommission);
    const [amCommissionPaidAt, setAMCommissionPaidAt] = React.useState(candidate?.amCommissionPaidAt);
    const [recruiterCommission, setRecruiterCommission] = React.useState(candidate?.recruiterCommission);
    const [recruiterCommissionPaidAt, setRecruiterCommissionPaidAt] = React.useState(
        candidate?.recruiterCommissionPaidAt
    );
    const [salary, setSalary] = React.useState(candidate?.hiredSalary ?? '');
    const [feesDue, setFeesDue] = React.useState(candidate?.fees);
    const [hireDate, setHireDate] = React.useState(candidate?.hiredAt);
    const [startDate, setStartDate] = React.useState(candidate?.startDate);
    const [invoiceSentDate, setInvoiceSentDate] = React.useState(candidate?.invoicedAt);
    const [paidDate, setPaidDate] = React.useState(candidate?.paidAt);

    const isBillingAdmin = hasRole(userPermissions, 'billing_admin');

    const canEdit = candidate && candidate.stage === hiredStage && (user.id === job.accountManagerId || isBillingAdmin);

    React.useEffect(() => {
        setCandidate(props.candidate);
    }, [props.candidate]);

    React.useEffect(() => {
        if (prevStage !== undefined && prevStage !== hiredStage && canEdit) {
            setEditDialogOpen(true);
        }
    }, [candidate]);

    const handleToggleEditDialog = () => setEditDialogOpen(!editDialogOpen);

    const handleSaveCandidateHiredInfo = async () => {
        setEditDialogOpen(false);
        const result = await updateRecord({
            variables: {
                jobId: candidate.jobId,
                personId: candidate.personId,
                updates: {
                    fees: feesDue,
                    hiredAt: hireDate,
                    hiredSalary: salary,
                    invoicedAt: invoiceSentDate,
                    paidAt: paidDate,
                    prePaidFees: prepaidFees,
                    signOnBonus,
                    startDate
                }
            }
        });
        const updated = { ...candidate, ...result.data.update_candidates.returning[0] };
        setCandidate(updated);
        setSignOnBonus(updated.signOnBonus ?? '');
        setPrepaidFees(updated.prePaidFees);
        setAMCommission(updated.amCommission);
        setAMCommissionPaidAt(updated.amCommissionPaidAt);
        setRecruiterCommission(updated.recruiterCommission);
        setRecruiterCommissionPaidAt(updated.recruiterCommissionPaidAt);
    };

    const handleChangeSalary = (event: { target: { value: string } }) => {
        setSalary(event.target.value);
    };

    const handleChangeFees = (event: { target: { value: string } }) => {
        setFeesDue(Number(event.target.value));
    };

    const handleDayPickerOpen = (ts: number, handler: (date: Date) => void) => () => {
        setDayDialogState({ ts, handler });
    };

    const handleCloseDayDialog = () => setDayDialogState(null);

    const handlePaidDateChange = (date: Date) => {
        setPaidDate(date.valueOf());
        setDayDialogState(null);
    };

    const handleHiredDateChange = (date: Date) => {
        setHireDate(date.valueOf());
        setDayDialogState(null);
    };

    const handleStartDateChange = (date: Date) => {
        setStartDate(date.valueOf());
        setDayDialogState(null);
    };

    const handleSignOnBonusChange = (event: { target: { value: string } }) => {
        setSignOnBonus(event.target.value);
    };

    const handlePrepaidFeesChange = (event: { target: { value: string } }) => {
        setPrepaidFees(Number(event.target.value));
    };

    const handleInvoiceSentDateChange = (date: Date) => {
        setInvoiceSentDate(date.valueOf());
        setDayDialogState(null);
    };

    const handleAMCommissionChange = (event: { target: { value: string } }) => {
        setAMCommission(Number(event.target.value));
    };

    const handleAMCommissionPaidAtChange = (date: Date) => {
        setAMCommissionPaidAt(date.valueOf());
        setDayDialogState(null);
    };

    const handleRecruiterCommissionChange = (event: { target: { value: string } }) => {
        setRecruiterCommission(Number(event.target.value));
    };

    const handleRecruiterCommissionPaidAtChange = (date: Date) => {
        setRecruiterCommissionPaidAt(date.valueOf());
        setDayDialogState(null);
    };

    if (!candidate) return null;

    const hiredDate =
        candidate.stage === hiredStage && candidate.hiredAt ? (
            <>
                <div className="info-label">Hired On</div>
                <div className="info-value">{fullDate(candidate.hiredAt)}</div>
            </>
        ) : null;

    const hiredSalary =
        candidate.stage === hiredStage && candidate.hiredSalary ? (
            <>
                <div className="info-label">Base Salary</div>
                <div className="info-value">{candidate.hiredSalary}</div>
            </>
        ) : null;

    const hiredSignOn =
        candidate.stage === hiredStage && candidate.signOnBonus ? (
            <>
                <div className="info-label">Sign-on Bonus</div>
                <div className="info-value">{candidate.signOnBonus}</div>
            </>
        ) : null;

    const invoiced =
        candidate.stage === hiredStage && candidate.invoicedAt ? (
            <>
                <div className="info-label">Invoice Sent On</div>
                <div className="info-value">{fullDate(candidate.invoicedAt)}</div>
            </>
        ) : null;

    const fees =
        candidate.stage === hiredStage && candidate.fees ? (
            <>
                <div className="info-label">Placement Fees</div>
                <div className="info-value">${candidate.fees.toLocaleString()}</div>
            </>
        ) : null;

    const feesAdjustment =
        candidate.stage === hiredStage && candidate.prePaidFees ? (
            <>
                <div className="info-label">Prepaid Fees</div>
                <div className="info-value">${candidate.prePaidFees.toLocaleString()}</div>
            </>
        ) : null;

    const paidAt =
        candidate.stage === hiredStage && candidate.paidAt ? (
            <>
                <div className="info-label">Fees Received Date</div>
                <div className="info-value">{fullDate(candidate.paidAt)}</div>
            </>
        ) : null;

    const candidateStartDate =
        candidate.stage === hiredStage && candidate.startDate ? (
            <>
                <div className="info-label">Candidate Start Date</div>
                <div className="info-value">{fullDate(candidate.startDate)}</div>
            </>
        ) : null;

    const guaranteeDate = startDate
        ? moment(startDate).add(GUARANTEE_PERIOD_DAYS, 'days').startOf('day').valueOf()
        : '';

    const candidateGuaranteeDate =
        candidate.stage === hiredStage && guaranteeDate ? (
            <>
                <div className="info-label">Guarantee Date</div>
                <div className="info-value">{guaranteeDate ? fullDate(guaranteeDate) : ''}</div>
            </>
        ) : null;

    const displayAMCommission =
        candidate.stage === hiredStage && candidate.amCommission ? (
            <>
                <div className="info-label">AM Commission</div>
                <div className="info-value">${candidate.amCommission.toLocaleString()}</div>
            </>
        ) : null;

    const displayAMCommissionPaidAt =
        candidate.stage === hiredStage && amCommissionPaidAt ? (
            <>
                <div className="info-label">AM Commission Paid</div>
                <div className="info-value">{amCommissionPaidAt ? fullDate(amCommissionPaidAt) : ''}</div>
            </>
        ) : null;

    const displayRecruiterCommission =
        candidate.stage === hiredStage && candidate.recruiterCommission ? (
            <>
                <div className="info-label">Recruiter Commission</div>
                <div className="info-value">${candidate.recruiterCommission.toLocaleString()}</div>
            </>
        ) : null;

    const displayRecruiterCommissionPaidAt =
        candidate.stage === hiredStage && recruiterCommissionPaidAt ? (
            <>
                <div className="info-label">Recruiter Commission Paid</div>
                <div className="info-value">{recruiterCommissionPaidAt ? fullDate(recruiterCommissionPaidAt) : ''}</div>
            </>
        ) : null;

    const editButton = canEdit ? (
        <IconButton className="edit-button" onClick={handleToggleEditDialog} disabled={loading}>
            <Edit />
        </IconButton>
    ) : null;

    const editDialog = editDialogOpen ? (
        <Dialog open={true} onClose={handleToggleEditDialog} maxWidth="md" css={dialogStyles}>
            <DialogTitle className="dialog-title">Edit Candidate's Hire Info</DialogTitle>
            <DialogContent className="dialog-content">
                <TextField
                    label="Hired Date"
                    value={hireDate ? fullDate(hireDate) : ''}
                    fullWidth={true}
                    onClick={handleDayPickerOpen(hireDate, handleHiredDateChange)}
                />
                <TextField
                    label="Candidate Start Date"
                    value={startDate ? fullDate(startDate) : ''}
                    fullWidth={true}
                    onClick={handleDayPickerOpen(startDate, handleStartDateChange)}
                />
                <TextField
                    label="Guarantee Date"
                    value={guaranteeDate ? fullDate(guaranteeDate) : ''}
                    fullWidth={true}
                />
                <TextField
                    label="Invoice Sent Date"
                    value={invoiceSentDate ? fullDate(invoiceSentDate) : ''}
                    fullWidth={true}
                    onClick={handleDayPickerOpen(invoiceSentDate, handleInvoiceSentDateChange)}
                    disabled={!isBillingAdmin}
                />
                <TextField
                    label="Fees Received Date"
                    value={paidDate ? fullDate(paidDate) : ''}
                    fullWidth={true}
                    onClick={handleDayPickerOpen(paidDate, handlePaidDateChange)}
                    disabled={!isBillingAdmin}
                />
                <TextField label="Base Salary" value={salary} onChange={handleChangeSalary} fullWidth={true} />
                <TextField
                    label="Sign-on Bonus"
                    value={signOnBonus}
                    onChange={handleSignOnBonusChange}
                    fullWidth={true}
                />
                <TextField label="Fees" value={feesDue} onChange={handleChangeFees} type="number" fullWidth={true} />
                <TextField
                    label="Prepaid Fees"
                    value={prepaidFees}
                    onChange={handlePrepaidFeesChange}
                    fullWidth={true}
                    type="number"
                    disabled={!isBillingAdmin}
                />
                <TextField
                    label="Recruiter Commission"
                    value={recruiterCommission}
                    onChange={handleRecruiterCommissionChange}
                    fullWidth={true}
                    type="number"
                    disabled={!isBillingAdmin}
                />
                <TextField
                    label="Recruiter Commission Paid Date"
                    value={recruiterCommissionPaidAt ? fullDate(recruiterCommissionPaidAt) : ''}
                    onClick={handleDayPickerOpen(recruiterCommissionPaidAt, handleRecruiterCommissionPaidAtChange)}
                    fullWidth={true}
                    disabled={!isBillingAdmin}
                />
                <TextField
                    label="AM Commission"
                    value={amCommission}
                    onChange={handleAMCommissionChange}
                    fullWidth={true}
                    type="number"
                    disabled={!isBillingAdmin}
                />
                <TextField
                    label="AM Commission Paid Date"
                    value={amCommissionPaidAt ? fullDate(amCommissionPaidAt) : ''}
                    onClick={handleDayPickerOpen(amCommissionPaidAt, handleAMCommissionPaidAtChange)}
                    fullWidth={true}
                    disabled={!isBillingAdmin}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleToggleEditDialog}>Cancel</Button>
                <Button onClick={handleSaveCandidateHiredInfo}>Save</Button>
            </DialogActions>
        </Dialog>
    ) : null;

    const dayDialog = dayDialogState ? (
        <Dialog open={true} onClose={handleCloseDayDialog} maxWidth="md">
            <DayPicker
                defaultMonth={dayDialogState.ts ? new Date(dayDialogState.ts) : new Date()}
                onDayClick={dayDialogState.handler}
            />
        </Dialog>
    ) : null;

    return (
        <div css={styles(theme)}>
            {editButton}
            {editDialog}
            {dayDialog}
            {hiredDate}
            {candidateStartDate}
            {candidateGuaranteeDate}
            {invoiced}
            {paidAt}
            {hiredSalary}
            {hiredSignOn}
            {fees}
            {feesAdjustment}
            <div className="info-label">Recruiter Percentage</div>
            <div className="info-value">{candidate.recruiterPercentage}</div>
            <div className="info-label">AM Percentage</div>
            <div className="info-value">{candidate.accountManagerPercentage}</div>
            {displayRecruiterCommission}
            {displayRecruiterCommissionPaidAt}
            {displayAMCommission}
            {displayAMCommissionPaidAt}
        </div>
    );
};
