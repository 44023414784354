import moment from 'moment';

import { getClosestWorkingDay } from './timestamp';

export interface Duration {
    start: number;
    end: number;
    baselineStart: number;
    baselineEnd: number;
    period: string;
    baselinePeriod: string;
}

interface DurationOpt extends Duration {
    label: string;
}

export interface Periods {
    days: number;
    weeks: number;
    fortnights?: number;
    months: number;
    quarters: number;
    years: number;
    allTime: boolean;
}

const defaultPeriods: Periods = {
    allTime: true,
    days: 0,
    fortnights: 0,
    months: 2,
    quarters: 4,
    weeks: 0,
    years: 2
};

const clampTime = (ts: number) => {
    if (ts > Date.now()) {
        return moment().endOf('day').valueOf();
    }
    return ts;
};

export const defaultDateFormat = 'MMM DD, YYYY';

const dayPeriodDesc = (start: number) =>
    moment(start).startOf('day').valueOf() === moment().startOf('day').valueOf()
        ? 'Today'
        : moment(start).startOf('day').valueOf() === moment().subtract(1, 'day').startOf('day').valueOf()
        ? 'Yesterday'
        : moment(start).format('dddd');

export const getDurationOptions = ({ days, fortnights, weeks, months, quarters, years, allTime } = defaultPeriods) => {
    const options: DurationOpt[] = [];

    let lastDayAdded = 0;
    for (let day = 0; day < days; day++) {
        const current = getClosestWorkingDay(moment().subtract(lastDayAdded, 'days').valueOf());
        const end = clampTime(moment(current).endOf('day').valueOf());
        const start = clampTime(moment(current).startOf('day').valueOf());
        lastDayAdded = moment().diff(moment(start), 'days') + 1;
        const baseline = getClosestWorkingDay(moment().subtract(lastDayAdded, 'days').valueOf());
        const baselineEnd = moment(baseline).endOf('day').valueOf();
        const baselineStart = moment(baseline).startOf('day').valueOf();
        const label = dayPeriodDesc(start);
        const period = label;
        const baselinePeriod = dayPeriodDesc(baselineStart);
        options.push({ start, end, baselineStart, baselineEnd, label, period, baselinePeriod });
    }

    for (let week = 0; week < weeks; week++) {
        const end = clampTime(moment().subtract(week, 'weeks').endOf('week').valueOf());
        const start = clampTime(moment().subtract(week, 'weeks').startOf('week').valueOf());
        const baselineStart = moment(start).subtract(1, 'weeks').valueOf();
        const baselineEnd = moment(end).subtract(1, 'weeks').valueOf();
        const label =
            week === 0
                ? 'This Week'
                : week === 1
                ? 'Previous Week'
                : `${moment(start).format(defaultDateFormat)} - ${moment(end).format(defaultDateFormat)}`;
        const period = label;
        const baselinePeriod = `${moment(baselineStart).format(defaultDateFormat)} - ${moment(baselineEnd).format(
            defaultDateFormat
        )}`;
        options.push({ start, end, baselineStart, baselineEnd, label, period, baselinePeriod });
    }

    for (let fortnight = 0; fortnight < (fortnights ?? 0); fortnight++) {
        const end = clampTime(
            moment()
                .subtract(fortnight * 2, 'weeks')
                .endOf('day')
                .valueOf()
        );
        const start = clampTime(moment(end).subtract(2, 'weeks').valueOf());
        const baselineStart = moment(start).subtract(2, 'weeks').valueOf();
        const baselineEnd = moment(end).subtract(2, 'weeks').valueOf();
        const label =
            fortnight === 0
                ? 'Last 14 days'
                : `${moment(start).format(defaultDateFormat)} - ${moment(end).format(defaultDateFormat)}`;
        const period = label;
        const baselinePeriod =
            fortnight === 0
                ? 'Previous 14 days'
                : `${moment(baselineStart).format(defaultDateFormat)} - ${moment(baselineEnd).format(
                      defaultDateFormat
                  )}`;
        options.push({ start, end, baselineStart, baselineEnd, label, period, baselinePeriod });
    }

    for (let month = 0; month < months; month++) {
        const end = clampTime(moment().subtract(month, 'months').endOf('month').valueOf());
        const start = clampTime(moment().subtract(month, 'months').startOf('month').valueOf());
        const label = moment().subtract(month, 'months').format('MMMM YYYY');
        const baselineEnd = moment(end).subtract(1, 'month').valueOf();
        const baselineStart = moment(start).subtract(1, 'month').valueOf();
        const period = label;
        const baselinePeriod = `${moment(baselineStart).format(defaultDateFormat)} - ${moment(baselineEnd).format(
            defaultDateFormat
        )}`;
        options.push({ start, end, baselineStart, baselineEnd, label, period, baselinePeriod });
    }

    for (let quarter = 0; quarter < quarters; quarter++) {
        const end = clampTime(moment().subtract(quarter, 'quarter').endOf('quarter').valueOf());
        const start = clampTime(moment().subtract(quarter, 'quarter').startOf('quarter').valueOf());
        const label = moment().subtract(quarter, 'quarter').format('[Q]Q YYYY');
        const baselineEnd = moment(end).subtract(1, 'quarter').valueOf();
        const baselineStart = moment(start).subtract(1, 'quarter').valueOf();
        const period = label;
        const baselinePeriod = `${moment(baselineStart).format(defaultDateFormat)} - ${moment(baselineEnd).format(
            defaultDateFormat
        )}`;
        options.push({ start, end, baselineStart, baselineEnd, label, period, baselinePeriod });
    }

    for (let year = 0; year < years; year++) {
        const end = clampTime(moment().subtract(year, 'year').endOf('year').valueOf());
        const start = clampTime(moment().subtract(year, 'year').startOf('year').valueOf());
        const label = moment().subtract(year, 'year').format('YYYY');
        const baselineEnd = moment(end).subtract(1, 'year').valueOf();
        const baselineStart = moment(start).subtract(1, 'year').valueOf();
        const period = label;
        const baselinePeriod = `${moment(baselineStart).format(defaultDateFormat)} - ${moment(baselineEnd).format(
            defaultDateFormat
        )}`;
        options.push({ start, end, baselineStart, baselineEnd, label, period, baselinePeriod });
    }

    if (allTime) {
        const end = clampTime(moment().endOf('day').valueOf());
        const start = clampTime(0);
        const label = 'All Time';
        const baselineEnd = 0;
        const baselineStart = 0;
        const period = label;
        const baselinePeriod = '';
        options.push({ start, end, baselineStart, baselineEnd, label, period, baselinePeriod });
    }

    return options;
};
