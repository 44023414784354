import { Delete } from '@material-ui/icons';
import { Avatar, CircularProgress, Divider, IconButton, IconMenu, MenuItem } from 'material-ui';
import { brown500, green300, lightBlue200, purple300, red400, red600, white } from 'material-ui/styles/colors';
import ActionWork from 'material-ui/svg-icons/action/work';
import NotInterested from 'material-ui/svg-icons/av/not-interested';
import Check from 'material-ui/svg-icons/navigation/check';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import SocialPerson from 'material-ui/svg-icons/social/person';
import * as React from 'react';
import { connect } from 'react-redux';

import { Permissions } from 'shared/models/permission';
import { hasRole } from 'shared/models/user';

import { deletePersonContact, getConfirmation, updatePersonContact } from '../actions';
import { Contact, ContactType, RequestErrors, State } from '../state';

const avatarSize = 18;

interface OwnProps {
    contact: Contact;
    personId: string;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    updatePersonContact: (
        personId: string,
        contact: Contact,
        attribute: keyof Contact,
        value: boolean | ContactType
    ) => void;
    deletePersonContact: (personId: string, channel: string, value: string) => void;
    getConfirmation: (onConfirm: () => void, description?: string | JSX.Element, title?: string) => void;
}

type ContactAttributesProps = OwnProps & ConnectedProps & ConnectedDispatch;

class ContactAttributesComponent extends React.Component<ContactAttributesProps, undefined> {
    isDisabled = () => {
        const { contact, pendingRequests, personId } = this.props;
        const updateRequestKey = `contact-update-request-${personId}-${contact.channel}`;
        const deleteRequestKey = `contact-delete-request-${personId}-${contact.channel}-${contact.value}`;
        return (
            (pendingRequests.has(updateRequestKey) && pendingRequests.get(updateRequestKey).isEmpty()) ||
            (pendingRequests.has(deleteRequestKey) && pendingRequests.get(deleteRequestKey).isEmpty())
        );
    };

    handleAttributeSelect = (attribute: keyof Contact) => () => {
        const { contact, personId } = this.props;
        if (!this.isDisabled()) {
            this.props.updatePersonContact(personId, contact, attribute, !contact[attribute]);
        }
    };

    handleContactDelete = () => () => {
        const { personId, contact } = this.props;
        if (!this.isDisabled()) {
            this.props.getConfirmation(
                () => {
                    this.props.deletePersonContact(personId, contact.channel, contact.value);
                },
                `This ${contact.channel} will be removed immediately.`,
                'Confirm'
            );
        }
    };

    handleContactTypeSelect = (contactType: ContactType) => () => {
        const { contact, personId } = this.props;
        if (!this.isDisabled()) {
            this.props.updatePersonContact(
                personId,
                contact,
                'contactType',
                contact.contactType !== contactType ? contactType : ContactType.Unknown
            );
        }
    };

    render() {
        const { contact, userPermissions } = this.props;
        const disabled = this.isDisabled();

        let content: JSX.Element;
        if (disabled) {
            const spinnerSize = 20;
            const spinnerThickness = 2;
            content = <CircularProgress size={spinnerSize} thickness={spinnerThickness} />;
        } else {
            const contactIconBackgroundColor = contact.primary
                ? purple300
                : contact.contactType === ContactType.Personal
                ? lightBlue200
                : brown500;

            const contactIcon = (
                <Avatar
                    size={avatarSize}
                    backgroundColor={contactIconBackgroundColor}
                    color={white}
                    className="avatar-contact-attribute"
                    icon={contact.contactType === ContactType.Personal ? <SocialPerson /> : <ActionWork />}
                />
            );
            const verifiedIcon = (
                <Avatar
                    size={avatarSize}
                    backgroundColor={green300}
                    color={white}
                    className="avatar-contact-attribute"
                    icon={<Check />}
                />
            );
            const invalidIcon = (
                <Avatar
                    size={avatarSize}
                    backgroundColor={red400}
                    color={white}
                    className="avatar-contact-attribute"
                    icon={<NotInterested />}
                />
            );

            const removeIcon = (
                <Avatar
                    size={avatarSize}
                    backgroundColor={red600}
                    color={white}
                    className="avatar-contact-attribute"
                    icon={<Delete />}
                />
            );

            const personIcon = (
                <Avatar
                    size={avatarSize}
                    backgroundColor={lightBlue200}
                    color={white}
                    className="avatar-contact-attribute"
                    icon={<SocialPerson />}
                />
            );

            const workIcon = (
                <Avatar
                    size={avatarSize}
                    backgroundColor={brown500}
                    color={white}
                    className="avatar-contact-attribute"
                    icon={<ActionWork />}
                />
            );

            const button = (
                <IconButton
                    disableTouchRipple={true}
                    style={{ width: 16, height: 16, padding: 0 }}
                    iconStyle={{ height: 16, width: 16 }}
                >
                    <MoreVertIcon className="icon-button-more" />
                </IconButton>
            );

            let primaryMenuItem: JSX.Element;
            if (contact.contactType !== ContactType.Unknown) {
                const primaryContactTypeIcon = (
                    <Avatar
                        size={avatarSize}
                        backgroundColor={purple300}
                        color={white}
                        className="avatar-contact-attribute"
                        icon={contact.contactType === ContactType.Personal ? <SocialPerson /> : <ActionWork />}
                    />
                );
                primaryMenuItem = (
                    <MenuItem
                        primaryText="Primary"
                        insetChildren={true}
                        onClick={this.handleAttributeSelect('primary')}
                        leftAvatar={primaryContactTypeIcon}
                        rightIcon={contact.primary ? <Check /> : null}
                    />
                );
            }

            let removeMenuItem: JSX.Element;
            let divider: JSX.Element;

            if (hasRole(userPermissions, 'contact_editor')) {
                divider = <Divider />;
                removeMenuItem = (
                    <MenuItem
                        primaryText="Remove"
                        insetChildren={true}
                        onClick={this.handleContactDelete()}
                        leftAvatar={removeIcon}
                        disabled={!hasRole(userPermissions, 'contact_editor')}
                    />
                );
            }

            content = (
                <div className="contact-attributes">
                    <div className="list-item-menu-button">
                        <IconMenu
                            iconButtonElement={button}
                            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                            desktop={true}
                        >
                            {primaryMenuItem}
                            <MenuItem
                                primaryText="Personal"
                                insetChildren={true}
                                onClick={this.handleContactTypeSelect(ContactType.Personal)}
                                leftAvatar={personIcon}
                                rightIcon={contact.contactType === ContactType.Personal ? <Check /> : null}
                            />
                            <MenuItem
                                primaryText="Work"
                                insetChildren={true}
                                onClick={this.handleContactTypeSelect(ContactType.Work)}
                                leftAvatar={workIcon}
                                rightIcon={contact.contactType === ContactType.Work ? <Check /> : null}
                            />
                            <MenuItem
                                primaryText="Verified"
                                insetChildren={true}
                                onClick={this.handleAttributeSelect('verified')}
                                leftAvatar={verifiedIcon}
                                rightIcon={contact.verified ? <Check /> : null}
                            />
                            <MenuItem
                                primaryText="Invalid"
                                insetChildren={true}
                                onClick={this.handleAttributeSelect('invalid')}
                                leftAvatar={invalidIcon}
                                rightIcon={contact.invalid ? <Check /> : null}
                            />
                            {divider}
                            {removeMenuItem}
                        </IconMenu>
                    </div>
                    {contact.contactType !== ContactType.Unknown ? contactIcon : null}
                    {contact.verified ? verifiedIcon : null}
                    {contact.invalid ? invalidIcon : null}
                </div>
            );
        }

        return content;
    }
}

const mapStateToProps = (state: State): any => ({
    pendingRequests: state.pendingRequests,
    userPermissions: state.session.userPermissions
});

const mapStateToDispatch = {
    deletePersonContact,
    getConfirmation,
    updatePersonContact
};

export const ContactAttributes = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapStateToDispatch
)(ContactAttributesComponent);
