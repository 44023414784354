import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography
} from '@material-ui/core';
import { Map } from 'immutable';
import { AsYouType, isValidNumber } from 'libphonenumber-js';
import { capitalize } from 'lodash';
import * as React from 'react';
import { HiringManagerData, hiringManagerDefaults } from 'shared/models/client';
import { v4 } from 'uuid';
import { isEmail } from 'validator';

const styles = css`
    .MuiDialogContent-root {
        width: 400px;

        .MuiFormControl-root {
            margin: 15px 0;

            .gender-select {
                .MuiSelect-icon {
                    display: none;
                }
            }
        }
    }
`;

const defaultCountry = 'US';

interface HiringManagerFormProps {
    hiringManager?: HiringManagerData;
    title: string;
    onSave: (hiringManager: HiringManagerData) => void;
    onCancel: () => void;
    clientDomains: string[];
}

interface HiringManagerFormState {
    id: string;
    name: {
        first: string;
        full: string;
        last: string;
    };
    email: string;
    phoneNumber?: string;
    linkedIn?: string;
    gender: 'male' | 'female' | 'other';
    role?: string;
    errors: Map<string, string>;
}

export class HiringManagerForm extends React.Component<HiringManagerFormProps, HiringManagerFormState> {
    constructor(props: HiringManagerFormProps) {
        super(props);
        this.state = Object.assign({}, hiringManagerDefaults, this.props.hiringManager, {
            errors: Map<string, string>()
        });
    }

    checkErrors = () => {
        const { name, email, phoneNumber, linkedIn } = this.state;
        let errors: Map<string, string> = Map();
        if (!name.full) {
            errors = errors.set('name', 'No name given!');
        }
        if (!email || !isEmail(email.trim())) {
            errors = errors.set('email', 'Invalid email!');
        }
        if (phoneNumber && !isValidNumber(phoneNumber, defaultCountry)) {
            errors = errors.set('phoneNumber', 'Invalid phone number!');
        }
        if (linkedIn && linkedIn.replace(/[^a-zA-Z0-9]+/g, '').indexOf('linkedin') === -1) {
            errors = errors.set('linkedIn', 'Invalid LinkedIn link!');
        }
        return errors;
    };

    handleSave = () => {
        const { id, name, email, phoneNumber, linkedIn, gender, role } = this.state;
        const hiringManager: HiringManagerData = {
            email: email.trim()?.toLowerCase(),
            gender,
            id: id ?? v4(),
            linkedIn,
            name,
            phoneNumber,
            role
        };
        const errors = this.checkErrors();
        if (errors.isEmpty()) {
            this.props.onSave(hiringManager);
        } else {
            this.setState({ errors });
        }
    };

    handleChange = (field: keyof HiringManagerData, value: any) => {
        this.setState({ [field]: value } as any);
    };

    handleFieldChange = (field: keyof HiringManagerData) => (event: React.ChangeEvent<HTMLInputElement>) => {
        this.handleChange(field, event.target.value.trim());
    };

    handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            this.handleChange('phoneNumber', event.target.value.trim());
        } else {
            const phoneNumber = new AsYouType(defaultCountry).input(event.target.value);
            this.handleChange('phoneNumber', phoneNumber.trim());
        }
    };

    handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.handleChange('role', event.target.value);
    };

    handleSelectGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.handleChange('gender', event.target.value);
    };

    handleNameChange = (field: keyof HiringManagerData['name']) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = Object.assign({}, this.state.name, { [field]: event.target.value });
        name.full = `${name.first} ${name.last}`;
        this.handleChange('name', name);
    };

    render() {
        const { title } = this.props;
        const { errors, gender, linkedIn, name, phoneNumber, email, role } = this.state;
        const { first: firstName, last: lastName } = name;
        const genderOptions = ['female', 'male', 'other'].map((genderOption) => {
            return (
                <MenuItem value={genderOption} key={genderOption}>
                    {capitalize(genderOption)}
                </MenuItem>
            );
        });
        const actions = [
            <Button key="cancel" onClick={this.props.onCancel}>
                Cancel
            </Button>,
            <Button key="save" onClick={this.handleSave}>
                Save
            </Button>
        ];
        return (
            <Dialog open={true} css={styles}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h5">{title}</Typography>
                </DialogTitle>
                <DialogContent>
                    <div className="hiring-manager-form">
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            className="hiring-manager-form-field"
                            label="First Name"
                            value={firstName}
                            onChange={this.handleNameChange('first')}
                            error={Boolean(errors.get('name', null))}
                            helperText={errors.get('name', null)}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            className="hiring-manager-form-field"
                            label="Last Name"
                            value={lastName}
                            onChange={this.handleNameChange('last')}
                            error={Boolean(errors.get('name', null))}
                            helperText={errors.get('name', null)}
                        />
                        <TextField
                            className="gender-select"
                            label="Gender"
                            select={true}
                            InputLabelProps={{ shrink: true }}
                            value={gender}
                            onChange={this.handleSelectGenderChange}
                        >
                            {genderOptions}
                        </TextField>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            className="hiring-manager-form-field"
                            label="linkedIn"
                            value={linkedIn}
                            onChange={this.handleFieldChange('linkedIn')}
                            error={Boolean(errors.get('name', null))}
                            helperText={errors.get('linkedIn')}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            className="hiring-manager-form-field"
                            label="Email"
                            value={email}
                            onChange={this.handleFieldChange('email')}
                            error={Boolean(errors.get('name', null))}
                            helperText={errors.get('email', null)}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            className="hiring-manager-form-field"
                            label="Phone"
                            value={phoneNumber}
                            onChange={this.handlePhoneChange}
                            error={Boolean(errors.get('name', null))}
                            helperText={errors.get('phoneNumber')}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            className="hiring-manager-form-field"
                            label="Role"
                            value={role}
                            onChange={this.handleRoleChange}
                            error={Boolean(errors.get('name', null))}
                            helperText={errors.get('role')}
                        />
                    </div>
                </DialogContent>
                <DialogActions>{actions}</DialogActions>
            </Dialog>
        );
    }
}
