import localForage from 'localforage';
import { Cancelable, debounce } from 'lodash';

import DraftWorker from '../lib/draft.worker';
import { logger } from './logger';

const draftExpirationMs = 604800000; // expire after 1 week
const debounceMs = 2000;

const debouncedFunctions = new Map<string, (<T>(data: T) => Promise<void>) & Cancelable>();
const pendingRequestsMap = new Map<number, () => void>();
let requestId = 0;

const draftWorker = new DraftWorker();

draftWorker.onmessage = (event: MessageEvent) => {
    const id = event.data.requestId;
    const resolver = pendingRequestsMap.get(id);
    if (resolver) {
        resolver();
        pendingRequestsMap.delete(id);
    } else {
        logger.debug('could not find pending request for id', id);
    }
};

const saveDraft = async <T>(draftId: string, data: T): Promise<void> => {
    return new Promise((resolve) => {
        const id = requestId++;
        draftWorker.postMessage({
            key: draftId,
            requestId: id,
            value: { data, expireAt: Date.now() + draftExpirationMs }
        });
        pendingRequestsMap.set(id, resolve);
    });
};

const getDraftDebouncedFunction = (draftId: string) => {
    if (debouncedFunctions.has(draftId)) {
        return debouncedFunctions.get(draftId);
    } else {
        const wrapper = <T>(data: T) => saveDraft(draftId, data);
        const func = debounce(wrapper, debounceMs, { leading: true });
        debouncedFunctions.set(draftId, func);
        return func;
    }
};

export async function updateDraft<T>(draftId: string, data: T) {
    const func = getDraftDebouncedFunction(draftId);
    return func(data);
}

export async function deleteDraft(draftId: string) {
    const func = getDraftDebouncedFunction(draftId);
    func.cancel();
    debouncedFunctions.delete(draftId);
    return localForage.removeItem(draftId);
}

export async function getDraft<T>(draftId: string): Promise<T> {
    const value = await localForage.getItem<{ data: T; expireAt: number }>(draftId);
    if (value && (value.expireAt === -1 || value.expireAt > Date.now())) {
        return value.data;
    } else {
        return null;
    }
}

export async function deleteExpiredDrafts() {
    const keys = await localForage.keys();
    for (const key of keys) {
        // fetching will prune the expired keys
        await getDraft(key);
    }
}
