import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Divider, Menu, MenuItem } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { CLIENT_WITH_JOBS, ClientWithJobs } from '../graphql/queries/clients';
import { JOB_TITLE, JobTitleData } from '../graphql/queries/job';

const styles = css`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
`;

const menuStyles = css`
    .menu-item {
        display: inline-flex;
        align-items: center;
    }
`;

export const ClientJobTitle: React.FC<{ clientId: string; jobId?: string }> = ({ clientId, jobId }) => {
    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [redirectTo, setRedirectTo] = React.useState<string>(undefined);

    const { data: clientData } = useQuery<{ client: ClientWithJobs }, { id: string }>(CLIENT_WITH_JOBS, {
        variables: { id: clientId }
    });
    const { data: jobData } = useQuery<{ job: JobTitleData }, { jobId: string }>(JOB_TITLE, {
        skip: !jobId,
        variables: { jobId }
    });

    React.useEffect(() => {
        if (redirectTo) {
            setRedirectTo(undefined);
        }
    }, [redirectTo]);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setAnchor(event.currentTarget);
        setMenuOpen(true);
    };

    const handleMenuRequestClose = () => {
        setAnchor(null);
        setMenuOpen(false);
    };

    const handleJobClick = (id: string) => () => {
        handleMenuRequestClose();
        if (jobId !== id) {
            const jobPathMatch = window.location.pathname.match(/^\/job\/.*\/(.*)$/i);
            const clientPathMatch = window.location.pathname.match(/^\/client\/.*\/(.*)/i);
            const suffix =
                jobPathMatch?.[1] === 'board' || clientPathMatch?.[1] === 'candidates'
                    ? id === undefined
                        ? 'candidates'
                        : 'board'
                    : jobPathMatch?.[1] === 'searches'
                    ? id === undefined
                        ? 'candidates'
                        : 'searches'
                    : jobPathMatch?.[1] ?? clientPathMatch?.[1];
            const prefix = id === undefined ? `/client/${clientId}/` : `/job/${id}/`;
            setRedirectTo(`${prefix}${suffix}`);
        }
    };

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    const jobOptions =
        clientData?.client.jobs.map((j) => (
            <MenuItem key={j.id} onClick={handleJobClick(j.id)}>
                <div className="menu-item">
                    {clientData?.client.name} <ChevronRight />
                    {j.title}
                </div>
            </MenuItem>
        )) ?? [];

    const jobTitle =
        jobId && jobData ? (
            <>
                <ChevronRight />
                <div className="job-title">{jobData?.job.title}</div>
            </>
        ) : null;

    return (
        <>
            <div css={styles} onClick={handleClick}>
                <div className="client-name">{clientData?.client.name}</div>
                {jobTitle}
            </div>
            <Menu
                anchorEl={anchor}
                open={menuOpen}
                onClose={handleMenuRequestClose}
                css={menuStyles}
                style={{ zIndex: 2000 }}
            >
                <MenuItem onClick={handleJobClick(undefined)}>
                    <div className="menu-item">
                        {clientData?.client.name} <ChevronRight /> All Jobs
                    </div>
                </MenuItem>
                <Divider />
                {jobOptions}
            </Menu>
        </>
    );
};
