import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Avatar,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Menu,
    MenuItem,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { CheckCircleOutline, Snooze } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { ReminderData } from 'shared/models/reminder';
import { interviewTime, timeRelativeDay } from '../../common/timestamp';
import { config } from '../../config';

import { UPDATE_REMINDER, UserReminder } from '../../graphql/queries/reminders';
import { useSlides } from '../../hooks/use-candidate-slides';
import { useNotificationsData } from '../../hooks/use-notifications-data';
import { usePageDialog } from '../../hooks/use-page-dialog';
import { useSession } from '../../hooks/use-session';
import { useSnackbar } from '../../hooks/use-snackbar';
import { useTimepicker } from '../../hooks/use-time-picker';
import { useUserContext } from '../../hooks/use-user-context';
import { ClientSettings } from '../client-settings';
import { HomePagePanel } from './home-page-panel';

const styles = (theme: Theme) => css`
    &.MuiListItem-root {
        align-items: flex-start;
        padding: 16px;
        position: relative;
    }

    .MuiAvatar-root,
    .MuiListItemText-root {
        cursor: pointer;
    }

    .caption {
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    .reminder-actions {
        display: flex;
        position: absolute;
        bottom: 18px;
        right: 12px;
        opacity: 0;

        .MuiIconButton-root {
            margin-left: 5px;
        }
    }

    &:hover {
        background: rgba(0, 0, 0, 0.025);
        .reminder-actions {
            opacity: 1;
        }
    }

    .reminder-text {
        border-left: 2px solid ${theme.palette.divider};
        padding-left: 8px;
        margin-top: 4px;
        font-style: italic;
    }

    .MuiListItemText-multiline {
        margin: 0;

        .MuiTypography-h6 {
            line-height: 1;
            margin-bottom: 4px;
        }
    }
`;

const Content: React.FC<{ rows: JSX.Element[] }> = ({ rows }) => <List disablePadding={true}>{rows}</List>;

const ReminderComponent: React.FC<{ data: UserReminder; list: UserReminder[] }> = ({ data, list }) => {
    const theme = useTheme();
    const { setList } = useSlides();
    const { user } = useUserContext();
    const { user: sessionUser } = useSession();
    const { setDialog } = usePageDialog();
    const { setSnackbar } = useSnackbar();
    const { getCustomTime } = useTimepicker();
    const [updateReminder] = useMutation<{}, { id: string; updates: Partial<ReminderData> }>(UPDATE_REMINDER);
    const [snoozeMenuAnchor, setSnoozeMenuAnchor] = React.useState<HTMLElement>(null);

    const handleSelect = () => {
        if (data.person) {
            const selected = {
                jobId: data.job?.id,
                personId: data.person?.id
            };
            const ids = list
                .filter((v) => v.person)
                .map((c) => {
                    return {
                        jobId: c.job?.id,
                        personId: c.person?.id
                    };
                });
            setList(ids, selected);
        } else if (data.client) {
            setDialog(<ClientSettings clientId={data.client.id} />, `/client/${data.client.id}/edit`);
        }
    };

    const handleMarkComplete = async () => {
        setSnackbar('Marking reminder complete');
        await updateReminder({ variables: { id: data.id, updates: { completed: true } } });
        const handleUndo = async () => {
            await updateReminder({ variables: { id: data.id, updates: { completed: false } } });
            setSnackbar('Reminder marked pending');
        };
        const undoButton = (
            <Button onClick={handleUndo} style={{ color: 'white' }}>
                Undo
            </Button>
        );
        setSnackbar('Reminder marked completed', undoButton);
    };

    const handleSnoozeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSnoozeMenuAnchor(event.currentTarget);
    };

    const handleCloseSnoozeMenu = () => setSnoozeMenuAnchor(null);

    const handleSnooze = (updatedRemindAt: number) => async () => {
        setSnoozeMenuAnchor(null);
        setSnackbar('Snoozing reminder');
        await updateReminder({ variables: { id: data.id, updates: { remindAt: updatedRemindAt } } });
        const handleUndo = async () => {
            await updateReminder({ variables: { id: data.id, updates: { remindAt: data.remindAt } } });
            setSnackbar('Reminder snooze canceled');
        };
        const undoButton = (
            <Button onClick={handleUndo} style={{ color: 'white' }}>
                Undo
            </Button>
        );
        setSnackbar(`Reminder snoozed till ${timeRelativeDay(updatedRemindAt)}`, undoButton);
    };

    const handleSnoozeCustom = () => {
        setSnoozeMenuAnchor(null);
        getCustomTime({
            buttonLabel: 'Snooze',
            onSelect: (ts: number) => {
                handleSnooze(ts)();
            }
        });
    };

    const { client, person, job, remindAt, text } = data;
    const primaryText = person ? person.name : client.name;
    const primary = <Typography variant="h6">{primaryText}</Typography>;
    const secondaryPrefix = job ? (
        <div>
            {job.client.name} - {job.title}
        </div>
    ) : null;
    const secondary = (
        <Typography variant="body2" color="textSecondary" component="div">
            {secondaryPrefix}
            <div className="reminder-text">{text}</div>
        </Typography>
    );
    const avatar = person?.profilePicUrl ? (
        <Avatar src={`${config.AssetHost}/${person.profilePicUrl}`} onClick={handleSelect} />
    ) : (
        <Avatar onClick={handleSelect}>{primaryText.charAt(0).toLocaleUpperCase()}</Avatar>
    );

    // tslint:disable:no-magic-numbers
    const snoozeMenu = snoozeMenuAnchor ? (
        <Menu anchorEl={snoozeMenuAnchor} onClose={handleCloseSnoozeMenu} open={true}>
            <MenuItem dense={true} onClick={handleSnooze(moment().add(1, 'hour').valueOf())}>
                1 hour
            </MenuItem>
            <MenuItem dense={true} onClick={handleSnooze(moment().add(3, 'hours').valueOf())}>
                3 hours
            </MenuItem>
            <MenuItem dense={true} onClick={handleSnooze(moment().add(1, 'day').valueOf())}>
                1 day
            </MenuItem>
            <MenuItem dense={true} onClick={handleSnoozeCustom}>
                Custom
            </MenuItem>
        </Menu>
    ) : null;
    // tslint:enable:no-magic-numbers

    const actions =
        user.id === sessionUser.id ? (
            <div className="reminder-actions">
                <Tooltip title="Snooze Reminder">
                    <IconButton size="small" onClick={handleSnoozeClick}>
                        <Snooze fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Mark Complete">
                    <IconButton size="small" onClick={handleMarkComplete}>
                        <CheckCircleOutline fontSize="small" />
                    </IconButton>
                </Tooltip>
                {snoozeMenu}
            </div>
        ) : null;

    return (
        <ListItem key={data.id} css={styles(theme)}>
            <ListItemAvatar>{avatar}</ListItemAvatar>
            <ListItemText onClick={handleSelect} disableTypography={true} primary={primary} secondary={secondary} />
            <div>
                <Typography color="textSecondary" noWrap={true} variant="caption" className="caption" component="div">
                    <span className="left-pad">{interviewTime(remindAt)}</span>
                </Typography>
                {actions}
            </div>
        </ListItem>
    );
};

export const Reminders: React.FC = () => {
    const { reminders } = useNotificationsData();

    return (
        <HomePagePanel
            RowComponent={ReminderComponent}
            ContentComponent={Content}
            records={reminders}
            title="Reminders"
            containerClass="list"
            collapseKeyId="reminders"
        />
    );
};
