import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Button } from '@material-ui/core';
import { LinkedIn } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { uniq } from 'lodash';
import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { getLISearchKeywords } from 'shared/models/profile';

import { PersonProfilePreview } from '../components/person-profile-preview';
import { Spinner } from '../core-ui/spinner';
import { SEARCH_PROFILES_SCORE_RESULTS, SearchResultScore } from '../graphql/queries/profile-score';
import { GET_PROFILES, ProfileRecord } from '../graphql/queries/profiles';
import { useSlides } from '../hooks/use-candidate-slides';
import { useCodeModal } from '../hooks/use-code-modal';
import { useProfileAdornments } from '../hooks/use-profile-adornment';
import { SearchResultsHeader } from './search-results-header';
import { useSearch } from './use-search';
import { useSearchResultProfileAdornments } from './use-search-result-profile-adornments';

const pageSize = 20;
const cssTransitionTimeout = 500;

const resultsStyles = css`
    .fade-scale-enter {
        opacity: 0;
        transform-origin: top;
        transform: scaleY(0);
    }

    .fade-scale-enter-active {
        opacity: 1;
        transform-origin: top;
        transform: scaleY(1);
        transition: opacity ${cssTransitionTimeout / 2}ms, transform ${cssTransitionTimeout}ms;
    }

    .fade-scale-exit {
        opacity: 1;
        transform-origin: top;
    }

    .fade-scale-exit-active {
        opacity: 0;
        transform-origin: top;
        transform: scaleY(0);
        transition: opacity ${cssTransitionTimeout / 2}ms, transform ${cssTransitionTimeout}ms;
    }
`;

export const SearchResults: React.FC<{ initialSelectedPersonId?: string }> = ({ initialSelectedPersonId }) => {
    const {
        data: search,
        searchResults,
        isPreviewOutdated,
        preview,
        searchInitialized,
        getSortedResults
    } = useSearch();
    const [currentPage, setPage] = React.useState(0);
    const { setList } = useSlides();
    const [fetching, setFetching] = React.useState<string[]>([]);
    const [notFoundProfiles, setNotFoundProfiles] = React.useState<string[]>([]);
    const [fetchProfiles, { loading: loadingProfiles }] = useLazyQuery<
        { profiles: ProfileRecord[] },
        { personIds: string[] }
    >(GET_PROFILES);
    const [fetchResultScores] = useLazyQuery<{ scores: SearchResultScore[] }>(SEARCH_PROFILES_SCORE_RESULTS);
    const [profiles, setProfiles] = React.useState<ProfileRecord[]>([]);
    const [resultScores, setResultScores] = React.useState<SearchResultScore[]>([]);
    const [selectedPersonId, setSelectedPersonId] = React.useState<string | null>(null);
    const [initialSelectedPersonIdSet, setInitialSelectedPersonIdSet] = React.useState(false);
    const scrollTop = React.useRef(null);
    const { showCodeModal } = useCodeModal();
    const { setAdornmentGenerator } = useProfileAdornments();

    const error = searchResults?.error;

    const sortedResults = getSortedResults();
    const resultsPage = sortedResults.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

    const getProfileAdornments = useSearchResultProfileAdornments(profiles, resultScores, setSelectedPersonId);

    React.useEffect(() => {
        setAdornmentGenerator(getProfileAdornments);
    }, [profiles, resultScores, setSelectedPersonId]);

    React.useEffect(() => {
        return () => {
            setAdornmentGenerator(null);
        };
    }, []);

    React.useEffect(() => {
        const personIds = resultsPage
            .map((r) => r.personId)
            .filter(
                (id) =>
                    profiles.find((p) => p.person.id === id) === undefined &&
                    !notFoundProfiles.includes(id) &&
                    !fetching.includes(id) &&
                    id
            );
        if (personIds?.length) {
            setFetching((old) => uniq([...old, ...personIds]));
            fetchProfiles({ variables: { personIds } }).then((result) => {
                setFetching((old) => uniq(old.filter((id) => !personIds.includes(id))));
                const newProfiles = result.data?.profiles;
                const missing = personIds.filter((id) => !newProfiles?.find((p) => p.person.id === id));
                if (missing.length) {
                    setNotFoundProfiles((old) => uniq([...old, ...missing]));
                }
                setProfiles((oldProfiles) => {
                    const prunedOldProfiles = oldProfiles.filter(
                        (p) => !newProfiles.find((np) => np.person.id === p.person.id)
                    );
                    return [...prunedOldProfiles, ...newProfiles];
                });
            });
        }
    }, [resultsPage, profiles, fetching, notFoundProfiles]);

    React.useEffect(() => {
        if (search?.searchProfilesScoreId) {
            const missingPersonIds = profiles
                .filter(
                    (p) =>
                        !resultScores.find(
                            (r) =>
                                r.personId === p.person.id && r.searchProfilesScoreId === search.searchProfilesScoreId
                        )
                )
                .map((p) => p.person.id);
            if (missingPersonIds.length) {
                fetchResultScores({
                    variables: { scoreId: search.searchProfilesScoreId, personIds: missingPersonIds }
                }).then((result) => {
                    setResultScores((old) => [...old, ...result.data?.scores]);
                });
            }
        }
    }, [search?.searchProfilesScoreId, profiles]);

    React.useEffect(() => {
        if (selectedPersonId) {
            const personIndex = sortedResults.findIndex((r) => r.personId === selectedPersonId);
            const pageForPerson = Math.floor(personIndex / pageSize);
            if (pageForPerson !== currentPage) {
                setPage(pageForPerson);
            }
            if (scrollTop?.current) {
                scrollTop.current.scrollIntoView({ inline: 'start', behavior: 'smooth' });
            }
        }
    }, [selectedPersonId]);

    React.useEffect(() => {
        setPage(0);
    }, [search?.resultsSort, search?.searchProfilesScoreId]);

    React.useEffect(() => {
        if (initialSelectedPersonId && sortedResults?.length && !initialSelectedPersonIdSet) {
            setInitialSelectedPersonIdSet(true);
            handleClick(initialSelectedPersonId)();
        }
    }, [initialSelectedPersonId, sortedResults, initialSelectedPersonIdSet]);

    const handlePageChange = (_1: any, newPage: number) => {
        setPage(newPage - 1);
    };

    const handleNavigate = (index: number) => {
        const list = sortedResults.filter((r) => !!r?.personId);
        const personId = list[index]?.personId;
        if (personId) {
            setSelectedPersonId(personId);
        } else {
            setSelectedPersonId(null);
        }
    };

    const handleClick = (personId: string) => () => {
        const list = sortedResults
            .map((r) => (r?.personId ? { personId: r.personId, selectedTab: 'profile' } : null))
            .filter((r) => !!r);
        setSelectedPersonId(personId);
        setList(list, { personId, selectedTab: 'profile' }, undefined, undefined, handleNavigate);
    };

    const handleGetLinkedinKeywords = () => {
        const list = resultsPage
            .map((r) => {
                const profile = profiles.find((p) => p.person.id === r.personId);
                return profile?.content;
            })
            .filter((p) => !!p);
        if (list.length) {
            const result = getLISearchKeywords(list);
            showCodeModal('Keywords', result);
        }
    };

    const pageCount = Math.ceil(sortedResults?.length / pageSize);

    let content: JSX.Element | JSX.Element[];

    if (resultsPage.length > 0) {
        const list = resultsPage
            .filter((r) => !notFoundProfiles.includes(r.personId))
            .map((r) => {
                const profile = profiles.find((p) => p.person.id === r.personId);
                const scrollTarget = r.personId === selectedPersonId ? <div ref={scrollTop} /> : null;
                return (
                    <CSSTransition key={r.personId} timeout={cssTransitionTimeout} classNames="fade-scale">
                        <div className="profile-preview" key={r.personId}>
                            {scrollTarget}
                            <div className="search-result-preview">
                                <PersonProfilePreview
                                    person={profile?.person}
                                    profile={profile?.content}
                                    viewedAt={profile?.viewedAt}
                                    onClick={handleClick(r.personId)}
                                    key={profile?.person?.id}
                                />
                            </div>
                        </div>
                    </CSSTransition>
                );
            });
        content = <TransitionGroup>{list}</TransitionGroup>;
    }

    const pagination =
        sortedResults?.length > 0 && pageCount > 1 ? (
            <Pagination count={pageCount} page={currentPage + 1} onChange={handlePageChange} className="pagination" />
        ) : null;

    if (!searchInitialized && (!search?.id || !preview) && !searchResults) {
        return null;
    }

    if (error) {
        return (
            <div className="job-search-results">
                <div className="search-results">
                    <div className="search-results-content">
                        <div className="search-error">{error}</div>
                    </div>
                </div>
            </div>
        );
    }

    if (!searchResults) {
        return (
            <div className="job-search-results">
                <div className="search-results">
                    <Spinner />
                </div>
            </div>
        );
    }

    const disabled = !searchInitialized && (isPreviewOutdated || !preview.fetched);

    return (
        <div className="job-search-results">
            <div className={`search-results ${disabled ? 'out-of-date' : ''}`}>
                <SearchResultsHeader />
                <div className="search-results-content" css={resultsStyles}>
                    {content}
                </div>
                <div className="search-results-footer">
                    <div className="li-query-button">
                        <Button
                            startIcon={<LinkedIn />}
                            size="small"
                            onClick={handleGetLinkedinKeywords}
                            disabled={loadingProfiles}
                        >
                            Keywords
                        </Button>
                    </div>
                    {pagination}
                </div>
            </div>
        </div>
    );
};
