export const sidebarContainerPageStyles = [
    {
        selector: '.profile.profile-slidein__profile',
        styles: 'width: 100vw; max-width: 2048px; transition: width 0.3s ease, max-width 0.3s ease;'
    },
    {
        selector: '.pagination-header__top-bar',
        styles: 'width: 100vw; max-width: 2048px; transition: width 0.3s ease, max-width 0.3s ease;'
    },
    {
        selector: '.profile__right-rail-container',
        styles: 'width: auto; min-width: 480px;'
    },
    {
        selector: 'main.profile-standalone__container .profile-standalone__profile',
        styles:
            'display: flex; width: 100vw; max-width: 2048px; padding: 0 20px; transition: width 0.3s ease, max-width 0.3s ease;'
    },
    {
        selector: '.profile-standalone__right-rail-container',
        styles: 'width: auto; min-width: 480px;'
    }
];

export const sidebarContainerStyles = `
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 2px 3px;
    flex: 0 0 auto;
    height: calc(100vh - 75px);
    margin-top: 1px;
    position: sticky;
    top: 0px;
    width: 380px;
    z-index: 140000;
`;
