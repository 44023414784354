import { MenuItem, TextField, TextFieldProps } from '@material-ui/core';
import { Map } from 'immutable';
import * as _ from 'lodash';
import * as React from 'react';

import { UserData } from 'shared/models/user';

import { withUsers } from '../hocs/with-users';

type UserSelectorProps = Pick<
    TextFieldProps,
    'label' | 'fullWidth' | 'helperText' | 'error' | 'disabled' | 'InputProps' | 'style'
> & {
    onSelect: (val: string | string[]) => void;
    value: string | string[];
    userFilter?: (user: UserData) => boolean;
    multiple?: boolean;
    includeNoneOpt?: boolean;
};

interface ConnectedProps {
    users: Map<string, UserData>;
}

type UserSelectorAllProps = UserSelectorProps & ConnectedProps;

const deselectValue = 'none';

class UserSelectorComponent extends React.Component<UserSelectorAllProps, undefined> {
    handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const val =
            event.target.value === deselectValue
                ? null
                : this.props.includeNoneOpt
                ? event.target.value
                : event.target.value !== null
                ? event.target.value
                : '';
        this.props.onSelect(val);
    };

    getFilteredUsers = () => {
        const { userFilter, users, value } = this.props;
        if (!userFilter) {
            return users.valueSeq();
        } else {
            return users.valueSeq().filter((u) => {
                if (userFilter(u)) {
                    return true;
                } else {
                    return u.id === value || (Array.isArray(value) && value.indexOf(u.id) !== -1);
                }
            });
        }
    };

    render() {
        const { value } = this.props;
        const usersMenu = this.getFilteredUsers()
            .sort((a, b) => a.name.full.localeCompare(b.name.full))
            .map((user) => {
                return (
                    <MenuItem value={user.id} key={user.id} selected={user.id === value}>
                        {user.name.full}
                    </MenuItem>
                );
            });
        // SelectField passes through props to DOM elements
        // filter out the props that are not needed by the DOM
        const selectFieldProps = _.pickBy(
            this.props,
            (_1, k: keyof UserSelectorProps) =>
                ['users', 'listsState', 'fetchUsers', 'userFilter', 'includeNoneOpt'].indexOf(k) === -1
        );

        const deselectOption = this.props.includeNoneOpt ? (
            <MenuItem value={deselectValue} selected={null === value}>
                None
            </MenuItem>
        ) : null;

        return (
            <TextField select={true} onChange={this.handleSelect} {...selectFieldProps}>
                {deselectOption}
                {usersMenu}
            </TextField>
        );
    }
}

export const UserSelector = withUsers<UserSelectorProps>(UserSelectorComponent);
