import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import { Button } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { FormContext, FormContextProps } from '../components/form-context';
import { JobFormClient } from '../components/job-form-client';
import { JobFormContract } from '../components/job-form-contract';
import { JobFormGeneral } from '../components/job-form-general';
import { JobFormPanel } from '../components/job-form-panel';
import { CREATE_JOB } from '../graphql/queries/job-form';
import { useModal } from '../hooks/use-modal';
import { useSnackbar } from '../hooks/use-snackbar';
import { JobType } from '../state';
import { FieldPaths } from '../types/field-paths';

const styles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    padding-bottom: 20px;
    overflow-y: auto;

    .form-main {
        width: 960px;
        margin: 0 auto;
    }

    .form-action-buttons {
        display: flex;
        width: 960px;
        justify-content: flex-end;

        .MuiButton-root {
            margin-left: 10px;
        }
    }
`;

interface NewJobData {
    accountManagerId: string;
    accountManagerPercentage: number;
    assignee: string;
    billingInfo: { prepayment: { invoiceSentAt: number; invoiceAmount: number } };
    clientId: string;
    discipline: string;
    jobType: JobType;
    placementFees: string;
    recruiterPercentage: number;
    title: string;
}

const jobDefaults: NewJobData = {
    accountManagerId: null,
    accountManagerPercentage: 5,
    assignee: null,
    billingInfo: null,
    clientId: null,
    discipline: null,
    jobType: null,
    placementFees: null,
    recruiterPercentage: 10,
    title: ''
};

export const NewJob: React.FC = () => {
    const [data, setData] = React.useState<NewJobData>(jobDefaults);
    const [isDirty, setIsDirty] = React.useState(false);
    const [errors, setErrors] = React.useState<Map<FieldPaths<NewJobData>, string>>(new Map());
    const [createJob, { loading }] = useMutation<{ job: { id: string } }, { job: NewJobData }>(CREATE_JOB);
    const { setSnackbar } = useSnackbar();
    const { getConfirmation } = useModal();
    const [redirect, setRedirect] = React.useState<string | null>(null);

    const handleCancel = () => {
        if (isDirty) {
            getConfirmation(() => setRedirect('/'), 'Are you sure you want to discard changes?', 'Discard changes');
        } else {
            setRedirect('/');
        }
    };

    const handleChange = (updated: Partial<NewJobData>) => {
        setIsDirty(true);
        setData((prev) => ({ ...prev, ...updated }));
    };

    const handleFieldChange = <K extends keyof NewJobData>(key: K) => (val: NewJobData[K]) => {
        handleChange({ [key]: val });
    };

    const validateFieldExists = <K extends keyof NewJobData>(key: K) => (val: NewJobData[K]) => {
        if ((typeof val === 'string' && val.trim().length === 0) || val === null || val === undefined) {
            setErrors((prev) => {
                const next = new Map(prev);
                next.set(key, 'Required');
                return next;
            });
            return false;
        } else {
            setErrors((prev) => {
                const next = new Map(prev);
                next.delete(key);
                return next;
            });
            return true;
        }
    };

    const handleSetError = (key: FieldPaths<NewJobData>, message: string) => {
        setErrors((prev) => {
            const next = new Map(prev);
            if (message) {
                next.set(key, message);
            } else {
                next.delete(key);
            }
            return next;
        });
    };

    const validateForm = () => {
        const fields: Array<keyof NewJobData> = [
            'title',
            'clientId',
            'discipline',
            'jobType',
            'accountManagerId',
            'assignee',
            'placementFees'
        ];
        let isValid = true;
        fields.forEach((field) => {
            isValid = validateFieldExists(field)(data[field]) && isValid;
        });
        return isValid;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setSnackbar('Creating job...');
            const result = await createJob({ variables: { job: data } });
            setSnackbar('Job created');
            setRedirect(`/job/${result.data.job.id}/edit`);
        } else {
            setSnackbar('Please fill in all required fields');
        }
    };

    const getError = (key: FieldPaths<NewJobData>) => errors.get(key);

    const context: FormContextProps<Partial<NewJobData>> = {
        data,
        disabled: loading,
        getError,
        isDirty,
        onChange: handleChange,
        onFieldChange: handleFieldChange,
        setError: handleSetError,
        validateFieldExists
    };

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <div css={styles}>
            <div className="form-main">
                <FormContext.Provider value={context}>
                    <JobFormPanel>
                        <JobFormClient />
                        <JobFormGeneral />
                        <JobFormContract />
                    </JobFormPanel>
                    <div className="form-action-buttons">
                        <Button disabled={loading} variant="contained" color="default" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            disabled={!isDirty || loading}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </div>
                </FormContext.Provider>
            </div>
        </div>
    );
};
