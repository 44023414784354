import { css } from '@emotion/core';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List as MUIList,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography
} from '@material-ui/core';
import { OrderedMap, Set } from 'immutable';
import * as React from 'react';

import { UserData } from 'shared/models/user';

import { zIndexes } from '../common/css-variables';
import { fullDate } from '../common/timestamp';
import { Loading } from '../core-ui/loading';
import { User as UserName } from '../sfc/user';
import { Client, Job, JobStatus, List } from '../state';

interface JobsListDialogProps {
    sourceJobId: string;
    jobs: OrderedMap<string, Job>;
    clients: List<Client>;
    user: UserData;
    loading: boolean;
    onRequestClose: () => void;

    multiple?: boolean;
    confirmLabel?: string;
    onJobSelect?: (jobIds: string | string[]) => void;
    jobsFilter?: (job: Job) => boolean;
}

type Filter = 'all' | 'discipline' | 'assigned';

const styles = css`
    .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .MuiButton-sizeSmall {
            min-width: 24px;

            .MuiButton-label {
                font-size: 11px;
            }

            &.active {
                font-weight: bold;
            }

            &.inactive {
                opacity: 0.7;
            }
        }
    }

    .MuiDialogContent-root {
        padding: 0;

        .job-info-li {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .assignee-info {
            margin-left: 20px;
            font-size: 13px;
        }

        .MuiList-root {
            padding: 0 0 10px 0;

            .MuiListItem-root {
                padding: 2px 24px;

                .MuiListItemIcon-root .MuiIconButton-root {
                    padding: 5px 9px;
                }
            }
        }
    }
`;

export const JobsListDialog: React.FC<JobsListDialogProps> = (props) => {
    const {
        jobs,
        clients,
        sourceJobId,
        user,
        onRequestClose,
        onJobSelect,
        jobsFilter,
        loading,
        multiple,
        confirmLabel
    } = props;
    const [filter, setFilter] = React.useState<Filter>(sourceJobId ? 'discipline' : 'assigned');
    const [selectedJobs, setSelectedJobs] = React.useState<Set<string>>(Set<string>());
    const [initialFilterCheck, setInitialFilterCheck] = React.useState<boolean>(false);
    const sourceJob = sourceJobId ? jobs.get(sourceJobId) : null;

    const jobFilter = (j: Job) => {
        const filterMatch =
            filter === 'all'
                ? true
                : filter === 'discipline'
                ? sourceJob && j.discipline === sourceJob.discipline
                : filter === 'assigned'
                ? j.assignee === user.id || j.accountManagerId === user.id
                : false;
        return j.status === JobStatus.Active && j.id !== sourceJobId && filterMatch && (!jobsFilter || jobsFilter(j));
    };

    React.useEffect(() => {
        if (!loading && !initialFilterCheck) {
            if (jobs && jobs.filter(jobFilter).isEmpty() && filter !== 'all') {
                setFilter('all');
            }
            setInitialFilterCheck(true);
        }
    }, [jobsFilter, jobs, filter, initialFilterCheck, loading]);

    const handleSetFilter = (f: Filter) => () => {
        setFilter(f);
        setSelectedJobs(Set<string>());
    };

    const handleJobSelect = (jobId: string) => () => {
        if (multiple) {
            if (selectedJobs.has(jobId)) {
                setSelectedJobs(selectedJobs.remove(jobId));
            } else {
                setSelectedJobs(selectedJobs.add(jobId));
            }
        } else {
            if (onJobSelect) {
                onJobSelect(jobId);
            }
        }
    };

    const handleConfirm = () => {
        onJobSelect(selectedJobs.toArray());
    };

    const handleCancel = () => {
        onRequestClose();
    };

    const filters: Filter[] = sourceJob ? ['all', 'assigned', 'discipline'] : ['all', 'assigned'];
    const filterOpts = filters.map((f) => (
        <Button size="small" key={f} onClick={handleSetFilter(f)} className={f === filter ? 'active' : 'inactive'}>
            {f}
        </Button>
    ));

    const jobsList = jobs
        .filter(jobFilter)
        .sortBy((j) => {
            const client = clients.list.get(j.clientId);
            return client.name + ' - ' + j.title;
        })
        .valueSeq()
        .toArray()
        .map((j) => {
            const client = clients.list.get(j.clientId);
            let amInfo;
            let tooltip;
            if (j.accountManagerId) {
                amInfo = (
                    <span>
                        <UserName id={j.accountManagerId} name="first" />
                    </span>
                );
                tooltip = (
                    <span>
                        Account Manager: <UserName id={j.accountManagerId} />
                        <br />
                    </span>
                );
            }
            tooltip = (
                <span>
                    {tooltip}
                    Assignee: <UserName id={j.assignee} />
                    <br />
                    Created: {fullDate(j.createdAt)}
                </span>
            );
            const assignee = j.accountManagerId === j.assignee ? undefined : <UserName id={j.assignee} name="first" />;
            const separator = assignee && amInfo ? '|' : undefined;
            const secondaryText = (
                <Tooltip title={tooltip}>
                    <div className="assignee-info">
                        {amInfo} {separator} {assignee}
                    </div>
                </Tooltip>
            );
            const checked = selectedJobs.has(j.id);
            const checkMark = multiple ? (
                <ListItemIcon>
                    <Checkbox edge="start" checked={checked} tabIndex={-1} disableRipple={true} />
                </ListItemIcon>
            ) : null;
            const primaryText = (
                <div className="job-info-li-main">
                    {client.name} - {j.title}
                </div>
            );
            const listItemContent = (
                <div className="job-info-li">
                    {primaryText}
                    {secondaryText}
                </div>
            );
            return (
                <ListItem key={j.id} onClick={handleJobSelect(j.id)} button={true}>
                    {checkMark}
                    <ListItemText primary={listItemContent} />
                </ListItem>
            );
        });

    const content = loading ? (
        <div style={{ height: 300, position: 'relative' }}>
            <Loading />
        </div>
    ) : (
        <MUIList>{jobsList}</MUIList>
    );

    const disabled = multiple ? selectedJobs.isEmpty() : false;
    const actions = multiple ? (
        <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleConfirm} disabled={disabled}>
                {confirmLabel || 'Confirm'}
            </Button>
        </DialogActions>
    ) : null;

    return (
        <Dialog
            open={true}
            onClose={onRequestClose}
            css={styles}
            maxWidth="md"
            fullWidth={true}
            style={{ zIndex: zIndexes.overEmailDialog }}
        >
            <DialogTitle>
                <div className="dialog-title">
                    <Typography variant="h4" component="div">
                        {multiple ? 'Select Jobs' : 'Select a Job'}
                    </Typography>
                    <div>{filterOpts}</div>
                </div>
            </DialogTitle>
            <DialogContent>{content}</DialogContent>
            {actions}
        </Dialog>
    );
};
