import { useSubscription } from '@apollo/client';
import { css } from '@emotion/core';
import {
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Theme,
    useTheme
} from '@material-ui/core';
import { kebabCase, sortBy } from 'lodash';
import moment from 'moment';
import * as React from 'react';

import { GUARANTEE_PERIOD_DAYS, INVOICE_DUE_DAYS } from 'shared/models/candidate';
import { internalClientIds, placementFeeJobTypes, sourcerJobTypes } from 'shared/models/client';

import { fullDate } from '../common/timestamp';
import { Candidate, CANDIDATE_BILLINGS } from '../graphql/queries/billing';
import { useSlides } from '../hooks/use-candidate-slides';

type Column =
    | 'Client'
    | 'Job'
    | 'Candidate'
    | 'Assignee'
    | 'AM'
    | 'Placed'
    | 'Start'
    | 'Guarantee'
    | 'Invoiced'
    | 'Paid'
    | 'Base'
    | 'Sign-on'
    | 'Contract'
    | 'Fees'
    | 'Pre-paid Fees'
    | 'Recruiter %'
    | 'Recruiter Commission'
    | 'Recruiter Commission Paid'
    | 'AM %'
    | 'AM Commission'
    | 'AM Commission Paid';

const rowsPerPage = 15;

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .selectors {
        flex: 0 0 auto;
        text-align: right;
        margin-bottom: 20px;

        .search-field,
        > .MuiInputBase-root {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            .MuiOutlinedInput-input {
                padding-top: 14px;
                padding-bottom: 14px;
                background: white;
            }
        }
    }

    .table {
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
        background: white;

        .MuiTableCell-root {
            &.client {
                min-width: 120px;
            }

            &.job {
                min-width: 120px;
                &.MuiTableCell-head {
                    position: sticky;
                }
            }

            &.candidate-name,
            &.am,
            &.assignee {
                min-width: 120px;
            }

            &.placed,
            &.start,
            &.guarantee,
            &.invoiced,
            &.paid {
                min-width: 90px;
            }

            &.recruiter-commission-paid,
            &.am-commission-paid {
                min-width: 140px;
            }

            &.base,
            &.sign-on,
            &.fees,
            &.pre-paid-fees,
            &.recruiter-commission,
            &.am-commission {
                min-width: 100px;
            }

            &.contract {
                min-width: 100px;
            }

            &.recruiter-commission-percentage {
                min-width: 100px;
            }

            &.am-commission-percentage {
                min-width: 100px;
            }

            &.pending {
                background: ${theme.palette.error.main};
                color: white;
                font-weight: 600;
            }
        }

        .table-pagination {
            text-align: right;
            padding: 18px 25px;
            font-size: 13px;
            text-transform: uppercase;
        }

        .table-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        tr {
            cursor: pointer;
        }

        tr:hover {
            background: ${theme.palette.action.hover};
        }
    }
`;

export const Billing: React.FC = () => {
    const theme = useTheme();
    const [jobTypes, setJobTypes] = React.useState<'placement-fees' | 'sourcer' | 'all'>('placement-fees');
    const [endTime, setEndTime] = React.useState(Date.now());
    const [startTime, setStartTime] = React.useState(0);
    const [sortCol, setSortCol] = React.useState<Column>('Placed');
    const [sortAsc, setSortAsc] = React.useState<'asc' | 'desc'>('desc');
    const [page, setPage] = React.useState(0);
    const getJobTypes = () =>
        jobTypes === 'placement-fees'
            ? placementFeeJobTypes
            : jobTypes === 'sourcer'
            ? sourcerJobTypes
            : placementFeeJobTypes.concat(sourcerJobTypes);
    const { data } = useSubscription<
        { candidates: Candidate[] },
        { jobType: string[]; startTime: number; endTime: number; excludeClientIds: string[] }
    >(CANDIDATE_BILLINGS, {
        variables: { jobType: getJobTypes(), startTime, endTime, excludeClientIds: internalClientIds }
    });
    const { setList } = useSlides();

    const columns: Column[] = [
        'Client',
        'Job',
        'Candidate',
        'Assignee',
        'AM',
        'Placed',
        'Start',
        'Guarantee',
        'Invoiced',
        'Paid',
        'Recruiter Commission Paid',
        'AM Commission Paid',
        'Contract',
        'Fees',
        'Pre-paid Fees',
        'Base',
        'Sign-on',
        'Recruiter %',
        'Recruiter Commission',
        'AM %',
        'AM Commission'
    ];
    const sortableColumns: Column[] = [
        'Client',
        'Candidate',
        'Assignee',
        'AM',
        'Placed',
        'Start',
        'Guarantee',
        'Invoiced',
        'Paid',
        'Recruiter Commission Paid',
        'AM Commission Paid',
        'Base',
        'Fees',
        'Recruiter Commission',
        'AM Commission'
    ];

    const handleJobTypesChange = (event: React.ChangeEvent<{ value: any }>) => {
        setJobTypes(event.target.value);
        setPage(0);
    };

    const handleStartTimeChange = (event: React.ChangeEvent<{ value: number }>) => {
        setStartTime(event.target.value);
        setEndTime(Date.now());
        setPage(0);
    };

    const ascSortCandidates = sortBy(data?.candidates, (c) => {
        switch (sortCol) {
            case 'AM':
                return c.accountManager?.name ?? '';
            case 'Assignee':
                return c.assignee.name ?? '';
            case 'Candidate':
                return c.person.name;
            case 'Guarantee':
                return c.startDate ?? 0;
            case 'Placed':
                return c.hiredAt ?? 0;
            case 'Client':
                return c.job.client.name;
            case 'Start':
                return c.startDate ?? 0;
            default:
                return null;
        }
    });

    const candidates = sortAsc === 'asc' ? ascSortCandidates : ascSortCandidates?.reverse();

    const handleSelectPerson = (personId: string, jobId: string) => () => {
        const list = candidates.map((c) => ({
            jobId: c.jobId,
            personId: c.personId
        }));
        const selected = {
            jobId,
            personId
        };
        setList(list, selected);
    };

    const handleSortChange = (col: Column) => () => {
        setPage(0);
        if (col === sortCol) {
            if (sortAsc === 'asc') {
                setSortAsc('desc');
            } else {
                setSortAsc('asc');
            }
        } else {
            setSortCol(col);
            setSortAsc('asc');
        }
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        // no-op
    };

    const headers = columns.map((c) => {
        const header =
            sortableColumns.indexOf(c) === -1 ? (
                c
            ) : (
                <TableSortLabel active={sortCol === c} direction={sortAsc} onClick={handleSortChange(c)}>
                    {c}
                </TableSortLabel>
            );
        return (
            <TableCell key={c} className={kebabCase(c)}>
                {header}
            </TableCell>
        );
    });

    const rows = candidates?.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((c) => {
        const guaranteeDateTs = moment(c.startDate).add(GUARANTEE_PERIOD_DAYS, 'days').startOf('day').valueOf();
        const guaranteeDate = c.startDate ? fullDate(guaranteeDateTs) : '';
        const invoicePending = moment(c.startDate).startOf('day').valueOf() < Date.now() && !c.invoicedAt;
        const paymentOverdue =
            c.invoicedAt && moment(c.invoicedAt).startOf('day').add(INVOICE_DUE_DAYS, 'days').valueOf() < Date.now();
        const recruiterCommissionPending = guaranteeDateTs < Date.now() && !c.recruiterCommissionPaidAt;
        const amCommissionPending = guaranteeDateTs < Date.now() && c.paidAt && !c.amCommissionPaidAt;
        const recruiterCommissionPaid = c.recruiterCommissionPaidAt
            ? fullDate(c.recruiterCommissionPaidAt)
            : recruiterCommissionPending
            ? '?'
            : '';
        return (
            <TableRow key={`${c.personId}-${c.jobId}`} onClick={handleSelectPerson(c.personId, c.jobId)}>
                <TableCell className="client">{c.job.client.name}</TableCell>
                <TableCell className="job">{c.job.title}</TableCell>
                <TableCell className="candidate">{c.person.name}</TableCell>
                <TableCell className="assignee">{c.assignee.name}</TableCell>
                <TableCell className="am">{c.accountManager?.name}</TableCell>
                <TableCell className="placed">{fullDate(c.hiredAt)}</TableCell>
                <TableCell className="start">{c.startDate ? fullDate(c.startDate) : ''}</TableCell>
                <TableCell className="guarantee">{guaranteeDate}</TableCell>
                <TableCell className={`invoiced ${invoicePending ? 'pending' : ''}`}>
                    {c.invoicedAt ? fullDate(c.invoicedAt) : invoicePending ? '?' : ''}
                </TableCell>
                <TableCell className={`paid ${paymentOverdue ? 'pending' : ''}`}>{paymentOverdue ? '?' : ''}</TableCell>
                <TableCell className={`recruiter-commission-paid ${recruiterCommissionPending ? 'pending' : ''}`}>
                    {recruiterCommissionPaid}
                </TableCell>
                <TableCell className={`am-commission-paid ${amCommissionPending ? 'pending' : ''}`}>
                    {c.amCommissionPaidAt ? fullDate(c.amCommissionPaidAt) : amCommissionPending ? '?' : ''}
                </TableCell>
                <TableCell className="contract">{c.job.contract}</TableCell>
                <TableCell className="fees">${c.fees?.toLocaleString()}</TableCell>
                <TableCell className="pre-paid-fees">
                    {c.prePaidFees ? `${c.prePaidFees.toLocaleString()}` : ''}
                </TableCell>
                <TableCell className="base">{c.hiredSalary}</TableCell>
                <TableCell className="sign-on">{c.signOnBonus}</TableCell>
                <TableCell className="recruiter-commission-percentage">{c.recruiterPercentage}</TableCell>
                <TableCell className="recruiter-commission">${c.recruiterCommission?.toLocaleString()}</TableCell>
                <TableCell className="am-commission-percentage">{c.accountManagerPercentage}</TableCell>
                <TableCell className="am-commission">${c.amCommission?.toLocaleString()}</TableCell>
            </TableRow>
        );
    });

    const pagination =
        data?.candidates === undefined ? (
            <div className="table-pagination">Loading...</div>
        ) : candidates.length > rowsPerPage ? (
            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={candidates.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className="pagination"
            />
        ) : (
            <div className="table-pagination">{candidates.length} Candidates</div>
        );

    // tslint:disable:no-magic-numbers
    return (
        <div css={styles(theme)}>
            <div className="selectors">
                <Select value={startTime} onChange={handleStartTimeChange} variant="outlined">
                    <MenuItem value={0}>All Time</MenuItem>
                    <MenuItem value={moment().subtract(1, 'month').endOf('day').valueOf()}>Last month</MenuItem>
                    <MenuItem value={moment().subtract(3, 'month').endOf('day').valueOf()}>Last 3 months</MenuItem>
                    <MenuItem value={moment().subtract(6, 'month').endOf('day').valueOf()}>Last 6 months</MenuItem>
                    <MenuItem value={moment().subtract(12, 'month').endOf('day').valueOf()}>Last 12 months</MenuItem>
                </Select>
                <Select value={jobTypes} onChange={handleJobTypesChange} variant="outlined">
                    <MenuItem value={'placement-fees'}>Contingency/Retained</MenuItem>
                    <MenuItem value={'sourcer'}>Sourcer Jobs</MenuItem>
                    <MenuItem value={'all'}>All Jobs</MenuItem>
                </Select>
            </div>
            <TableContainer className="table">
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>{headers}</TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
                <div className="table-footer">{pagination}</div>
            </TableContainer>
        </div>
    );
    // tslint:enable:no-magic-numbers
};
