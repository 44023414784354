import { css } from '@emotion/core';
import { Typography } from '@material-ui/core';
import React from 'react';

import { TextField } from '../core-ui/form-fields';
import { JobFormData } from '../graphql/queries/job-form';
import { useFormContext } from './form-context';

const styles = css`
    .ai-context-section {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;

        &:last-child {
            margin-bottom: 0;
        }

        .MuiTypography-h6 {
            margin-bottom: 10px;
        }
    }
`;

export const JobFormAIContext: React.FC = () => {
    const { data, onFieldChange, disabled } = useFormContext<JobFormData>();

    const handleNestedFieldChange = <
        T extends 'outreachAIContext' | 'scoringAIContext' | 'submissionGenAIContext',
        K extends keyof JobFormData[T]
    >(
        context: T,
        field: K
    ) => (value: JobFormData[T][K]) => {
        onFieldChange(context)({
            ...data[context],
            [field]: value
        });
    };

    return (
        <div css={styles}>
            <div className="ai-context-section">
                <Typography variant="h6" component="div">
                    Initial Outreach Generation
                </Typography>
                <div className="job-field-row">
                    <TextField
                        label="Directions"
                        value={data.outreachAIContext?.directions}
                        onChange={handleNestedFieldChange('outreachAIContext', 'directions')}
                        disabled={disabled}
                        multiline={true}
                    />
                </div>
            </div>
            <div className="ai-context-section">
                <Typography variant="h6" component="div">
                    Profile Scoring
                </Typography>
                <div className="job-field-row">
                    <TextField
                        label="Directions"
                        value={data.scoringAIContext?.directions}
                        onChange={handleNestedFieldChange('scoringAIContext', 'directions')}
                        disabled={disabled}
                        multiline={true}
                    />
                </div>
            </div>
            <div className="ai-context-section">
                <Typography variant="h6" component="div">
                    Submission Generation
                </Typography>
                <div className="job-field-row">
                    <TextField
                        label="Directions"
                        value={data.submissionGenAIContext?.directions}
                        onChange={handleNestedFieldChange('submissionGenAIContext', 'directions')}
                        disabled={disabled}
                        multiline={true}
                    />
                </div>
            </div>
        </div>
    );
};
