import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import React from 'react';

import { useQuery } from '@apollo/client';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';

import { DayPicker } from '../../core-ui/react-day-picker';
import { METRICS_FILTERS_DATA } from '../../graphql/queries/metrics';
import {
    Duration,
    DurationOptionLabel,
    getConversionDurationOptions,
    getLabel,
    getTrendDurationOptions
} from './metrics-durations';
import { useMetricsDashboardData } from './use-metrics-dashboard-data';

interface DateRangeSelectorData {
    duration: Duration;
    onChange: (value: Duration) => void;
}

const styles = (theme: Theme) => css`
    .MuiDialogTitle-root {
        border-bottom: thin solid ${theme.palette.divider};
    }

    .MuiDialogActions-root {
        border-top: thin solid ${theme.palette.divider};
    }

    .settings-content {
        .settings-content-item {
            padding: 15px 0 20px;
            border-bottom: thin solid ${theme.palette.divider};

            &:last-child {
                border-bottom: none;
            }

            .settings-content-item-description {
                padding: 15px 0;
                display: flex;

                .date-field {
                    flex: 1 1 auto;
                    margin: 0 20px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .settings-content-item-description-note {
            font-size: 12px;
            margin-top: -5px;
            opacity: 0.75;

            span {
                padding-right: 25px;
            }
        }
    }
`;

const dateRangeDialogStyles = (theme: Theme) => css`
    .pickers {
        .picker {
            margin: 10px 0 5px;
            padding: 10px 0;
            border-bottom: thin solid ${theme.palette.divider};

            .MuiTypography-h5 {
                text-align: center;
            }

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 5px;
            }
        }
    }
`;

const DateRangeSelectorDialog: React.FC<{
    initialDuration: Duration;
    onChange: (value: Duration) => void;
    onClose: () => void;
    open: boolean;
}> = ({ initialDuration, onChange, onClose, open }) => {
    const [duration, setDuration] = React.useState(initialDuration);
    const theme = useTheme();

    const handleUpdate = () => {
        onChange(duration);
        onClose();
    };

    const handleRangeSelectorChange = (kind: 'main' | 'baseline') => (range: { from: Date; to: Date }) => {
        if (range) {
            const updated =
                kind === 'main'
                    ? { ...duration, start: range.from?.getTime(), end: range.to?.getTime() }
                    : { ...duration, baselineStart: range.from?.getTime(), baselineEnd: range.to?.getTime() };
            setDuration(updated);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} css={dateRangeDialogStyles(theme)}>
            <DialogContent>
                <div className="pickers">
                    <div className="picker">
                        <Typography variant="h5">Period</Typography>
                        <DayPicker
                            defaultMonth={new Date(duration.end)}
                            mode="range"
                            selected={{ from: new Date(duration.start), to: new Date(duration.end) }}
                            onSelect={handleRangeSelectorChange('main')}
                        />
                    </div>
                    <div className="picker">
                        <Typography variant="h5">Baseline</Typography>
                        <DayPicker
                            defaultMonth={new Date(duration.baselineEnd)}
                            mode="range"
                            selected={{ from: new Date(duration.baselineStart), to: new Date(duration.baselineEnd) }}
                            onSelect={handleRangeSelectorChange('baseline')}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleUpdate}>Update</Button>
            </DialogActions>
        </Dialog>
    );
};

export const MetricsSettingsDialog: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
    const theme = useTheme();
    const { data: filtersData } = useQuery<{
        accountManagers: Array<{ id: string; name: string }>;
        recruiters: Array<{ id: string; name: string }>;
        clients: Array<{ id: string; name: string }>;
        teams: Array<{
            id: string;
            name: string;
            lead: { id: string; name: string };
            users: Array<{ user: { id: string; name: string } }>;
        }>;
    }>(METRICS_FILTERS_DATA);
    const {
        conversionDuration,
        trendDuration,
        onConversionDurationChange,
        onTrendDurationChange,
        restrictToAccountManagerIds,
        restrictToClientIds,
        restrictToRecruiterIds,
        restrictToTeam,
        setRestrictToAccountManagerIds,
        setRestrictToClientIds,
        setRestrictToRecruiterIds,
        setRestrictToTeam
    } = useMetricsDashboardData();
    const [customDateSelectorData, setCustomDateSelectorData] = React.useState<DateRangeSelectorData | undefined>(
        undefined
    );

    const handleCloseDateSelector = () => setCustomDateSelectorData(undefined);

    const handleSelectDateRange = (kind: 'conversion' | 'trend') => (label: DurationOptionLabel) => () => {
        if (label === 'Custom') {
            setCustomDateSelectorData({
                duration: kind === 'conversion' ? conversionDuration.value : trendDuration.value,
                onChange: (value) => {
                    if (kind === 'conversion') {
                        onConversionDurationChange(label, value);
                    } else {
                        onTrendDurationChange(label, value);
                    }
                }
            });
        } else {
            if (kind === 'conversion') {
                onConversionDurationChange(label, undefined);
            } else {
                onTrendDurationChange(label, undefined);
            }
        }
    };

    const handleSelectFilter = (updater: (ids: string[]) => void) => (_1: React.SyntheticEvent, newValue: string) => {
        if (!newValue) {
            updater(undefined);
        } else {
            updater([newValue]);
        }
    };

    const handleSelectTeam = (role: 'recruiter' | 'account_manager') => (
        _1: React.SyntheticEvent,
        newValue: string
    ) => {
        if (!newValue) {
            setRestrictToTeam(undefined);
        } else {
            const team = filtersData?.teams.find((t) => t.id === newValue);
            setRestrictToTeam({ id: team.id, users: team.users.map((u) => u.user.id).concat(team.lead.id), role });
        }
    };

    const dateSelectorDialog = customDateSelectorData ? (
        <DateRangeSelectorDialog
            initialDuration={customDateSelectorData.duration}
            onChange={customDateSelectorData.onChange}
            open={true}
            onClose={handleCloseDateSelector}
        />
    ) : null;

    const conversionMenuOpts = getConversionDurationOptions().map((opt) => (
        <MenuItem key={opt.label} value={opt.label} onClick={handleSelectDateRange('conversion')(opt.label)}>
            {opt.label}
        </MenuItem>
    ));

    const trendMenuOpts = getTrendDurationOptions().map((opt) => (
        <MenuItem key={opt.label} value={opt.label} onClick={handleSelectDateRange('trend')(opt.label)}>
            {opt.label}
        </MenuItem>
    ));

    const renderInput = (params: RenderInputParams) => <TextField {...params} variant="outlined" />;
    const getOptionLabel = (data: Array<{ id: string; name: string }>) => (option: string) =>
        data.find((d) => d.id === option)?.name ?? 'All';
    const getOptions = (data: Array<{ id: string; name: string }>) => data?.map((d) => d.id);

    const amSelector = filtersData?.accountManagers ? (
        <Autocomplete
            fullWidth={true}
            options={getOptions(filtersData?.accountManagers)}
            getOptionLabel={getOptionLabel(filtersData?.accountManagers)}
            renderInput={renderInput}
            value={restrictToAccountManagerIds?.[0] ?? ''}
            onChange={handleSelectFilter(setRestrictToAccountManagerIds)}
        />
    ) : null;

    const clientSelector = filtersData?.clients ? (
        <Autocomplete
            fullWidth={true}
            options={getOptions(filtersData?.clients)}
            getOptionLabel={getOptionLabel(filtersData?.clients)}
            renderInput={renderInput}
            value={restrictToClientIds?.[0] ?? ''}
            onChange={handleSelectFilter(setRestrictToClientIds)}
        />
    ) : null;

    const recruiterSelector = filtersData?.recruiters ? (
        <Autocomplete
            fullWidth={true}
            options={getOptions(filtersData?.recruiters)}
            getOptionLabel={getOptionLabel(filtersData?.recruiters)}
            renderInput={renderInput}
            value={restrictToRecruiterIds?.[0] ?? ''}
            onChange={handleSelectFilter(setRestrictToRecruiterIds)}
        />
    ) : null;

    const teamSelectorRecruiters = filtersData?.teams ? (
        <Autocomplete
            fullWidth={true}
            options={getOptions(filtersData?.teams)}
            getOptionLabel={getOptionLabel(filtersData?.teams)}
            renderInput={renderInput}
            value={restrictToTeam?.role === 'recruiter' ? restrictToTeam.id : ''}
            onChange={handleSelectTeam('recruiter')}
        />
    ) : null;

    const teamSelectorAMs = filtersData?.teams ? (
        <Autocomplete
            fullWidth={true}
            options={getOptions(filtersData?.teams)}
            getOptionLabel={getOptionLabel(filtersData?.teams)}
            renderInput={renderInput}
            value={restrictToTeam?.role === 'account_manager' ? restrictToTeam.id : ''}
            onChange={handleSelectTeam('account_manager')}
        />
    ) : null;

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md" css={styles(theme)}>
            <DialogTitle>
                <Typography variant="h4">Dashboard Settings</Typography>
            </DialogTitle>
            <DialogContent>
                <div className="settings-content">
                    <div className="settings-content-item">
                        <Typography variant="h5">Trends</Typography>
                        <div className="settings-content-item-description">
                            <Select value={trendDuration.label} fullWidth={true} variant="outlined">
                                {trendMenuOpts}
                                <MenuItem value="Custom" onClick={handleSelectDateRange('trend')('Custom')}>
                                    Custom
                                </MenuItem>
                            </Select>
                        </div>
                        <div className="settings-content-item-description-note">
                            <span>Period: {getLabel(trendDuration.value.start, trendDuration.value.end)}</span>
                            <span>
                                Baseline: {getLabel(trendDuration.value.baselineStart, trendDuration.value.baselineEnd)}
                            </span>
                        </div>
                    </div>
                    <div className="settings-content-item">
                        <Typography variant="h5">Conversions</Typography>
                        <div className="settings-content-item-description">
                            <Select value={conversionDuration.label} fullWidth={true} variant="outlined">
                                {conversionMenuOpts}
                                <MenuItem value="Custom" onClick={handleSelectDateRange('conversion')('Custom')}>
                                    Custom
                                </MenuItem>
                            </Select>
                        </div>
                        <div className="settings-content-item-description-note">
                            <span>
                                Period: {getLabel(conversionDuration.value.start, conversionDuration.value.end)}
                            </span>
                            <span>
                                Baseline:{' '}
                                {getLabel(conversionDuration.value.baselineStart, conversionDuration.value.baselineEnd)}
                            </span>
                        </div>
                    </div>
                    <div className="settings-content-item">
                        <Typography variant="h5">Client</Typography>
                        <div className="settings-content-item-description">{clientSelector}</div>
                    </div>
                    <div className="settings-content-item">
                        <Typography variant="h5">Team (AM Role)</Typography>
                        <div className="settings-content-item-description">{teamSelectorAMs}</div>
                    </div>
                    <div className="settings-content-item">
                        <Typography variant="h5">Team (Recruiter Role)</Typography>
                        <div className="settings-content-item-description">{teamSelectorRecruiters}</div>
                    </div>
                    <div className="settings-content-item">
                        <Typography variant="h5">Account Manager</Typography>
                        <div className="settings-content-item-description">{amSelector}</div>
                    </div>
                    <div className="settings-content-item">
                        <Typography variant="h5">Recruiter</Typography>
                        <div className="settings-content-item-description">{recruiterSelector}</div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
            {dateSelectorDialog}
        </Dialog>
    );
};
