// cspell:ignore middot gratipay
import { LinkedIn } from '@material-ui/icons';
import * as _ from 'lodash';
import { Paper } from 'material-ui';
import * as React from 'react';

import { standardizeUrl } from 'profile-parser';

import { CompanyInfo } from '../components/company-info';
import { SchoolInfo } from '../components/school-info';
import { externalLink, highlightContent, pair, periodWithDuration } from '../core-ui/profile/utils';
import { profileEssentials } from '../extension/profile-essentials';
import { useProfileAdornments } from '../hooks/use-profile-adornment';
import { Profile } from '../types/profile';
import { ProfileAge } from './profile-age';

interface PersonProfileProps {
    personId: string;
    profile: Profile;
    jobId?: string;
    viewedAt?: number;
    showYearsExpSummary?: boolean;
}

const skillColumns = 2;
const pastPositionsCount = 2;

// const logo = (url: string) => url ? (
//     <div className='logo'>
//         <img src={`https://media.licdn.com/media${url}`} />
//     </div>
// ) : undefined;

const section = (icon: JSX.Element, content: JSX.Element[], title: string, contentClass?: string) =>
    content ? (
        <Paper className="detailed-profile-card">
            <div className="icon">{icon}</div>
            <div className="card-content">
                <div className="section-title">{title}</div>
                <div className={contentClass}>{content}</div>
            </div>
        </Paper>
    ) : undefined;

export const PersonProfile = ({ personId, profile, viewedAt, showYearsExpSummary, jobId }: PersonProfileProps) => {
    const { getAdornmentsForPerson } = useProfileAdornments();
    const { insertElements, highlightTerms } = getAdornmentsForPerson(personId, jobId);
    const highlight = (content: string) => {
        return highlightContent(content, highlightTerms);
    };

    if (!profile) return null;

    const currentPositions = profile.positions.filter((p) => p.current);
    const latestCompany = currentPositions && currentPositions.length > 0 ? currentPositions[0] : undefined;
    const latestExp = latestCompany ? <CompanyInfo data={latestCompany} highlight={highlight} /> : undefined;
    const currentEducation = profile.educations.filter((e) => e.current)[0];
    const latestEdu = currentEducation ? <SchoolInfo education={currentEducation} highlight={highlight} /> : undefined;
    const latestExpEdu =
        latestExp && latestEdu ? (
            <span>
                {latestExp} <span className="middot-separator" /> {latestEdu}
            </span>
        ) : latestExp || latestEdu ? (
            <span>
                {latestExp}
                {latestEdu}
            </span>
        ) : undefined;
    const pastPositions = profile.positions.filter((p) => !p.current);
    const prevPositionsList =
        pastPositions && pastPositions.length > 0
            ? pastPositions.slice(0, pastPositionsCount).map((p, i) => {
                  const company = <CompanyInfo data={p} highlight={highlight} />;
                  return (
                      <div key={`${p.positionId}-${i}`} className="position-info">
                          {highlight(p.title)}
                          <span className="middot-separator" />
                          {company}
                      </div>
                  );
              })
            : undefined;
    const prevPositions = prevPositionsList ? (
        <div style={{ marginBottom: '15px' }}>
            <div className="h4">Previous Positions</div>
            {prevPositionsList}
        </div>
    ) : undefined;
    const eduSummary = currentEducation ? (
        <div>
            <div className="h4">Education</div>
            <div>
                <SchoolInfo education={currentEducation} highlight={highlight} /> <span className="middot-separator" />{' '}
                {highlight(currentEducation.degree)} <span className="middot-separator" />{' '}
                {highlight(currentEducation.fieldOfStudy)}
            </div>
        </div>
    ) : undefined;
    const prevPositionsAndEdu =
        prevPositions || eduSummary ? (
            <div className="separated">
                {prevPositions}
                {eduSummary}
            </div>
        ) : undefined;

    const summary = profile.summary ? <div className="summary separated">{highlight(profile.summary)}</div> : undefined;
    let profileAge;
    if (viewedAt) {
        profileAge = <ProfileAge viewedAt={viewedAt} />;
    }
    const profileExternalLink =
        profile.publicLink ?? profile.recruiterLiteLink ? (
            <a
                style={{ marginLeft: '10px', fontSize: '32px' }}
                href={`https://${standardizeUrl(profile.publicLink ?? profile.recruiterLiteLink)}`}
                target="_blank"
            >
                <LinkedIn fontSize="inherit" />
            </a>
        ) : null;
    const linkElements = (profile.websites || []).map((w, i) => <div key={`${w.url}-${i}`}>{externalLink(w.url)}</div>);
    const websites =
        profile.websites && profile.websites.length > 0 ? (
            <div className="websites separated">
                <div className="icon">
                    <i className="material-icons list-icon">link</i>
                </div>
                <div className="links">{linkElements}</div>
            </div>
        ) : undefined;

    let careerInterests: JSX.Element[] = [];
    if (profile.careerInterests) {
        const interestDescription: any = {
            availableStartingMonth: 'Available Starting',
            interestedCompanySize: 'Company Size',
            interestedFunction: 'Function',
            interestedIndustries: 'Industries',
            interestedIntroductionStatement: 'Introduction Statement',
            interestedLocations: 'Locations',
            interestedSeniority: 'Seniority',
            interestedTitles: 'Titles',
            seekingContractPosition: 'Contract',
            seekingFreelancePosition: 'Freelance',
            seekingFullTimePosition: 'Full Time',
            seekingInternshipPosition: 'Internship',
            seekingRemotePosition: 'Remote'
        };
        const relevant = _.pick(profile.careerInterests, Object.keys(interestDescription));
        const positionTypes: string[] = [];
        for (const name in relevant) {
            if (relevant.hasOwnProperty(name)) {
                const desc = (relevant as any)[name];
                if (desc === false || desc === null) continue;
                if (desc === true) {
                    positionTypes.push(interestDescription[name]);
                } else if (Array.isArray(desc)) {
                    if (desc.length > 0) {
                        const descText = desc.map((v, i) => <div key={`${v}-${i}`}>{highlight(v)}</div>);
                        careerInterests.push(
                            <div className="interest" key={name}>
                                <div className="kind">{interestDescription[name]}</div>
                                <div className="value">{descText}</div>
                            </div>
                        );
                    }
                } else {
                    careerInterests.push(
                        <div className="interest" key={name}>
                            <div className="kind">{interestDescription[name]}</div>
                            <div className="value">{highlight(desc)}</div>
                        </div>
                    );
                }
            }
        }
        if (positionTypes.length > 0) {
            const posText = positionTypes.map((v, i) => <div key={`${v}-${i}`}>{highlight(v)}</div>);
            careerInterests.push(
                <div className="interest" key="positionTypes">
                    <div className="kind">Position Type</div>
                    <div className="value">{posText}</div>
                </div>
            );
        }
    }
    if (careerInterests.length === 0) careerInterests = undefined;

    const positions =
        profile.positions && profile.positions.length > 0
            ? profile.positions.map((pos, i) => {
                  const company = <CompanyInfo data={pos} highlight={highlight} />;
                  return (
                      <div key={`${pos.positionId}-${i}`} className="position separated">
                          <div className="header">
                              <div>
                                  <div className="title">{highlight(pos.title)}</div>
                                  {company}
                                  <div>{highlight(pos.location)}</div>
                                  <div>{periodWithDuration(pos)}</div>
                              </div>
                              {/* {logo(pos.companyLogo)} */}
                          </div>
                          {pos.summary ? <div className="summary">{highlight(pos.summary)}</div> : undefined}
                      </div>
                  );
              })
            : undefined;

    const educations =
        profile.educations && profile.educations.length > 0
            ? profile.educations.map((edu, i) => {
                  const degree = pair(highlight(edu.degree), highlight(edu.fieldOfStudy), ' - ');
                  return (
                      <div key={`${edu.educationId}-${i}`} className="education separated">
                          <div className="header">
                              <div>
                                  <SchoolInfo education={edu} highlight={highlight} />
                                  <div>{degree}</div>
                                  <div>{pair(edu.startDate, edu.endDate, '-')}</div>
                              </div>
                              {/* {logo(edu.schoolLogo)} */}
                          </div>
                          {edu.notes ? <div className="summary">{highlight(edu.notes)}</div> : undefined}
                      </div>
                  );
              })
            : undefined;

    const projects =
        profile.projects && profile.projects.length > 0
            ? profile.projects.map((proj, i) => {
                  const description = proj.description ? (
                      <div className="summary">{highlight(proj.description)}</div>
                  ) : undefined;
                  return (
                      <div key={`${proj.title}-${i}`} className="project separated">
                          <div className="header">
                              <div>
                                  <div className="title">{highlight(proj.title)}</div>
                                  <div>{proj.startDate}</div>
                              </div>
                          </div>
                          {description}
                      </div>
                  );
              })
            : undefined;

    let skills;
    if (profile.skills && profile.skills.length > 0) {
        const chunkSize = Math.ceil(profile.skills.length / skillColumns);
        const skillsChunks = _.chunk(profile.skills, chunkSize);
        skills = skillsChunks.map((chunk, i) => {
            const elements = chunk.map((s) => <li key={`${s}-${i}`}>{highlight(s)}</li>);
            return <ul key={`${i}-${i}`}>{elements}</ul>;
        });
    }

    const patents =
        profile.patents && profile.patents.length > 0
            ? profile.patents.map((p, i) => {
                  const patentSummary = p.summary ? (
                      <div className="summary">
                          {highlight(p.summary)}
                          <br />
                          {externalLink(p.externalUrl)}
                      </div>
                  ) : undefined;
                  return (
                      <div key={`${p.title}-${i}`} className="patent separated">
                          <div className="header">
                              <div>
                                  <div className="title">{highlight(p.title)}</div>
                              </div>
                          </div>
                          {patentSummary}
                      </div>
                  );
              })
            : undefined;

    const publications =
        profile.publications && profile.publications.length > 0
            ? profile.publications.map((p, i) => (
                  <div key={i} className="publication separated">
                      <div className="header">
                          <div>
                              <div className="title">{highlight(p.title)}</div>
                              <div>{highlight(p.publisher)}</div>
                              <div>{highlight(p.publicationDate)}</div>
                          </div>
                      </div>
                      {p.summary ? <div className="summary">{highlight(p.summary)}</div> : undefined}
                  </div>
              ))
            : undefined;

    const certifications =
        profile.certifications && profile.certifications.length > 0
            ? profile.certifications.map((c, i) => (
                  <div key={`${c.name}-${i}`} className="certification separated">
                      <div className="header">
                          <div>
                              <div className="title">{highlight(c.name)}</div>
                              <div>{highlight(c.authority)}</div>
                              <div>{c.startDate}</div>
                          </div>
                      </div>
                  </div>
              ))
            : undefined;

    const courses =
        profile.coursesByOccupation && profile.coursesByOccupation.length > 0
            ? profile.coursesByOccupation.map((c, i) => {
                  const coursesList = c.courses.map((course, idx) => (
                      <div key={idx}>
                          {highlight(course.name)} {highlight(course.number)}
                      </div>
                  ));
                  return (
                      <div key={`${c.occupationOrganizationName}-${i}`} className="course-org separated">
                          <div className="header">
                              <div className="title">{highlight(c.occupationOrganizationName)}</div>
                          </div>
                          <div className="summary">{coursesList}</div>
                      </div>
                  );
              })
            : undefined;

    const awards =
        profile.awards && profile.awards.length > 0
            ? profile.awards.map((a, i) => <div key={`${a}-${i}`}>{highlight(a)}</div>)
            : undefined;

    const honors =
        profile.honors && profile.honors.length > 0
            ? profile.honors.map((h, i) => (
                  <div style={{ marginBottom: '12px' }} key={`${h.title}-${i}`}>
                      <div>{highlight(h.title)}</div>
                      {h.issuer ? <div>{highlight(h.issuer)}</div> : undefined}
                      {h.issueDate ? <div>{highlight(h.issueDate)}</div> : undefined}
                      {h.description ? <div>{highlight(h.description)}</div> : undefined}
                  </div>
              ))
            : undefined;
    let awardsAndHonors: JSX.Element[];
    if (honors) {
        awardsAndHonors = [];
        awardsAndHonors.push(
            <div key="honors" className="awards separated">
                <div className="summary">{honors}</div>
            </div>
        );
    }
    if (awards) {
        awardsAndHonors = awardsAndHonors || [];
        awardsAndHonors.push(
            <div key="awards" className="awards separated">
                <div className="summary">{awards}</div>
            </div>
        );
    }

    const organizations =
        profile.organizations && profile.organizations.length > 0
            ? profile.organizations.map((c, i) => (
                  <div key={`${c.name}-${i}`} style={{ marginBottom: '12px' }}>
                      <div className="title">{highlight(c.name)}</div>
                      <div>{pair(c.startDate, c.endDate, ' - ')}</div>
                  </div>
              ))
            : undefined;

    const yearsExpSummary = showYearsExpSummary ? (
        <div className="years separated">{profileEssentials(profile)}</div>
    ) : null;

    const headlineParts = profile.headline?.split(/ at /) ?? [];
    const headline =
        headlineParts.length > 1 ? (
            <span>
                {highlight(headlineParts.slice(0, headlineParts.length - 1).join(''))} at{' '}
                <span className="company-name">{highlight(headlineParts[headlineParts.length - 1])}</span>
            </span>
        ) : (
            <span>{highlight(headlineParts.join(''))}</span>
        );

    const scoreExplanationAdornment = insertElements?.find((a) => a.slot === 'explanationDetailedView')?.element;

    const summarySection = (
        <Paper className="detailed-profile-card">
            <div className="icon">
                <i className="material-icons list-icon">description</i>
            </div>
            <div className="card-content">
                <div className="section-title">
                    Summary
                    <div className="profile-info">
                        <div className="chips">
                            {profileAge}
                            {profileExternalLink}
                        </div>
                    </div>
                </div>
                <div className="header separated">
                    <h1>{headline}</h1>
                    <h2>{latestExpEdu}</h2>
                    <h2>
                        {highlight(profile.location)}
                        <span className="middot-separator" />
                        {highlight(profile.industry)}
                    </h2>
                </div>
                {scoreExplanationAdornment}
                {prevPositionsAndEdu}
                {summary}
                {websites}
                {yearsExpSummary}
            </div>
        </Paper>
    );

    return (
        <div className="detailed-profile">
            {summarySection}
            {section(<i className="material-icons list-icon">work</i>, positions, 'Experience')}
            {section(<i className="material-icons list-icon">school</i>, educations, 'Education')}
            {section(<i className="fas fa-wrench fa-lg list-icon" />, skills, 'Skills', 'skills-list')}
            {section(<i className="fas fa-clipboard-list fa-lg list-icon" />, projects, 'Projects')}
            {section(<i className="fas fa-certificate fa-lg list-icon" />, patents, 'Patents')}
            {section(<i className="fas fa-newspaper fa-lg list-icon" />, publications, 'Publications')}
            {section(<i className="fas fa-certificate fa-lg list-icon" />, certifications, 'Certifications')}
            {section(<i className="fas fa-book fa-lg list-icon" />, courses, 'Courses')}
            {section(<i className="fas fa-trophy fa-lg list-icon" />, awardsAndHonors, 'Awards and Honors')}
            {section(<i className="fas fa-building fa-lg list-icon" />, organizations, 'Organizations')}
            {section(<i className="fab fa-gratipay fa-lg list-icon" />, careerInterests, 'Interests')}
        </div>
    );
};
