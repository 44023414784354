import React from 'react';

import { RichTextField, TextField } from '../core-ui/form-fields';
import { JobFormData } from '../graphql/queries/job-form';
import { useFormContext } from './form-context';

export const JobFormCompanyInfo: React.FC = () => {
    const {
        data: { client },
        onFieldChange,
        disabled
    } = useFormContext<JobFormData>();

    const handleFieldChange = <T extends keyof JobFormData['client']>(field: T) => (
        value: JobFormData['client'][T]
    ) => {
        onFieldChange('client')({ ...client, [field]: value });
    };

    return (
        <div>
            <div className="job-field-row">
                <RichTextField
                    value={client.description}
                    label="Company Info"
                    onChange={handleFieldChange('description')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={client.salesPitch}
                    label="Memorable Selling Point"
                    onChange={handleFieldChange('salesPitch')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={client.funding}
                    label="Funding"
                    onChange={handleFieldChange('funding')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={client.foundedDate}
                    label="Founded"
                    onChange={handleFieldChange('foundedDate')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={client.hq}
                    label="Headquarters"
                    onChange={handleFieldChange('hq')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={client.teamSize}
                    label="Team Size"
                    onChange={handleFieldChange('teamSize')}
                    disabled={disabled}
                />
            </div>
            <div className="job-field-row">
                <TextField
                    value={client.website}
                    label="Website"
                    onChange={handleFieldChange('website')}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
