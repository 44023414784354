import { CircularProgress, TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';

import { standardizeUrl } from 'profile-parser';
import { isValidUrl } from '../../common/utils';
import { Company as CompanyData } from '../../graphql/queries/companies';
import { useCompanyNames } from '../../hooks/use-company-names';

interface Company {
    name: string;
    url: string;
}

interface CompanyNameSelectProps {
    selected: Company;
    onSelect: (val: Company) => void;
    disabled: boolean;
    label?: string;
    variant?: 'standard' | 'outlined' | 'filled' | 'no-underline';
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    recruiterLinkOnly?: boolean;
}

const minInputLengthForSearch = 2;
const loadingSize = 20;

export const CompanyNameSelectField: React.FC<CompanyNameSelectProps> = ({
    selected,
    onSelect,
    disabled,
    variant,
    onKeyPress,
    label
}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [isUserInteracting, setIsUserInteracting] = useState(false);
    const [urlError, setUrlError] = useState<string>('');
    const { companyNames: options, loading } = useCompanyNames(inputValue);

    useEffect(() => {
        if (inputValue?.length >= minInputLengthForSearch) {
            if (isUserInteracting) {
                setOpen(true);
            }
        } else {
            setOpen(false);
        }
    }, [inputValue, isUserInteracting]);

    useEffect(() => {
        if (options && selected && selected?.name === selected?.url) {
            const selectedOption = options.find((option) => option.url === selected?.url);
            if (selectedOption) {
                setInputValue(selectedOption.name);
                onSelect(selectedOption);
            }
        }
    }, [options]);

    const handleInputChange = (_1: React.ChangeEvent<{}>, newInputValue: string, reason: string) => {
        setInputValue(newInputValue);
        setIsUserInteracting(reason === 'input');
        setUrlError('');
        if (newInputValue.length >= minInputLengthForSearch) {
            setOpen(reason === 'input'); // Open the dropdown when input value meets the minimum length
        } else {
            setOpen(false); // Close the dropdown if below the minimum length
        }
    };

    const handleInputBlur = () => {
        // Check if inputValue matches any of the options
        const isOption = options.some((option) => option.name === inputValue || option.url === inputValue);
        if (!isOption) {
            // If inputValue is not an option, process it
            let url = inputValue?.trim();
            if (isValidUrl(url)) {
                url =
                    'https://' +
                    standardizeUrl(url?.trim())?.replace(/(?<!\/recruiter)\/company\//, '/recruiter/company/');
            }
            const error =
                url && (!isValidUrl(url) || !url?.match(/^https:\/\/www\.linkedin\.com\/recruiter\/company\/\d+$/))
                    ? 'Please enter a valid Linkedin company URL'
                    : '';
            if (error) {
                setUrlError(error);
            } else {
                onSelect({ name: url, url });
            }
        }
    };

    const handleChange = (_1: React.ChangeEvent<{}>, value: CompanyData | null) => {
        if (value) {
            onSelect({ name: value.name, url: value.url });
            setInputValue(value.name); // Update inputValue to the selected name
        } else {
            onSelect({ name: '', url: '' }); // Handle clearing the selection
            setInputValue(''); // Clear inputValue if selection is cleared
        }
        setUrlError('');
        setOpen(false); // Close the dropdown when an option is selected or cleared
        setIsUserInteracting(false); // User is no longer actively interacting
    };

    const getOptionSelected = (option: CompanyData, value: CompanyData) => {
        return option.name === value.name || option.url === value.url;
    };

    const getOptionLabel = (option: CompanyData) => {
        if (options) {
            const existingOption = options.find((o) => o.url === option.url);
            if (existingOption) {
                return existingOption.name;
            }
        }
        return option.name;
    };
    const renderOption = (option: CompanyData) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <span>{option.name}</span>
                <span>{option.count.toLocaleString()}</span>
            </div>
        );
    };

    const renderInput = (params: TextFieldProps) => {
        const disableUnderline = variant === 'no-underline' ? { disableUnderline: true } : {};
        const inputProps = {
            ...params.InputProps,
            ...disableUnderline,
            endAdornment: (
                <>
                    {loading ? <CircularProgress color="inherit" size={loadingSize} /> : null}
                    {params.InputProps.endAdornment}
                </>
            )
        };
        const inputVariant = variant === 'no-underline' ? 'standard' : variant ?? 'outlined';
        return (
            <TextField
                {...params}
                label={label ?? 'Company'}
                placeholder="Start typing a company name or URL"
                variant={inputVariant}
                InputProps={inputProps}
                InputLabelProps={{ shrink: true }}
                onBlur={handleInputBlur}
                helperText={urlError}
                error={!!urlError}
                onKeyPress={onKeyPress}
            />
        );
    };

    return (
        <Autocomplete
            open={open && options.length > 0}
            options={options}
            freeSolo={true}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            onInputChange={handleInputChange}
            onChange={handleChange}
            value={selected || null}
            loading={loading}
            renderInput={renderInput}
            renderOption={renderOption}
            className="company-name-select"
            disabled={disabled}
        />
    );
};
