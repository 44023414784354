// cspell:ignore stackoverflow angellist CDNs cloudfront licdn Unparseable
import * as _ from 'lodash';
import * as validator from 'validator';
import { parseURL, serializeURL } from 'whatwg-url';

import { standardizeUrl } from 'profile-parser';

function standardizeName(name: string) {
    if (name === undefined || name === null) return name;
    let stdName = _.lowerCase(name);
    if (stdName === '') {
        stdName = 'Unparseable'; // upper case on purpose - to not match profile data with string "unparseable"
    }
    return stdName;
}

export function isURL(value: string): boolean {
    if (value.trim().match(/\s/)) return false;
    const link = value.match(/^https?:\/\//i) ? value : `https://${value}`;
    try {
        return validator.isURL(serializeURL(parseURL(link)));
    } catch (err) {
        return false;
    }
}

export function standardizeBlacklistEntry(value: string) {
    if (!value) {
        return value;
    }
    if (isURL(value)) {
        return standardizeUrl(value);
    } else {
        return standardizeName(value);
    }
}
