import moment from 'moment';
import { ActivityMetricType } from 'shared/models/activity-metric';
import { MetricsData } from '../graphql/queries/metrics';
import { chartDate } from './timestamp';

export function getChartHeight(count: number) {
    // tslint:disable:no-magic-numbers
    const minPad = 80;
    if (count < 10) {
        return count * 50 + minPad;
    } else if (count < 16) {
        return count * 45 + minPad;
    } else if (count < 24) {
        return count * 40 + minPad;
    } else {
        return count * 40 + minPad;
    }
    // tslint:enable:no-magic-numbers
}

interface AllFields {
    search_create: 1;
    manual_sourcing: 1;
    awaiting_am_approval: 1;
    awaiting_client_feedback: 1;
    rocket_screen_complete: 1;
    being_scheduled: 1;
    email_send_candidate: 1;
    email_send_client: 1;
    outreach: 1;
    response_received: 1;
    initial_phone_call: 1;
    follow_up_phone_call: 1;
    text_message: 1;
    client_first_round: 1;
    client_second_round: 1;
    offer: 1;
    hired: 1;
}

export type Field = ActivityMetricType;

export type ClientFieldMetrics = Partial<
    {
        [Property in keyof AllFields]: {
            value: number;
            jobs: Array<{
                id: string;
                name: string;
                value: number;
            }>;
        };
    }
>;

export interface ClientMetrics {
    clientId: string;
    client: string;
    metrics: ClientFieldMetrics;
}

type FunnelCounts = Partial<{ [Property in keyof AllFields]: number }>;

export type FieldsSelected = {
    [Property in keyof AllFields]: boolean;
};

export const clientActivityFields: Field[] = [
    'search_create',
    'manual_sourcing',
    'response_received',
    'rocket_screen_complete',
    'awaiting_am_approval',
    'awaiting_client_feedback',
    'client_first_round',
    'client_second_round',
    'offer',
    'hired'
];

export const funnelViewMetrics: Field[] = [
    'search_create',
    'manual_sourcing',
    'outreach',
    'response_received',
    'initial_phone_call',
    'rocket_screen_complete',
    'follow_up_phone_call',
    'text_message',
    'email_send_candidate',
    'email_send_client',
    'awaiting_am_approval',
    'awaiting_client_feedback',
    'client_first_round',
    'client_second_round',
    'offer',
    'hired'
];

export const funnelMetricFields: Field[] = [
    'manual_sourcing',
    'outreach',
    'response_received',
    'rocket_screen_complete',
    'awaiting_am_approval',
    'awaiting_client_feedback',
    'client_first_round',
    'client_second_round',
    'offer',
    'hired'
];

export const defaultAggregateViewSelected: FieldsSelected = clientActivityFields.reduce(
    (acc, f) => ({ ...acc, [f]: true }),
    {}
) as FieldsSelected;

export const defaultFunnelSelected: FieldsSelected = funnelMetricFields.reduce(
    (acc, f) => ({ ...acc, [f]: true }),
    {}
) as FieldsSelected;

export const fieldColors: Map<Field, string> = new Map([
    ['awaiting_am_approval', 'rgb(255, 178, 122)'],
    ['awaiting_client_feedback', 'rgb(255, 117, 87)'],
    ['email_send_candidate', 'rgb(226, 192, 68)'],
    ['email_send_client', 'rgb(68, 157, 209)'],
    ['manual_sourcing', 'rgb(249, 163, 164)'],
    ['search_create', 'rgb(13, 126, 160)'],
    ['outreach', 'rgb(68, 157, 209)'],
    ['response_received', 'rgb(19, 216, 170)'],
    ['rocket_screen_complete', 'rgb(114, 190, 244)'],
    ['initial_phone_call', 'rgb(86, 83, 254)'],
    ['follow_up_phone_call', 'rgb(41, 131, 255)'],
    ['text_message', 'rgb(0, 177, 242)'],
    ['client_first_round', 'rgb(206, 147, 216)'],
    ['client_second_round', 'rgb(240, 98, 146)'],
    ['offer', 'rgb(0, 200, 83)'],
    ['hired', 'rgb(186, 104, 200)']
] as Array<[Field, string]>);

export const fieldLabels: Map<Field, string> = new Map([
    ['awaiting_am_approval', 'Submits to AM'],
    ['awaiting_client_feedback', 'Client Submissions'],
    ['email_send_candidate', 'Candidate Email Followups'],
    ['email_send_client', 'Client Email Followups'],
    ['manual_sourcing', 'Manual Sourcing'],
    ['phone_calls_and_texts', 'Phone calls and Texts'],
    ['search_create', 'ML Searches'],
    ['outreach', 'Outreach'],
    ['response_received', 'Response Received'],
    ['rocket_screen_complete', 'Phone Screens'],
    ['initial_phone_call', 'Initial Phone Calls'],
    ['follow_up_phone_call', 'Follow Up Calls'],
    ['text_message', 'Text messages'],
    ['client_first_round', 'Client Accepts'],
    ['client_second_round', 'Final Rounds'],
    ['offer', 'Offers'],
    ['hired', 'Placements']
] as Array<[Field, string]>);

export function getMetricsByClient(metrics: MetricsData[], variant: 'cost' | 'count') {
    return metrics?.reduce((acc, m) => {
        const index = acc.findIndex((v) => v.clientId === m.job.client.id);
        const getFieldMetric = (f: Field, includeKinds?: ActivityMetricType[]) => {
            const current = acc[index]?.metrics?.[f] ?? { value: 0, jobs: [] };
            if ((includeKinds && includeKinds.includes(m.kind)) || (!includeKinds && m.kind === f)) {
                const metric = { value: m[variant], jobs: [{ id: m.job.id, name: m.job.title, value: m[variant] }] };
                const jobIndex = current.jobs.findIndex((j) => j.id === m.job.id);
                const jobs =
                    jobIndex === -1
                        ? current.jobs.concat([{ id: m.job.id, name: m.job.title, value: m[variant] }])
                        : current.jobs.slice(0, jobIndex).concat(
                              [
                                  {
                                      id: m.job.id,
                                      name: m.job.title,
                                      value: m[variant] + current.jobs[jobIndex].value
                                  }
                              ],
                              current.jobs.slice(jobIndex + 1)
                          );
                return { value: current.value + metric.value, jobs };
            } else {
                return current;
            }
        };
        const clientData: ClientMetrics = {
            client: m.job.client.name,
            clientId: m.job.client.id,
            metrics: {
                awaiting_am_approval: getFieldMetric('awaiting_am_approval'),
                awaiting_client_feedback: getFieldMetric('awaiting_client_feedback'),
                client_first_round: getFieldMetric('client_first_round'),
                client_second_round: getFieldMetric('client_second_round'),
                email_send_candidate: getFieldMetric('email_send_candidate'),
                email_send_client: getFieldMetric('email_send_client'),
                follow_up_phone_call: getFieldMetric('follow_up_phone_call'),
                hired: getFieldMetric('hired'),
                initial_phone_call: getFieldMetric('initial_phone_call'),
                manual_sourcing: getFieldMetric('manual_sourcing'),
                offer: getFieldMetric('offer'),
                outreach: getFieldMetric('outreach'),
                response_received: getFieldMetric('response_received'),
                rocket_screen_complete: getFieldMetric('rocket_screen_complete'),
                search_create: getFieldMetric('search_create'),
                text_message: getFieldMetric('text_message')
            }
        };
        if (index !== -1) {
            acc[index] = clientData;
        } else {
            acc = acc.concat([clientData]);
        }
        return acc;
    }, [] as ClientMetrics[]);
}

export const customTooltip = (
    metrics: ClientMetrics[],
    fields: Field[],
    series: Array<{ data: number[]; name: string }>
) => ({ dataPointIndex, seriesIndex }: { dataPointIndex: number; seriesIndex: number }) => {
    const record = metrics[dataPointIndex];
    const field = fields[seriesIndex];
    const label = fieldLabels.get(field);
    const total = series[seriesIndex].data.reduce((acc, val) => acc + val, 0);
    const percent = Math.round((series[seriesIndex].data[dataPointIndex] / total) * 100); // tslint:disable-line
    const jobs = record.metrics[field].jobs
        .map(
            (j) => `
        <div class="metrics-tooltip-header">Job</div>
        <div class="metrics-tooltip-value">${j.name}: ${j.value}</div>
    `
        )
        .join('\n');
    return `
        <div class="metrics-tooltip">
            <div class="metrics-tooltip-header">Client</div>
            <div class="metrics-tooltip-value">${record.client}</div>
            <div class="metrics-tooltip-header">${label}</div>
            <div class="metrics-tooltip-value">${series[seriesIndex].data[dataPointIndex]}</div>
            <div class="metrics-tooltip-header">Percent</div>
            <div class="metrics-tooltip-value">${percent}% of all ${label}</div>
            ${jobs}
        </div>`;
};

export const getFieldColors = (fields: Field[], hoveredField: Field) => {
    return fields.map((f) => {
        const fieldColor = fieldColors.get(f);
        if (hoveredField && hoveredField !== f) {
            return fieldColor.replace(/^rgb\((.*)\)$/, 'rgba($1,0.08)');
        } else {
            return fieldColor;
        }
    });
};

const getPeriodSize = (delta: number): 'day' | 'week' | 'month' => {
    // tslint:disable:no-magic-numbers
    return delta <= moment.duration(5, 'weeks').valueOf()
        ? 'day'
        : delta < moment.duration(14, 'months').valueOf()
        ? 'week'
        : 'month';
    // tslint:enable:no-magic-numbers
};

const getPeriods = (startTs: number, endTs: number, periodSize?: 'day' | 'week' | 'month') => {
    const period = periodSize ?? getPeriodSize(endTs - startTs);
    let curr = moment(startTs).startOf(period).valueOf();
    const dates: Array<{ tick: string; label: string; start: number; end: number }> = [];
    do {
        const end = moment(curr).endOf(period).valueOf();
        const tick =
            period === 'day'
                ? moment(curr).format('MMM DD')
                : period === 'week'
                ? moment().isSame(moment(curr), 'year')
                    ? moment((curr + end) / 2).format('MMM DD')
                    : moment((curr + end) / 2).format('MMM DD, YYYY')
                : moment(curr).format('MMM YYYY');
        const label = `${chartDate(curr, end)}`;
        dates.push({ tick, label, start: curr, end });
        curr = moment(curr).add(1, period).startOf(period).valueOf();
        if (curr > endTs) {
            break;
        }
    } while (true);
    return dates;
};

export const metricsByPeriod = (
    metrics: Array<{ count: number; kind: ActivityMetricType; date: string }>,
    startTs: number,
    endTs: number,
    periodSize?: 'day' | 'week' | 'month'
) => {
    const startTsSanitized = startTs === 0 ? new Date(metrics?.[0]?.date ?? Date.now()).valueOf() : startTs;
    const result: Array<{
        period: { tick: string; label: string; start: number; end: number };
        metrics: FunnelCounts;
    }> = [];
    const periods = getPeriods(startTsSanitized, endTs, periodSize);
    for (const period of periods) {
        result.push({ period, metrics: {} });
    }
    for (const metric of metrics ?? []) {
        const ts = moment(metric.date).valueOf();
        const index = result.findIndex((p) => p.period.start <= ts && p.period.end >= ts);
        if (index !== -1) {
            result[index].metrics[metric.kind] = (result[index].metrics[metric.kind] ?? 0) + metric.count;
        }
    }
    return result;
};

export const funnelMetrics = (
    metrics: Array<{ count: number; cost: number; kind: ActivityMetricType }>,
    variant: 'cost' | 'count' = 'count'
) => {
    return metrics?.reduce(
        (acc, metric) => ({
            ...acc,
            [metric.kind]: (acc[metric.kind] ?? 0) + metric[variant]
        }),
        {} as FunnelCounts
    );
};
