import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Checkbox,
    Dialog,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Theme,
    Tooltip,
    useTheme
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Cancel, Clear, FilterList } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Map, Set } from 'immutable';
import { sortBy, startCase, times } from 'lodash';
import moment from 'moment';
import { FC, useState } from 'react';
import DocumentTitle from 'react-document-title';

import { fullDate } from '../common/timestamp';
import { DayPicker } from '../core-ui/react-day-picker';
import { SCHEDULED_OUTREACHES_ALL, ScheduledOutreach, UPDATE_SCHEDULED_OUTREACHES } from '../graphql/queries/outreach';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { Header } from './header';

const skeletonRowsCount = 5;
const rowsPerPage = 10;

const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .selectors {
        display: flex;
        flex: 0 0 auto;
        text-align: right;
        margin-bottom: 20px;
        width: 300px;
        justify-content: end;
        align-self: end;
        padding: 10px 0;

        .MuiFormControl-root {
            margin: 0 0 0 15px;
        }

        .MuiInputBase-root {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            .MuiOutlinedInput-input {
                background: white;
                padding: 14px 14px;
            }
        }
    }

    .table {
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
        background: white;

        .table-pagination {
            text-align: right;
            padding: 18px 25px;
            font-size: 13px;
            text-transform: uppercase;
        }

        .table-footer {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;

            .summary {
                margin-left: 15px;
                font-size: 14px;
            }
        }

        .outreach-status {
            display: inline-flex;
            align-items: center;
        }

        .cancel-outreach-button {
            margin-left: 10px;
            opacity: 0;
        }

        tr:hover {
            background: ${theme.palette.action.hover};
            .cancel-outreach-button {
                opacity: 1;
            }
        }

        .table-header-cell {
            display: inline-flex;
            align-items: center;
        }

        .column-action-icon {
            display: inline-flex;
            align-items: center;
            opacity: 0;
            transition: opacity 200ms;
            margin-left: 5px;
            cursor: pointer;

            .MuiSvgIcon-root {
                font-size: 1.25rem;
                color: ${theme.palette.text.secondary};
            }

            &.visible {
                opacity: 1;
            }
        }

        th:hover {
            .column-action-icon {
                opacity: 1;
            }
        }
    }
`;

const filterMenuStyles = (theme: Theme) => css`
    .MuiMenuItem-root.MuiListItem-gutters {
        padding: 0 14px 0 8px;

        .filter-value {
            display: flex;
            justify-content: space-between;
            flex: 1 1 auto;

            .filter-value-count {
                margin-left: 20px;
            }
        }
    }

    .clear-menu {
        font-style: italic;
        border-bottom: thin solid ${theme.palette.divider};

        &.MuiMenuItem-root.MuiListItem-gutters {
            padding: 8px 15px;
        }

        .MuiSvgIcon-root {
            margin-right: 10px;
        }
    }
`;

type Column = 'Client' | 'Job' | 'Recruiter' | 'Sending Time' | 'Requested Time' | 'Status' | 'Requested' | 'Added';

export const AllOutreaches: FC = () => {
    const theme = useTheme();
    const { getConfirmation } = useModal();
    const { setSnackbar } = useSnackbar();
    const { user } = useSession();

    const [page, setPage] = useState(0);

    const [sortCol, setSortCol] = useState<Column>('Sending Time');
    const [sortAsc, setSortAsc] = useState<'asc' | 'desc'>('desc');

    const [filters, setFilters] = useState<Map<Column, Set<string | number>>>(Map());
    const [filterMenuAnchor, setFilterMenuAnchor] = useState(null);
    const [filterMenuColumn, setFilterMenuColumn] = useState<Column>(null);

    const [startDateDialogState, setStartDateDialogState] = useState<boolean>(false);
    const [startDate, setStartDate] = useState(Date.now());

    const [endDateDialogState, setEndDateDialogState] = useState<boolean>(false);
    const [endDate, setEndDate] = useState(Date.now());

    const columns: Column[] = [
        'Client',
        'Job',
        'Recruiter',
        'Requested Time',
        'Status',
        'Requested',
        'Sending Time',
        'Added'
    ];

    const filterableColumns: Column[] = ['Recruiter', 'Status'];

    const [updateScheduledOutreach, { loading }] = useMutation<{}, { id: string; updates: Partial<ScheduledOutreach> }>(
        UPDATE_SCHEDULED_OUTREACHES
    );

    const { data: outreachesData, loading: fetchLoading, refetch } = useQuery<
        { scheduled_outreaches: ScheduledOutreach[] },
        { startDate: number; endDate: number }
    >(SCHEDULED_OUTREACHES_ALL, {
        variables: {
            endDate: moment(endDate).endOf('day').valueOf(),
            startDate: moment(startDate).startOf('day').valueOf()
        }
    });

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        /* no-op */
    };

    const handleSortChange = (col: Column) => () => {
        setPage(0);
        if (col === sortCol) {
            if (sortAsc === 'asc') {
                setSortAsc('desc');
            } else {
                setSortAsc('asc');
            }
        } else {
            setSortCol(col);
            setSortAsc('asc');
        }
    };

    const handleClickFilter = (col: Column) => (event: React.MouseEvent) => {
        setFilterMenuColumn(col);
        setFilterMenuAnchor(event.target);
    };

    const handleCloseFilterMenu = () => {
        setFilterMenuAnchor(null);
        setFilterMenuColumn(null);
    };

    const handleFilterOptionClick = (opt: string | number) => () => {
        let colFilters = filters.get(filterMenuColumn) ?? Set();
        if (colFilters.has(opt)) {
            colFilters = colFilters.delete(opt);
        } else {
            colFilters = colFilters.add(opt);
        }
        const updated =
            colFilters.size > 0 ? filters.set(filterMenuColumn, colFilters) : filters.remove(filterMenuColumn);
        setFilters(updated);
        setPage(0);
    };

    const handleClearFilter = () => {
        setFilters(filters.remove(filterMenuColumn));
    };

    const valueFunc = (col: Column) => (c: ScheduledOutreach) => {
        switch (col) {
            case 'Client':
                return c.job.client?.name;
            case 'Job':
                return c.job.title;
            case 'Recruiter':
                return c.user.name;
            case 'Requested Time':
                return c.requestedAt;
            case 'Status':
                return c.status;
            case 'Requested':
                return c.limit;
            case 'Sending Time':
                return c.scheduledAt;
            case 'Added':
                return c.addedCount;
            default:
                return null;
        }
    };

    let filterMenuPopover;
    if (filterMenuAnchor) {
        const values = outreachesData?.scheduled_outreaches.map(valueFunc(filterMenuColumn));
        let valuesWithCounts = Map<string | number, number>();
        for (const value of values) {
            valuesWithCounts = valuesWithCounts.set(value, valuesWithCounts.get(value, 0) + 1);
        }
        valuesWithCounts = valuesWithCounts.sortBy((_1, k) => k);
        const menuItems = valuesWithCounts
            .map((val, opt) => (
                <MenuItem key={opt} dense={true} onClick={handleFilterOptionClick(opt)}>
                    <Checkbox checked={filters.get(filterMenuColumn, Set()).has(opt)} />
                    <div className="filter-value">
                        <div className="filter-value-name">
                            {filterMenuColumn === 'Status' ? startCase(opt.toString()) : opt}
                        </div>
                        <div className="filter-value-count">{val}</div>
                    </div>
                </MenuItem>
            ))
            .valueSeq();
        filterMenuPopover = (
            <Menu open={true} anchorEl={filterMenuAnchor} onClose={handleCloseFilterMenu} css={filterMenuStyles(theme)}>
                <MenuItem dense={true} onClick={handleClearFilter} className="clear-menu">
                    <Clear />
                    Clear All
                </MenuItem>
                {menuItems}
            </Menu>
        );
    }

    const headers = columns.map((col) => {
        const filterIcon =
            filterableColumns.indexOf(col) === -1 ? null : (
                <span
                    className={`column-action-icon ${filters.has(col) ? 'visible' : ''}`}
                    onClick={handleClickFilter(col)}
                >
                    <FilterList />
                </span>
            );
        const sortIcon = sortAsc === 'desc' ? <ArrowDownward /> : <ArrowUpward />;
        const sort = (
            <span className={`column-action-icon ${sortCol === col ? 'visible' : ''}`} onClick={handleSortChange(col)}>
                {sortIcon}
            </span>
        );
        return (
            <TableCell key={col}>
                <span className="table-header-cell">
                    <span onClick={handleSortChange(col)}>{col}</span>
                    {sort}
                    {filterIcon}
                </span>
            </TableCell>
        );
    });

    const skeletonRows = times(skeletonRowsCount).map((i) => (
        <TableRow key={i}>
            <TableCell colSpan={columns.length}>
                <Skeleton variant="rect" />
            </TableCell>
        </TableRow>
    ));

    const onConfirmCancelOutreach = (id: string) => async () => {
        await updateScheduledOutreach({
            variables: {
                id,
                updates: {
                    status: 'canceled'
                }
            }
        });
        setSnackbar('Scheduled Outreach Canceled');
        refetch();
    };

    const handleCancelOutreach = (id: string) => () => {
        getConfirmation(onConfirmCancelOutreach(id), 'This will cancel this scheduled outreach', 'Please confirm');
    };

    const filteredOutreaches = outreachesData?.scheduled_outreaches?.filter((c) => {
        const filtersMatch = filters.reduce((res, val, col) => res && val.has(valueFunc(col)(c)), true);
        return filtersMatch;
    });

    const ascSortOutreaches = sortBy(filteredOutreaches, (c) => {
        switch (sortCol) {
            case 'Client':
                return c.job.client?.name ?? '';
            case 'Job':
                return c.job.title ?? '';
            case 'Recruiter':
                return c.user.name ?? '';
            case 'Requested Time':
                return c.requestedAt ?? 0;
            case 'Status':
                return c.status ?? '';
            case 'Requested':
                return c.limit ?? '';
            case 'Sending Time':
                return c.scheduledAt ?? 0;
            case 'Added':
                return c.addedCount ?? 0;

            default:
                return null;
        }
    });

    const outreaches = sortAsc === 'asc' ? ascSortOutreaches : ascSortOutreaches?.reverse();

    const rows =
        outreaches === undefined
            ? skeletonRows
            : outreaches.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((outreach) => {
                  const cancelButton =
                      user.id === outreach.user.id && outreach.status === 'scheduled' ? (
                          <Tooltip title="Cancel Outreach">
                              <span className="cancel-outreach-button">
                                  <IconButton
                                      disabled={loading}
                                      onClick={handleCancelOutreach(outreach.id)}
                                      size="small"
                                  >
                                      <Cancel fontSize="small" />
                                  </IconButton>
                              </span>
                          </Tooltip>
                      ) : null;
                  const status = (
                      <span className="outreach-status">
                          {startCase(outreach.status)} {cancelButton}
                      </span>
                  );
                  return (
                      <TableRow key={`${outreach.id}`}>
                          <TableCell>{outreach.job.client.name}</TableCell>
                          <TableCell>{outreach.job.title}</TableCell>
                          <TableCell>{outreach.user.name}</TableCell>
                          <TableCell>{moment(outreach.requestedAt).format('MMM DD, YYYY hh:mm A')}</TableCell>
                          <TableCell>{status}</TableCell>
                          <TableCell>{outreach.limit}</TableCell>
                          <TableCell>{moment(outreach.scheduledAt).format('MMM DD, YYYY hh:mm A')}</TableCell>
                          <TableCell>{outreach.addedCount}</TableCell>
                      </TableRow>
                  );
              });

    const pagination = fetchLoading ? (
        <div className="table-pagination">Loading...</div>
    ) : outreaches.length > rowsPerPage ? (
        <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            count={outreaches.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    ) : (
        <div className="table-pagination">{outreaches.length} Outreaches</div>
    );

    const handleDatePickerOpen = (start: boolean) => async () => {
        start ? setStartDateDialogState(true) : setEndDateDialogState(true);
    };

    const handleDateChange = (start: boolean) => async (date: Date) => {
        start ? setStartDate(date.valueOf()) : setEndDate(date.valueOf());
        start ? setStartDateDialogState(false) : setEndDateDialogState(false);
    };
    const handleCloseDateDialog = (start: boolean) => () =>
        start ? setStartDateDialogState(false) : setEndDateDialogState(false);

    const startDateDialog = startDateDialogState ? (
        <Dialog open={true} onClose={handleCloseDateDialog(true)} maxWidth="md">
            <DayPicker
                defaultMonth={startDate ? new Date(startDate) : new Date()}
                onDayClick={handleDateChange(true)}
            />
        </Dialog>
    ) : null;

    const endDateDialog = endDateDialogState ? (
        <Dialog open={true} onClose={handleCloseDateDialog(false)} maxWidth="md">
            <DayPicker defaultMonth={endDate ? new Date(endDate) : new Date()} onDayClick={handleDateChange(false)} />
        </Dialog>
    ) : null;

    const totalAdded = outreaches?.reduce((acc, outreach) => acc + outreach.addedCount, 0);
    const totalRequested = outreaches?.reduce((acc, outreach) => {
        const limit = parseInt(outreach.limit, 10);
        return !isNaN(limit) ? acc + limit : acc;
    }, 0);

    const summary = (
        <div className="summary">
            Total Added: {totalAdded.toLocaleString()} / Total Requested: {totalRequested.toLocaleString()}
        </div>
    );

    return (
        <DocumentTitle title="Outreaches">
            <div id="container">
                <Header title="Outreaches" />
                <div css={styles(theme)}>
                    <div className="selectors">
                        <TextField
                            label="Start Date"
                            value={startDate ? fullDate(startDate) : ''}
                            onClick={handleDatePickerOpen(true)}
                            variant="outlined"
                        />
                        {startDateDialog}

                        <TextField
                            label="End Date"
                            value={endDate ? fullDate(endDate) : ''}
                            onClick={handleDatePickerOpen(false)}
                            variant="outlined"
                        />
                        {endDateDialog}
                    </div>
                    <TableContainer className="table">
                        <Table stickyHeader={true}>
                            <TableHead>
                                <TableRow>{headers}</TableRow>
                            </TableHead>
                            <TableBody>{rows}</TableBody>
                        </Table>
                        <div className="table-footer">
                            {pagination}

                            {summary}
                        </div>
                    </TableContainer>

                    {filterMenuPopover}
                </div>
            </div>
        </DocumentTitle>
    );
};
