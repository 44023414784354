import { css } from '@emotion/core';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@material-ui/core';
import { ArrowDownward, ArrowForward, Email, HourglassEmpty, Phone } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

import { interviewTime } from '../../common/timestamp';
import { config } from '../../config';
import { Interview } from '../../graphql/queries/home';
import { useSlides } from '../../hooks/use-candidate-slides';
import { useUserContext } from '../../hooks/use-user-context';
import { HomePagePanel } from './home-page-panel';
import { InfoIcon } from './info-icon';

interface InterviewProps {
    title: string;
    header?: JSX.Element;
    interviews: Interview[];
    showIfEmpty: boolean;
    rowsPerPage?: number;
    disableCollapse?: boolean;
}

const amStagesMinId = 15;

// tslint:disable: no-magic-numbers
const styles = css`
    .past {
        opacity: 0.65;
    }

    .caption {
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    .no-interviews {
        text-align: center;
        padding: 30px 10px;
    }
`;
// tslint:enable: no-magic-numbers

const Content: React.FC<{ rows: JSX.Element[]; header: JSX.Element }> = ({ rows, header }) => {
    const content =
        rows.length > 0 ? (
            <List disablePadding={true}>{rows}</List>
        ) : (
            <div className="no-interviews">
                <Typography variant="overline" color="textSecondary">
                    No Interviews
                </Typography>
            </div>
        );
    return (
        <>
            {header}
            <div css={styles} className="section-body">
                {content}
            </div>
        </>
    );
};

const InterviewListItem: React.FC<{ data: Interview; list: Interview[] }> = ({ data: interview, list }) => {
    const refreshState = useState(interview.startTime);
    const { user } = useUserContext();
    const theme = useTheme();
    const { setList } = useSlides();

    const handleSelect = (personId: string, jobId: string) => () => {
        const ids = list.map((c) => ({
            jobId: c.candidate.jobId,
            personId: c.candidate.personId
        }));
        setList(ids, { personId, jobId });
    };

    let timer: NodeJS.Timer;

    useEffect(() => {
        if (interview.startTime > Date.now()) {
            timer = setTimeout(() => {
                refreshState[1](Date.now());
            }, interview.startTime - Date.now());
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, []);

    const {
        candidate: {
            assignee,
            person: { name, profilePicUrl, phones },
            job,
            pendingEmailsCount,
            stage
        },
        startTime,
        kind
    } = interview;
    const primary = <Typography variant="h6">{name}</Typography>;
    const candidateAssignee =
        assignee.id === user?.id ? null : (
            <span className={kind === 'Rocket Screen' ? '' : 'assignee'}>{assignee.name}</span>
        );
    const stageInfo = kind === 'Rocket Screen' ? null : <span>{stage.label}</span>;
    const stageAndAssignee =
        candidateAssignee || stageInfo ? (
            <div>
                {stageInfo} {candidateAssignee}
            </div>
        ) : null;
    const secondary = (
        <Typography variant="body2" color="textSecondary" component="div">
            <div>
                {job.client.name} - {job.title}
            </div>
            {stageAndAssignee}
        </Typography>
    );
    const avatar = profilePicUrl ? (
        <Avatar src={`${config.AssetHost}/${profilePicUrl}`} />
    ) : (
        <Avatar>{name.charAt(0).toLocaleUpperCase()}</Avatar>
    );
    const missingPhone =
        interview.kind === 'Rocket Screen' && phones.length === 0 ? (
            <InfoIcon
                tooltip="Phone number missing"
                color={theme.palette.error.main}
                icon={<Phone />}
                className="left-pad"
            />
        ) : null;

    const hasCandidateEmails = interview.candidate.pendingEmails.find((c) => !c.isClientComm);
    const hasClientEmails = interview.candidate.pendingEmails.find((c) => !!c.isClientComm);
    const pendingEmailsIconColor =
        hasCandidateEmails && hasClientEmails ? '#691b99' : hasClientEmails ? '#367cf7' : '#f44336';
    const pendingEmails =
        pendingEmailsCount > 0 ? (
            <InfoIcon tooltip="Pending Emails" color={pendingEmailsIconColor} icon={<Email />} className="left-pad" />
        ) : null;
    const postCompleteStatus =
        interview.kind === 'Rocket Screen' && interview.startTime < Date.now() ? (
            interview.candidate.disqualified ? (
                <InfoIcon
                    tooltip="Disqualified"
                    color={theme.palette.error.main}
                    icon={<ArrowDownward />}
                    className="left-pad"
                />
            ) : interview.candidate.stage.id >= amStagesMinId ? (
                <InfoIcon
                    tooltip="Moved Forward"
                    color={theme.palette.success.main}
                    icon={<ArrowForward />}
                    className="left-pad"
                />
            ) : (
                <InfoIcon
                    tooltip="Needs Action"
                    color={theme.palette.warning.main}
                    icon={<HourglassEmpty />}
                    className="left-pad"
                />
            )
        ) : null;
    return (
        <ListItem
            key={interview.id}
            onClick={handleSelect(interview.candidate.personId, interview.candidate.jobId)}
            className={interview.startTime < Date.now() ? 'past' : ''}
            button={true}
        >
            <ListItemAvatar>{avatar}</ListItemAvatar>
            <ListItemText disableTypography={true} primary={primary} secondary={secondary} />
            <Typography color="textSecondary" noWrap={true} variant="caption" className="caption">
                {missingPhone} {pendingEmails} {postCompleteStatus}{' '}
                <span className="left-pad">{interviewTime(startTime)}</span>
            </Typography>
        </ListItem>
    );
};

export const Interviews: React.FC<InterviewProps> = ({ interviews, title, header, showIfEmpty, disableCollapse }) => {
    return (
        <HomePagePanel
            RowComponent={InterviewListItem}
            ContentComponent={Content}
            records={interviews}
            title={title}
            containerClass=""
            showIfEmpty={showIfEmpty}
            contentProps={{ header }}
            collapseKeyId={`panel-${title}`}
            disableCollapse={disableCollapse}
        />
    );
};
