import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import * as React from 'react';

import { ClientForm } from './client-form';

interface OwnProps {
    open: boolean;
    onRequestClose: () => void;
    clientId?: string;
}

export const ClientFormDialog: React.FC<OwnProps> = (props) => {
    const { clientId } = props;

    return (
        <Dialog open={props.open} onClose={props.onRequestClose} maxWidth="md" fullWidth={true}>
            <DialogTitle disableTypography={true}>
                <Typography variant="h5">{clientId ? 'Edit Client' : 'Add Client'}</Typography>
            </DialogTitle>
            <DialogContent>
                <ClientForm clientId={clientId} onClose={props.onRequestClose} />
            </DialogContent>
        </Dialog>
    );
};
