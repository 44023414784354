import { css } from '@emotion/core';
import { Button } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';

import { Permissions } from 'shared/models/permission';
import { hasRole } from 'shared/models/user';

import { State } from '../state';
import { ClientFormDialog } from './client-form-dialog';

const buttonStyle = css`
    color: white;
    margin-right: 15px;
`;

interface ConnectedProps {
    userPermissions: Permissions;
}

interface AddClientState {
    clientFormOpen: boolean;
}

class AddClientComponent extends React.Component<ConnectedProps, AddClientState> {
    constructor(props: ConnectedProps) {
        super(props);
        this.state = { clientFormOpen: false };
    }

    handleClientFormOpen = () => {
        this.setState({ clientFormOpen: true });
    };

    handleClientFormClose = () => {
        this.setState({ clientFormOpen: false });
    };

    render() {
        const { userPermissions } = this.props;
        const { clientFormOpen } = this.state;
        if (!hasRole(userPermissions, 'client_creator')) {
            return null;
        }
        return (
            <div>
                <Button css={buttonStyle} onClick={this.handleClientFormOpen}>
                    Create New Client
                </Button>
                <ClientFormDialog open={clientFormOpen} onRequestClose={this.handleClientFormClose} />
            </div>
        );
    }
}

const mapStateToProps = (state: State) => ({
    userPermissions: state.session.userPermissions
});
export const AddClient = connect<ConnectedProps, undefined, {}>(mapStateToProps)(AddClientComponent);
