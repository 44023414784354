import { ApolloClient, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import { getLocalStorageKey } from '../common/local-storage';
import { logger } from '../common/logger';
import { config } from '../config';

const { authToken } = getLocalStorageKey('session', { authToken: undefined });

const webSocketUri =
    config.Env === 'production' ? `wss://${config.GraphAPIEndpoint}` : `ws://${config.GraphAPIEndpoint}`;
const httpUri =
    config.Env === 'production' ? `https://${config.GraphAPIEndpoint}` : `http://${config.GraphAPIEndpoint}`;

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
            logger.warn('apollo graphql error', {
                requestData: {
                    locations,
                    message,
                    operation: operation?.operationName,
                    path,
                    variables: operation?.variables
                }
            })
        );
    }

    if (networkError) {
        logger.debug('apollo network error', {
            requestData: {
                operation: operation?.operationName,
                variables: operation?.variables
            }
        });
    }
});

const headers = (role?: string) =>
    !!role ? { Authorization: `Bearer ${authToken}`, 'x-hasura-role': role } : { Authorization: `Bearer ${authToken}` };

const httpLink = (role?: string) => {
    return new HttpLink({
        fetch,
        headers: headers(role),
        uri: httpUri
    });
};

const wsLink = (role?: string) => {
    return new GraphQLWsLink(
        createClient({
            connectionParams: {
                headers: headers(role)
            },
            url: webSocketUri
        })
    );
};

const splitLink = (role?: string) =>
    split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink(role),
        httpLink(role)
    );

export const client = (role?: string) =>
    new ApolloClient({
        cache: new InMemoryCache(),
        connectToDevTools: true,
        link: from([errorLink, splitLink(role)])
    });
