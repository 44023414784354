import produce from 'immer';
import { blue200, pink200, red200, red600 } from 'material-ui/styles/colors';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { SearchConfigLocations, SearchTerm, toggleSearchTerm } from 'shared/models/search';

import { TextfieldWithChips } from '../core-ui/textfield-with-chips';
import { useModal } from '../hooks/use-modal';
import { SearchStatus } from '../state';
import { renderOption } from './search-autocomplete-option';
import { SearchGroup, SearchGroupAction } from './search-group';
import { searchLocations } from './search-locations';
import { useSearch } from './use-search';
import { useSearchPresets } from './use-search-presets';
import { useSearchPresetsForm } from './use-search-presets-form';

export const SearchGeneralLogisticsLocations: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config.general;
    const onChange = searchContext.onConfigFieldChange('general');
    const readonly = searchContext.readonly;
    const { requestPresets, updateSelectedPresets, searchPresets, getGroupSearchTerms } = useSearchPresets();
    const { requestPresetsSave } = useSearchPresetsForm();
    const { getConfirmation } = useModal();
    const savedSearch = searchContext.data.status && searchContext.data.status !== SearchStatus.Initial;

    const configRef = useRef(config);

    useEffect(() => {
        configRef.current = config;
    }, [config]);

    const handleToggleLocationPreset = useCallback(
        (index: number) => (label: string, _1: string[], id: string) => {
            const { locations } = configRef.current;
            const old = locations[index];
            const newLs = toggleSearchTerm({ isPreset: true, label, presetId: id }, old.locations);
            const updated = Object.assign({}, old, { locations: newLs });
            const newLocations = locations.slice(0, index).concat([updated], locations.slice(index + 1));
            onChange(
                produce(configRef.current, (draft) => {
                    draft.locations = newLocations;
                })
            );
            updateSelectedPresets(newLocations[index].locations.filter((n) => n.isPreset));
        },
        [onChange, updateSelectedPresets]
    );

    const handleAddLocationsPresetRequest = useCallback(
        (index: number) => () => {
            requestPresets(
                handleToggleLocationPreset(index),
                ['locations'],
                configRef.current.locations[index].locations.filter((n) => n.isPreset)
            );
        },
        [handleToggleLocationPreset]
    );

    const handleAddLocations = () => {
        onChange(
            produce(config, (draft) => {
                const { locations } = configRef.current;
                draft.locations = locations.concat([{ locations: [], negative: false }]);
            })
        );
    };

    const handleRemoveLocations = (index: number) => () => {
        const { locations } = configRef.current;
        const newLocations = locations.slice(0, index).concat(locations.slice(index + 1));
        if (locations[index].locations.length > 0) {
            getConfirmation(
                () =>
                    onChange(
                        produce(config, (draft) => {
                            draft.locations = newLocations;
                        })
                    ),
                'Are you sure you want to remove a list of locations?',
                'Remove Confirmation'
            );
        } else {
            onChange(
                produce(config, (draft) => {
                    draft.locations = newLocations;
                })
            );
        }
    };

    const handleUpdateLocations = (index: number) => <T extends keyof SearchConfigLocations>(field: T) => (
        value: SearchConfigLocations[T]
    ) => {
        const { locations } = configRef.current;
        const updated = Object.assign({}, locations[index], { [field]: value });
        const newLocations = locations.slice(0, index).concat([updated], locations.slice(index + 1));
        onChange(
            produce(config, (draft) => {
                draft.locations = newLocations;
            })
        );
    };
    const handleToggleLocations = (index: number) => () => {
        const { locations } = configRef.current;
        handleUpdateLocations(index)('negative')(!locations[index].negative);
    };

    const handlePresetSaved = (index: number) => (preset: SearchTerm) => {
        const { locations } = configRef.current;
        const old = locations[index];
        const updated = { ...old, locations: [preset] };
        const newLocations = locations.slice(0, index).concat([updated], locations.slice(index + 1));
        onChange(Object.assign({}, config, { locations: newLocations }));
    };

    const handleSaveAsPreset = (index: number, names: SearchTerm[]) => () => {
        requestPresetsSave({
            groups: ['locations'],
            postSave: handlePresetSaved(index),
            selectedGroup: 'locations',
            terms: names
        });
    };

    const getChipStyle = <T extends { negative: boolean }>(g: T) => (value: SearchTerm) => {
        const { isPreset } = value;
        const backgroundColor = g.negative && isPreset ? pink200 : g.negative ? red200 : isPreset ? blue200 : undefined;
        return { backgroundColor };
    };
    const getChipFromString = (label: string) => ({ isPreset: false, label });
    const getStringFromChip = (chip: SearchTerm) => chip.label;

    const locationOptions = useMemo(() => {
        const mappedLocationOptions = searchLocations.map((l) => ({ label: l, isPreset: false }));
        return getGroupSearchTerms('locations', mappedLocationOptions);
    }, [searchLocations, searchPresets]);

    const locationLists = config.locations
        .filter((l) => l.locations.length > 0 || !savedSearch)
        .map((l, i) => {
            const actions: SearchGroupAction[] = [{ name: 'Add', onSelect: handleAddLocations }];
            if (config.locations.length > 1) {
                actions.push({ name: 'Remove', onSelect: handleRemoveLocations(i) });
            }
            actions.push({
                checked: l.negative,
                name: 'Negative',
                onSelect: handleToggleLocations(i),
                style: l.negative ? { color: red600 } : {}
            });
            actions.push({ name: 'Presets', onSelect: handleAddLocationsPresetRequest(i) });
            if (l.locations.length > 0) {
                actions.push({ name: 'Save as Preset', onSelect: handleSaveAsPreset(i, l.locations) });
            }
            return (
                <SearchGroup readonly={readonly} actions={actions} key={i} className="and-group">
                    <div className="search-requirements-field with-context" key={i}>
                        <div style={{ flex: '1 1 auto' }}>
                            <label className="search-label">Current Location</label>
                            <div className="search-requirements-value">
                                <TextfieldWithChips
                                    chips={l.locations}
                                    hintText="Add location"
                                    onChange={handleUpdateLocations(i)('locations')}
                                    readonly={readonly}
                                    options={locationOptions}
                                    getChipFromString={getChipFromString}
                                    getStringFromChip={getStringFromChip}
                                    getChipStyle={getChipStyle(l)}
                                    renderOption={renderOption}
                                />
                            </div>
                        </div>
                    </div>
                </SearchGroup>
            );
        });

    return <div>{locationLists}</div>;
};
