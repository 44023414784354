import { startCase } from 'lodash';
import { blue200, pink200, red200 } from 'material-ui/styles/colors';
import * as React from 'react';

import {
    allCountries,
    degreeOptions,
    FieldOfStudy,
    fieldOfStudyName,
    fosOptions,
    maxRangeValue,
    SearchConfigEducation as SearchConfigEducationType,
    SearchFieldOfStudy,
    SearchRankedSchool,
    SearchTerm,
    toggleSearchTerm
} from 'shared/models/search';

import { RangeSelectField } from '../core-ui/range-select-field';
import { SelectField } from '../core-ui/simple-select-field';
import { TextfieldWithChips } from '../core-ui/textfield-with-chips';
import { useModal } from '../hooks/use-modal';
import { SearchStatus } from '../state';
import { SearchGroup, SearchGroupAction } from './search-group';
import { SearchRequirementsSection } from './search-requirements-section';
import { SearchSchoolTextField } from './search-school-text-field';
import { useSearch } from './use-search';
import { useSearchPresets } from './use-search-presets';
import { useSearchPresetsForm } from './use-search-presets-form';

const rankStep = 10;
const maxRank = 500;

export const SearchConfigEducation: React.FC = () => {
    const searchContext = useSearch();
    const config = searchContext.data.config.education;
    const onChange = searchContext.onConfigFieldChange('education');
    const readonly = searchContext.readonly;
    const savedSearch = searchContext.data.status && searchContext.data.status !== SearchStatus.Initial;

    const { requestPresets, updateSelectedPresets } = useSearchPresets();
    const { requestPresetsSave } = useSearchPresetsForm();

    const configRef = React.useRef(config);
    configRef.current = config;

    if (!config) return null;

    const { maxDegree, minDegree, schools, rankedSchools, fieldOfStudy, negativeTerms } = config;
    const { getConfirmation } = useModal();

    const handleUpdate = <T extends keyof SearchConfigEducationType>(field: T) => (
        val: SearchConfigEducationType[T]
    ) => {
        const updated = Object.assign({}, configRef.current, { [field]: val });
        onChange(updated);
    };

    const handleToggleSchoolPreset = (label: string, _1: string[], id: string) => {
        const newSchools = toggleSearchTerm({ isPreset: true, label, presetId: id }, configRef.current.schools);
        handleUpdate('schools')(newSchools);
        updateSelectedPresets(newSchools.filter((school) => school.isPreset));
    };

    const handleAddSchoolsPresetRequest = () => {
        requestPresets(
            handleToggleSchoolPreset,
            ['schools'],
            configRef.current.schools.filter((school) => school.isPreset)
        );
    };

    const handleRemoveSchoolNames = () => {
        getConfirmation(
            () => handleUpdate('schools')(undefined),
            'Are you sure you want to remove school names?',
            'Remove Confirmation'
        );
    };

    const handleAddSchoolNamesField = () => {
        handleUpdate('schools')([]);
    };

    const handleAddRankedSchoolsRequest = () => {
        const newRankedSchools = rankedSchools.concat([
            {
                country: 'USA',
                discipline: 'Overall',
                schoolMaxRank: maxRangeValue,
                schoolMinRank: 1
            }
        ]);
        handleUpdate('rankedSchools')(newRankedSchools);
    };

    const handleRemoveRankedSchoolsRequest = (index: number) => () => {
        const newRankedSchools = rankedSchools.slice(0, index).concat(rankedSchools.slice(index + 1));
        getConfirmation(
            () => handleUpdate('rankedSchools')(newRankedSchools),
            'Are you sure you want to remove school rank requirements?',
            'Remove Confirmation'
        );
    };

    const handleRankedSchoolSelect = (index: number) => <T extends keyof SearchRankedSchool>(field: T) => (
        val: SearchRankedSchool[T]
    ) => {
        const old = rankedSchools[index];
        const newRankedSchool = Object.assign({}, old, { [field]: val });
        const newRankedSchools = rankedSchools
            .slice(0, index)
            .concat([newRankedSchool, ...rankedSchools.slice(index + 1)]);
        handleUpdate('rankedSchools')(newRankedSchools);
    };

    const handleUpdateSchoolRank = (index: number) => (schoolMinRank: number, schoolMaxRank: number) => {
        const currentRankedSchool = rankedSchools[index];
        const newRankedSchool = Object.assign({}, currentRankedSchool, { schoolMaxRank, schoolMinRank });
        const newRankedSchools = rankedSchools
            .slice(0, index)
            .concat([newRankedSchool, ...rankedSchools.slice(index + 1)]);
        handleUpdate('rankedSchools')(newRankedSchools);
    };

    const handleUpdateDegreeRange = (min: number, max: number) => {
        const newMinDegree = degreeOptions[min].value;
        const newMaxDegree = degreeOptions[max].value;
        const updated = Object.assign({}, configRef.current, { minDegree: newMinDegree, maxDegree: newMaxDegree });
        onChange(updated);
    };

    const handleAddNegativeTerms = () => {
        handleUpdate('negativeTerms')([]);
    };

    const handleRemoveNegativeTerms = () => {
        getConfirmation(
            () => handleUpdate('negativeTerms')(undefined),
            'Are you sure you want to remove negative terms?',
            'Remove Confirmation'
        );
    };

    const handlePresetSaved = (preset: SearchTerm) => {
        handleUpdate('schools')([preset]);
    };

    const handleSaveAsPreset = () => {
        requestPresetsSave({
            groups: ['schools'],
            postSave: handlePresetSaved,
            selectedGroup: 'schools',
            terms: schools
        });
    };

    const getChipFromString = (val: string) => ({ isPreset: false, label: val });
    const getStringFromChip = (chip: SearchTerm) => chip.label;
    const getChipStyle = (negative: boolean) => (value: SearchTerm) => {
        const { isPreset } = value;
        const backgroundColor = negative && isPreset ? pink200 : negative ? red200 : isPreset ? blue200 : undefined;
        return { backgroundColor };
    };

    const getChipFromStringFos = (value: string, ds?: boolean) => {
        if (ds) {
            return { mappedGroup: true, value: fosOptions.find((fos) => fieldOfStudyName[fos] === value) };
        }
        return { mappedGroup: false, value };
    };
    const getStringFromChipFos = (chip: SearchFieldOfStudy) => {
        if (chip.mappedGroup) {
            return fieldOfStudyName[chip.value as FieldOfStudy];
        }
        return chip.value;
    };
    const getChipPropsFos = (chip: SearchFieldOfStudy) => {
        const { mappedGroup } = chip;
        const backgroundColor = mappedGroup ? blue200 : undefined;
        return { backgroundColor };
    };

    const schoolRankOptions = [];
    schoolRankOptions.push(1);
    for (let i = 1; i <= maxRank / rankStep; i++) {
        const value = i * rankStep;
        schoolRankOptions.push(value);
    }
    schoolRankOptions.push(maxRangeValue);

    const dataSource = fosOptions
        .filter((fos) => !fieldOfStudy?.find((f) => f.value === fos && f.mappedGroup))
        .map((s) => ({ mappedGroup: true, value: s }));

    const schoolRankOpts = schoolRankOptions.map((o) => ({
        name: o === maxRangeValue ? 'Max' : null,
        value: o
    }));
    const schoolRankField = rankedSchools?.map((r, i) => {
        const schoolRankActions = [
            { name: 'Add', onSelect: handleAddRankedSchoolsRequest },
            { name: 'Remove', onSelect: handleRemoveRankedSchoolsRequest(i) }
        ];
        return (
            <SearchGroup
                className="or-group search-ranked-school"
                key={i}
                actions={schoolRankActions}
                readonly={readonly}
            >
                <div className="search-requirements-field">
                    <label className="search-label">Rank</label>
                    <div className="search-requirements-value range-value">
                        <RangeSelectField
                            readonly={readonly}
                            max={r.schoolMaxRank}
                            min={r.schoolMinRank}
                            maxValueOpts={schoolRankOpts}
                            minValueOpts={schoolRankOpts}
                            onChange={handleUpdateSchoolRank(i)}
                        />
                    </div>
                </div>
                <div className="search-requirements-field">
                    <label className="search-label">Country</label>
                    <div className="search-requirements-value">
                        <SelectField
                            disabled={readonly}
                            selected={r.country}
                            options={allCountries}
                            getOptionLabel={startCase}
                            onSelect={handleRankedSchoolSelect(i)('country')}
                        />
                    </div>
                </div>
                <div className="search-requirements-field">
                    <label className="search-label">Discipline</label>
                    <div className="search-requirements-value">
                        <SelectField
                            disabled={readonly}
                            selected={r.discipline}
                            options={['CS', 'Overall']}
                            onSelect={handleRankedSchoolSelect(i)('discipline')}
                        />
                    </div>
                </div>
            </SearchGroup>
        );
    });

    const majorsSection =
        !fieldOfStudy || (savedSearch && fieldOfStudy?.length === 0) ? null : (
            <div className="search-requirements-field">
                <label className="search-label">Majors</label>
                <div className="search-requirements-value">
                    <TextfieldWithChips
                        chips={fieldOfStudy}
                        onChange={handleUpdate('fieldOfStudy')}
                        getChipFromString={getChipFromStringFos}
                        getStringFromChip={getStringFromChipFos}
                        getChipStyle={getChipPropsFos}
                        options={dataSource}
                        readonly={readonly}
                    />
                </div>
            </div>
        );

    const schoolRanksSection =
        rankedSchools?.length > 0 ? <div className="search-requirements-field">{schoolRankField}</div> : null;

    const schoolActions = [{ name: 'Presets', onSelect: handleAddSchoolsPresetRequest }];
    schoolActions.push({ name: 'Remove', onSelect: handleRemoveSchoolNames });
    if (schools?.length > 0) {
        schoolActions.push({ name: 'Save as Preset', onSelect: handleSaveAsPreset });
    }

    const schoolNamesSection =
        schools && (!savedSearch || schools.length > 0) ? (
            <div className="search-requirements-field">
                <SearchGroup readonly={readonly} actions={schoolActions}>
                    <label className="search-label">School</label>
                    <div className="search-requirements-value">
                        <SearchSchoolTextField
                            readonly={readonly}
                            chips={schools}
                            onChange={handleUpdate('schools')}
                            getChipFromString={getChipFromString}
                            getStringFromChip={getStringFromChip}
                            getChipStyle={getChipStyle(false)}
                        />
                    </div>
                </SearchGroup>
            </div>
        ) : null;

    const minDegreeSelected = degreeOptions.findIndex((d) => d.value === minDegree);
    const maxDegreeSelected = degreeOptions.findIndex((d) => d.value === maxDegree);
    const degreeItems = degreeOptions.map((d, i) => ({ name: d.name, value: i }));

    const degreeActions: SearchGroupAction[] = [];
    if (!rankedSchools || rankedSchools.length === 0) {
        degreeActions.push({ name: 'Add School Ranks', onSelect: handleAddRankedSchoolsRequest });
    }
    if (!schools) {
        degreeActions.push({ name: 'Add School Names', onSelect: handleAddSchoolNamesField });
    }

    degreeActions.push({ name: 'Add Negative Terms', onSelect: handleAddNegativeTerms });

    const negativeTermsSection = negativeTerms ? (
        <div className="search-requirements-field">
            <SearchGroup readonly={readonly} actions={[{ name: 'Remove', onSelect: handleRemoveNegativeTerms }]}>
                <label className="search-label">Negative Terms</label>
                <div className="search-requirements-value">
                    <TextfieldWithChips
                        readonly={readonly}
                        chips={negativeTerms}
                        hintText="Add negative term"
                        onChange={handleUpdate('negativeTerms')}
                        getChipFromString={getChipFromString}
                        getStringFromChip={getStringFromChip}
                        getChipStyle={getChipStyle(true)}
                    />
                </div>
            </SearchGroup>
        </div>
    ) : null;

    const degreeSection =
        minDegreeSelected !== -1 && maxDegreeSelected !== -1 ? (
            <div className="search-requirements-field">
                <SearchGroup readonly={readonly} actions={degreeActions}>
                    <label className="search-label">Degree Required</label>
                    <div className="search-requirements-value radio-buttons">
                        <RangeSelectField
                            min={minDegreeSelected}
                            max={maxDegreeSelected}
                            minValueOpts={degreeItems}
                            maxValueOpts={degreeItems}
                            readonly={readonly}
                            onChange={handleUpdateDegreeRange}
                            menuStyle={{ width: 192 }}
                        />
                    </div>
                </SearchGroup>
            </div>
        ) : null;

    return (
        <SearchRequirementsSection section="education">
            {degreeSection}
            {majorsSection}
            {schoolRanksSection}
            {schoolNamesSection}
            {negativeTermsSection}
        </SearchRequirementsSection>
    );
};
